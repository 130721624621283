import React, {useState} from 'react';
import {Badge} from '../ui/badge';
import {Pause, Play, Save} from 'lucide-react';
import {Workflow} from './WorkflowsTable';
import {Drawer} from 'vaul';
import {Button} from "../ui/button";
import {Card} from "../ui/card";
import {useSearchParams} from "react-router-dom";
import {Label} from "../ui/label";
import {Textarea} from "../ui/textarea";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import axios from '../../utility/customAxios';
import {JsonForms} from '@jsonforms/react';
import {materialRenderers} from '@jsonforms/material-renderers';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {cn} from "../ui/lib/utils";
import {useToast} from "../ui/use-toast"; // Import useToast hook
import {WorkflowRunsTable} from './WorkflowRunsTable';

interface WorkflowDetailsDrawerProps {
    workflow: Workflow;
    onWorkflowUpdate: () => void;
}

// Create a custom theme to match shadcn style
const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'hsl(var(--background-light))',
                        borderRadius: '0.5rem',
                        border: '1px solid hsl(var(--border))',
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        }
                    },
                    '& .MuiInputBase-input': {
                        color: 'hsl(var(--text-light))',
                        padding: '0.75rem',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'hsl(var(--text-medium))',
                        marginBottom: '0.5rem',
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                    backgroundColor: 'hsl(var(--background-light))',
                    borderRadius: '0.5rem',
                    border: '1px solid hsl(var(--border))',
                    color: 'hsl(var(--text-light))',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '& .MuiSelect-select': {
                        padding: '0.75rem',
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                    color: 'hsl(var(--text-medium))',
                    '&.Mui-checked': {
                        color: 'hsl(var(--primary))',
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                    '& .MuiSwitch-track': {
                        backgroundColor: 'hsl(var(--text-medium))',
                    },
                    '& .Mui-checked': {
                        '& .MuiSwitch-thumb': {
                            backgroundColor: 'hsl(var(--primary))',
                        },
                        '& + .MuiSwitch-track': {
                            backgroundColor: 'hsl(var(--primary))',
                            opacity: 0.5,
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: 'hsl(var(--text-medium))',
                    marginBottom: '0.5rem',
                    display: 'block',
                    '&.Mui-focused': {
                        color: 'hsl(var(--text-medium))',
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginBottom: '1.5rem',
                }
            }
        },
        // Add spacing for array fields
        MuiList: {
            styleOverrides: {
                root: {
                    '& > .MuiListItem-root': {
                        marginBottom: '1rem',
                    }
                }
            }
        },
        // Add spacing for object fields
        MuiGrid: {
            styleOverrides: {
                root: {
                    '& > .MuiGrid-item': {
                        marginBottom: '4px',
                    }
                }
            }
        }
    },
    spacing: 4,
    palette: {
        mode: 'dark',
        primary: {
            main: 'hsl(var(--primary))',
        },
        background: {
            default: 'hsl(var(--background))',
            paper: 'hsl(var(--background-light))',
        },
        text: {
            primary: 'hsl(var(--text-light))',
            secondary: 'hsl(var(--text-medium))',
        }
    }
});

function getWorkflowTypeColor(type: string) {
    switch (type.toLowerCase()) {
        case "rightsizing":
            return "bg-purple-500";
        case "alert_monitor":
            return "bg-red-500";
        case "sync_metrics":
            return "bg-blue-500";
        default:
            return "bg-primary";
    }
}

export function WorkflowDetailsDrawerButton({workflow, onWorkflowUpdate}: WorkflowDetailsDrawerProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [parameters, setParameters] = useState(workflow.parameters);
    const [rawParameters, setRawParameters] = useState(JSON.stringify(workflow.parameters, null, 2));
    const [activeTab, setActiveTab] = useState("form");
    const [isOpen, setIsOpen] = React.useState(false);
    const {toast} = useToast(); // Use the hook

    React.useEffect(() => {
        setIsOpen(searchParams.get("workflowId") === workflow.uuid);
    }, [searchParams]);

    const handleToggleWorkflow = async () => {
        try {
            if (workflow.enabled) {
                await axios.put(`/api/v1/workflow/pause`, {
                    workflowUuid: workflow.uuid
                });
            } else {
                await axios.put(`/api/v1/workflow/unpause`, {
                    workflowUuid: workflow.uuid
                });
            }
            onWorkflowUpdate();
        } catch (error) {
            console.error('Error toggling workflow:', error);
        }
    };

    const handleUpdateParameters = async () => {
        try {
            const paramsToUpdate = activeTab === "form" ? parameters : JSON.parse(rawParameters);
            await axios.put(`/api/v1/workflow/updateParameters`, {
                workflowUuid: workflow.uuid,
                parameters: paramsToUpdate
            });
            onWorkflowUpdate();
        } catch (error: any) {
            console.error('Error updating workflow parameters:', error);
            if (error.response?.data?.details) {
                // Show validation errors
                toast({
                    title: "Validation Error",
                    description: (
                        <div className="mt-2">
                            {error.response.data.details.map((err: string, i: number) => (
                                <p key={i} className="text-sm text-destructive">{err}</p>
                            ))}
                        </div>
                    ),
                    variant: "destructive",
                });
            } else {
                // Show generic error
                toast({
                    title: "Error",
                    description: error.response?.data?.error || "Failed to update parameters",
                    variant: "destructive",
                });
            }
        }
    };

    const handleFormChange = ({data}: any) => {
        setParameters(data);
        setRawParameters(JSON.stringify(data, null, 2));
    };

    const handleRawChange = (value: string) => {
        try {
            const parsed = JSON.parse(value);
            setParameters(parsed);
            setRawParameters(value);
        } catch (error) {
            // If the JSON is invalid, just update the raw value
            setRawParameters(value);
        }
    };

    return (
        <Drawer.Root modal={false} direction={"right"} open={isOpen}>
            <Button
                className="bg-primarytransparent border border-primary"
                onClick={() => {
                    setSearchParams(prev => {
                        prev.set("workflowId", workflow.uuid);
                        return prev;
                    });
                }}
            >
                <span className="sr-only">Open workflow details</span>
                Settings
            </Button>
            <Drawer.Portal>
                <Drawer.Content
                    data-vaul-no-drag
                    draggable={false}
                    onAbort={() => setSearchParams(prev => {
                        prev.delete("workflowId");
                        return prev;
                    })}
                    onDragEnd={() => setSearchParams(prev => {
                        prev.delete("workflowId");
                        return prev;
                    })}
                    onInteractOutside={() => setSearchParams(prev => {
                        prev.delete("workflowId");
                        return prev;
                    })}
                    className="bg-backgrounddark text-textmedium flex border-l border-border flex-col h-full w-[70%] mt-24 fixed bottom-0 right-0 z-50"
                >
                    <div className="p-6 space-y-6 overflow-y-auto">
                        {/* Header Section */}
                        <div className="border-b border-border pb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h1 className="text-2xl font-bold text-textlight">{workflow.name}</h1>
                                <div className={"flex space-x-2"}>
                                    <Button
                                        className="bg-primarytransparent border border-primary"
                                        onClick={async () => {
                                            try {
                                                await axios.put(`/api/v1/workflow/trigger`, {
                                                    workflowUuid: workflow.uuid
                                                });
                                                toast({
                                                    title: "Workflow Triggered",
                                                    description: "Workflow has been triggered successfully.",
                                                });
                                            } catch (error) {
                                                console.error('Error triggering workflow:', error);
                                            }
                                        }}>
                                        Trigger Workflow
                                    </Button>
                                    <Button
                                        className={cn("inline-flex items-center gap-2"
                                            , workflow.enabled ? "bg-redtransparenter border border-red-500" : "bg-secondarytransparenter border border-secondary"
                                        )}
                                        onClick={handleToggleWorkflow}
                                    >
                                        {workflow.enabled ? (
                                            <>
                                                <Pause className="h-4 w-4"/>
                                                Pause Workflow
                                            </>
                                        ) : (
                                            <>
                                                <Play className="h-4 w-4"/>
                                                Resume Workflow
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <Badge
                                    variant={workflow.enabled ? "default" : "secondary"}
                                    className={workflow.enabled ? "bg-green-500" : "bg-yellow-600"}
                                >
                                    {workflow.enabled ? 'Enabled' : 'Disabled'}
                                </Badge>
                                <Badge
                                    variant="secondary"
                                    className={getWorkflowTypeColor(workflow.workflowType)}
                                >
                                    {workflow.workflowType}
                                </Badge>
                            </div>
                        </div>

                        {/* Description Section */}
                        <div className="space-y-2">
                            <h3 className="text-lg font-semibold text-textlight">Description</h3>
                            <p className="text-sm leading-relaxed text-textmedium">{workflow.description}</p>
                        </div>

                        {/* Parameters Section */}
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold text-textlight">Parameters</h3>
                                <Button
                                    onClick={handleUpdateParameters}
                                    className="inline-flex items-center gap-2 bg-primarytransparent border border-primary"
                                >
                                    <Save className="h-4 w-4"/>
                                    Save Changes
                                </Button>
                            </div>
                            <Card className="p-4 bg-backgroundlight border-border">
                                <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                                    <TabsList className="grid w-full grid-cols-2">
                                        <TabsTrigger value="form">Form</TabsTrigger>
                                        <TabsTrigger value="json">JSON</TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="form" className="mt-4 space-y-4">
                                        <ThemeProvider theme={theme}>
                                            <JsonForms
                                                schema={workflow.parametersSchema}
                                                data={parameters}
                                                renderers={materialRenderers}
                                                onChange={handleFormChange}
                                            />
                                        </ThemeProvider>
                                    </TabsContent>
                                    <TabsContent value="json" className="mt-4">
                                        <div className="space-y-2">
                                            <Label>JSON Configuration</Label>
                                            <Textarea
                                                value={rawParameters}
                                                onChange={(e) => handleRawChange(e.target.value)}
                                                className="font-mono min-h-[300px]"
                                            />
                                        </div>
                                    </TabsContent>
                                </Tabs>
                            </Card>
                        </div>

                        {/* Workflow Runs Section */}
                        <WorkflowRunsTable workflowUUID={workflow.uuid}/>

                        {/* Metadata Section */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-textlight">Metadata</h3>
                            <Card className="p-4 bg-backgroundlight border-border">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label className="text-textmedium mb-1 block">Created At</Label>
                                        <p className="text-sm text-textlight">{new Date(workflow.createdAt).toLocaleString()}</p>
                                    </div>
                                    <div>
                                        <Label className="text-textmedium mb-1 block">Last Updated</Label>
                                        <p className="text-sm text-textlight">{new Date(workflow.updatedAt).toLocaleString()}</p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
