import React from 'react';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'guards/AuthGuard';
import ServiceCatalogue from "pages/ServiceCatalogue";
import Logs from "pages/Logs";
import Traces from "pages/Traces";
import {BaseViewTest} from "../pages/BaseView";
import {Service} from "../pages/Service";
import Billing from "../pages/Billing";
import DashboardView from "../pages/Dashboard";
import {DashboardSearch} from "../pages/DashboardSearch";
import {AlertCreation} from "../pages/AlertCreation";
import SlackRedirect from "../pages/SlackRedirect";
import {Alerts} from "../pages/Alerts";
import {Alert} from "../pages/Alert";
import {Settings} from "../pages/Settings";
import {ServiceMap} from "../pages/ServiceMap";
import PagerDutyRedirect from "../pages/PagerDutyRedirect";
import MetricExplorer from "../pages/MetricExplorer";
import AwsMarketplaceRedirect from "../pages/AwsMarketplaceRedirect";
import Infrastructure from "../pages/Infrastructure";
import {K8sEvents} from "../pages/K8sEvents";
import {Issues} from "../pages/Issues";
import HomePage from "../pages/HomePage";
import UptimeMonitorExample from "../pages/UptimeMonitorExample";
import PublicStatusPage from "../pages/PublicStatusPage";

const MainRoutes = {
    path: '/',
    children: [
        {
            path: 'status/:org/:slug',
            element: <PublicStatusPage />
        },
        {
            path: 'awsmarketplace-redirect',
            element: <AwsMarketplaceRedirect/>
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <HomePage/>
                },
                {
                    path: 'home',
                    element: <HomePage/>
                },
                {
                    path: 'slack-redirect',
                    element: <SlackRedirect/>
                },
                {
                    path: 'pagerduty-redirect',
                    element: <PagerDutyRedirect/>
                },
                {
                    path: 'service-catalog',
                    children: [
                        {
                            path: '',
                            element: <ServiceCatalogue/>
                        }
                    ],
                },
                {
                    path: '/service-catalogue',
                    element: <ServiceCatalogue/>
                },
                {
                    path: 'logs',
                    children: [
                        {
                            path: '',
                            element: <Logs/>
                        }
                    ],
                },
                {
                    path: 'traces',
                    children: [
                        {
                            path: '',
                            element: <Traces/>
                        }
                    ],
                },
                {
                    path: 'k8s-events',
                    children: [
                        {
                            path: '',
                            element: <K8sEvents/>
                        }
                    ],
                },
                {
                    path: 'issues',
                    children: [
                        {
                            path: '',
                            element: <Issues/>
                        }
                    ],
                },
                {
                    path: 'metric-explorer',
                    children: [
                        {
                            path: '',
                            element: <MetricExplorer/>
                        },
                    ],
                },
                {
                    path: 'alert',
                    children: [
                        {
                            path: '',
                            element: <Alert/>
                        }
                    ],
                },
                {
                    path: 'alerts',
                    children: [
                        {
                            path: '',
                            element: <Alerts/>,
                        },
                    ],
                },
                {
                    path: 'new-alert',
                    children: [
                        {
                            path: '',
                            element: <AlertCreation/>
                        }
                    ],
                },
                {
                    path: 'dashboards',
                    children: [
                        {
                            path: '',
                            element: <DashboardSearch/>
                        }
                    ],
                },
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: '',
                            element: <DashboardView/>
                        }
                    ]
                },
                {
                    path: 'baseview',
                    children: [
                        {
                            path: '',
                            element: <BaseViewTest/>
                        }
                    ],
                },
                {
                    path: 'service',
                    children: [
                        {
                            path: '',
                            element: <Service/>
                        }
                    ],
                },
                {
                    path: 'service-map',
                    children: [
                        {
                            path: '',
                            element: <ServiceMap/>
                        },
                    ],
                },
                {
                    path: 'infrastructure',
                    children: [
                        {
                            path: '',
                            element: <Infrastructure/>
                        },
                    ],
                },
                {
                    path: 'billing',
                    children: [
                        {
                            path: '',
                            element: <Billing/>
                        }
                    ],
                },
                {
                    path: 'settings',
                    children: [
                        {
                            path: '',
                            element: <Settings/>
                        },
                    ],
                },
                {
                    path: 'uptime-monitor-example',
                    children: [
                        {
                            path: '',
                            element: <UptimeMonitorExample />
                        }
                    ]
                },
            ]
        }
    ]
};

export default MainRoutes;
