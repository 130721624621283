import React from 'react';
import {Badge} from '../ui/badge';
import {BellOff, CheckCircle, XCircle} from 'lucide-react';
import {Issue} from './IssuesTable';
import {Drawer} from 'vaul';
import {Button} from "../ui/button";
import {Card} from "../ui/card";
import {IssueTimeline} from './IssueTimeline';
import {useSearchParams} from "react-router-dom";
import {MetricSpecifier, MultiMetoroMetricsChart} from "../Charts/MultiMetoroMetricsCharts";
import {MetricType} from "../../pages/MetricsTest";
import {RateFunctionType} from "../Dashboarding/widgets/MetricSelector";
import {ChartType} from "../Charts/MetoroChart";
import {MuteIssueDialog} from './MuteIssueDialog';

interface IssueDetailsDrawerProps {
    issue: Issue;
}

function RightsizingSpecificSection(props: { issue: Issue }) {
    if (props.issue.type !== "rightsizing") {
        return null;
    }

    const rightSizingIssue = props.issue.attributes.get("rightsizing_issue");

    if (rightSizingIssue === undefined) {
        return null;
    }
    const filters = new Map<string, string[]>();
    filters.set("container_name", [props.issue.attributes.get("container_name")!]);
    filters.set("service_name", [props.issue.attributes.get("service")!]);
    filters.set("environment", [props.issue.attributes.get("environment")!]);
    const kubernetesFilters = new Map<string, string[]>();
    kubernetesFilters.set("ServiceName", [props.issue.attributes.get("service")!]);
    kubernetesFilters.set("Environment", [props.issue.attributes.get("environment")!]);
    kubernetesFilters.set("Kind", ["Pod"]);

    const metricSpecifiers: MetricSpecifier[] = []
    if (rightSizingIssue.includes("cpu")) {
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_cpu_usage_seconds_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [{
                id: "1",
                functionType: RateFunctionType.PerSecond
            }],
            aggregation: "avg",
            visualization: {
                displayName: "Avg container CPU Usage",
            }
        })
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_cpu_usage_seconds_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [{
                id: "1",
                functionType: RateFunctionType.PerSecond
            }],
            aggregation: "max",
            visualization: {
                displayName: "Max container CPU Usage",
            }
        })
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_cpu_usage_seconds_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [{
                id: "1",
                functionType: RateFunctionType.PerSecond
            }],
            aggregation: "min",
            visualization: {
                displayName: "Min container CPU Usage",
            }
        })
        if (rightSizingIssue.includes("limit")) {
            metricSpecifiers.push({
                metricType: MetricType.Metric,
                metricName: "container_resources_cpu_limit_cores",
                splits: [],
                filters: filters,
                excludeFilters: new Map<string, string[]>(),
                functions: [],
                aggregation: "avg",
                visualization: {
                    lineDash: [10, 10],
                    lineColor: "#ff6384",
                    lineDotColor: "#ff638400",
                    lineDotSize: 0,
                    displayName: "CPU Limit",
                }
            })
        }
        if (rightSizingIssue.includes("request")) {
            metricSpecifiers.push({
                metricName: "container_resources_cpu_requests_cores",
                metricType: MetricType.Kubernetes,
                jsonPath: "spec.containers[0].resources.requests.cpu",
                splits: [],
                filters: kubernetesFilters,
                excludeFilters: new Map<string, string[]>(),
                functions: [],
                aggregation: "avg",
                visualization: {
                    lineDash: [10, 10],
                    lineColor: "#ff6384",
                    lineDotColor: "#ff638400",
                    lineDotSize: 0,
                    displayName: "CPU Requests",
                }
            })
        }
    }
    if (rightSizingIssue.includes("memory")) {
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_memory_rss_bytes",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "avg",
            visualization: {
                displayName: "Avg Container Memory Usage",
            }
        })
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_memory_rss_bytes",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "max",
            visualization: {
                displayName: "Max Container Memory Usage",
            }
        })
        metricSpecifiers.push({
            metricType: MetricType.Metric,
            metricName: "container_resources_memory_rss_bytes",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "min",
            visualization: {
                displayName: "Min Container Memory Usage",
            }
        })
        if (rightSizingIssue.includes("limit")) {
            metricSpecifiers.push({
                metricType: MetricType.Metric,
                metricName: "container_resources_memory_limit_bytes",
                splits: [],
                filters: filters,
                excludeFilters: new Map<string, string[]>(),
                functions: [],
                aggregation: "avg",
                visualization: {
                    lineDash: [10, 10],
                    lineColor: "#ff6384",
                    lineDotColor: "#ff638400",
                    lineDotSize: 0,
                    displayName: "Memory Limit",
                }
            })
        }
        if (rightSizingIssue.includes("request")) {
            metricSpecifiers.push({
                metricName: "container_resources_memory_requests_bytes",
                metricType: MetricType.Kubernetes,
                jsonPath: "spec.containers[0].resources.requests.memory",
                splits: [],
                filters: kubernetesFilters,
                excludeFilters: new Map<string, string[]>(),
                functions: [],
                aggregation: "avg",
                visualization: {
                    lineDash: [10, 10],
                    lineColor: "#ff6384",
                    lineDotColor: "#ff638400",
                    lineDotSize: 0,
                    displayName: "Memory Requests",
                }
            })
        }
    }


    return (
        <MultiMetoroMetricsChart
            className={"bg-backgroundmedium h-[256px] flex grow shrink relative"}
            type={ChartType.Line}
            startTime={Math.floor(Date.now() / 1000) - 86400} endTime={Math.floor(Date.now() / 1000)}
            metricSpecifiers={
                metricSpecifiers
            }/>
    )
}

function OOMSpecificSection(props: { issue: Issue }) {
    if (props.issue.type !== "oom") {
        return null;
    }

    const filters = new Map<string, string[]>();
    filters.set("service_name", [props.issue.attributes.get("service")!]);
    filters.set("environment", [props.issue.attributes.get("environment")!]);
    const kubernetesFilters = new Map<string, string[]>();
    kubernetesFilters.set("ServiceName", [props.issue.attributes.get("service")!]);
    kubernetesFilters.set("Environment", [props.issue.attributes.get("environment")!]);
    kubernetesFilters.set("Kind", ["Pod"]);

    const metricSpecifiers: MetricSpecifier[] = [
        {
            metricType: MetricType.Metric,
            metricName: "container_oom_kills_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "sum",
            visualization: {
                displayName: "Container OOMs",
            }
        }
    ];

    return (
        <div>
            <h2 className={"text-xl mb-4 font-bold"}>OOM Kills for {props.issue.attributes.get("service")}</h2>
            <MultiMetoroMetricsChart
                className={"bg-backgroundmedium h-[256px] flex grow shrink relative"}
                type={ChartType.Bar}
                startTime={Math.floor(Date.now() / 1000) - 86400} endTime={Math.floor(Date.now() / 1000)}
                metricSpecifiers={metricSpecifiers}
            />
        </div>
    )
}

function CPUThrottlingSpecificSection(props: { issue: Issue }) {
    if (props.issue.type !== "cpu_throttling") {
        return null;
    }

    const filters = new Map<string, string[]>();
    filters.set("service_name", [props.issue.attributes.get("service")!]);
    filters.set("environment", [props.issue.attributes.get("environment")!]);

    const metricSpecifiers: MetricSpecifier[] = [
        {
            metricType: MetricType.Metric,
            metricName: "container_resources_cpu_throttled_seconds_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "sum",
            visualization: {
                displayName: "CPU Throttled Seconds",
                lineColor: "#ff6384",
                lineDotColor: "#ff6384",
            }
        },
        {
            metricType: MetricType.Metric,
            metricName: "container_resources_cpu_usage_seconds_total",
            splits: [],
            filters: filters,
            excludeFilters: new Map<string, string[]>(),
            functions: [],
            aggregation: "sum",
            visualization: {
                displayName: "CPU Usage Seconds",
                lineColor: "#36a2eb",
                lineDotColor: "#36a2eb",
            }
        }
    ];

    return (
        <div>
            <h2 className={"text-xl font-bold mb-4"}>
                Throttling for {props.issue.attributes.get("service")}
            </h2>
            <p className={"max-w-full"}>
                CPU Throttled Seconds shows the number of seconds that a container was suspended due to Kubernetes CPU limits.
                For example, if the throttled seconds is 30, that means that the application wanted to use more CPU for 30 seconds
                in that time period but was denied it because of the CPU limits.
            </p>
            <br/>
            <p className={"max-w-full"}>
                This might seem confusing at first if your CPU usage is under the limit. The default quota period in
                Kubernetes is 100 milliseconds. So if you define a CPU limit of 50 millicores for a pod, it sets up a
                Linux cgroup CPU quota of 5ms for each 100ms period. Therefore, if in one 100ms period the container
                needs to use more than 5 milliseconds of CPU, throttling will occur.
            </p>
            <br/>
            <p className={"mb-4"}>
                This is particularly problematic for services that primarily serve requests, as throttling manifests as increased
                latency.
            </p>
            <MultiMetoroMetricsChart
                className={"bg-backgroundmedium h-[256px] flex grow shrink relative"}
                type={ChartType.Line}
                startTime={Math.floor(Date.now() / 1000) - 86400}
                endTime={Math.floor(Date.now() / 1000)}
                metricSpecifiers={metricSpecifiers}
            />
        </div>
    );
}

export function IssueDetailsDrawerButton({issue}: IssueDetailsDrawerProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isOpen, setIsOpen] = React.useState(false);
    const [showMuteDialog, setShowMuteDialog] = React.useState(false);

    React.useEffect(() => {
        console.log("setting isOpen to", searchParams.get("issueId") === issue.uuid);
        setIsOpen(searchParams.get("issueId") === issue.uuid);
    }, [searchParams]);

    function getStatusBadgeColor(open: boolean) {
        return open ? "bg-red-500" : "bg-primary";
    }

    function getSeverityBadgeColor(severity: string) {
        switch (severity) {
            case "high":
                return "bg-red-500";
            case "medium":
                return "bg-yellow-500";
            case "low":
                return "bg-primary";
            default:
                return "bg-primary";
        }
    }

    function getTypeBadgeColor(type: string) {
        switch (type) {
            case "rightsizing":
                return "bg-purple-500";
            default:
                return "bg-primary";
        }
    }

    return (
        <Drawer.Root modal={false} direction={"right"} open={isOpen}>
            <Button
                className={"bg-primarytransparent border-primary border"}
                size="sm"
                onClick={() => {
                    setSearchParams(prev => {
                        prev.set("issueId", issue.uuid);
                        return prev;
                    })
                }}
            >
                View Details
            </Button>
            <Drawer.Portal>
                <Drawer.Content
                    data-vaul-no-drag
                    draggable={false}
                    onAbort={() => setSearchParams(
                        prev => {
                            prev.delete("issueId");
                            return prev;
                        })}
                    onDragEnd={() => setSearchParams(
                        prev => {
                            prev.delete("issueId");
                            return prev;
                        })
                    }
                    onInteractOutside={() => setSearchParams(
                        prev => {
                            prev.delete("issueId");
                            return prev;
                        })
                    }
                    className="bg-backgrounddark text-textmedium flex border-l flex-col h-full w-[70%] mt-24 fixed bottom-0 right-0 z-50"
                >
                    <div className="p-6 space-y-6 overflow-y-auto">
                        {/* Header Section */}
                        <div className="border-b border-border pb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h1 className="text-2xl font-bold text-textlight">{issue.title}</h1>
                                {issue.attributes.get("service") && issue.attributes.get("environment") && (
                                    <Button
                                        className={"bg-primarytransparent border-primary border"}
                                        onClick={() => {
                                            window.open(`/service?service=${issue.attributes.get("service")}&tab=k8s&environment=${issue.attributes.get("environment")}&startEnd=`, '_blank');
                                        }}
                                    >
                                        View Service
                                    </Button>
                                )}
                            </div>
                            <div className="flex gap-2">
                                <Badge
                                    className={`${getStatusBadgeColor(issue.open === "true")} text-white rounded flex items-center gap-1 max-w-max px-3 py-1`}>
                                    {issue.open === "true" ? <XCircle className="w-4 h-4"/> :
                                        <CheckCircle className="w-4 h-4"/>}
                                    {issue.open === "true" ? "Open" : "Closed"}
                                </Badge>
                                <Badge
                                    className={`${getTypeBadgeColor(issue.type)} text-white rounded flex items-center gap-1 max-w-max px-3 py-1`}>
                                    {issue.type}
                                </Badge>
                                <Badge
                                    className={`${getSeverityBadgeColor(issue.severity)} text-white rounded flex items-center gap-1 max-w-max px-3 py-1`}>
                                    {issue.severity}
                                </Badge>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="text-textmedium hover:text-textlight"
                                    onClick={() => setShowMuteDialog(true)}
                                >
                                    <BellOff className="w-4 h-4 mr-1"/>
                                    Mute Similar
                                </Button>
                            </div>
                        </div>

                        {/* Description Section */}
                        <div className="space-y-2">
                            <h3 className="text-lg font-semibold text-textlight">Description</h3>
                            <p className="text-sm leading-relaxed w-max-full">{issue.description}</p>
                        </div>

                        {
                            <RightsizingSpecificSection issue={issue}/>
                        }

                        {
                            <OOMSpecificSection issue={issue}/>
                        }

                        {
                            <CPUThrottlingSpecificSection issue={issue}/>
                        }

                        {/* Attributes Section */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-textlight">Attributes</h3>
                            <Card className="p-4 bg-backgroundlight border-border">
                                <div className="grid grid-cols-2 gap-4">
                                    {Array.from(issue.attributes.entries()).map(([key, value]) => (
                                        <div key={key} className="space-y-1">
                                            <div className="text-sm font-medium text-textlight">{key}</div>
                                            <div className="text-sm">{value}</div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </div>

                        {/* IDs Section */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-textlight">Details</h3>
                            <Card className="p-4 bg-backgroundlight border-border">
                                <div className="space-y-3">
                                    <div className="grid grid-cols-[120px,1fr] gap-2">
                                        <div className="text-sm font-medium text-textlight">Issue ID</div>
                                        <div className="text-sm font-mono">{issue.uuid}</div>
                                    </div>
                                    <div className="grid grid-cols-[120px,1fr] gap-2">
                                        <div className="text-sm font-medium text-textlight">Workflow ID</div>
                                        <div className="text-sm font-mono">{issue.workflowUuid}</div>
                                    </div>
                                    {issue.workflowRunUuid && (
                                        <div className="grid grid-cols-[120px,1fr] gap-2">
                                            <div className="text-sm font-medium text-textlight">Run ID</div>
                                            <div className="text-sm font-mono">{issue.workflowRunUuid}</div>
                                        </div>
                                    )}
                                    <div className="grid grid-cols-[120px,1fr] gap-2">
                                        <div className="text-sm font-medium text-textlight">Organization</div>
                                        <div className="text-sm font-mono">{issue.organizationUuid}</div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        {/* Timeline Section */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-textlight">Firing Timeline</h3>
                            <IssueTimeline issue={issue}/>
                        </div>
                        <MuteIssueDialog
                            issue={issue}
                            open={showMuteDialog}
                            onOpenChange={setShowMuteDialog}
                        />
                    </div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
