export const ServiceNetworkTemplate = `
    {
      "widgetType": "Group",
      "title": "Service Network Overview",
      "position": {
        "x": 0,
        "y": 0,
        "w": 12,
        "h": 10
      },
      "children": [
        {
          "widgetType": "Markdown",
          "content": "### Network Usage - Bytes Sent\\nThis template serves as a starting point to look into network usage across your containers. Adapt this to your own needs!\\n\\n\\nNetwork Usage - Bytes Sent - This shows the bytes/second sent out of application containers. This is suitable to look at anything not in kube-system or running in privileged mode",
          "position": {
            "x": 0,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_bytes_sent_total",
              "aggregation": "sum",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "6f3b3c53-8d00-44f2-b246-4defcb0085a2",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Total Bytes Sent"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "excludeFilters": {
                "dataType": "Map",
                "value": []
              }
            }
          ],
          "type": "bar",
          "widgetType": "MetricChart",
          "title": "Total Service Network Usage - Bytes Sent",
          "position": {
            "x": 3,
            "y": 0,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_bytes_sent_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "5a5fc1e1-e28c-4e52-930f-321513f46f95",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max Pod Bytes Sent"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "excludeFilters": {
                "dataType": "Map",
                "value": []
              }
            },
            {
              "visualization": {
                "displayName": "Avg Pod Bytes Sent"
              },
              "metricName": "container_net_bytes_sent_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "avg",
              "metricType": "metric",
              "functions": [
                {
                  "id": "e769228f-9e71-4c75-9bc9-97a67ba66bca",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "excludeFilters": {
                "dataType": "Map",
                "value": []
              }
            },
            {
              "visualization": {
                "displayName": "Min Pod Bytes Sent"
              },
              "metricName": "container_net_bytes_sent_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "min",
              "metricType": "metric",
              "functions": [
                {
                  "id": "83aa42cc-8e44-40c5-bdfb-5086ebfd39cd",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service Network Usage - Bytes Sent - Aggregates",
          "position": {
            "x": 6,
            "y": 0,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_bytes_sent_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "5241ca5c-dedc-4744-813f-976de5d6c497",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max Pod Bytes Sent"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ],
              "excludeFilters": {
                "dataType": "Map",
                "value": []
              }
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service Network Usage - Bytes Sent - Top Pods",
          "position": {
            "x": 9,
            "y": 0,
            "w": 3,
            "h": 3
          }
        },
        {
          "widgetType": "Markdown",
          "content": "### Network Usage - Bytes Sent \\n\\nThis shows the bytes/second sent out of application containers. This is suitable to look at anything not in kube-system or running in privileged mode\\n",
          "position": {
            "x": 0,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "metricSpecifiers": [
            {
              "visualization": {
                "displayName": "Total Bytes Sent"
              },
              "metricName": "container_net_bytes_received_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "sum",
              "metricType": "metric",
              "functions": [
                {
                  "id": "fabba951-8b39-4c88-97ee-03ffa0ec9f88",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0
            }
          ],
          "type": "bar",
          "widgetType": "MetricChart",
          "title": "Total Service Network Usage - Bytes Received",
          "position": {
            "x": 3,
            "y": 3,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_bytes_received_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "a7721df2-ad21-4add-b13c-4a5041dbe407",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max Pod Bytes Received"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              }
            },
            {
              "visualization": {
                "displayName": "Avg Pod Bytes Received"
              },
              "metricName": "container_net_bytes_received_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "avg",
              "metricType": "metric",
              "functions": [
                {
                  "id": "876eb91f-7411-43ed-b877-c2d999f15fd7",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0
            },
            {
              "visualization": {
                "displayName": "Min Pod Bytes Received"
              },
              "metricName": "container_net_bytes_received_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "min",
              "metricType": "metric",
              "functions": [
                {
                  "id": "5b54cb43-24b0-483b-ba8f-86b9fc9e4e1d",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service Network Usage - Bytes Received - Aggregates",
          "position": {
            "x": 6,
            "y": 3,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_bytes_received_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "916fd39a-6807-4cf9-bc19-a2061da109a6",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max Pod Bytes Sent"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ]
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service Network Usage - Bytes Received - Top Pods",
          "position": {
            "x": 9,
            "y": 3,
            "w": 3,
            "h": 3
          }
        },
        {
          "widgetType": "Markdown",
          "content": "### Service TCP Connections Opened Per Second\\nThis shows the bytes/second sent out of application containers. This is suitable to look at anything not in kube-system or running in privileged mode\\n",
          "position": {
            "x": 0,
            "y": 6,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_tcp_successful_connects_total",
              "aggregation": "sum",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "55b9fb6a-0c96-4dfa-8bd1-a9c3b50e6694",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max TCP Connections Opened Per Second"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              }
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service TCP Connections Opened Per Second",
          "position": {
            "x": 3,
            "y": 6,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_tcp_successful_connects_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                null
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max TCP Connections Opened Per Second"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              }
            },
            {
              "visualization": {
                "displayName": "Avg TCP Connections Opened Per Second"
              },
              "metricName": "container_net_tcp_successful_connects_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "avg",
              "metricType": "metric",
              "functions": [
                null
              ],
              "bucketSize": 0
            },
            {
              "visualization": {
                "displayName": "Min TCP Connections Opened Per Second"
              },
              "metricName": "container_net_tcp_successful_connects_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [],
              "aggregation": "min",
              "metricType": "metric",
              "functions": [
                null
              ],
              "bucketSize": 0
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service TCP Connections Opened Per Second - Aggregates",
          "position": {
            "x": 6,
            "y": 6,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_net_tcp_successful_connects_total",
              "aggregation": "max",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [
                {
                  "id": "a92e0ff1-1616-4aeb-a226-b89c498a0bf1",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0,
              "visualization": {
                "displayName": "Max Pod Bytes Sent"
              },
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ]
            }
          ],
          "type": "line",
          "widgetType": "MetricChart",
          "title": "Service TCP Connections Opened Per Second - Top Pods",
          "position": {
            "x": 9,
            "y": 6,
            "w": 3,
            "h": 3
          }
        }
      ],
      "variables": [
        {
          "name": "environment",
          "key": "environment",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "service_name",
          "key": "service_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "pod_name",
          "key": "pod_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        }
      ]
    }`