import {BaseView} from "./BaseView";
import React, {useEffect, useState} from "react";
import {Input} from "../components/ui/input";
import {Lightbulb, SearchIcon} from "lucide-react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {v4 as uuidv4} from "uuid";
import {dashboardJsonReplacer} from "../components/Dashboarding/Dashboard";
import {MdContentCopy, MdDelete} from "react-icons/md";
import {toast} from "../components/ui/use-toast";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../components/ui/alert-dialog";

export interface DashboardSearchResult {
    id: string
    name: string
}

export interface DashboardSearchResponse {
    results: DashboardSearchResult[]
}

function DashboardSearchBar(props: {
    search: string,
    setSearch: (value: (((prevState: string) => string) | string)) => void
}) {
    const navigate = usePreserveQueryParamsNavigate()
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    const handleCreateDashboard = (config: any) => {
        const dashboardId = uuidv4();
        console.log("Creating dashboard with config:", config)
        const dashboardConfig = {
            id: dashboardId,
            name: config.title || "New Dashboard",
            dashboardJson: JSON.stringify({
                widgetType: "Group",
                title: config.title || "New Dashboard",
                position: {
                    "x": 0,
                    "y": 0,
                    "w": 12,
                    "h": 12
                },
                children: config.children || [],
                variables: config.variables || [],
            }, dashboardJsonReplacer),
            defaultTimeRange: "1h" // Default time range for new dashboards
        };

        axios.post('/api/v1/dashboard', dashboardConfig)
            .then(() => {
                navigate(`/dashboard?dashboardId=${dashboardId}`);
            })
            .catch((error) => {
                console.error('Failed to create dashboard:', error);
                // TODO: Add proper error handling/notification
            });
    };

    return <div className={"flex grow h-12 gap-4"}>
        <div
            className={"bg-backgroundmedium flex-none grow h-12 px-[7px] py-[9px] focus-within:border-primary hover:cursor-pointer hover:border-primary rounded border justify-between items-center gap-2 inline-flex"}>
            <Input
                placeholder={"Search for dashboards"}
                className={"bg-transparent focus-visible:ring-0 border-none border-0 ring-0 outline-0 text-textmedium"}
                value={props.search} onChange={(e) => {
                props.setSearch(e.target.value)
            }}/>
            <SearchIcon className={"text-textdark"}/>
        </div>
        <div className={""}>
            <Button className={"h-12 px-4 bg-secondarytransparenter border border-secondary text-textmedium"}
                    onClick={() => {
                        navigate("/dashboard")
                    }
                    }>Create Dashboard</Button>
        </div>
    </div>
}

function DashboardSearchResults(props: {
    dashboards: DashboardSearchResult[],
    onRefresh: () => void
}) {
    const navigate = usePreserveQueryParamsNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [dashboardToDelete, setDashboardToDelete] = useState<DashboardSearchResult | null>(null);

    const handleDelete = async (dashboard: DashboardSearchResult) => {
        try {
            await axios.delete(`/api/v1/dashboard`, {
                params: {
                    dashboardId: dashboard.id
                }
            });
            toast({
                title: "Dashboard deleted successfully",
                duration: 2000
            });
            props.onRefresh();
        } catch (error: any) {
            console.error('Failed to delete dashboard:', error);
            const errorMessage = error.response?.status === 403
                ? "You don't have permission to delete dashboards"
                : "Please try again";

            toast({
                title: "Failed to delete dashboard",
                description: errorMessage,
                variant: "destructive",
                duration: 2000
            });
        }
    };

    const handleDuplicate = async (dashboard: DashboardSearchResult) => {
        try {
            // First get the dashboard configuration
            const response = await axios.get(`/api/v1/dashboard`, {
                params: {
                    dashboardId: dashboard.id
                }
            });

            // Create a new dashboard with the same configuration
            const newDashboardId = uuidv4();
            await axios.post(`/api/v1/dashboard`, {
                id: newDashboardId,
                name: `${dashboard.name} (Copy)`,
                dashboardJson: response.data.dashboardJson,
                defaultTimeRange: response.data.defaultTimeRange
            });

            toast({
                title: "Dashboard duplicated successfully",
                duration: 2000
            });

            // Refresh the list
            props.onRefresh();
        } catch (error) {
            console.error('Failed to duplicate dashboard:', error);
            toast({
                title: "Failed to duplicate dashboard",
                description: "Please try again",
                variant: "destructive",
                duration: 2000
            });
        }
    };

    const handleDownload = async (dashboard: DashboardSearchResult) => {
        try {
            const response = await axios.get(`/api/v1/dashboard`, {
                params: {
                    dashboardId: dashboard.id
                }
            });

            // Get the widget configuration using recursiveSave
            const widget = {
                widgetType: "Group",
                title: response.data.name,
                position: {
                    x: 0,
                    y: 0,
                    w: 12,
                    h: 12
                },
                children: JSON.parse(response.data.dashboardJson).children || [],
                variables: JSON.parse(response.data.dashboardJson).variables || [],
            };

            const blob = new Blob([JSON.stringify(widget, dashboardJsonReplacer, 2)], {type: 'application/json'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${dashboard.name.toLowerCase().replace(/\s+/g, '-')}-dashboard.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            toast({
                title: "Dashboard configuration downloaded",
                duration: 2000
            });
        } catch (error) {
            console.error('Failed to download dashboard:', error);
            toast({
                title: "Failed to download dashboard",
                description: "Please try again",
                variant: "destructive",
                duration: 2000
            });
        }
    };

    const deleteDialog = (
        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
            <AlertDialogContent className={"text-textlight sm:max-w-[600px]"}>
                <AlertDialogHeader className="space-y-6">
                    <AlertDialogTitle className="text-2xl">Delete Dashboard</AlertDialogTitle>
                    <AlertDialogDescription className={"text-textmedium"}>
                        <div className={"flex flex-col space-y-4"}>
                            <div className={"text-textmedium text-base"}>Are you sure you want to delete
                                "{dashboardToDelete?.name}"? This action cannot be undone.
                            </div>
                            <div className="flex flex-col gap-6 rounded border border-border p-4">
                                <div
                                    className="flex items-center gap-3 bg-backgrounddark">
                                    <Lightbulb className={"w-6 h-6 text-primary flex-shrink-0"}/>
                                    <div className={"text-textmedium"}>You can download a backup of the dashboard
                                        configuration before deleting:
                                    </div>
                                </div>
                                <Button
                                    onClick={() => dashboardToDelete && handleDownload(dashboardToDelete)}
                                    className="w-fit bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 py-2 flex items-center gap-2"
                                >
                                    <MdContentCopy className="h-4 w-4"/>
                                    Download Configuration
                                </Button>
                            </div>
                        </div>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-8">
                    <AlertDialogCancel className={"border border-border rounded"} onClick={() => {
                        setShowDeleteDialog(false);
                        setDashboardToDelete(null);
                    }}>
                        Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                        onClick={() => {
                            if (dashboardToDelete) {
                                handleDelete(dashboardToDelete);
                            }
                            setShowDeleteDialog(false);
                            setDashboardToDelete(null);
                        }}
                        className="rounded bg-red-500/10 border border-red-500 text-textmedium hover:text-textlight"
                    >
                        Delete
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );

    return (
        <>
            {deleteDialog}
            <div
                className="mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
                <div
                    className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
                    <div className="h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl">Dashboard
                        Name
                    </div>
                </div>
                <div className="flex flex-col grow shrink">
                    {props.dashboards.map((dashboard) => {
                        return (
                            <div
                                key={dashboard.id}
                                className="w-full h-12 px-4 py-2 border-b border-backgroundmedium hover:bg-backgrounddark flex justify-between items-center gap-4"
                            >
                                <div
                                    onClick={() => navigate(`/dashboard?dashboardId=${dashboard.id}`)}
                                    className="grow h-full font-normal font-['Inter'] leading-8 text-textmedium hover:cursor-pointer hover:text-textlight"
                                >
                                    {dashboard.name}
                                </div>
                                <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                onClick={() => handleDuplicate(dashboard)}
                                                className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-2 h-8"
                                            >
                                                <MdContentCopy className="h-4 w-4"/>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Clone Dashboard</p>
                                        </TooltipContent>
                                    </Tooltip>

                                    <Button
                                        onClick={() => {
                                            setDashboardToDelete(dashboard);
                                            setShowDeleteDialog(true);
                                        }}
                                        className="bg-red-500/10 border border-red-500 text-textmedium hover:text-textlight rounded px-2 h-8"
                                        title="Delete Dashboard"
                                    >
                                        <MdDelete className="h-4 w-4"/>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    {props.dashboards.length === 0 && (
                        <div className={"flex justify-center items-center h-full text-textmedium flex-col gap-4"}>
                            <div className={"text-2xl text-textdark"}>No dashboards found</div>
                            <div className={"text-lg text-textdark"}>
                                To create a new dashboard, use the Create Dashboard button in the top right.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

function DashboardSearchComponent() {
    const [search, setSearch] = React.useState<string>("")
    const [dashboards, setDashboards] = React.useState<DashboardSearchResult[]>([])
    const [isLoading, setIsLoading] = React.useState(true)

    const fetchDashboards = () => {
        setIsLoading(true)
        let searchParams = ""
        if (search) {
            searchParams = `?query=${search}`
        }
        axios.get<DashboardSearchResponse>(`/api/v1/dashboardSearch${searchParams}`)
            .then((response) => {
                setDashboards(response.data.results)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDashboards()
    }, [search])

    return <div className={"flex flex-col grow shrink p-4"}>
        <div className={"h-max"}>
            <DashboardSearchBar search={search} setSearch={setSearch}/>
        </div>
        <div className={"flex grow shrink min-h-0"}>
            {isLoading ? (
                <div className="w-full flex justify-center items-center">
                    <div className="text-textmedium">Loading dashboards...</div>
                </div>
            ) : (
                <DashboardSearchResults
                    dashboards={dashboards}
                    onRefresh={fetchDashboards}
                />
            )}
        </div>
    </div>
}

const DashboardSearch = () => {
    return <BaseView title={"Dashboard Search"} disableClusterSelector={true}>
        <DashboardSearchComponent/>
    </BaseView>
}


export {
    DashboardSearch
}