import {MetricType} from "../../pages/MetricsTest";
import {MetricFunction} from "./widgets/MetricSelector";
import {ChartType} from "../Charts/MetoroChart";
import {MetricSpecifier} from "../Charts/MultiMetoroMetricsCharts";
import {Formula} from "../../types/formula";

type WidgetType = 'MetricChart'| 'Group' | 'Markdown' | "Stat"

// This is relative to the parent
interface WidgetPosition {
    x: number | undefined,
    y: number | undefined,
    w: number | undefined,
    h: number | undefined,
}

interface Widget {
    widgetType: WidgetType
    position: WidgetPosition | undefined
}

interface Variable {
    name: string
    key: string
    defaultValue: string
    defaultType: MetricType
    defaultMetricName?: string
    isOverrideable: boolean
}

// This defines all we need to rehydrate a group widget
interface GroupWidget extends Widget {
    title?: string,
    children: Widget[],
    variables?: Variable[]
}

// This defines all we need to rehydrate a metric chart widget
interface OldMetricChartWidget extends Widget {
    metricName: string;
    filters?: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits?: string[];
    aggregation: string;
    title?: string;
    type: ChartType;
    metricType: MetricType; // could be metric or trace.
    functions: MetricFunction[];
}

interface MultiMetricChartWidget extends Widget {
    title?: string
    type: ChartType;
    metricSpecifiers: MetricSpecifier[]
    formulas?: Formula[]
}

interface MarkdownWidget extends Widget {
    content: string
}

interface StatWidget extends Widget {
    title?: string
    metricSpecifier: MetricSpecifier
    reduceOption: ReduceOption
    mappings?: StatWidgetMapping[]
    precision?: number  // Number of decimal points to display
    fontSize?: string  // Font size for the displayed value (e.g. "text-xl", "text-2xl", "text-3xl", etc.)
}

type ReduceOption = "avg" | "sum" | "max" | "min" | "lastNotNull" | "last"
export const ReduceOptions = ["avg", "sum", "max", "min", "lastNotNull", "last"]

interface StatWidgetMapping {
    operator: ">" | "<" | "==" | ">=" | "<=" | "range"
    value: number
    from?: number
    to?: number
    color: string
    type: "background" | "text"
    textMapping?: string
}

export type {Widget, GroupWidget, OldMetricChartWidget, MultiMetricChartWidget, WidgetType, WidgetPosition, MarkdownWidget, Variable, StatWidget, ReduceOption, StatWidgetMapping}