export const NodeTemplate = `
{
  "widgetType": "Group",
  "title": "Node Overview",
  "position": {
    "x": 0,
    "y": 0,
    "w": 12,
    "h": 12
  },
  "children": [
    {
      "widgetType": "Markdown",
      "content": "### Node Overview\\nThis template gives you a good jumping off point to examine hosts in detail. If it looks like a host is playing up. Check the metrics in here.\\n\\nCpu Usage - This shows the number of cores used across the node and the pods running on the node\\n\\nCpu Delay shows the number of seconds that a container was suspended for from a cpu due to either cpu limits or resource starvation on the node. So for example, if the throttled seconds is 30, that means that the application wanted to use more cpu for 30 seconds in that time period but was denied it.\\n\\nThis is particularly bad in services the primarily serve requests as this manifests as increased latency.\\n",
      "position": {
        "x": 0,
        "y": 0,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "visualization": {
            "displayName": "Core Usage"
          },
          "metricName": "node_resources_cpu_usage_seconds_total",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [
            "mode"
          ],
          "aggregation": "sum",
          "metricType": "metric",
          "functions": [
            {
              "id": "02b2115b-c294-4aec-b65f-a87bae922702",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "CPU Core Usage",
      "position": {
        "x": 3,
        "y": 0,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_resources_cpu_usage_seconds_total",
          "aggregation": "max",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "57f45de1-1d2a-4386-999a-944187d7216f",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [
            "pod_name"
          ],
          "title": "Active TCP Connections"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Top CPU Core Usage By Pod",
      "position": {
        "x": 6,
        "y": 0,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_resources_cpu_delay_seconds_total",
          "aggregation": "max",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "57f45de1-1d2a-4386-999a-944187d7216f",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [
            "pod_name"
          ],
          "title": "Active TCP Connections"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Top CPU Delay By Pod",
      "position": {
        "x": 9,
        "y": 0,
        "w": 3,
        "h": 3
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### Memory \\nMemory usage shows the current memory usage across this node as well as pods on the node.\\n\\nAdditionally it also shows all pod OOMs on the selected node.\\n",
      "position": {
        "x": 0,
        "y": 3,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "visualization": {
            "displayName": "Total Memory"
          },
          "metricName": "node_resources_memory_total_bytes",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [],
          "aggregation": "sum",
          "metricType": "metric",
          "functions": [],
          "bucketSize": 0
        },
        {
          "visualization": {
            "displayName": "Available Memory"
          },
          "metricName": "node_resources_memory_available_bytes",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [],
          "aggregation": "sum",
          "metricType": "metric",
          "functions": [],
          "bucketSize": 0
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Total vs Available Memory",
      "position": {
        "x": 3,
        "y": 3,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "visualization": {
            "displayName": "Total Memory"
          },
          "metricName": "container_resources_memory_rss_bytes",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [
            "pod_name"
          ],
          "aggregation": "max",
          "metricType": "metric",
          "functions": [],
          "bucketSize": 0
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Top Memory Usage By Pod",
      "position": {
        "x": 6,
        "y": 3,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_oom_kills_total",
          "aggregation": "max",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "57f45de1-1d2a-4386-999a-944187d7216f",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          },
          "splits": [
            "pod_name"
          ],
          "title": "Active TCP Connections"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Top OOMs By Pod",
      "position": {
        "x": 9,
        "y": 3,
        "w": 3,
        "h": 3
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### Disk Read\\nDisk bytes read shows the number of bytes read per second by the node and all pods on the node\\n",
      "position": {
        "x": 0,
        "y": 6,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_resources_disk_read_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "13217b89-ab17-45fa-bb0a-ba4270efc104",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "device"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Disk Bytes Read Per Second By Device",
      "position": {
        "x": 3,
        "y": 6,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_resources_disk_io_time_seconds_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "device"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Disk Time Spent Reading By Device",
      "position": {
        "x": 6,
        "y": 6,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_resources_disk_read_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "pod_name"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Disk Bytes Read Per Second By Pod",
      "position": {
        "x": 9,
        "y": 6,
        "w": 3,
        "h": 3
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### Disk Write\\nDisk bytes written shows the number of bytes written per second by the node and all pods on the node\\n",
      "position": {
        "x": 0,
        "y": 9,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_resources_disk_written_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "13217b89-ab17-45fa-bb0a-ba4270efc104",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "device"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Disk Bytes Written Per Second By Device",
      "position": {
        "x": 3,
        "y": 9,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_resources_disk_write_time_seconds_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "device"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Disk Time Spent Writing By Device",
      "position": {
        "x": 6,
        "y": 9,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_resources_disk_written_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "pod_name"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Disk Bytes Read Per Second By Pod",
      "position": {
        "x": 9,
        "y": 9,
        "w": 3,
        "h": 3
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### Network Received\\nNetword bytes received shows the number of bytes received per second by the node and all pods on the node\\n",
      "position": {
        "x": 0,
        "y": 12,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_net_received_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Network Bytes / Second Received",
      "position": {
        "x": 3,
        "y": 12,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_bytes_received_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "pod_name"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Network Bytes / Second Received By Pod",
      "position": {
        "x": 6,
        "y": 12,
        "w": 3,
        "h": 3
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### Network Sent\\nNetword bytes sent shows the number of bytes sent per second by the node and all pods on the node\\n",
      "position": {
        "x": 0,
        "y": 15,
        "w": 3,
        "h": 3
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "node_net_transmitted_bytes_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Network Bytes / Second Sent",
      "position": {
        "x": 3,
        "y": 15,
        "w": 3,
        "h": 3
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_bytes_sent_total",
          "aggregation": "sum",
          "label": "Active Connections",
          "metricType": "metric",
          "functions": [
            {
              "id": "00b90e86-7602-49f8-b8ea-bce12d6e696a",
              "functionType": "perSecond"
            }
          ],
          "bucketSize": 0,
          "visualization": {},
          "splits": [
            "pod_name"
          ],
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "kubernetes.io/hostname",
                [
                  "$hostname"
                ]
              ]
            ]
          }
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Network Bytes / Second Sent By Pod",
      "position": {
        "x": 6,
        "y": 15,
        "w": 3,
        "h": 3
      }
    }
  ],
  "variables": [
    {
      "name": "hostname",
      "key": "kubernetes.io/hostname",
      "defaultType": "metric",
      "defaultMetricName": "node_resources_cpu_usage_seconds_total",
      "defaultValue": "*",
      "isOverrideable": true
    }
  ]
}`