import React, {useState} from "react";
import {getLogLeveLIndicatorColor} from "./utils";
import {format} from "date-fns";
import {customTheme2} from "../../K8sInfo/YamlViewer";
import SyntaxHighlighter from "react-syntax-highlighter";


function LogHeader(props: {
    logLevel: string,
    datetime: number
}) {
    const logLevelColor = getLogLeveLIndicatorColor(props.logLevel);
    return (
        <div className="w-full grow h-[22px] justify-start items-center gap-4 inline-flex">
            <div className={"p-1 rounded justify-center items-center flex " + logLevelColor}>
                <div className="text-textlight text-sm font-medium leading-[14px]">{props.logLevel.toUpperCase()}</div>
            </div>
            <div className="self-stretch justify-start items-center gap-2.5 flex">
                <div className="whitespace-nowrap text-textmedium text-sm font-medium leading-[14px]">
                    {format(new Date(props.datetime), "PPPppp")}
                </div>
            </div>
        </div>
    );
}

export function LogMessage(props: {
    colourBandOverride?: string,
    hideButtons?: boolean,
    logLevel: string,
    logMessage: string
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    const [wrapLines, setWrapLines] = useState(true);
    let logLevelIndicatorColor = getLogLeveLIndicatorColor(props.logLevel)
    if (props.colourBandOverride !== undefined) {
        logLevelIndicatorColor = props.colourBandOverride
    }
    return <div
        className="w-full grow min-h-[15vh] pr-1 max-h-64 bg-backgroundmedium rounded justify-start items-start gap-4 inline-flex">
        <div className="w-[3px] relative h-full">
            <div className={"w-[3px] h-full left-0 top-0 absolute rounded " + logLevelIndicatorColor}/>
        </div>
        <div className="flex w-full h-full pr-4 relative">
            {!props.hideButtons && <div className="absolute top-[-42px] right-0 p-1 flex gap-2 items-center pr-3">
                <label className="flex items-center gap-2 text-textmedium cursor-pointer">
                    <input
                        type="checkbox"
                        checked={wrapLines}
                        onChange={(e) => setWrapLines(e.target.checked)}
                        className="form-checkbox h-4 w-4"
                    />
                    Wrap lines
                </label>
                {/*    Icons to include or exclude log lines*/}
                <div
                    className="cursor-pointer text-textmedium bg-secondarytransparenter border-secondary border rounded p-1"
                    onClick={() => {
                        // If the log message is already in the regexes, don't add it again
                        let searchElement = props.logMessage;
                        // Escape re2 special characters
                        searchElement = searchElement.replace(/['-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                        if (props.regexes.includes(searchElement)) {
                            return;
                        }

                        // If the log message is in the exclude regexes, remove it from there
                        if (props.excludeRegexes.includes(searchElement)) {
                            let regexes = [...props.excludeRegexes]
                            regexes = regexes.filter((regex) => regex !== searchElement);
                            props.setExcludeRegexes(regexes);
                        }

                        let regexes = [...props.regexes]
                        regexes.push(searchElement);
                        props.setRegexes(regexes);
                    }}>
                    Include in search
                </div>
                <div className="cursor-pointer text-textmedium bg-redtransparenter border-red-500 border rounded p-1"
                     onClick={() => {
                         // If the log message is already in the exclude regexes, don't add it again
                         let searchElement = props.logMessage;
                         // Escape re2 special characters
                         searchElement = searchElement.replace(/['-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                         if (props.excludeRegexes.includes(searchElement)) {
                             return;
                         }

                         // If the log message is in the regexes, remove it from there
                         if (props.regexes.includes(searchElement)) {
                             let regexes = [...props.regexes]
                             regexes = regexes.filter((regex) => regex !== searchElement);
                             props.setRegexes(regexes);
                         }
                         let regexes = [...props.excludeRegexes]
                         regexes.push(searchElement);
                         props.setExcludeRegexes(regexes);
                     }}>
                    Exclude from search
                </div>
            </div>}
            <div className="w-full h-full">
                <SyntaxHighlighter
                    className="w-full h-full"
                    wrapLongLines={wrapLines}
                    // @ts-ignore
                    style={customTheme2}
                    language={"text"}
                    customStyle={{
                        margin: 0,
                        padding: '1rem',
                        height: '100%',
                        width: '100%',
                        overflow: 'auto',
                        backgroundColor: 'transparent',
                        whiteSpace: wrapLines ? 'pre-wrap' : 'pre',
                        wordBreak: wrapLines ? 'break-word' : 'normal',
                        overflowWrap: wrapLines ? 'break-word' : 'normal'
                    }}
                    >
                    {props.logMessage.replace("\\n", "\n\n")}
                </SyntaxHighlighter>
            </div>
        </div>
    </div>
}

function LogMessageContainer(props: {
    overrideColourBand?: string,
    hideExcludeSearchButtons?: boolean,
    logLevel: string,
    logMessage: string,
    datetime: number,
    regexes: string[],
    excludeRegexes: string[],
    setRegexes: (regexes: string[]) => void,
    setExcludeRegexes: (regexes: string[]) => void
}) {

    let log = props.logMessage;
    // Let's pretty print the log message if it's a JSON object
    // This doesnt work very well right now, but it's a good starting point
    try {
        const parsedLog = JSON.parse(props.logMessage);
        log = JSON.stringify(parsedLog, null, 2);
    } catch (e) {
        // Do nothing
    }


    return (<div className="w-full flex-col justify-start items-start gap-16 inline-flex">
            <div className="flex-col justify-start items-start flex">
                <LogHeader logLevel={props.logLevel} datetime={props.datetime}/>
            </div>
            <LogMessage
                hideButtons={props.hideExcludeSearchButtons}
                colourBandOverride={props.overrideColourBand}
                regexes={props.regexes}
                excludeRegexes={props.excludeRegexes}
                logLevel={props.logLevel} logMessage={log}
                setRegexes={props.setRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
            />
        </div>
    )
}

export default LogMessageContainer;