import axios from "../../utility/customAxios";

export interface UptimeMonitor {
    uuid: string;
    name: string;
    description: string;
    statusMetricName: string;
    responseTimeMetricName: string;
}

export interface StatusPage {
    uuid: string;
    name: string;
    description: string;
    slug: string;
    isPublic: boolean;
    monitorUuids: string[];
    organizationUuid: string;
}

export interface PublicStatusPageResponse {
    uuid: string;
    name: string;
    description: string;
    slug: string;
    isPublic: boolean;
    monitorUuids: string[];
    organizationUuid: string;
    monitors: UptimeMonitor[];
}

export interface CreateStatusPageRequest {
    name: string;
    description: string;
    slug: string;
    isPublic: boolean;
    monitorUuids: string[];
}

export interface UpdateStatusPageRequest {
    name: string;
    description: string;
    slug: string;
    isPublic: boolean;
    monitorUuids: string[];
}

export interface ListStatusPagesResponse {
    statusPages: StatusPage[];
}

export const createStatusPage = async (request: CreateStatusPageRequest): Promise<StatusPage> => {
    const response = await axios.post<StatusPage>("/api/v1/statusPage/create", request);
    return response.data;
};

export const updateStatusPage = async (uuid: string, request: UpdateStatusPageRequest): Promise<StatusPage> => {
    const response = await axios.put<StatusPage>(`/api/v1/statusPage/update?uuid=${encodeURIComponent(uuid)}`, request);
    return response.data;
};

export const getStatusPage = async (uuid: string): Promise<StatusPage> => {
    const response = await axios.get<StatusPage>(`/api/v1/statusPage?uuid=${encodeURIComponent(uuid)}`);
    return response.data;
};

export const getPublicStatusPage = async (org: string, slug: string): Promise<PublicStatusPageResponse> => {
    const response = await axios.get<PublicStatusPageResponse>(`/api/v1/statusPage/${org}/${slug}`);
    return response.data;
};

export const listStatusPages = async (): Promise<ListStatusPagesResponse> => {
    const response = await axios.get<ListStatusPagesResponse>("/api/v1/statusPage/list");
    return response.data;
};

export const deleteStatusPage = async (uuid: string): Promise<void> => {
    await axios.delete(`/api/v1/statusPage/delete?uuid=${encodeURIComponent(uuid)}`);
};

export const setOrganizationStatusPagePath = async (path: string): Promise<void> => {
    await axios.post("/api/v1/statusPage/path", { path });
}; 