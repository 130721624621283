import React, { useState } from 'react';
import { UptimeMonitorChart } from '../components/Charts/UptimeMonitorChart';
import { Button } from '../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';

export default function UptimeMonitorExample() {
    const [timeRange, setTimeRange] = useState('24h');

    console.log("UptimeMonitorExample");

    // Calculate time ranges
    const now = Date.now();
    const getStartTime = (range: string) => {
        switch (range) {
            case '1h':
                return now - 60 * 60 * 1000;
            case '6h':
                return now - 6 * 60 * 60 * 1000;
            case '24h':
                return now - 24 * 60 * 60 * 1000;
            case '7d':
                return now - 7 * 24 * 60 * 60 * 1000;
            case '30d':
                return now - 30 * 24 * 60 * 60 * 1000;
            default:
                return now - 24 * 60 * 60 * 1000;
        }
    };


    return (
        <div className="container mx-auto p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-textlight">Uptime Monitor Examples</h1>
                <div className="flex items-center gap-4">
                    <Select value={timeRange} onValueChange={setTimeRange}>
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select time range" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="1h">Last 1 hour</SelectItem>
                            <SelectItem value="6h">Last 6 hours</SelectItem>
                            <SelectItem value="24h">Last 24 hours</SelectItem>
                            <SelectItem value="7d">Last 7 days</SelectItem>
                            <SelectItem value="30d">Last 30 days</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="space-y-6">
                {/* Example 1: API Gateway */}
                <UptimeMonitorChart
                    title="API Gateway"
                    statusMetricName="uptime_monitor_prod2_status"
                    responseTimeMetricName="uptime_monitor_prod2_response_time"
                    startTime={Math.floor(getStartTime(timeRange) / 1000)}
                    endTime={Math.floor(now / 1000)}
                    org="example"
                />

                {/* Example 2: Database */}
                <UptimeMonitorChart
                    title="Database"
                    statusMetricName="uptime_monitor_prod2_status"
                    responseTimeMetricName="uptime_monitor_prod2_response_time"
                    startTime={Math.floor(getStartTime(timeRange) / 1000)}
                    endTime={Math.floor(now / 1000)}
                    org="example"
                />

                {/* Example 3: Authentication Service */}
                <UptimeMonitorChart
                    title="Authentication Service"
                    statusMetricName="uptime_monitor_prod2_status"
                    responseTimeMetricName="uptime_monitor_prod2_response_time"
                    startTime={Math.floor(getStartTime(timeRange) / 1000)}
                    endTime={Math.floor(now / 1000)}
                    org="example"
                />
            </div>

            <div className="mt-8 p-4 bg-backgroundlight rounded-lg border">
                <h2 className="text-lg font-semibold text-textmedium mb-4">About This Demo</h2>
                <p className="text-textdark mb-4">
                    This page demonstrates different uptime scenarios using the Visavail.js visualization library.
                    Each monitor shows:
                </p>
                <ul className="list-disc list-inside text-textdark space-y-2 ml-4">
                    <li>Current status (Operational/Degraded)</li>
                    <li>Uptime percentage</li>
                    <li>Timeline of uptime/downtime periods</li>
                    <li>Response time trends</li>
                </ul>
                <p className="text-textdark mt-4">
                    Use the time range selector above to view different time periods.
                </p>
            </div>
        </div>
    );
} 