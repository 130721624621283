import React, {useEffect} from "react";
import axios from "../../utility/customAxios";
import {Dialog, DialogContent} from "../../components/ui/dialog";
import {OnboardingFlow} from "../Onboarding";
import {Button} from "../../components/ui/button";
import {cn} from "../../components/ui/lib/utils";
import SyntaxHighlighter from "react-syntax-highlighter";
import {customTheme} from "../../components/K8sInfo/YamlViewer";
import AuthContext from "../../contexts/JWTContext";
import {Tooltip, TooltipContent, TooltipTrigger} from "../../components/ui/tooltip";

export function ClusterSettings() {
    const context = React.useContext(AuthContext);
    const [clusters, setClusters] = React.useState<string[]>([]);
    const [openAddClusterModal, setOpenAddClusterModal] = React.useState(false)
    const [triggerReload, setTriggerReload] = React.useState(false)
    const [openDeleteClusterModal, setOpenDeleteClusterModal] = React.useState<boolean[]>([])
    const [currentUser, setCurrentUser] = React.useState<{isAdmin: boolean} | null>(null);

    useEffect(() => {
        // Call backend to get the clusters
        axios.get("/api/v1/environments").then((response) => {
            setClusters(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload]);

    useEffect(() => {
        if (!context?.user?.email) return;
        axios.get("/api/v1/account/organization").then((response) => {
            const users = response.data.organizationMembers;
            const foundUser = users.find((user: any) => user.email === context?.user?.email);
            if (foundUser) {
                setCurrentUser(foundUser);
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [context?.user?.email]);

    const deletionCommands = `
    $ helm uninstall -n metoro metoro-exporter
    `
    return <div className={"m-4 grow shrink"}>
        <div className={"flex justify-between"}>
            <h2 className={"text-lg font-bold"}>Your Clusters</h2>
            <div className={"flex flex-col justify-center"}>
                <Dialog open={openAddClusterModal}>
                    <DialogContent className={"w-3/4"}
                                   onInteractOutside={() => setOpenAddClusterModal(false)}
                                   onEscapeKeyDown={() => setOpenAddClusterModal(false)}
                        // onBlur={() => setOpenAddClusterModal(false)}
                    >
                        <div
                            className={"blur-none flex min-w-0 min-h-0 grow shrink justify-center"}>
                            <OnboardingFlow addCluster={true}/>
                        </div>
                    </DialogContent>
                </Dialog>

                <Tooltip>
                    <TooltipTrigger>
                        <Button
                            onClick={() => setOpenAddClusterModal(true)}
                            disabled={!currentUser?.isAdmin}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Add
                            Cluster</Button>
                    </TooltipTrigger>
                    <TooltipContent side={"left"}>
                        {!currentUser?.isAdmin ? "Only admins can add a cluster" : "Add a new cluster for Metoro to monitor"}
                    </TooltipContent>
                </Tooltip>
            </div>
        </div>
        <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                <div
                    className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>Cluster
                    Name
                </div>
            </div>
            <div className={"bg-backgroundlight"}>
                {
                    clusters.map((cluster, index) => {
                        return <div
                            className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-between items-start gap-4 flex grow shrink`, index === clusters.length - 1 ? "border-b" : "")}>
                            <div
                                className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{cluster || "Unknown"}
                            </div>
                            {currentUser?.isAdmin && (
                                <div className={"flex flex-col justify-center"}>
                                    <Dialog open={openDeleteClusterModal[index]}>
                                        <DialogContent className={"w-max"}
                                        >
                                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                                <h3 className={"text-lg font-bold"}>Remove cluster</h3>
                                                <p className={"text-sm"}>If you would like to remove this cluster, please
                                                    run the following commands to uninstall Metoro from your cluster: </p>
                                                <div className={"border flex min-h-0 min-w-0 grow shrink rounded"}>

                                                    <SyntaxHighlighter
                                                        language="bash" customStyle={{width: "100%"}}
                                                        // @ts-ignore
                                                        style={customTheme}>
                                                        {deletionCommands}
                                                    </SyntaxHighlighter>
                                                </div>

                                                <div className={"flex justify-end gap-4"}>
                                                    <Button
                                                        onClick={() => setOpenDeleteClusterModal(prev => {
                                                            prev[index] = false
                                                            return [...prev]
                                                        })}
                                                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                    <Button
                                                        onClick={() => {
                                                            axios.delete("/api/v1/environment?envName=" + cluster).then(() => {
                                                                setTriggerReload(prev => !prev)
                                                            }).catch(e => console.error(e))
                                                            setOpenDeleteClusterModal(prev => {
                                                                prev[index] = false
                                                                return [...prev]
                                                            })
                                                        }}
                                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <Button
                                        onClick={() => {
                                            setOpenDeleteClusterModal(prev => {
                                                prev[index] = true
                                                return [...prev]
                                            })
                                        }}
                                        className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove
                                    </Button>
                                </div>
                            )}
                        </div>
                    })
                }
            </div>
        </div>
        <div className="flex-none mt-4">
            <a
                href="https://github.com/metoro-io/metoro-helm-charts"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary/80 text-sm flex items-center gap-2"
            >
                View Metoro Helm Charts
                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                </svg>
            </a>
        </div>
    </div>
}