import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Textarea } from './ui/textarea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from './ui/use-toast';
import { createStatusPage, updateStatusPage, StatusPage } from '../clients/metoro/status_page';
import { UptimeMonitor } from '../clients/metoro/uptime_monitor';
import { LoadingSpinner } from './LoadingSpinner';
import { Link } from 'react-router-dom';
import { cn } from './ui/lib/utils';
import { PlusIcon } from 'lucide-react';

export interface FormValues {
    name: string;
    description: string;
    slug: string;
    isPublic: boolean;
    monitorUuids: string[];
}

export interface StatusPageFormProps {
    onSubmit?: (data: FormValues) => void;
    initialData?: StatusPage;
    monitors: UptimeMonitor[];
    isEditing?: boolean;
    orgPath: string;
}

const SLUG_REGEX = /^[a-zA-Z0-9-_]+$/;

export function StatusPageForm({ onSubmit, initialData, monitors, isEditing = false, orgPath }: StatusPageFormProps) {
    const [loading, setLoading] = useState(false);
    const [slugError, setSlugError] = useState<string | null>(null);
    const [formValues, setFormValues] = useState<FormValues>({
        name: initialData?.name || '',
        description: initialData?.description || '',
        slug: initialData?.slug || '',
        isPublic: initialData?.isPublic || false,
        monitorUuids: initialData?.monitorUuids || [],
    });
    const [selectedMonitors, setSelectedMonitors] = useState<UptimeMonitor[]>([]);

    useEffect(() => {
        // Initialize selected monitors based on monitorUuids
        const selectedMonitors = formValues.monitorUuids
            .map(uuid => monitors.find(m => m.uuid === uuid))
            .filter((m): m is UptimeMonitor => m !== undefined);
        setSelectedMonitors(selectedMonitors);
    }, [monitors, formValues.monitorUuids]);

    const validateSlug = (slug: string): boolean => {
        if (!SLUG_REGEX.test(slug)) {
            setSlugError("Slug can only contain letters, numbers, hyphens, and underscores");
            return false;
        }
        setSlugError(null);
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateSlug(formValues.slug)) {
            return;
        }

        if (onSubmit) {
            onSubmit(formValues);
        }
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(selectedMonitors);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSelectedMonitors(items);
        setFormValues({
            ...formValues,
            monitorUuids: items.map(m => m.uuid),
        });
    };

    const availableMonitorsFiltered = monitors.filter(
        monitor => !selectedMonitors.some(m => m.uuid === monitor.uuid)
    );

    return (
        <form onSubmit={handleSubmit} className="space-y-8">
            <div className="space-y-6">
                <div className="space-y-2">
                    <Label className="text-textmedium block">Name</Label>
                    <Input
                        value={formValues.name}
                        onChange={e => setFormValues({ ...formValues, name: e.target.value })}
                        placeholder="My Status Page"
                        className="bg-backgrounddark border-border text-textmedium"
                        required
                    />
                </div>

                <div className="space-y-2">
                    <Label className="text-textmedium block">Description</Label>
                    <Textarea
                        value={formValues.description}
                        onChange={e => setFormValues({ ...formValues, description: e.target.value })}
                        placeholder="Describe what this status page is for..."
                        className="bg-backgrounddark text-textmedium border-border min-h-[100px]"
                    />
                </div>

                <div className="space-y-2">
                    <Label className="text-textmedium block">URL Slug</Label>
                    <Input
                        value={formValues.slug}
                        onChange={e => {
                            const newSlug = e.target.value;
                            setFormValues({ ...formValues, slug: newSlug });
                            if (newSlug) {
                                validateSlug(newSlug);
                            } else {
                                setSlugError(null);
                            }
                        }}
                        placeholder="my-status-page"
                        className={cn(
                            "bg-backgrounddark border-border",
                            slugError && "border-red-500"
                        )}
                        required
                    />
                    {slugError && (
                        <p className="text-sm text-red-500">{slugError}</p>
                    )}
                    {formValues.slug && !slugError && (
                        <div className="mt-2 text-sm text-textdark">
                            Status page URL: <Link to={`/status/${orgPath}/${formValues.slug}`} className="text-primary hover:underline">/status/{orgPath}/{formValues.slug}</Link>
                        </div>
                    )}
                    <p className="text-sm text-textdark">
                        Only letters, numbers, hyphens, and underscores are allowed.
                    </p>
                </div>

                <div className="flex items-center gap-2 py-2">
                    <Switch
                        checked={formValues.isPublic}
                        onCheckedChange={checked => setFormValues({ ...formValues, isPublic: checked })}
                    />
                    <Label className="text-textmedium">Make this status page public</Label>
                </div>

                <div className="space-y-6 pt-2">
                    <Label className="text-textmedium text-lg block">Monitors</Label>
                    <div className="grid grid-cols-2 gap-6">
                        <div className="space-y-3">
                            <div className="flex items-center justify-between">
                                <Label className="text-textmedium">Available Monitors</Label>
                                <span className="text-xs text-textdark">(Click to add)</span>
                            </div>
                            <div className="border border-border rounded bg-backgroundmedium p-4 min-h-[300px]">
                                {availableMonitorsFiltered.length === 0 ? (
                                    <div className="text-sm text-textdark text-center py-8">
                                        No more monitors available
                                    </div>
                                ) : (
                                    <div className="space-y-2">
                                        {availableMonitorsFiltered.map(monitor => (
                                            <div
                                                key={monitor.uuid}
                                                onClick={() => {
                                                    setSelectedMonitors([...selectedMonitors, monitor]);
                                                    setFormValues({
                                                        ...formValues,
                                                        monitorUuids: [...formValues.monitorUuids, monitor.uuid],
                                                    });
                                                }}
                                                className="flex items-center justify-between p-3 bg-backgroundlight border hover:bg-background/70 rounded cursor-pointer text-textmedium"
                                            >
                                                <div>
                                                {monitor.name}
                                                </div>
                                                <Button variant="ghost" size="sm" className="text-textdark">
                                                    <PlusIcon className="w-4 h-4" />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between mb-3">
                                <Label className="text-textmedium">Selected Monitors</Label>
                                <span className="text-xs text-textdark">(Drag to reorder)</span>
                            </div>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="selected-monitors">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={cn(
                                                "border border-border rounded bg-backgroundmedium p-4 min-h-[300px] relative",
                                                snapshot.isDraggingOver && "bg-backgroundmedium"
                                            )}
                                        >
                                            {selectedMonitors.length === 0 ? (
                                                <div className="text-sm text-textdark text-center py-8">
                                                    No monitors selected
                                                </div>
                                            ) : (
                                                selectedMonitors.map((monitor, index) => (
                                                    <Draggable
                                                        key={monitor.uuid}
                                                        draggableId={monitor.uuid}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={cn(
                                                                    "flex border bg-backgroundlight items-center justify-between p-3 mb-2 rounded cursor-grab active:cursor-grabbing text-textmedium",
                                                                    snapshot.isDragging && "shadow-lg relative z-50"
                                                                )}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                {monitor.name}
                                                                <Button
                                                                    type="button"
                                                                    variant="ghost"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        const newSelected = selectedMonitors.filter(
                                                                            m => m.uuid !== monitor.uuid
                                                                        );
                                                                        setSelectedMonitors(newSelected);
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            monitorUuids: newSelected.map(m => m.uuid),
                                                                        });
                                                                    }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>

            {formValues.isPublic && (
                <div className="rounded-lg border-2 border-yellow-600/50 bg-yellow-600/10 p-4 space-y-3 text-yellow-600">
                    <div className="font-semibold text-yellow-600">Warning: Public Metrics</div>
                    <div className="space-y-2">
                        <p>Making this status page public will make the following metrics publicly accessible:</p>
                        <ul className="list-disc pl-4 space-y-2 text-yellow-600">
                            {selectedMonitors.map(monitor => (
                                <li key={monitor.uuid}>
                                    <span className="font-medium">{monitor.name}:</span>
                                    <div className="pl-2 text-sm">
                                        <div>• {monitor.statusMetricName}</div>
                                        <div>• {monitor.responseTimeMetricName}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <p className="text-sm font-medium mt-4">Anyone on the internet will be able to view these metrics.</p>
                    </div>
                </div>
            )}

            <div className="flex justify-end">
                <Button 
                    type="submit" 
                    disabled={loading}
                    className="bg-primary text-textlight hover:bg-primary/90"
                >
                    {loading ? <LoadingSpinner /> : isEditing ? 'Update Status Page' : 'Create Status Page'}
                </Button>
            </div>
        </form>
    );
} 