import React, {useEffect, useRef, useState} from 'react'
import {GridStack} from "gridstack";
import {Button} from "../ui/button";
import {GroupWidget, MetricChartWidget, Widget} from "./internalwidgets";
import {recursiveSave} from "./utils";
import {Group} from "./widgets/Group";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";
import axios from "../../utility/customAxios";
import {v4 as uuidv4} from 'uuid';
import {MdModeEdit, MdOutlineSave} from "react-icons/md";
import {useDebouncedCallback} from "use-debounce";
import {NavigateOptions} from "react-router/dist/lib/context";
import {Dialog, DialogContent, DialogDescription, DialogTitle} from "../ui/dialog";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../ui/tooltip";
import {cn} from "../ui/lib/utils";


interface DashboardProps {
    setDashboardTitle?: (title: string) => void
    dashboardTitle?: string
}

function updateDashboard(searchParams: URLSearchParams,
                         setSearchParams: (nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void,
                         setInitialWidget: (value: (((prevState: (GroupWidget | undefined)) => (GroupWidget | undefined)) | GroupWidget | undefined)) => void,
                         setEditable: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                         setShowNewChartInjectedDialog: (value: (((prevState: boolean) => boolean) | boolean)) => void,
) {
    if (!searchParams.has("dashboardId")) {
        setSearchParams((prev) => {
            let urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set("dashboardId", uuidv4())
            return urlSearchParams
        })
    }
    const injectedChartJson = searchParams.get("addChart")
    const injectedChart = injectedChartJson ? JSON.parse(injectedChartJson, dashboardJsonReviver) as MetricChartWidget : undefined

    if (searchParams.get("dashboardId") !== "new") {
        axios.get(`/api/v1/dashboard?dashboardId=${searchParams.get("dashboardId")}`).then((response) => {
            const data = JSON.parse(response.data.dashboardJson, dashboardJsonReviver) as GroupWidget
            // If we have an injected chart, add it to the root group
            if (injectedChart) {
                data.children.push(injectedChart)
                setEditable(true)
                setShowNewChartInjectedDialog(true)
            }
            setInitialWidget(data)
            // Remove the injected chart from the URL
            setSearchParams((prev) => {
                let urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.delete("addChart")
                return urlSearchParams
            })
        }).catch((e) => {
            setInitialWidget({
                widgetType: "Group",
                title: "New Dashboard",
                position: {
                    "x": 0,
                    "y": 0,
                    "w": 12,
                    "h": 12
                },
                children: []
            } as GroupWidget)
        })
    }
}

export function ButtonWithTooltip({icon: Icon, tooltipText, onClick, highlightColour}: {
    icon: React.ElementType,
    tooltipText: string,
    onClick: () => void,
    highlightColour?: string
}) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        className={cn(`flex items-center justify-center text-textmedium hover:cursor-pointer`, "hover:text-secondary", highlightColour ? `hover:text-${highlightColour}` : "")}
                        variant={"ghost"}
                        size={"icon"}
                        onClick={onClick}>
                        <Icon className={`w-4 h-4 text`}/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent className={"bg-backgroundmedium border rounded text-textmedium p-1"}>
                    <p className="text-textmedium">{tooltipText}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}

function Dashboard(props: DashboardProps) {
    const [grids, setGrids] = React.useState<Map<string, GridStack>>(new Map<string, GridStack>())
    const [widgets, setWidgets] = React.useState<Map<string, Widget>>(new Map<string, Widget>())
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialWidget, setInitialWidget] = React.useState<GroupWidget>()
    const [editable, setEditable] = React.useState<boolean>(false)
    const [showNewChartInjectedDialog, setShowNewChartInjectedDialog] = React.useState<boolean>(false)
    const debouncedUpdateDasboard = useDebouncedCallback(updateDashboard, 10)
    const [gridStyle, setGridStyle] = useState({})

    // When the dashboard editing mode changes, update the gridstacks so that they can be edited / resized / moved
    useEffect(() => {
        grids.forEach((grid) => {
            grid.setStatic(!editable)
        })
    }, [editable])

    useEffect(() => {
        const gridStyleToSet = {
            backgroundColor: 'var(--background-dark)',
            backgroundSize: `calc(100% / 12) 128px`, // Hardcoded cell height for the grid cells.
            backgroundImage: `
      linear-gradient(to right, var(--primary-transparent) 1px, transparent 1px),
      linear-gradient(to bottom, var(--primary-transparent) 1px, transparent 1px)
    `,
        };
        if (editable) {
            setGridStyle(gridStyleToSet)
        } else {
            setGridStyle({})
        }
    }, [editable]);

    useEffect(() => {
        if (props.setDashboardTitle) {
            let title = (widgets.get("root") as GroupWidget)?.title
            if (title) {
                props.setDashboardTitle(title)
            }
        }
    }, [widgets])

    // Pull the configuration from the backend
    useEffect(() => {
        debouncedUpdateDasboard(searchParams, setSearchParams, setInitialWidget, setEditable, setShowNewChartInjectedDialog);
    }, [searchParams])
    if (!initialWidget) {
        return <div>Loading...</div>
    }

    let saveButton = (
        <ButtonWithTooltip
            icon={MdOutlineSave}
            tooltipText="Save current dashboard configuration"
            onClick={() => {
                let widget = recursiveSave("root", widgets, grids);
                let dashboardJson = JSON.stringify(widget, dashboardJsonReplacer);
                axios.post(`/api/v1/dashboard`, {
                    name: props.dashboardTitle,
                    id: searchParams.get("dashboardId"),
                    dashboardJson: dashboardJson,
                }).then(() => {
                    setEditable(false);
                }).catch((e) => {
                    console.error(e);
                });
            }}
        />
    );

    let editButton = (
        <ButtonWithTooltip
            icon={MdModeEdit}
            tooltipText="Toggle edit mode"
            onClick={() => {
                setEditable(!editable);
            }}
        />
    );
    return <div className={"flex flex-col grow overflow-y-auto"}>
        <Dialog open={showNewChartInjectedDialog}>
            <DialogContent
                className={"w-1/3 text-textmedium"}
                onInteractOutside={() => setShowNewChartInjectedDialog(false)}>
                <DialogTitle>
                    You added a new chart
                </DialogTitle>
                <DialogDescription>
                    The chart has been added to the bottom of the dashboard. Make sure you save to keep it.
                </DialogDescription>
            </DialogContent>
        </Dialog>
        <Group
            stylesheet={gridStyle}
            editable={editable}
            editButton={editButton}
            saveButton={saveButton}
            className={"w-full h-full"}
            id={"root"} widget={initialWidget}
            grids={grids} setGrids={setGrids} widgets={widgets} setWidgets={setWidgets}/>
    </div>
}

// @ts-ignore
export function dashboardJsonReplacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

// @ts-ignore
export function dashboardJsonReviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}


export {Dashboard};
export type {GroupWidget};
