import React, { useEffect, useState } from 'react';
import { Button } from "./ui/button";
import { cn } from "./ui/lib/utils";

interface RegexTesterProps {
    pattern: string;
    onClose: () => void;
    testString: string;
    setTestString: (value: string) => void;
    title?: string;
    description?: string;
}

export function RegexTester({
    pattern,
    onClose,
    testString,
    setTestString,
    title = "Test Your Pattern",
    description
}: RegexTesterProps) {
    const [isValidRegex, setIsValidRegex] = useState(true);
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        try {
            if (!pattern) {
                setIsValidRegex(true);
                setMatches(false);
                return;
            }

            const regex = new RegExp(pattern);
            setIsValidRegex(true);
            setMatches(regex.test(testString));
        } catch (error) {
            setIsValidRegex(false);
            setMatches(false);
        }
    }, [testString, pattern]);

    return (
        <div className="flex-1 border-l pl-8">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold text-textmedium">{title}</h3>
                <Button
                    onClick={onClose}
                    className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                >
                    Hide Tester
                </Button>
            </div>

            {description && (
                <p className="text-sm text-textdark mb-4">{description}</p>
            )}

            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-textmedium mb-2">
                        Test String
                    </label>
                    <textarea
                        value={testString}
                        onChange={(e) => setTestString(e.target.value)}
                        className="w-full min-h-[100px] p-3 bg-backgrounddark border border-border text-textmedium rounded font-mono"
                        placeholder="Enter your test string here"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-textmedium mb-2">
                        Result
                    </label>
                    <div className={cn(
                        "w-full p-3 border rounded font-medium",
                        matches 
                            ? "bg-green-500/10 border-green-500 text-green-500" 
                            : "bg-red-500/10 border-red-500 text-red-500"
                    )}>
                        {isValidRegex 
                            ? (matches ? "✓ Pattern matches" : "✗ Pattern does not match") 
                            : "Invalid regular expression"}
                    </div>
                </div>
            </div>
        </div>
    );
} 