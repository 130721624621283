import * as React from "react"
import { cn } from "./lib/utils"

interface VerticalTabsProps {
  data: Array<{
    title: string;
    content: React.ReactNode;
  }>;
  className?: string;
}

export function VerticalTabs({ data, className }: VerticalTabsProps) {
  const [activeTabId, setActiveTabId] = React.useState(0);

  return (
    <div className={cn("flex flex-col w-full h-[calc(100vh-280px)]", className)}>
      <div className="flex w-full h-full">
        <div className="w-48 relative shrink-0">
          <ul className="flex flex-col">
            {data.map((item, index) => (
              <li key={index} className="w-full">
                <button
                  onClick={() => setActiveTabId(index)}
                  className={cn(
                    "w-full text-left px-4 py-2 text-sm transition-colors relative h-9",
                    "hover:text-primary hover:bg-primarytransparent",
                    "block w-full",
                    activeTabId === index
                      ? "text-primary bg-primarytransparent"
                      : "text-textmedium bg-transparent"
                  )}
                >
                  <span className="block truncate">{item.title}</span>
                </button>
              </li>
            ))}
          </ul>
          <div
            className="absolute left-0 top-0 w-0.5 bg-primary transition-transform duration-200"
            style={{ 
              transform: `translateY(${activeTabId * 36}px)`,
              height: '36px'
            }}
            aria-hidden="true"
          />
        </div>
        <div className="flex-1 pl-8 flex flex-col w-full h-full">
          {data.map((item, index) => (
            <div
              key={index}
              className={cn(
                "transition-opacity duration-200 border rounded bg-backgroundlight w-full h-full overflow-auto",
                activeTabId === index ? "opacity-100" : "opacity-0 hidden"
              )}
            >
              <div className="p-4">
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
} 
