import React, {useEffect} from "react";
import axios from "../../utility/customAxios";
import {Button} from "../../components/ui/button";
import {cn} from "../../components/ui/lib/utils";
import {Dialog, DialogContent} from "../../components/ui/dialog";
import {KnownService} from "../AlertCreation";
import {
    DropDownItem, dropDownItemArrayToStringArray, MultiSelectorDropDown,
    stringArrayToDropDownItemArray
} from "../../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {Input} from "../../components/ui/input";
import {VerticalTabs} from "../../components/ui/vertical-tabs";
import {toast} from "../../components/ui/use-toast";
import AuthContext from "../../contexts/JWTContext";
import {Tooltip, TooltipContent, TooltipTrigger} from "../../components/ui/tooltip";


interface NamespaceFilterArgs {
    namespaces: string[]
    regex: string
}

interface ServiceFilterArgs {
    services: string[]
    regex: string
}

enum FilterType {
    Namespace = "namespace",
    Service = "service"
}

enum IncludeOrExclude {
    Include = "include",
    Exclude = "exclude"
}

// A filter is defined by the following
interface Filter {
    id: string
    // Empty means all environments
    environments: string[]
    // Whether the exclusion is inclusive or exclusive
    inclusionType: IncludeOrExclude
    // The type of exclusion
    filterType: FilterType
    // The args if the exclusion type is a namespace
    namespaceFilterArgs: NamespaceFilterArgs | undefined
    // The args if the exclusion type is a service
    serviceFilterArgs: ServiceFilterArgs | undefined
}

type TraceRedact = {
    uuid: string;
    environments: string[];
    serviceNames: string[];
    pattern: string;
    replacement: string;
}

export function IngestionSettings() {
    const tabData = [
        {
            title: "Log Filters",
            content: <LogExclusionSettings/>
        },
        {
            title: "Trace Redactions",
            content: <TraceRedactionSettings/>
        }
    ]

    return (
        <div className="flex flex-col space-y-4 p-4">
            <h2 className="text-xl font-bold">Ingestion Settings</h2>
            <div className={"text-textdark"}> You can add log filters or trace endpoint redactions for filter or redact sensitive data from being ingested.</div>
            <div className={"mt-4"}>
                <VerticalTabs data={tabData} />
            </div>
        </div>
    )
}

export function LogExclusionSettings() {
    const context = React.useContext(AuthContext);
    const [logFilters, setLogFilters] = React.useState<Filter[]>([]);
    const [currentUser, setCurrentUser] = React.useState<{isAdmin: boolean} | null>(null);

    // Delete modal
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([])
    const [triggerReload, setTriggerReload] = React.useState(false)
    const [openAddModal, setOpenAddModal] = React.useState(false)

    useEffect(() => {
        axios.get("/api/v1/logFilters").then((response) => {
            setLogFilters(response.data.logFilters)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload])

    useEffect(() => {
        if (!context?.user?.email) return;
        axios.get("/api/v1/account/organization").then((response) => {
            const users = response.data.organizationMembers;
            const foundUser = users.find((user: any) => user.email === context?.user?.email);
            if (foundUser) {
                setCurrentUser(foundUser);
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [context?.user?.email]);

    return (
        <div className={"m-4"}>
            <div className={"flex justify-between"}>
                <div>
                    <h2 className={"text-lg font-bold text-textmedium"}>Log Filters</h2>
                    <p className={"text-sm text-textdark"}>
                        Add filters to prevent logs from being exported from your cluster. This helps protect sensitive data and manage verbose logging.{" "}
                        <a
                            href="https://metoro.io/docs/logs/overview#log-exclusions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:text-primary/80 inline-flex items-center gap-1"
                        >
                            Learn more
                            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                            </svg>
                        </a>
                    </p>
                </div>
                <div className={"flex flex-col justify-center"}>
                    <NewLogFilterModal
                        openAddModal={openAddModal}
                        setOpenAddModal={setOpenAddModal}
                        setLogFilters={setLogFilters}
                        setTriggerReload={() => {
                            setTriggerReload(prev => !prev)
                        }}
                    />
                    <Tooltip>
                        <TooltipTrigger>
                            <Button
                                onClick={() => setOpenAddModal(true)}
                                disabled={!currentUser?.isAdmin}
                                className={"bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"}>Add Filter</Button>
                        </TooltipTrigger>
                        <TooltipContent side={"left"}>
                            {!currentUser?.isAdmin ? "Only admins can add a log filter" : "Add a new log filter"}
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
            {logFilters !== undefined && logFilters.length > 0 &&
                <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
                    <div
                        className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                        <div
                            className={`w-[100px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Type
                        </div>
                        <div
                            className={`w-[300px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Environments
                        </div>
                        <div
                            className={`w-[100px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Class
                        </div>
                        <div
                            className={`w-[300px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Entities
                        </div>
                        <div
                            className={`flex-1 min-w-0 h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Regex
                        </div>
                    </div>
                    <div className={"bg-backgroundlight"}>
                        {
                            logFilters.map((filter, index) => {
                                return <div
                                    className={cn(`w-full flex-none min-h-[48px] px-4 py-2 border-t justify-start items-start gap-4 flex grow shrink`, index === logFilters.length - 1 ? "border-b" : "")}>
                                    <div
                                        className={`w-[100px] font-normal font-['Inter'] py-2 text-textmedium text-md`}>{filter.inclusionType || "Unknown"}
                                    </div>
                                    <div
                                        className={`w-[300px] font-normal font-['Inter'] py-2 text-textmedium text-md`}>
                                        {filter.environments && filter.environments.length > 0
                                            ? filter.environments.map((env, i) => (
                                                <div key={i} className="leading-6">{env}</div>
                                            ))
                                            : "All"}
                                    </div>
                                    <div
                                        className={`w-[100px] font-normal font-['Inter'] py-2 text-textmedium text-md`}>
                                        {filter.filterType}
                                    </div>
                                    <div
                                        className={`w-[300px] font-normal font-['Inter'] py-2 text-textmedium text-md`}>
                                        {filter.filterType === FilterType.Service 
                                            ? (filter.serviceFilterArgs?.services && filter.serviceFilterArgs.services.length > 0
                                                ? filter.serviceFilterArgs.services.map((service, i) => (
                                                    <div key={i} className="leading-6">{service}</div>
                                                ))
                                                : "All")
                                            : (filter.namespaceFilterArgs?.namespaces && filter.namespaceFilterArgs.namespaces.length > 0
                                                ? filter.namespaceFilterArgs.namespaces.map((namespace, i) => (
                                                    <div key={i} className="leading-6">{namespace}</div>
                                                ))
                                                : "All")}
                                    </div>
                                    <div className={"flex-1 min-w-0 overflow-x-auto py-2"}>
                                        <div className="whitespace-nowrap">
                                            {filter.filterType === FilterType.Service ? filter.serviceFilterArgs?.regex : filter.namespaceFilterArgs?.regex}
                                        </div>
                                    </div>
                                    {currentUser?.isAdmin && (
                                        <div className={"flex-none flex flex-col justify-center"}>
                                            <Dialog open={openDeleteModal[index]}>
                                                <DialogContent className={"w-1/3"}>
                                                    <div className={"flex flex-col gap-4 text-textmedium"}>
                                                        <h3 className={"text-lg font-bold"}>Remove filter</h3>
                                                        <div className={"text-textmedium"}>Are you sure you want to
                                                            remove filter ?
                                                        </div>
                                                        <div className={"flex justify-end gap-4"}>
                                                            <Button
                                                                onClick={() => setOpenDeleteModal(prev => {
                                                                    prev[index] = false
                                                                    return [...prev]
                                                                })}
                                                                className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                            <Button
                                                                onClick={() => {
                                                                    axios.delete("/api/v1/logFilter?id=" + filter.id).then(() => {
                                                                        setTriggerReload(prev => !prev)
                                                                    }).catch(e => console.error(e))
                                                                    setOpenDeleteModal(prev => {
                                                                        prev[index] = false
                                                                        return [...prev]
                                                                    })
                                                                }}
                                                                className={"bg-red-500/20 border border-red-500 text-textmedium hover:text-textlight rounded"}>Remove</Button>
                                                        </div>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                            <Button
                                                onClick={() => {
                                                    setOpenDeleteModal(prev => {
                                                        prev[index] = true
                                                        return [...prev]
                                                    })
                                                }}
                                                className={"bg-red-500/20 h-[32px] border border-red-500 text-textmedium hover:text-textlight rounded"}>Remove</Button>
                                        </div>
                                    )}
                                </div>
                            })
                        }
                    </div>
                </div>}
            {logFilters.length <= 0 && <div
                className="w-full flex-none mt-4 p-4 rounded bg-backgroundlight border flex grow shrink">
                Add a log filter to prevent Metoro from exporting logs that match specific conditions.
            </div>}
        </div>
    );
}

function NewLogFilterModal(
    props: {
        openAddModal: boolean,
        setOpenAddModal: (open: boolean) => void,
        setLogFilters: (logFilters: Filter[]) => void,
        setTriggerReload: () => void
    }
) {
    const [services, setServices] = React.useState<KnownService[]>([])
    const [namespaces, setNamespaces] = React.useState<string[]>([])

    const [logFilter, setLogFilter] = React.useState<Filter>({
        id: "",
        environments: [],
        inclusionType: IncludeOrExclude.Exclude,
        filterType: FilterType.Service,
        namespaceFilterArgs: undefined,
        serviceFilterArgs: {
            services: [],
            regex: ".*"
        },
    })
    const [environments, setEnvironments] = React.useState<DropDownItem[]>([])
    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(stringArrayToDropDownItemArray(response.data.environments))
        }).catch((e) => {
            console.error(e)
        })
    }, [])


    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: logFilter.environments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    // Get the namespaces
    useEffect(() => {
        let url = "/api/v1/namespaces"
        if (logFilter.environments.length > 0) {
            url = url + "?environments=" + logFilter.environments.join(",")
        }
        axios.get(url).then((response) => {
            setNamespaces(response.data.namespaces)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    return (
        <Dialog open={props.openAddModal}>
            <DialogContent className={"w-1/2"} onInteractOutside={() => props.setOpenAddModal(false)}>
                <div className={"flex flex-col gap-4 text-textmedium"}>
                    <h3 className={"text-lg font-bold"}>Add a log filter</h3>
                    {/* Button group for inclusion and exclusion*/}
                    <p>
                        1. Select whether you want to include or exclude log lines that match the filter pattern.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Exclude ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Exclude
                                return {...prev}
                            })}>
                            Exclude</Button>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Include ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Include
                                return {...prev}
                            })}>
                            Include</Button>
                    </div>
                    <p>
                        2. Select the environments that you want this filter to apply to:
                    </p>
                    <MultiSelectorDropDown
                        selectorSingular={"Environment"}
                        possibleItems={environments}
                        selectedItems={stringArrayToDropDownItemArray(logFilter.environments)}
                        setSelectedItems={(selectedItems) => {
                            setLogFilter((prev) => {
                                prev.environments = dropDownItemArrayToStringArray(selectedItems)
                                return {...prev}
                            })
                        }}
                    />
                    <p>
                        3. Select whether you want to match all services in a set of namespaces or a specific set of
                        services.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Service ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Service) {
                                    return prev
                                }

                                prev.filterType = FilterType.Service
                                prev.serviceFilterArgs = {
                                    services: [],
                                    regex: prev.namespaceFilterArgs?.regex || ".*"
                                }
                                prev.namespaceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Services</Button>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Namespace ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Namespace) {
                                    return prev
                                }
                                prev.filterType = FilterType.Namespace
                                prev.namespaceFilterArgs = {
                                    namespaces: [],
                                    regex: prev.serviceFilterArgs?.regex || ".*"
                                }
                                prev.serviceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Namespaces</Button>
                    </div>
                    <div className={"text-textmedium"}>
                        <div>
                            4. Select the {logFilter.filterType === FilterType.Service ? "services" : "namespaces"} that
                            you want this exclusion pattern to be applied to:
                        </div>
                    </div>
                    {
                        logFilter.filterType === FilterType.Service &&
                        <MultiSelectorDropDown
                            selectorSingular={"Service"}
                            possibleItems={
                                services.map((service) => {
                                    return {
                                        displayName: service.displayName,
                                        value: service.serviceName
                                    }
                                })
                            }
                            selectedItems={stringArrayToDropDownItemArray(logFilter.serviceFilterArgs?.services || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.services = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }
                    {
                        logFilter.filterType === FilterType.Namespace &&
                        <MultiSelectorDropDown
                            selectorSingular={"Namespace"}
                            possibleItems={stringArrayToDropDownItemArray(namespaces)}
                            selectedItems={stringArrayToDropDownItemArray(logFilter.namespaceFilterArgs?.namespaces || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.namespaces = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }

                    <p className={"text-sm"}>5. Enter the exclusion pattern that will be used to exclude
                        matching log lines from ingestion: </p>
                    <Input
                        value={logFilter.filterType == FilterType.Namespace ? logFilter.namespaceFilterArgs?.regex : logFilter.serviceFilterArgs?.regex}
                        onChange={(e) => {
                            if (logFilter.filterType == FilterType.Namespace) {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                            if (logFilter.filterType == FilterType.Service) {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                        }}
                        type={"text"} placeholder={"Exclusion regex pattern"}
                        className={"border border-primary rounded p-2"}/>
                    <div className={"flex justify-end gap-4"}>
                        <Button
                            onClick={() => props.setOpenAddModal(false)}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                        <Button
                            onClick={() => {
                                axios.post("/api/v1/logFilter", {
                                    ...logFilter,
                                }).then(() => {
                                    props.setTriggerReload()
                                    props.setOpenAddModal(false)
                                }).catch((e) => {
                                    console.error(e)
                                })

                            }}
                            className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Save</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export function TraceRedactionSettings() {
    const context = React.useContext(AuthContext);
    const [traceRedacts, setTraceRedacts] = React.useState<TraceRedact[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([]);
    const [triggerReload, setTriggerReload] = React.useState(false);
    const [openAddModal, setOpenAddModal] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState<{isAdmin: boolean} | null>(null);

    useEffect(() => {
        axios.get("/api/v1/traceRedacts")
            .then((response) => {
                setTraceRedacts(response.data.traceRedacts);
                setOpenDeleteModal(new Array(response.data.traceRedacts.length).fill(false));
            })
            .catch((e) => {
                console.error("Failed to fetch trace redaction rules:", e);
                toast({
                    title: "Error",
                    description: "Failed to fetch trace redaction rules",
                    variant: "destructive"
                });
            });
    }, [triggerReload]);

    useEffect(() => {
        if (!context?.user?.email) return;
        axios.get("/api/v1/account/organization").then((response) => {
            const users = response.data.organizationMembers;
            const foundUser = users.find((user: any) => user.email === context?.user?.email);
            if (foundUser) {
                setCurrentUser(foundUser);
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [context?.user?.email]);

    const handleDelete = async (uuid: string) => {
        try {
            await axios.delete(`/api/v1/traceRedact?uuid=${uuid}`);
            setTriggerReload(prev => !prev);
            toast({
                title: "Success",
                description: "Trace redaction rule deleted successfully"
            });
        } catch (error) {
            console.error("Failed to delete trace redaction rule:", error);
            toast({
                title: "Error",
                description: "Failed to delete trace redaction rule",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="m-4">
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-bold text-textmedium">Trace Redaction Rules</h2>
                    <p className="text-sm text-textdark">
                        Configure rules to redact sensitive information from the http.url and http.path attributes in your traces.{" "}
                        <a
                            href="https://metoro.io/docs/traces/redaction"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:text-primary/80 inline-flex items-center gap-1"
                        >
                            Learn more
                            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                            </svg>
                        </a>
                    </p>
                </div>
                <Tooltip>
                    <TooltipTrigger>
                        <Button
                            onClick={() => setOpenAddModal(true)}
                            disabled={!currentUser?.isAdmin}
                            className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                        >
                            Add Rule
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side={"left"}>
                        {!currentUser?.isAdmin ? "Only admins can add a trace redact rule" : "Add a new trace redaction rule"}
                    </TooltipContent>
                </Tooltip>
            </div>

            {traceRedacts.length === 0 ? (
                <div className="w-full flex-none mt-4 p-4 rounded bg-backgroundlight border flex grow shrink">
                    Add a trace redaction rule to redact sensitive information from your traces.
                </div>
            ) : (
                <div className="border-l border-r border-t mt-4 rounded-t bg-backgroundlight">
                    <div className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                        <div className="w-[360px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl">
                            Environments
                        </div>
                        <div className="w-[360px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl">
                            Services
                        </div>
                        <div className="flex grow shrink h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl">
                            Pattern → Replacement
                        </div>
                    </div>

                    <div className="bg-backgroundlight">
                        {traceRedacts.map((redact, index) => (
                            <div
                                key={redact.uuid}
                                className={cn(
                                    "w-full flex-none min-h-[48px] px-4 py-2 border-t justify-start items-start gap-4 flex grow shrink",
                                    index === traceRedacts.length - 1 ? "border-b" : ""
                                )}
                            >
                                <div className="w-[360px] font-normal font-['Inter'] py-2 text-textmedium text-md">
                                    {redact.environments && redact.environments.length > 0
                                        ? redact.environments.map((env, i) => (
                                            <div key={i} className="leading-6">{env}</div>
                                          ))
                                        : "All"}
                                </div>
                                <div className="w-[360px] font-normal font-['Inter'] py-2 text-textmedium text-md">
                                    {redact.serviceNames && redact.serviceNames.length > 0
                                        ? redact.serviceNames.map((service, i) => (
                                            <div key={i} className="leading-6">{service}</div>
                                          ))
                                        : "All"}
                                </div>
                                <div className="flex grow shrink font-normal font-['Inter'] py-2 text-textmedium text-md">
                                    <div className="truncate">{redact.pattern}</div>
                                    <div className="text-textdark mx-2">→</div>
                                    <div className="truncate">{redact.replacement}</div>
                                </div>
                                {currentUser?.isAdmin && (
                                    <div className="flex flex-col justify-center">
                                        <Dialog open={openDeleteModal[index]}>
                                            <DialogContent className="w-1/3">
                                                <div className="flex flex-col gap-4 text-textmedium">
                                                    <h3 className="text-lg font-bold">Remove redaction rule</h3>
                                                    <div className="text-textmedium">
                                                        Are you sure you want to remove this redaction rule?
                                                    </div>
                                                    <div className="flex justify-end gap-4">
                                                        <Button
                                                            onClick={() => {
                                                                const newOpenDeleteModal = [...openDeleteModal];
                                                                newOpenDeleteModal[index] = false;
                                                                setOpenDeleteModal(newOpenDeleteModal);
                                                            }}
                                                            className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                handleDelete(redact.uuid);
                                                                const newOpenDeleteModal = [...openDeleteModal];
                                                                newOpenDeleteModal[index] = false;
                                                                setOpenDeleteModal(newOpenDeleteModal);
                                                            }}
                                                            className="bg-red-500/20 border border-red-500 text-textmedium hover:text-textlight rounded"
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                        <Button
                                            onClick={() => {
                                                const newOpenDeleteModal = [...openDeleteModal];
                                                newOpenDeleteModal[index] = true;
                                                setOpenDeleteModal(newOpenDeleteModal);
                                            }}
                                            className="bg-red-500/20 h-[32px] border border-red-500 text-textmedium hover:text-textlight rounded"
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <NewTraceRedactionModal
                openAddModal={openAddModal}
                setOpenAddModal={setOpenAddModal}
                setRedacts={setTraceRedacts}
                setTriggerReload={() => setTriggerReload(prev => !prev)}
            />
        </div>
    );
}

export function NewTraceRedactionModal(
    props: {
        openAddModal: boolean,
        setOpenAddModal: (open: boolean) => void,
        setRedacts: (redacts: TraceRedact[]) => void,
        setTriggerReload: () => void
    }
) {
    const [services, setServices] = React.useState<KnownService[]>([]);
    const [environments, setEnvironments] = React.useState<DropDownItem[]>([]);
    const [showRegexTester, setShowRegexTester] = React.useState(false);

    const [traceRedact, setTraceRedact] = React.useState<{
        environments: string[],
        serviceNames: string[],
        pattern: string,
        replacement: string
    }>({
        environments: [],
        serviceNames: [],
        pattern: "",
        replacement: ""
    });

    const [testString, setTestString] = React.useState("Example: password=secret123&token=abc123");
    const [redactedOutput, setRedactedOutput] = React.useState("");
    const [isValidRegex, setIsValidRegex] = React.useState(true);

    // Fetch environments
    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(stringArrayToDropDownItemArray(response.data.environments))
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    // Fetch services when environments change
    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: traceRedact.environments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [traceRedact.environments]);

    // Update redacted output whenever pattern, replacement, or test string changes
    useEffect(() => {
        try {
            if (!traceRedact.pattern) {
                setRedactedOutput(testString);
                setIsValidRegex(true);
                return;
            }

            const regex = new RegExp(traceRedact.pattern, 'g');
            setIsValidRegex(true);
            setRedactedOutput(testString.replace(regex, traceRedact.replacement || '$1'));
        } catch (error) {
            setIsValidRegex(false);
            setRedactedOutput(testString);
        }
    }, [testString, traceRedact.pattern, traceRedact.replacement]);

    const handleSave = async () => {
        try {
            const response = await axios.post("/api/v1/traceRedact", {
                environments: traceRedact.environments,
                serviceNames: traceRedact.serviceNames,
                pattern: traceRedact.pattern,
                replacement: traceRedact.replacement
            });
            props.setTriggerReload();
            props.setOpenAddModal(false);
            toast({
                title: "Success",
                description: "Trace redaction rule created successfully"
            });
        } catch (error) {
            console.error("Failed to create trace redaction rule:", error);
            toast({
                title: "Error",
                description: "Failed to create trace redaction rule",
                variant: "destructive"
            });
        }
    };

    return (
        <Dialog open={props.openAddModal}>
            <DialogContent 
                className={cn("bg-background", showRegexTester ? "w-[80%] max-w-[1400px]" : "w-1/2")}
                onInteractOutside={() => props.setOpenAddModal(false)}
            >
                <div className="flex gap-8">
                    <div className="flex-1 flex flex-col gap-4 text-textmedium">
                        <div className="flex justify-between items-center">
                            <h3 className="text-lg font-bold">Add a trace redaction rule</h3>
                            <Button
                                onClick={() => setShowRegexTester(prev => !prev)}
                                className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                            >
                                {showRegexTester ? "Hide Regex Tester" : "Test Regex"}
                            </Button>
                        </div>
                        
                        <p>1. Select the environments that you want this redaction rule to apply to:</p>
                        <MultiSelectorDropDown
                            selectorSingular={"Environment"}
                            possibleItems={environments}
                            selectedItems={stringArrayToDropDownItemArray(traceRedact.environments)}
                            setSelectedItems={(selectedItems) => {
                                setTraceRedact(prev => ({
                                    ...prev,
                                    environments: dropDownItemArrayToStringArray(selectedItems)
                                }))
                            }}
                        />
                        <p className={"text-xs text-textdark"}>
                            Leave empty to apply to all environments
                        </p>

                        <p>2. Select the services that you want this redaction rule to apply to:</p>
                        <MultiSelectorDropDown
                            selectorSingular={"Service"}
                            possibleItems={
                                services.map((service) => ({
                                    displayName: service.displayName,
                                    value: service.serviceName
                                }))
                            }
                            selectedItems={stringArrayToDropDownItemArray(traceRedact.serviceNames)}
                            setSelectedItems={(selectedItems) => {
                                setTraceRedact(prev => ({
                                    ...prev,
                                    serviceNames: dropDownItemArrayToStringArray(selectedItems)
                                }))
                            }}
                        />
                        <p className={"text-xs text-textdark"}>
                            Leave empty to apply to all services
                        </p>

                        <p className={"text-sm"}>3. Enter the regex pattern to match sensitive information:</p>
                        <Input
                            value={traceRedact.pattern}
                            onChange={(e) => setTraceRedact(prev => ({ ...prev, pattern: e.target.value }))}
                            type={"text"}
                            placeholder={"e.g., password=([^&\\s]+)"}
                            className={cn(
                                "border border-primary rounded p-2",
                                !isValidRegex && "border-red-500"
                            )}
                        />
                        {!isValidRegex && (
                            <p className="text-sm text-red-500">Invalid regular expression</p>
                        )}
                        <p className={"text-xs text-textdark"}>
                            Use regex capture groups to define what should be redacted. Example: password=([^&\s]+) will match "password=secret123"
                        </p>

                        <p className={"text-sm"}>4. Enter the replacement text:</p>
                        <Input
                            value={traceRedact.replacement}
                            onChange={(e) => setTraceRedact(prev => ({ ...prev, replacement: e.target.value }))}
                            type={"text"}
                            placeholder={"e.g., password=REDACTED"}
                            className={"border border-primary rounded p-2"}
                        />

                        <div className={"flex justify-end gap-4"}>
                            <Button
                                onClick={() => props.setOpenAddModal(false)}
                                className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSave}
                                disabled={!traceRedact.pattern || !traceRedact.replacement}
                                className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>
                                Save
                            </Button>
                        </div>
                    </div>

                    {showRegexTester && (
                        <div className="flex-1 border-l pl-8">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-bold text-textmedium">Test Your Pattern</h3>
                                <Button
                                    onClick={() => setShowRegexTester(prev => !prev)}
                                    className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                                >
                                    Hide Tester
                                </Button>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-textmedium mb-2">
                                        Test String
                                    </label>
                                    <textarea
                                        value={testString}
                                        onChange={(e) => setTestString(e.target.value)}
                                        className="w-full min-h-[100px] p-3 bg-backgrounddark border border-border text-textmedium rounded font-mono"
                                        placeholder="Enter your test string here"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-textmedium mb-2">
                                        Result with Redaction Applied
                                    </label>
                                    <div className="w-full min-h-[100px] p-3 bg-backgrounddark border border-border text-textmedium rounded font-mono whitespace-pre-wrap">
                                        {redactedOutput}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
