import LogMessageContainer from "components/SidePanel/Log/LogMessageContainer";
import React, {ReactNode} from "react";
import TagContainer, {TagTitle} from "components/SidePanel/Tags/TagContainer";
import {ServerCogIcon, ServerIcon, TagIcon} from "lucide-react";
import {Log} from "pages/Logs";
import {JSONTree} from "react-json-tree";
import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {cn} from "../../ui/lib/utils";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../ui/tooltip";
import {Command, CommandGroup, CommandItem, CommandList} from "../../ui/command";


const theme = {
    scheme: 'custom',
    author: 'ecola & chris',
    base00: '#182338',
    base01: '#1D273F',
    base02: '#2DD881',
    base03: '#C6D3E2',
    base04: '#182338',
    base05: '#C6D3E2',
    base06: '#C6D3E2',
    base07: '#C6D3E2',
    base08: '#C6D3E2',
    base09: '#C6D3E2',
    base0A: '#C6D3E2',
    base0B: '#C6D3E2',
    base0C: '#C6D3E2',
    base0D: '#3793FF',
    base0E: '#C6D3E2',
    base0F: '#C6D3E2',
};

function LogPanelContent(props: {
    log: Log
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    return <div data-vaul-no-drag
                className="h-full w-full p-4 bg-backgrounddark shadow flex-col justify-start items-center gap-8 inline-flex overflow-y-auto">
        <div className="flex-col w-full justify-start items-start gap-4 flex">
            <LogMessageContainer
                logLevel={props.log.severity} logMessage={props.log.message}
                datetime={props.log.time}
                regexes={props.regexes}
                setRegexes={props.setRegexes}
                excludeRegexes={props.excludeRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
            />
        </div>
        <div className="flex-col justify-start items-start gap-8 flex w-full">
            <TagContainer
                title={"Infrastructure Tags"}
                attributes={props.log.resourceAttributes}
                icon={<ServerIcon className={"text-textdark"}/>}
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
            />

            <div
                className={"p-4 bg-backgroundmedium rounded border flex-col justify-start items-start gap-2.5 inline-flex w-full"}>
                <div>
                    <TagTitle title={"Custom Tags"} icon={<TagIcon className={"text-textdark"}/>}/>
                </div>
                <JSONTree
                    // @ts-ignore
                    valueRenderer={(value) => <JsonValueRenderer value={value}
                                                                 logAttributes={props.log.logAttributes}
                                                                 filter={props.filter}
                                                                 setFilter={props.setFilter}
                                                                 excludeFilter={props.excludeFilter}
                                                                 setExcludeFilter={props.setExcludeFilter}/>}
                    theme={{
                        extend: theme,
                        // underline keys for literal values
                        valueLabel: {
                            fontWeight: 'bold',
                        },
                        nestedNodeLabel: ({style}, keyPath, nodeType, expanded) => ({
                            style: {
                                ...style,
                                fontWeight: 'bold',
                            },
                        }),
                    }} data={props.log.logAttributes}/>
            </div>
        </div>
    </div>
}

// @ts-ignore
function JsonValueRenderer(props: {
    logAttributes: Map<string, string>,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void
    setExcludeFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>,
    value: unknown
}) {
    const [isOpen, setIsOpen] = React.useState(false);

    const stringValue = (props.value as String).replace(/"/g, "")
    if (stringValue === "") {
        return ""
    }
    // @ts-ignore
    const innerElement = <div
        className={"text-left hover:bg-backgroundlight w-full border border-backgroundmedium my-1 px-4 py-1 hover:border-primary hover:cursor-pointer "}>
        {stringValue}
    </div>
    if (props.logAttributes === undefined || props.logAttributes === null) {
        return innerElement
    }

    // Reverse lookup the key for the value
    let tagKey, tagValue = "";
    let tagKeyPair = Object.entries(props.logAttributes).find(([key, value]) =>
        value.toString() === stringValue);
    if (tagKeyPair === undefined) {
        return innerElement
    } else {
        tagKey = tagKeyPair[0]
        tagValue = tagKeyPair[1]
    }

    return <Popover open={isOpen}>
        <PopoverTrigger
            onClick={(e) => {
                if (props.filter || props.excludeFilter) {
                    setIsOpen(prev => {
                        return !prev
                    })
                }
        }}>
            {innerElement}
        </PopoverTrigger>
        <PopoverContent
            onBlur={() => setIsOpen(false)}
            onKeyDown={
                (e) => {
                    if (e.key === "Escape") {
                        setIsOpen(false)
                    }
                }
            }
            className={"bg-backgroundmedium border rounded text-textmedium z-50"}>
            <Command>
                <CommandList>
                    <CommandGroup>
                        <CommandItem
                            className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                            key={tagKey + "-filter"}
                            onSelect={() => {
                                if (props.filter) {
                                    const newFilter = new Map(props.filter);
                                    if (!newFilter.has(tagKey)) {
                                        newFilter.set(tagKey, [tagValue]);
                                    } else {
                                        newFilter.get(tagKey)?.push(tagValue)
                                    }
                                    props.setFilter!(newFilter);
                                    setIsOpen(false);
                                }
                            }}
                        >
                            Filter by this tag
                        </CommandItem>
                        <CommandItem
                            className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                            key={tagKey + "-exclude"}
                            onSelect={() => {
                                if (props.excludeFilter) {
                                    const newFilter = new Map(props.excludeFilter);
                                    if (!newFilter.has(tagKey)) {
                                        newFilter.set(tagKey, [tagValue]);
                                    } else {
                                        newFilter.get(tagKey)?.push(tagValue)
                                    }
                                    props.setExcludeFilter!(newFilter);
                                    setIsOpen(false);
                                }
                            }}
                        >
                            Exclude this tag
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>

}

export default LogPanelContent;
