import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {dashboardJsonReviver} from "./Dashboarding/Dashboard";
import {
    createMetricChartPropsFromMetricChartWidget,
    MetoroMetricsChart,
    MetoroMetricsChartProps
} from "../pages/MetricsTest";
import {MultiMetricChartWidget, OldMetricChartWidget} from "./Dashboarding/internalwidgets";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {Dialog, DialogContent} from "./ui/dialog";
import {isMulti, MultiMetoroMetricsChart, MultiMetoroMetricsChartProps} from "./Charts/MultiMetoroMetricsCharts";

export function ExpandedChartDialog() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps | MultiMetoroMetricsChartProps | undefined>(undefined)
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    useEffect(() => {
        let fullScreenChart = searchParams.get("fullscreenChart")
        let injectedChart: MultiMetricChartWidget | OldMetricChartWidget | undefined = undefined

        if (fullScreenChart !== null) {
            injectedChart = JSON.parse(decodeURIComponent(fullScreenChart), dashboardJsonReviver) as MultiMetricChartWidget | OldMetricChartWidget
        }
        if (injectedChart === undefined) {
            return
        }

        let metoroChartProps: MetoroMetricsChartProps | MultiMetoroMetricsChartProps;
        if ('metricSpecifiers' in injectedChart) {
            // This is a MultiMetricChartWidget
            metoroChartProps = {
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                metricSpecifiers: injectedChart.metricSpecifiers,
                title: injectedChart.title,
                type: injectedChart.type,
                formulas: injectedChart.formulas
            };
        } else {
            // This is an OldMetricChartWidget
            metoroChartProps = createMetricChartPropsFromMetricChartWidget(injectedChart, Math.floor(timeRange.getStartEnd()[0].getTime() / 1000), Math.floor(timeRange.getStartEnd()[1].getTime() / 1000))
        }
        setChartProps(metoroChartProps)

        setIsDialogOpen(true)
    }, [searchParams]);

    return <Dialog open={isDialogOpen}>
        <DialogContent className={"w-[75vw] h-[75vh] p-0"} onInteractOutside={
            () => {
                setSearchParams(prev => {
                    let existing = new URLSearchParams(window.location.search)
                    existing.delete("fullscreenChart")
                    return existing
                })
                setIsDialogOpen(false)
            }}>
            {
                chartProps && isMulti(chartProps) && <MultiMetoroMetricsChart {...(chartProps as MultiMetoroMetricsChartProps)}/>
            }
            {
                chartProps && !isMulti(chartProps) && <MetoroMetricsChart {...(chartProps as MetoroMetricsChartProps)}/>
            }
        </DialogContent>
    </Dialog>
}

