import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {ChevronsUpDown} from "lucide-react";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";
import {cn} from "../ui/lib/utils";
import React, {useEffect} from "react";
import {DropDownItem} from "./MultiSelectorDropdown/MultiSelectorDropDown";


export interface SingleSelectorSearchableDropdownProps {
    noMatchString: string;
    possibleItems: DropDownItem[];
    selected: DropDownItem;
    setSelected: (item: DropDownItem) => void;
    searchPlaceholder: string;
}

export function SingleSelectorSearchableDropdown(props: SingleSelectorSearchableDropdownProps) {
    const [openDropdown, setOpenDropdown] = React.useState(false);
    const [matches, setMatches] = React.useState<DropDownItem[]>(props.possibleItems);
    const [currSearch, setCurrSearch] = React.useState(props.selected.displayName);

    useEffect(() => {
        if (currSearch !== undefined && currSearch !== "") {
            setMatches(props.possibleItems.filter((item) => item.displayName?.toLowerCase().includes(currSearch.toLowerCase())));
        } else {
            setMatches(props.possibleItems);
        }
    }, [currSearch]);

    return <div className={"items-center flex flex-col grow justify-center border-r"}>
        <Popover open={openDropdown} modal={true}>
            <PopoverTrigger asChild className={"flex grow"}>
                <div
                    role="combobox"
                    aria-expanded={openDropdown}
                    className="hover:cursor-pointer hover:text-white flex w-full items-center justify-between text-textmedium px-2 text-xl h-[48px] capitalize"
                    onClick={() => setOpenDropdown(!openDropdown)}
                >
                    {props.selected.displayName}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"} avoidCollisions={true}
                            onFocusOutside={() => setOpenDropdown(false)}
                            onEscapeKeyDown={() => setOpenDropdown(false)}
                            onInteractOutside={() => setOpenDropdown(false)}
                            className="p-0 text-textlight bg-backgroundmedium w-[50vw]">
                <Command>
                    <CommandInput placeholder={props.searchPlaceholder} value={currSearch} id={"free_text_input"}
                                  onChangeCapture={(e) => {
                                      setCurrSearch(e.currentTarget.value)
                                  }}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty>{props.noMatchString}</CommandEmpty>
                        <CommandGroup>
                            {
                                matches.map((match, index) => {
                                    return <CommandItem
                                        className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                        key={index} onSelect={() => {
                                        setOpenDropdown(false);
                                        props.setSelected(match);
                                    }}>
                                        {match.displayName}
                                    </CommandItem>
                                })
                            }
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
}