import { GroupWidget, MultiMetricChartWidget, StatWidget, StatWidgetMapping, Variable, Widget, MarkdownWidget } from '../internalwidgets';
import { ChartType } from '../../Charts/MetoroChart';
import { MetricSpecifier } from '../../Charts/MultiMetoroMetricsCharts';
import { MetricType } from '../../../pages/MetricsTest';
import { RateFunctionType } from '../widgets/MetricSelector';

interface GrafanaThreshold {
  color: string;
  value: number | null;
}

interface GrafanaMapping {
  type: 'value' | 'range';
  options: {
    [key: string]: {
      color: string;
      text?: string;
    } | {
      from?: number;
      to?: number;
      result: {
        color: string;
        text?: string;
      }
    }
  }
}

interface GrafanaPanel {
  type: string;
  title?: string;
  gridPos: {
    x: number;
    y: number;
    w: number;
    h: number;
  };
  targets?: Array<{
    expr: string;
    legendFormat?: string;
  }>;
  fieldConfig?: {
    defaults: {
      thresholds?: {
        mode?: string;
        steps: Array<{
          color: string;
          value: number | null;
        }>;
      };
      mappings?: Array<{
        type: 'value' | 'range' | 'special';
        options: {
          [key: string]: {
            color: string;
            text?: string;
            index?: number;
          }
        } | {
          match: string;
          result: {
            color: string;
            text: string;
            index?: number;
          }
        };
      }>;
      custom?: {
        showPoints?: 'never' | 'auto' | 'always';
      };
      unit?: string;
      displayName?: string;
    }
  };
  options?: {
    reduceOptions?: {
      calcs: string[];
    };
    textMode?: string;
    colorMode?: string;
    graphMode?: string;
    justifyMode?: string;
    content?: string;
  };
  panels?: GrafanaPanel[];
  collapsed?: boolean;
}

interface GrafanaVariable {
  name: string;
  type: string;
  query: {
    query: string;
    refId?: string;
  };
  current: {
    value: string | string[] | { value: string; text: string };
    text?: string;
  };
  options?: Array<{
    value: string;
    text: string;
  }>;
}

interface GrafanaDashboard {
  title: string;
  panels: GrafanaPanel[];
  templating?: {
    list: GrafanaVariable[];
  };
}

// Convert Grafana's reduce option to Metoro's format
function convertReduceOption(grafanaCalc: string): "avg" | "sum" | "max" | "min" | "lastNotNull" | "last" {
  switch (grafanaCalc) {
    case 'mean':
      return 'avg';
    case 'sum':
      return 'sum';
    case 'max':
      return 'max';
    case 'min':
      return 'min';
    case 'lastNotNull':
      return 'lastNotNull';
    case 'last':
      return 'last';
    default:
      return 'lastNotNull';
  }
}

// Convert Grafana regex filter pattern to array of explicit values
function convertRegexPattern(value: string): string[] | null {
  // If the pattern is .* in any form (with or without quotes, with or without ~), return null
  if (value.replace(/[~"]/g, '').trim() === '.*') {
    return null;
  }

  // Remove the leading ~ and any quotes and parentheses
  const cleanValue = value.replace(/^~"?\(?/, '').replace(/["\)]$/, '');

  // Handle patterns like "probe/default/(console|chat)-external"
  const parenthesesRegex = /^([^(]+)\(([^)]+)\)(.*)$/;
  const parenthesesMatch = cleanValue.match(parenthesesRegex);
  
  if (parenthesesMatch) {
    const prefix = parenthesesMatch[1];  // "probe/default/"
    const options = parenthesesMatch[2].split('|').map(o => o.trim());  // ["console", "chat"]
    const suffix = parenthesesMatch[3];  // "-external"
    
    return options.map(option => prefix + option + suffix);
  }

  // Handle simple patterns like "chat-ui|kong|console"
  return cleanValue.split('|').map(v => v.trim());
}

// Extract precision from round function if present
function extractPrecision(expr: string): number | undefined {
  const roundMatch = expr.match(/^round\((.*?),\s*([\d.]+)\)$/);
  if (roundMatch) {
    const precision = parseFloat(roundMatch[2]);
    // Convert decimal places to integer precision
    // e.g., 0.001 -> 3 decimal places, 0.01 -> 2 decimal places
    if (precision < 1) {
      return Math.abs(Math.log10(precision));
    }
    return 0; // if precision is >= 1, round to whole numbers
  }
  return undefined;
}

// Normalize the order of aggregation and rate functions to have rate on the outside
// and remove round functions as precision is handled in Metoro visualizations
function normalizePromQLExpression(expr: string): string {
  // First remove any round() functions
  const roundMatch = expr.match(/^round\((.*?),\s*[\d.]+\)$/);
  if (roundMatch) {
    expr = roundMatch[1];
  }

  // Match patterns like sum(rate(...)) or rate(sum(...))
  const sumRateMatch = expr.match(/^(sum|avg|min|max|count|topk|bottomk)\(rate\((.*?)\)\)$/);
  const rateSumMatch = expr.match(/^rate\((sum|avg|min|max|count|topk|bottomk)\((.*?)\)\)$/);

  if (sumRateMatch) {
    // Already in the form sum(rate(...)), no change needed
    return expr;
  } else if (rateSumMatch) {
    // Convert rate(sum(...)) to sum(rate(...))
    const [_, agg, innerExpr] = rateSumMatch;
    return `${agg}(rate(${innerExpr}))`;
  }

  return expr;
}

// Extract individual metrics from a complex expression
function extractMetrics(expr: string): string[] {
  // Find all metric names with their labels
  const metricPattern = /([a-zA-Z_][a-zA-Z0-9_]*)\{([^}]*)\}/g;
  const matches = Array.from(expr.matchAll(metricPattern));
  return matches.map(match => match[0]);
}

// Extract by clause dimensions from a PromQL expression
function extractByClauseDimensions(expr: string): string[] {
  const byDimensions = new Set<string>();
  
  // Match any 'by' clause in the expression
  const byClauseRegex = /by\s*\((.*?)\)/g;
  let match;
  
  while ((match = byClauseRegex.exec(expr)) !== null) {
    const dimensions = match[1].split(',').map(s => s.trim());
    dimensions.forEach(dim => byDimensions.add(dim));
  }
  
  return Array.from(byDimensions);
}

// Convert Grafana legend format to Metoro display name
function convertLegendFormat(legendFormat: string | undefined, metricName: string): string {
  if (!legendFormat) {
    return metricName;
  }

  // Replace Grafana style variables {{label}} with Metoro style $label
  return legendFormat.replace(/\{\{([^}]+)\}\}/g, (_, label) => `$${label}`);
}

// Convert Grafana expression to Metoro metric specifier
function convertExpressionToMetricSpecifier(expr: string, legendFormat?: string, showPoints?: 'never' | 'auto' | 'always'): MetricSpecifier | MetricSpecifier[] {
  // First normalize the expression to have rate on the outside if possible
  // and remove round functions
  expr = normalizePromQLExpression(expr);

  // Check if the expression contains multiple metrics
  const metrics = extractMetrics(expr);
  if (metrics.length > 1) {
    // Create a separate metric specifier for each metric in the expression
    return metrics.map(metricExpr => {
      const metricSpecifier: MetricSpecifier = {
        metricName: '',
        metricType: MetricType.Metric,
        filters: new Map(),
        excludeFilters: new Map(),
        splits: [],
        aggregation: 'avg',  // default aggregation
        functions: [],
        bucketSize: 0,
        visualization: {
          displayName: '',  // Always empty string, ignoring legendFormat
          lineDotSize: showPoints === 'never' ? 0 : 4  // Set lineDotSize based on showPoints
        }
      };

      // Extract metric name and labels from PromQL
      const matches = metricExpr.match(/([a-zA-Z_][a-zA-Z0-9_]*)\{([^}]*)\}/);
      if (matches) {
        metricSpecifier.metricName = matches[1];
        
        // Parse labels into filters
        const labels = matches[2].split(',').map(l => l.trim());
        labels.forEach(label => {
          const [key, value] = label.split('=').map(s => s.trim().replace(/"/g, ''));
          if (key && value) {
            // Skip env label, datasource-related labels, and .* patterns
            if (key !== 'env' && !key.includes('datasource')) {
              // First convert regex patterns if present
              const expandedValues = value.startsWith('~') ? 
                convertRegexPattern(value) :
                value.split('|');

              // Only add the filter if we have valid values (not .* pattern)
              if (expandedValues !== null) {
                // Then handle $__all conversion
                const finalValues = expandedValues.map(v => v.trim() === '$__all' ? '*' : v.trim());
                
                if (!metricSpecifier.filters) {
                  metricSpecifier.filters = new Map();
                }
                metricSpecifier.filters.set(key, finalValues);
              }
            }
          }
        });
      }

      return metricSpecifier;
    });
  }

  // If there's only one metric, use the existing logic
  const metricSpecifier: MetricSpecifier = {
    metricName: '',
    metricType: MetricType.Metric,
    filters: new Map(),
    excludeFilters: new Map(),
    splits: [],
    aggregation: 'avg',  // default aggregation
    functions: [],
    bucketSize: 0,
    visualization: {
      displayName: '',  // Always empty string, ignoring legendFormat
      lineDotSize: showPoints === 'never' ? 0 : 4  // Set lineDotSize based on showPoints
    }
  };

  // Extract all by clause dimensions
  const splitDimensions = extractByClauseDimensions(expr);
  if (splitDimensions.length > 0) {
    metricSpecifier.splits = splitDimensions;
  }

  // Check for aggregation function
  const aggMatch = expr.match(/^(sum|avg|min|max|count|topk|bottomk)\(/i);
  if (aggMatch) {
    metricSpecifier.aggregation = aggMatch[1].toLowerCase();
    
    // Handle special cases for topk and bottomk which might have parameters
    if (metricSpecifier.aggregation === 'topk' || metricSpecifier.aggregation === 'bottomk') {
      const paramMatch = expr.match(/^[^(]+\((\d+),\s*(.*)/);
      if (paramMatch) {
        metricSpecifier.bucketSize = parseInt(paramMatch[1]);
        expr = paramMatch[2];
      }
    }
  }

  // Extract metric name and labels from PromQL
  const matches = expr.match(/([a-zA-Z_][a-zA-Z0-9_]*)\{([^}]*)\}/);
  if (matches) {
    metricSpecifier.metricName = matches[1];
    
    // Parse labels into filters
    const labels = matches[2].split(',').map(l => l.trim());
    labels.forEach(label => {
      const [key, value] = label.split('=').map(s => s.trim().replace(/"/g, ''));
      if (key && value) {
        // Skip env label, datasource-related labels, and .* patterns
        if (key !== 'env' && !key.includes('datasource')) {
          // First convert regex patterns if present
          const expandedValues = value.startsWith('~') ? 
            convertRegexPattern(value) :
            value.split('|');

          // Only add the filter if we have valid values (not .* pattern)
          if (expandedValues !== null) {
            // Then handle $__all conversion
            const finalValues = expandedValues.map(v => v.trim() === '$__all' ? '*' : v.trim());
            
            if (!metricSpecifier.filters) {
              metricSpecifier.filters = new Map();
            }
            metricSpecifier.filters.set(key, finalValues);
          }
        }
      }
    });
  } else {
    // If no labels, just use the whole expression as metric name
    metricSpecifier.metricName = expr;
  }

  // Only convert rate function to perSecond, ignore increase as it's handled implicitly
  if (expr.includes('rate(')) {
    metricSpecifier.functions.push({
      id: crypto.randomUUID(),
      functionType: RateFunctionType.PerSecond
    });
  }

  // Clean up metric name by removing rate/increase functions
  if (metricSpecifier.metricName.includes('rate(') || metricSpecifier.metricName.includes('increase(')) {
    // Extract the actual metric name from inside rate() or increase()
    const functionMatch = metricSpecifier.metricName.match(/(?:rate|increase)\(([^)]+)\)/);
    if (functionMatch) {
      metricSpecifier.metricName = functionMatch[1];
    }
  }

  return metricSpecifier;
}

// Convert Grafana color names to hex values
function convertGrafanaColor(color: string): string {
  const colorMap: { [key: string]: string } = {
    'dark-green': '#2DD881',   // Updated green
    'dark-yellow': '#ffeb3b',  // Updated yellow
    'dark-red': '#f44336',     // Updated red
    'dark-blue': '#3793FF',    // Updated blue
    'dark-orange': '#FF780A',  // Keeping original
    'dark-purple': '#A352CC',  // Keeping original
    'semi-dark-green': '#2DD881',  // Updated green
    'semi-dark-yellow': '#ffeb3b', // Updated yellow
    'semi-dark-red': '#f44336',    // Updated red
    'semi-dark-blue': '#3793FF',   // Updated blue
    'semi-dark-orange': '#FF780A', // Keeping original
    'semi-dark-purple': '#A352CC', // Keeping original
    'light-green': '#2DD881',   // Updated green
    'light-yellow': '#ffeb3b',  // Updated yellow
    'light-red': '#f44336',     // Updated red
    'light-blue': '#3793FF',    // Updated blue
    'light-orange': '#FF9830',  // Keeping original
    'light-purple': '#B877D9',  // Keeping original
    'green': '#2DD881',    // Updated green
    'yellow': '#ffeb3b',   // Updated yellow
    'red': '#f44336',      // Updated red
    'blue': '#3793FF',     // Updated blue
    'orange': '#FF780A',   // Keeping original
    'purple': '#A352CC',   // Keeping original
    'super-light-green': '#2DD881',  // Updated green
    'super-light-yellow': '#ffeb3b', // Updated yellow
    'super-light-red': '#f44336',    // Updated red
    'super-light-blue': '#3793FF',   // Updated blue
    'super-light-orange': '#FFB357',  // Keeping original
    'super-light-purple': '#DFB4F4'   // Keeping original
  };

  // If it's already a hex color or not found in the map, return as is
  return colorMap[color.toLowerCase()] || color;
}

// Convert Grafana thresholds and mappings to Metoro format
function convertThresholdsAndMappings(panel: GrafanaPanel, displayName?: string): StatWidgetMapping[] {
  const mappings: StatWidgetMapping[] = [];
  const effectiveDisplayName = panel.fieldConfig?.defaults.displayName || displayName;

  // Helper function to format text mapping with displayName prefix
  const formatTextMapping = (text: string) => {
    if (effectiveDisplayName) {
      return `${effectiveDisplayName} ${text}`;
    }
    return text;
  };

  // First check for value mappings as they take precedence
  if (panel.fieldConfig?.defaults.mappings) {
    let hasValueMappings = false;
    let exactMappings: StatWidgetMapping[] = [];
    let rangeMappings: StatWidgetMapping[] = [];

    // First collect all mappings
    panel.fieldConfig.defaults.mappings.forEach(mapping => {
      if (mapping.type === 'value') {
        Object.entries(mapping.options).forEach(([value, config]) => {
          if ('color' in config) {
            hasValueMappings = true;
            exactMappings.push({
              operator: '==',
              value: Number(value),
              color: convertGrafanaColor(config.color),
              type: panel.options?.colorMode === 'background' ? 'background' : 'text',
              textMapping: formatTextMapping(config.text || '$value')
            });
          }
        });
      } else if (mapping.type === 'range') {
        const options = mapping.options as {
          from?: number;
          to?: number;
          result: {
            color: string;
            text?: string;
          }
        };
        
        hasValueMappings = true;
        rangeMappings.push({
          operator: 'range',
          value: 0, // This won't be used for range
          from: options.from ?? -Infinity,
          to: options.to ?? Infinity,
          color: convertGrafanaColor(options.result.color),
          type: panel.options?.colorMode === 'background' ? 'background' : 'text',
          textMapping: formatTextMapping(options.result.text || '$value')
        });
      }
    });

    if (hasValueMappings) {
      // Add exact value mappings first
      mappings.push(...exactMappings);
      // Add range mappings
      mappings.push(...rangeMappings);
      return mappings;
    }
  }

  // Only process thresholds if we didn't find any value mappings
  if (panel.fieldConfig?.defaults.thresholds?.steps) {
    // Sort steps by value in descending order, handling null values
    const sortedSteps = [...panel.fieldConfig.defaults.thresholds.steps]
      .filter(step => step.value !== null)
      .sort((a, b) => (b.value || 0) - (a.value || 0));

    // Add mappings in descending order (highest to lowest)
    sortedSteps.forEach((step, index) => {
      const nextStep = sortedSteps[index + 1];
      if (nextStep) {
        // Create a range mapping between this step and the next
        mappings.push({
          operator: 'range',
          value: 0, // Not used for range
          from: nextStep.value || 0,
          to: step.value || 0,
          color: convertGrafanaColor(step.color),
          type: panel.options?.colorMode === 'background' ? 'background' : 'text',
          textMapping: formatTextMapping('$value')
        });
      } else {
        // For the last step, create a "greater than" mapping
        mappings.push({
          operator: '>',
          value: step.value || 0,
          color: convertGrafanaColor(step.color),
          type: panel.options?.colorMode === 'background' ? 'background' : 'text',
          textMapping: formatTextMapping('$value')
        });
      }
    });

    // Add base state (if exists)
    const baseState = panel.fieldConfig.defaults.thresholds.steps.find(step => step.value === null);
    if (baseState) {
      mappings.push({
        operator: '>=',
        value: Number.NEGATIVE_INFINITY,
        color: convertGrafanaColor(baseState.color),
        type: panel.options?.colorMode === 'background' ? 'background' : 'text',
        textMapping: formatTextMapping('$value')
      });
    }
  }

  // Only create a default mapping if we have no other mappings and a displayName
  if (mappings.length === 0 && effectiveDisplayName) {
    mappings.push({
      operator: '>=',
      value: Number.NEGATIVE_INFINITY,
      color: '#808080',  // Default gray color
      type: panel.options?.colorMode === 'background' ? 'background' : 'text',
      textMapping: formatTextMapping('$value')
    });
  }

  return mappings;
}

// Extract variables from Grafana expressions
function extractVariables(expr: string): Variable[] {
  const variables: Variable[] = [];
  const matches = expr.match(/\$([a-zA-Z0-9_]+)/g);
  
  if (matches) {
    matches.forEach(match => {
      const name = match.substring(1);
      // Skip $env and $datasource as they're handled specially
      if (name !== 'env' && name !== 'datasource') {
        variables.push({
          name,
          key: name,
          defaultValue: '',
          defaultType: MetricType.Metric,
          isOverrideable: true
        });
      }
    });
  }

  return variables;
}

// Keep track of current positions for different widget types
let currentStatRow = 0;
let currentStatCol = 0;
let currentChartRow = 0;
let currentChartCol = 0;

// Reset the position trackers (call this before starting a new dashboard conversion)
function resetPositionTrackers() {
  currentStatRow = 0;
  currentStatCol = 0;
  currentChartRow = 0;
  currentChartCol = 0;
}

// Convert Grafana grid position to Metoro grid position
function convertPosition(grafanaPos: { x: number; y: number; w: number; h: number }, panelType?: string) {
  let position;

  if (panelType === 'stat') {
    // Stats are 3 columns wide, 4 per row
    position = {
      x: currentStatCol * 3,
      y: currentStatRow * 2,  // Each row is 2 units high
      w: 3,                   // Fixed width of 3 units
      h: 2                    // Fixed height of 2 units
    };

    // Update position trackers for stats
    currentStatCol++;
    if (currentStatCol >= 4) {  // After 4 stats, move to next row
      currentStatCol = 0;
      currentStatRow++;
    }
  } else if (panelType === 'text' || panelType === 'row') {
    // Skip positioning for text and row panels
    position = undefined;
  } else {
    // Charts are 6 columns wide, 2 per row
    position = {
      x: currentChartCol * 6,
      y: (currentStatRow + 1) * 2 + currentChartRow * 4,  // Start after stats, each row is 4 units high
      w: 6,                    // Fixed width of 6 units
      h: 4                     // Fixed height of 4 units
    };

    // Update position trackers for charts
    currentChartCol++;
    if (currentChartCol >= 2) {  // After 2 charts, move to next row
      currentChartCol = 0;
      currentChartRow++;
    }
  }

  return position;
}

// Convert a single Grafana panel to a Metoro widget
function convertPanel(panel: GrafanaPanel): Widget | null {
  // Skip row type panels
  if (panel.type === 'row') {
    return null;
  }

  // Handle text panels
  if (panel.type === 'text') {
    const markdownWidget: MarkdownWidget = {
      widgetType: 'Markdown',
      position: undefined,
      content: panel.options?.content || ''
    };
    return markdownWidget;
  }

  const position = convertPosition(panel.gridPos, panel.type);

  if (panel.type === 'stat') {
    // Extract precision from the expression if round() is present
    const precision = panel.targets?.[0]?.expr ? 
      extractPrecision(panel.targets[0].expr) : undefined;

    const metricSpecifier = panel.targets?.[0] ? 
      convertExpressionToMetricSpecifier(panel.targets[0].expr, panel.targets[0].legendFormat) : {
      metricName: '',
      metricType: MetricType.Metric,
      filters: new Map(),
      excludeFilters: new Map(),
      splits: [],
      aggregation: 'avg',
      functions: [],
      bucketSize: 0,
      visualization: {
        displayName: ''
      }
    };

    // If we got multiple metric specifiers, use the first one for stat widget
    const finalMetricSpecifier = Array.isArray(metricSpecifier) ? metricSpecifier[0] : metricSpecifier;

    const statWidget: StatWidget = {
      widgetType: 'Stat',
      position,
      title: panel.title,
      metricSpecifier: finalMetricSpecifier,
      reduceOption: panel.options?.reduceOptions?.calcs?.[0] ? 
        convertReduceOption(panel.options.reduceOptions.calcs[0]) : 'lastNotNull',
      mappings: convertThresholdsAndMappings(panel, finalMetricSpecifier.visualization?.displayName),
      precision: precision ?? 1,  // Use extracted precision or default to 1
      fontSize: 'text-2xl'  // Default font size
    };
    return statWidget;
  }

  // Default to metric chart for other panel types
  const chartWidget: MultiMetricChartWidget = {
    widgetType: 'MetricChart',
    position,
    title: panel.title,
    type: panel.type === 'timeseries' ? ChartType.Line : ChartType.Bar,
    metricSpecifiers: panel.targets?.flatMap(target => {
      const result = convertExpressionToMetricSpecifier(
        target.expr, 
        target.legendFormat,
        panel.fieldConfig?.defaults.custom?.showPoints
      );
      return Array.isArray(result) ? result : [result];
    }) || []
  };

  return chartWidget;
}

// Parse Grafana label_values query to extract metric name and label key
function parseGrafanaVariableQuery(query: string): { metricName: string; key: string } | null {
  // Match pattern: label_values(metric_name{filters},label_key)
  const match = query.match(/label_values\(([^,]+)\{[^}]*\},([^)]+)\)/);
  if (match) {
    return {
      metricName: match[1].trim(),
      key: match[2].trim()
    };
  }
  return null;
}

// Convert Grafana variable value to string
function getVariableValue(current: GrafanaVariable['current']): string {
  if (typeof current.value === 'string') {
    return current.value;
  }
  if (Array.isArray(current.value)) {
    return current.value[0] || '';
  }
  if (typeof current.value === 'object') {
    return current.value.value || '';
  }
  return '';
}

// Convert Grafana variable to Metoro variable format
function convertVariable(variable: GrafanaVariable): Variable | null {
  // Only convert query type variables
  if (variable.type !== 'query') {
    return null;
  }

  // Skip env and datasource variables
  if (variable.name === 'env' || variable.name === 'datasource') {
    return null;
  }

  // Parse the query to get metric name and key
  const queryDetails = variable.query.query ? 
    parseGrafanaVariableQuery(variable.query.query) : null;

  return {
    name: variable.name,
    key: queryDetails?.key || variable.name,  // Use parsed key or fallback to name
    defaultValue: '*',  // Always set to '*' for all variables
    defaultType: MetricType.Metric,
    defaultMetricName: queryDetails?.metricName || 'prometheus_build_info',  // Use parsed metric name or default
    isOverrideable: true
  };
}

// Main function to convert Grafana dashboard to Metoro format
export function convertGrafanaDashboardToMetoro(grafanaDashboard: GrafanaDashboard): GroupWidget {
  // Reset position trackers before starting conversion
  resetPositionTrackers();

  const rootGroup: GroupWidget = {
    widgetType: 'Group',
    position: undefined,
    title: grafanaDashboard.title,
    children: grafanaDashboard.panels.flatMap(panel => {
      // If it's a row panel with sub-panels, convert the sub-panels
      if (panel.type === 'row' && panel.panels) {
        return panel.panels.map(convertPanel).filter((w): w is Widget => w !== null);
      }
      // Otherwise convert the panel itself
      const widget = convertPanel(panel);
      return widget ? [widget] : [];
    }),
    variables: []
  };

  // Extract variables from templating section
  if (grafanaDashboard.templating?.list) {
    rootGroup.variables = grafanaDashboard.templating.list
      .map(convertVariable)
      .filter((v): v is Variable => v !== null);
  }

  return rootGroup;
}
