import {cn} from "../../ui/lib/utils";
import {DateTimePickerRange} from "../../ui/DateTimeSelector";
import {MultiMetoroMetricsChart, MultiMetoroMetricsChartProps} from "../../Charts/MultiMetoroMetricsCharts";
import {ChartType} from "../../Charts/MetoroChart";
import React from "react";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {StatChart} from "../../Charts/StatChart";
import {MetricQuerySelectorPanel} from "./MultiMetricSelector";
import {ReduceOption, ReduceOptions, StatWidget, StatWidgetMapping} from "../internalwidgets";
import {RuntimeVariable} from "../Dashboard";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import {AggregateMonitorEvaluationPayload, AggregationFunction, MetricAlert} from "../../../pages/alerts/MetricAlert";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {TraceAlert} from "../../../pages/alerts/TraceAlert";
import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../ui/command";
import {Button} from "@mui/material";
import {Input} from "../../ui/input";
import {Label} from "../../ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../ui/select";
import ColorPicker from "../../ui/color-picker";
import {Cross2Icon} from "@radix-ui/react-icons";

export function StatSelectorPanel(props: {
    widget: StatWidget,
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    runtimeVariables: RuntimeVariable[]
    onSave?: (widget: StatWidget) => void
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [chartProps, setChartProps] = React.useState<MultiMetoroMetricsChartProps>({
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
        type: ChartType.Line,
        variables: props.runtimeVariables,
        hideLegend: false,
        metricSpecifiers: [props.widget.metricSpecifier],
    });
    const [reduceOption, setReduceOption] = React.useState<ReduceOption>(props.widget.reduceOption);
    const [mappings, setMappings] = React.useState<StatWidgetMapping[]>(props.widget.mappings || []);
    const [title, setTitle] = React.useState<string>(props.widget.title || "New Stat");
    const [precision, setPrecision] = React.useState<number>(props.widget.precision ?? 2);
    const [fontSize, setFontSize] = React.useState<string>(props.widget.fontSize || "text-2xl");

    // Update state when widget prop changes
    React.useEffect(() => {
        setChartProps(prev => ({
            ...prev,
            metricSpecifiers: [props.widget.metricSpecifier],
            variables: props.runtimeVariables,
        }));
        setReduceOption(props.widget.reduceOption);
        setMappings(props.widget.mappings || []);
        setTitle(props.widget.title || "");
        setPrecision(props.widget.precision ?? 2);
        setFontSize(props.widget.fontSize || "text-2xl");
    }, [props.widget.metricSpecifier, props.widget.reduceOption, props.widget.mappings, props.widget.title, props.widget.precision, props.widget.fontSize, props.runtimeVariables]);

    const handleSave = () => {
        const updatedWidget: StatWidget = {
            ...props.widget,
            metricSpecifier: chartProps.metricSpecifiers[0],
            reduceOption: reduceOption,
            mappings: mappings,
            title: title,
            precision: precision,
            fontSize: fontSize
        };

        if (props.onSave) {
            props.onSave(updatedWidget);
        }
        props.setIsDialogOpen(false);
    };

    return <div
        className={cn("flex flex-col grow rounded bg-backgrounddark overflow-y-auto border gap-4")}>
        <div className={"flex-none flex justify-between border-b h-[32px]"}>
            <div className={"flex flex-col justify-center text-textdark ml-2 items-center text-center"}>
                Chart Builder
            </div>
            <DateTimePickerRange/>
        </div>
        <div className={"flex gap-6 p-4"}>
            <div className={"flex flex-col justify-center min-w-[200px]"}>
                <div className={"text-textmedium text-sm mb-2"}>Current Value</div>
                <div className={"bg-backgrounddark border rounded p-4 text-textmedium"}>
                    <StatChart
                        runtimeVariables={props.runtimeVariables}
                        metricSpecifier={chartProps.metricSpecifiers[0]}
                        reduceOption={reduceOption}
                        title={title}
                        mappings={mappings}
                        precision={precision}
                        fontSize={fontSize}
                        key={JSON.stringify(mappings)}
                    />
                </div>
            </div>
            <div className={cn("flex flex-col grow bg-backgrounddark rounded")}>
                <MultiMetoroMetricsChart
                    {...chartProps}
                    variables={props.runtimeVariables}
                    startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                    endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                    hideLegend={false}
                    className={"flex flex-grow h-[200px]"}
                />
            </div>
        </div>
        <div className={"p-4 flex flex-col gap-4"}>
            <MetricQuerySelectorPanel variables={props.runtimeVariables}
                                      chartProps={chartProps}
                                      setChartProps={setChartProps}
                                      disableMultipleMetrics={true}
                                      disableVisualEditButton={true}
                                      metricSelectorDisplaySettings={{
                                          showFormulaSelector: false,
                                          showTypeSelector: true,
                                          showMetricName: true,
                                          showGroupBy: false,
                                          showAggregation: true,
                                          showFilters: true,
                                          showJsonPath: true,
                                      }}
                                      stepNumber={1}
            />
            <ReduceOptionSelector
                reduceOption={reduceOption as ReduceOption}
                setReduceOption={setReduceOption}
            />

            <VisualRulesSelector
                stepNumber={3}
                mappings={mappings}
                setMappings={setMappings}
            />

            <div className={"flex flex-col gap-4"}>
                <div className={"flex justify-start gap-2"}>
                    <div
                        className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                        4
                    </div>
                    <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                        Configure Display
                    </div>
                </div>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex items-center gap-4"}>
                        <Label className="text-textmedium min-w-[100px]">Precision</Label>
                        <Select
                            value={precision.toString()}
                            onValueChange={(value) => setPrecision(parseInt(value))}
                        >
                            <SelectTrigger
                                className="w-[100px] bg-backgrounddark border border-border rounded text-textmedium">
                                <SelectValue
                                    placeholder="Decimal points"/>
                            </SelectTrigger>
                            <SelectContent className={"bg-backgrounddark text-textmedium"}>
                                {[0, 1, 2, 3, 4].map((value) => (
                                    <SelectItem
                                        key={value}
                                        className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                        value={value.toString()}
                                    >
                                        {value}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={"flex items-center gap-4"}>
                        <Label className="text-textmedium min-w-[100px]">Font Size</Label>
                        <Select
                            value={fontSize}
                            onValueChange={setFontSize}
                        >
                            <SelectTrigger
                                className="w-[150px] bg-backgrounddark border border-border rounded text-textmedium">
                                <SelectValue placeholder="Select font size"/>
                            </SelectTrigger>
                            <SelectContent className={"bg-backgrounddark text-textmedium"}>
                                <SelectItem
                                    className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                    value="text-lg"
                                >
                                    Small
                                </SelectItem>
                                <SelectItem
                                    className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                    value="text-xl"
                                >
                                    Medium
                                </SelectItem>
                                <SelectItem
                                    className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                    value="text-2xl"
                                >
                                    Large
                                </SelectItem>
                                <SelectItem
                                    className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                    value="text-3xl"
                                >
                                    Extra Large
                                </SelectItem>
                                <SelectItem
                                    className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                    value="text-4xl"
                                >
                                    Huge
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className={"flex items-center gap-4"}>
                        <Label className="text-textmedium min-w-[100px]">Title</Label>
                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter title (optional)"
                            className={"h-12 w-1/3 focus:ring-0 focus:border-border focus:ring-border text-textlight border border-border shadow-none"}
                        />
                    </div>
                </div>
            </div>

            <div className={"flex justify-center items"}>
                <div
                    onClick={handleSave}
                    className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight p-2 rounded hover:bg-primarydark cursor-pointer"}>
                    Save
                </div>
            </div>
        </div>

    </div>
}

function ReduceOptionSelector(props: {
    stepNumber?: number,
    reduceOption?: ReduceOption,
    setReduceOption: React.Dispatch<React.SetStateAction<ReduceOption>>
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber ? props.stepNumber : 2}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Reduce Function
            </div>
        </div>


        <div className={"flex items-center gap-x-2 gap-y-4 w-max"}>
            <div className={"text-textdark w-1/2"}> Reduce function determines how to reduce the values in timeframe to a single value
            </div>
            <div
                className={"border rounded px-2 min-w-[73px] flex flex-col justify-center bg-backgrounddark items-center text-center text-textmedium border-r"}>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <div
                            className={"flex justify-center items-center text-textmedium gap-2 p-1"}>
                            <div>{props.reduceOption ? props.reduceOption : "lastNotNull"}</div>
                            <ChevronDownIcon className={"text-textmedium"}/>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side={"bottom"}
                                         className="mt-2 bg-background text-textmedium rounded">
                        <DropdownMenuRadioGroup value={"aggregateFunctions"}
                                                onValueChange={(value) => {
                                                    props.setReduceOption(value as ReduceOption)
                                                }}>
                            {ReduceOptions.map((value, key) => {
                                return <DropdownMenuRadioItem className="hover:bg-backgroundlight"
                                                              key={key}
                                                              value={value}>{value}</DropdownMenuRadioItem>
                            })}
                        </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    </div>
}

function VisualRulesSelector(props: {
    stepNumber?: number,
    mappings: StatWidgetMapping[],
    setMappings: React.Dispatch<React.SetStateAction<StatWidgetMapping[]>>
}) {
    const [isColorPickerOpen, setIsColorPickerOpen] = React.useState<boolean>(false);
    const [selectedMappingIndex, setSelectedMappingIndex] = React.useState<number>(-1);

    const addNewRule = () => {
        props.setMappings([...props.mappings, {
            operator: ">",
            value: 0,
            color: "#2DD881", // Default to secondary color
            type: "background"
        }]);
    };

    const addNewRangeRule = () => {
        props.setMappings([...props.mappings, {
            operator: "range",
            value: 0, // This won't be used for range
            from: 0,
            to: 100,
            color: "#2DD881", // Default to secondary color
            type: "background"
        }]);
    };

    const updateMapping = (index: number, field: keyof StatWidgetMapping, value: any) => {
        const newMappings = [...props.mappings];
        newMappings[index] = {
            ...newMappings[index],
            [field]: value
        };
        props.setMappings(newMappings);
    };

    const deleteMapping = (index: number) => {
        const newMappings = props.mappings.filter((_, i) => i !== index);
        props.setMappings(newMappings);
    };

    return <div className={"flex flex-col gap-4 text-textmedium"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber ? props.stepNumber : 3}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Visual Formatting Options
            </div>
        </div>
        <div className={"flex flex-col gap-y-4"}>
            {props.mappings.map((mapping, index) => (
                <div key={index} className="flex items-center gap-4 p-4 border rounded bg-backgroundmedium">
                    <Label className="text-textmedium">If value is</Label>
                    <Select
                        value={mapping.operator}
                        onValueChange={(value: ">" | "<" | "==" | ">=" | "<=" | "range") => {
                            const newMapping = { ...mapping };
                            if (value === "range") {
                                newMapping.operator = value;
                                newMapping.from = 0;
                                newMapping.to = 100;
                                // Keep the existing value for reference
                                props.setMappings(props.mappings.map((m, i) => i === index ? newMapping : m));
                            } else {
                                newMapping.operator = value;
                                // If switching from range, use the 'from' value as the new single value
                                if (mapping.operator === "range") {
                                    newMapping.value = mapping.from || 0;
                                }
                                delete newMapping.from;
                                delete newMapping.to;
                                props.setMappings(props.mappings.map((m, i) => i === index ? newMapping : m));
                            }
                        }}
                    >
                        <SelectTrigger className="w-[180px] bg-backgrounddark border border-border rounded">
                            <SelectValue placeholder="Select operator"/>
                        </SelectTrigger>
                        <SelectContent className={"bg-backgrounddark text-textmedium"}>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="range">between (range)</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value=">">greater than</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="<">less than</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="==">equal to</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value=">=">greater or equal</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="<=">less or equal</SelectItem>
                        </SelectContent>
                    </Select>

                    {mapping.operator === "range" ? (
                        <>
                            <Label className="text-textmedium">from</Label>
                            <Input
                                type="number"
                                value={mapping.from}
                                onChange={(e) => updateMapping(index, 'from', parseFloat(e.target.value))}
                                className="w-[100px] bg-backgrounddark text-textmedium"
                            />
                            <Label className="text-textmedium">to</Label>
                            <Input
                                type="number"
                                value={mapping.to}
                                onChange={(e) => updateMapping(index, 'to', parseFloat(e.target.value))}
                                className="w-[100px] bg-backgrounddark text-textmedium"
                            />
                        </>
                    ) : (
                        <Input
                            type="number"
                            value={mapping.value}
                            onChange={(e) => updateMapping(index, 'value', parseFloat(e.target.value))}
                            className="w-[100px] bg-backgrounddark text-textmedium"
                        />
                    )}

                    <Select
                        value={mapping.type}
                        onValueChange={(value) => updateMapping(index, 'type', value)}
                    >
                        <SelectTrigger className="w-[180px] bg-backgrounddark border border-border rounded">
                            <SelectValue placeholder="Select type"/>
                        </SelectTrigger>
                        <SelectContent className={"bg-backgrounddark text-textmedium"}>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="background">Background color</SelectItem>
                            <SelectItem
                                className={"hover:bg-secondarytransparenter hover:border-secondary hover:border"}
                                value="text">Text color</SelectItem>
                        </SelectContent>
                    </Select>

                    <ColorPicker
                        value={mapping.color}
                        onChange={(color) => updateMapping(index, 'color', color)}
                    >
                        <div
                            className="w-[36px] h-[36px] rounded border cursor-pointer"
                            style={{backgroundColor: mapping.color}}
                        />
                    </ColorPicker>

                    <Input
                        value={mapping.textMapping || ""}
                        onChange={(e) => updateMapping(index, 'textMapping', e.target.value)}
                        className="w-[200px] bg-backgrounddark text-textmedium"
                        placeholder="Optional text (use $value)"
                    />

                    <button
                        onClick={() => deleteMapping(index)}
                        className="text-textmedium hover:text-textlight"
                    >
                        <Cross2Icon className="h-4 w-4"/>
                    </button>
                </div>
            ))}

            <div className="flex gap-2">
                <div
                    onClick={addNewRule}
                    className={"text-textmedium px-2 py-1 border rounded hover:text-textlight cursor-pointer"}
                >
                    + Add Rule
                </div>
            </div>

            {/* Add click outside handler to close color picker */}
            {isColorPickerOpen && (
                <div
                    className="fixed inset-0 z-40"
                    onClick={() => setIsColorPickerOpen(false)}
                />
            )}
        </div>
    </div>
}