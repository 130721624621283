import React, {useState, useEffect} from "react";
import {Variable} from "../internalwidgets";
import {cn} from "../../ui/lib/utils";
import {Button} from "../../ui/button";
import {Input} from "../../ui/input";
import {PencilIcon, PlusIcon, Trash2Icon} from "lucide-react";
import {MetoroMetricsChartProps, MetricType} from "../../../pages/MetricsTest";
import {SingleSelectDropdown} from "../../Input/SingleSelectDropdown";
import {DropDownItem} from "../../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {MetricQuerySelector} from "./MetricSelector";
import {Checkbox} from "../../ui/checkbox";
import {Label} from "../../ui/label";
import {useToast} from "../../ui/use-toast";
import {VariableSelectorDropDown} from "./GroupVariableManager";
import {SingleSelectorSearchableDropdown} from "../../Input/SingleSelectorSearchableDropdown";
import axios from "../../../utility/customAxios";
import timerange from "../../../store/reducers/timerange";
import {useSelector} from "react-redux";
import {ChartType} from "../../Charts/MetoroChart";

interface VariablesDrawerProps {
    onClose: () => void;
    variables?: Variable[];
    onAddVariable?: (variable: Variable) => void;
    onEditVariable?: (index: number, variable: Variable) => void;
    onDeleteVariable?: (index: number) => void;
    widgetId?: string;
    runtimeVariables?: Map<string, RuntimeVariable[]>;
    setRuntimeVariables?: React.Dispatch<React.SetStateAction<Map<string, RuntimeVariable[]>>>;
}

interface RuntimeVariable {
    name: string;
    key: string;
    value: string;
}

const metricTypeItems: DropDownItem[] = [
    {value: MetricType.Metric, displayName: "Metric"},
    {value: MetricType.Trace, displayName: "Trace"}
];

interface EditingVariable extends Variable {
    chartProps: MetoroMetricsChartProps;
}

const DefaultValueSelector = (props: {
    variable: EditingVariable,
    onValueChange: (value: string) => void
}) => {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [items, setItems] = React.useState<DropDownItem[]>([])

    React.useEffect(() => {
        if (!props.variable.chartProps.splits || props.variable.chartProps.splits.length === 0) {
            return;
        }

        const key = props.variable.chartProps.splits[0];
        
        if (props.variable.defaultType === MetricType.Trace) {
            axios.post("/api/v1/tracesSummaryIndividualAttribute", {
                    "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                    "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                    "attribute": key
                },
            ).then((response) => {
                let items = response.data.attribute.map((x: any) => {
                    return {
                        displayName: x.value,
                        value: x.value
                    }
                })
                setItems(items)
            })
        }
        if (props.variable.defaultType === MetricType.Metric) {
            axios.post("/api/v1/metricIndividualAttribute", {
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                "metricName": props.variable.chartProps.metricName,
                "attribute": key
            }).then(
                (response) => {
                    setItems(response.data.attribute.map((x: any) => {
                        return {
                            displayName: x,
                            value: x,
                        }
                    }))
                }
            )
        }
    }, [props.variable.chartProps.splits, props.variable.defaultType, props.variable.chartProps.metricName, timeRange]);

    const defaultItem: DropDownItem = {
        displayName: props.variable.defaultValue,
        value: props.variable.defaultValue
    };

    return (
        <SingleSelectorSearchableDropdown
            selected={items.find(x => x.value === props.variable.defaultValue) ?? defaultItem}
            possibleItems={items}
            setSelected={(item) => props.onValueChange(item.value)}
            noMatchString="No matching values found"
            searchPlaceholder="Search values..."
            allowAnyString={true}
            displaySettings={
              {
                  smallSize: true,
                  borderClassName: "border"
              }
            }
        />
    );
};

export const VariablesDrawer = (props: VariablesDrawerProps) => {
    const [isAddingVariable, setIsAddingVariable] = useState(false);
    const [editingVariables, setEditingVariables] = useState<{ [key: number]: EditingVariable }>({});
    const [localVariables, setLocalVariables] = useState<Variable[]>(props.variables ?? []);
    const [newVariable, setNewVariable] = useState<EditingVariable>({
        name: "",
        key: "",
        defaultType: MetricType.Metric,
        defaultValue: "*",
        isOverrideable: true,
        chartProps: {
            metricName: "",
            metricType: MetricType.Metric,
            aggregation: "avg",
            filters: new Map(),
            splits: [],
            type: ChartType.Line,
            functions: [],
            startTime: 0,
            endTime: 0
        }
    });
    const {toast} = useToast();

    // Keep local variables in sync with props
    useEffect(() => {
        if (JSON.stringify(localVariables) !== JSON.stringify(props.variables)) {
            setLocalVariables(props.variables ?? []);
        }
    }, [props.variables]);

    const handleStartEdit = (index: number, variable: Variable) => {
        setEditingVariables(prev => ({
            ...prev,
            [index]: {
                ...variable,
                chartProps: {
                    metricName: variable.defaultMetricName || "",
                    metricType: variable.defaultType,
                    aggregation: "avg",
                    filters: new Map(),
                    splits: [variable.key],
                    type: ChartType.Line,
                    functions: [],
                    startTime: 0,
                    endTime: 0
                }
            }
        }));
    };

    const handleCancelEdit = (index: number) => {
        setEditingVariables(prev => {
            const newState = {...prev};
            delete newState[index];
            return newState;
        });
    };

    const handleSaveEdit = (index: number) => {
        const editingVariable = editingVariables[index];
        if (!editingVariable) return;

        // Check for name collision, excluding the current variable being edited
        const nameExists = localVariables.some((v, i) => i !== index && v.name === editingVariable.name);
        if (nameExists) {
            toast({
                variant: "destructive",
                title: "Name already exists",
                description: "A variable with this name already exists. Please choose a different name."
            });
            return;
        }

        if (props.onEditVariable && editingVariable) {
            const key = editingVariable.chartProps.splits![0];
            const updatedVariable = {
                name: editingVariable.name,
                key: key,
                defaultType: editingVariable.chartProps.metricType,
                defaultMetricName: editingVariable.chartProps.metricName || undefined,
                defaultValue: editingVariable.defaultValue || "",
                isOverrideable: editingVariable.isOverrideable
            };
            
            // Update runtime variables if needed
            if (props.widgetId && props.runtimeVariables && props.setRuntimeVariables) {
                props.setRuntimeVariables(prev => {
                    const newMap = new Map(prev);
                    const currentVars = newMap.get(props.widgetId!) ?? [];
                    const updatedVars = currentVars.map(v => 
                        v.name === updatedVariable.name 
                            ? { ...v, key: updatedVariable.key, value: updatedVariable.defaultValue }
                            : v
                    );
                    newMap.set(props.widgetId!, updatedVars);
                    return newMap;
                });
            }
            
            props.onEditVariable(index, updatedVariable);
            setEditingVariables(prev => {
                const newState = {...prev};
                delete newState[index];
                return newState;
            });
        }
    };

    const handleUpdateEditingVariable = (index: number, updates: Partial<EditingVariable>) => {
        setEditingVariables(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                ...updates
            }
        }));
    };

    const handleAddVariable = () => {
        if (!newVariable.name) return;

        // Check for name collision
        const nameExists = localVariables.some(v => v.name === newVariable.name);
        if (nameExists) {
            toast({
                variant: "destructive",
                title: "Name already exists",
                description: "A variable with this name already exists. Please choose a different name."
            });
            return;
        }

        if (props.onAddVariable) {
            const key = newVariable.chartProps.splits![0];
            const variable = {
                name: newVariable.name,
                key: key,
                defaultType: newVariable.chartProps.metricType,
                defaultMetricName: newVariable.chartProps.metricName !== "" ? newVariable.chartProps.metricName : undefined,
                defaultValue: newVariable.defaultValue,
                isOverrideable: newVariable.isOverrideable
            };

            // Add to runtime variables if needed
            if (props.widgetId && props.runtimeVariables && props.setRuntimeVariables) {
                props.setRuntimeVariables(prev => {
                    const newMap = new Map(prev);
                    const currentVars = newMap.get(props.widgetId!) ?? [];
                    currentVars.push({
                        name: variable.name,
                        key: variable.key,
                        value: variable.defaultValue
                    });
                    newMap.set(props.widgetId!, currentVars);
                    return newMap;
                });
            }

            // Update local variables
            setLocalVariables(prev => [...prev, variable]);

            props.onAddVariable(variable);
            setNewVariable({
                name: "",
                key: "",
                defaultType: MetricType.Metric,
                defaultValue: "",
                isOverrideable: true,
                chartProps: {
                    metricName: "",
                    metricType: MetricType.Metric,
                    aggregation: "avg",
                    filters: new Map(),
                    splits: [],
                    type: ChartType.Line,
                    functions: [],
                    startTime: 0,
                    endTime: 0
                }
            });
            setIsAddingVariable(false);
        }
    };

    const handleDeleteVariable = (index: number) => {
        if (props.onDeleteVariable) {
            const variableToDelete = localVariables[index];
            
            // Remove from runtime variables if needed
            if (props.widgetId && props.runtimeVariables && props.setRuntimeVariables && variableToDelete) {
                props.setRuntimeVariables(prev => {
                    const newMap = new Map(prev);
                    const currentVars = newMap.get(props.widgetId!) ?? [];
                    const updatedVars = currentVars.filter((_, i) => i !== index);
                    newMap.set(props.widgetId!, updatedVars);
                    return newMap;
                });
            }
            
            // Remove from editing state if it's being edited
            setEditingVariables(prev => {
                const newState = {...prev};
                delete newState[index];
                return newState;
            });

            // Update local variables
            setLocalVariables(prev => {
                const newVars = [...prev];
                newVars.splice(index, 1);
                return newVars;
            });
            
            // Notify parent of deletion
            props.onDeleteVariable(index);
        }
    };

    return (
        <div className="flex flex-col p-4 h-full">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-textmedium">Variables</h2>
                <Button
                    onClick={() => setIsAddingVariable(true)}
                    className="h-8 px-2 bg-primarytransparent border-primary rounded border text-textmedium"
                >
                    <PlusIcon className="h-4 w-4 mr-1"/>
                    Add Variable
                </Button>
            </div>
            {isAddingVariable && (
                <div className={cn(
                    "flex flex-col p-3 mb-4 rounded",
                    "border border-borderdark",
                    "bg-backgroundmedium",
                    "gap-4"
                )}>
                    <div className={"font-bold text-md text-textlight"}>
                        Variable Name
                    </div>
                    <Input
                        placeholder="Variable name"
                        value={newVariable.name}
                        onChange={(e) => setNewVariable(prev => ({...prev, name: e.target.value}))}
                        className="h-8"
                    />
                    <div className={"font-bold text-md text-textlight"}>
                        Possible Values
                    </div>
                    <div className={"flex gap-x-4"}>
                        <SingleSelectDropdown
                            selectedItemTitle="Type"
                            selectedItem={metricTypeItems.find(item => item.value === newVariable.defaultType) || metricTypeItems[0]}
                            possibleItems={metricTypeItems}
                            setSelectedItem={(item) => {
                                const metricType = item.value as MetricType;
                                setNewVariable(prev => ({
                                    ...prev,
                                    defaultType: metricType,
                                    defaultValue: "",
                                    chartProps: {
                                        ...prev.chartProps,
                                        metricType,
                                        splits: [],
                                        metricName: metricType === MetricType.Metric ? prev.chartProps.metricName : ""
                                    }
                                }));
                            }}
                        />
                        <MetricQuerySelector
                            displaySettings={{
                                customGroupByString: "Select key",
                                showMetricName: newVariable.defaultType === MetricType.Metric,
                                showFilters: false,
                                showAggregation: false,
                                showGroupBy: true
                            }}
                            setChartProps={(chartProps) => {
                                if (typeof chartProps === 'function') {
                                    setNewVariable(prev => ({
                                        ...prev,
                                        chartProps: chartProps(prev.chartProps)
                                    }));
                                } else {
                                    setNewVariable(prev => ({
                                        ...prev,
                                        chartProps: {
                                            ...prev.chartProps,
                                            ...chartProps
                                        }
                                    }));
                                }
                            }}
                            chartProps={newVariable.chartProps}
                        />
                    </div>
                    <div className={"font-bold text-md text-textlight"}>
                        Default Value
                    </div>
                    <DefaultValueSelector
                        variable={newVariable}
                        onValueChange={(value) => setNewVariable(prev => ({...prev, defaultValue: value}))}
                    />
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="isOverrideable"
                            checked={newVariable.isOverrideable}
                            onCheckedChange={(checked) =>
                                setNewVariable(prev => ({...prev, isOverrideable: checked as boolean}))
                            }
                        />
                        <Label htmlFor="isOverrideable" className="text-sm text-textmedium">Allow overriding this
                            variable</Label>
                    </div>
                    <div className="flex gap-2 justify-end mt-2">
                        <Button
                            onClick={() => setIsAddingVariable(false)}
                            className="h-8 px-2 bg-backgrounddark text-textmedium"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddVariable}
                            disabled={
                                !(newVariable.defaultType === MetricType.Metric &&
                                    (newVariable.chartProps.metricName !== "" &&
                                        newVariable.name !== "" &&
                                        newVariable.chartProps.splits &&
                                        newVariable.chartProps.splits.length !== 0)) &&
                                !(newVariable.defaultType === MetricType.Trace &&
                                    (newVariable.name !== "" &&
                                        newVariable.chartProps.splits &&
                                        newVariable.chartProps.splits.length !== 0))
                            }
                            className="h-8 px-2 bg-primarytransparent border-primary rounded border text-textmedium"
                        >
                            Add
                        </Button>
                    </div>
                </div>
            )}
            <div className="flex flex-col gap-4">
                {localVariables.map((variable, index) => {
                    const isEditing = index in editingVariables;
                    const editingVariable = editingVariables[index];

                    if (isEditing && editingVariable) {
                        return (
                            <div key={index} className={cn(
                                "flex flex-col p-3 rounded",
                                "border border-borderdark",
                                "bg-backgroundmedium",
                                "gap-4"
                            )}>
                                <div className={"font-bold text-md text-textlight"}>
                                    Variable Name
                                </div>
                                <Input
                                    placeholder="Variable name"
                                    value={editingVariable.name}
                                    onChange={(e) => handleUpdateEditingVariable(index, {name: e.target.value})}
                                    className="h-8"
                                />
                                <div className={"font-bold text-md text-textlight"}>
                                    Possible Values
                                </div>
                                <div className={"flex gap-x-4"}>
                                    <SingleSelectDropdown
                                        selectedItemTitle="Type"
                                        selectedItem={metricTypeItems.find(item => item.value === editingVariable.defaultType) || metricTypeItems[0]}
                                        possibleItems={metricTypeItems}
                                        setSelectedItem={(item) => {
                                            const metricType = item.value as MetricType;
                                            handleUpdateEditingVariable(index, {
                                                defaultType: metricType,
                                                defaultValue: "*",
                                                chartProps: {
                                                    ...editingVariable.chartProps,
                                                    metricType,
                                                    splits: [],
                                                    metricName: metricType === MetricType.Metric ? editingVariable.chartProps.metricName : ""
                                                }
                                            });
                                        }}
                                    />
                                    <MetricQuerySelector
                                        displaySettings={{
                                            customGroupByString: "Select key",
                                            showMetricName: editingVariable.defaultType === MetricType.Metric,
                                            showFilters: false,
                                            showAggregation: false,
                                            showGroupBy: true
                                        }}
                                        setChartProps={(chartProps) => {
                                            if (typeof chartProps === 'function') {
                                                handleUpdateEditingVariable(index, {
                                                    chartProps: chartProps(editingVariable.chartProps)
                                                });
                                            } else {
                                                handleUpdateEditingVariable(index, {
                                                    chartProps: {
                                                        ...editingVariable.chartProps,
                                                        ...chartProps
                                                    }
                                                });
                                            }
                                        }}
                                        chartProps={editingVariable.chartProps}
                                    />
                                </div>
                                <div className={"font-bold text-md text-textlight"}>
                                    Default Value
                                </div>
                                <DefaultValueSelector
                                    variable={editingVariable}
                                    onValueChange={(value) => handleUpdateEditingVariable(index, {defaultValue: value})}
                                />
                                <div className="flex items-center space-x-2">
                                    <Checkbox
                                        id={`isOverrideable-${index}`}
                                        checked={editingVariable.isOverrideable}
                                        onCheckedChange={(checked) =>
                                            handleUpdateEditingVariable(index, {isOverrideable: checked as boolean})
                                        }
                                    />
                                    <Label htmlFor={`isOverrideable-${index}`} className="text-sm text-textmedium">
                                        Allow overriding this variable
                                    </Label>
                                </div>
                                <div className="flex gap-2 justify-end mt-2">
                                    <Button
                                        onClick={() => handleCancelEdit(index)}
                                        className="h-8 px-2 bg-backgrounddark text-textmedium"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => handleSaveEdit(index)}
                                        disabled={
                                            !editingVariable.name ||
                                            (editingVariable.chartProps.metricType === MetricType.Metric &&
                                                (!editingVariable.chartProps.metricName ||
                                                    !editingVariable.chartProps.splits?.length)) ||
                                            (editingVariable.chartProps.metricType === MetricType.Trace &&
                                                !editingVariable.chartProps.splits?.length)
                                        }
                                        className="h-8 px-2 bg-primarytransparent border-primary rounded border text-textmedium"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div key={index} className={cn(
                            "flex justify-between p-3 rounded",
                            "border border-borderdark",
                            "bg-backgroundmedium"
                        )}>
                            <div className="flex flex-col gap-1">
                                <div className="text-sm font-medium text-textmedium">
                                    {variable.name}
                                </div>
                                <div className="text-xs text-textmedium">
                                    Type: {variable.defaultType}
                                </div>
                                <div className="text-xs text-textmedium">
                                    {variable.isOverrideable ? "Can be overridden" : "Cannot be overridden"}
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <Button
                                    onClick={() => handleStartEdit(index, variable)}
                                    className="h-8 px-2 bg-transparent hover:bg-backgrounddark text-textmedium"
                                >
                                    <PencilIcon className="h-4 w-4"/>
                                </Button>
                                <Button
                                    onClick={() => handleDeleteVariable(index)}
                                    className="h-8 px-2 bg-transparent hover:bg-backgrounddark text-textmedium"
                                >
                                    <Trash2Icon className="h-4 w-4"/>
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
