import React from "react";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { toast } from "./ui/use-toast";
import { LoadingSpinner } from "./LoadingSpinner";
import { StatusPageForm, FormValues } from "./StatusPageForm";
import { CreateStatusPageRequest, StatusPage, createStatusPage, deleteStatusPage, listStatusPages, updateStatusPage, setOrganizationStatusPagePath } from "../clients/metoro/status_page";
import { UptimeMonitor } from "../clients/metoro/uptime_monitor";
import axios from "../utility/customAxios";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { LayoutDashboard } from "lucide-react";
import AuthContext from "../contexts/JWTContext";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export function StatusPageIntegration() {
    const context = React.useContext(AuthContext);
    const [open, setOpen] = React.useState(false);
    const [openListModal, setOpenListModal] = React.useState(false);
    const [statusPages, setStatusPages] = React.useState<StatusPage[]>([]);
    const [monitors, setMonitors] = React.useState<UptimeMonitor[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [editingStatusPage, setEditingStatusPage] = React.useState<StatusPage | null>(null);
    const [pathDialogOpen, setPathDialogOpen] = React.useState(false);
    const [pathInput, setPathInput] = React.useState("");
    const [pathLoading, setPathLoading] = React.useState(false);
    const [orgPath, setOrgPath] = React.useState<string>("");
    const [currentUser, setCurrentUser] = React.useState<{isAdmin: boolean} | null>(null);

    React.useEffect(() => {
        loadData();
    }, []);

    React.useEffect(() => {
        if (!context?.user?.email) return;
        axios.get("/api/v1/account/organization").then((response) => {
            const users = response.data.organizationMembers;
            const foundUser = users.find((user: any) => user.email === context?.user?.email);
            if (foundUser) {
                setCurrentUser(foundUser);
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [context?.user?.email]);

    const loadData = async () => {
        setLoading(true);
        try {
            const [statusPagesResponse, monitorsResponse, orgResponse] = await Promise.all([
                listStatusPages(),
                axios.get("/api/v1/uptimeMonitor/list"),
                axios.get("/api/v1/account/organization")
            ]);
            setStatusPages(statusPagesResponse.statusPages);
            setMonitors(monitorsResponse.data.uptimeMonitors);
            setOrgPath(orgResponse.data.statusPagePath || orgResponse.data.uuid);
        } catch (e) {
            console.error(e);
            toast({
                title: "Error",
                className: "text-textlight",
                description: "Failed to load data",
                variant: "destructive",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (formValues: FormValues) => {
        try {
            if (editingStatusPage) {
                await updateStatusPage(editingStatusPage.uuid, formValues);
                toast({
                    title: "Success",
                    className: "text-textlight",
                    description: "Status page updated successfully",
                });
            } else {
                await createStatusPage(formValues);
                toast({
                    title: "Success",
                    className: "text-textlight",
                    description: "Status page created successfully",
                });
            }
            loadData();
            setOpen(false);
            setEditingStatusPage(null);
        } catch (e) {
            console.error(e);
            toast({
                title: "Error",
                className: "text-textlight",
                description: `Failed to ${editingStatusPage ? 'update' : 'create'} status page`,
                variant: "destructive",
            });
        }
    };

    const handleDelete = async (uuid: string) => {
        try {
            await deleteStatusPage(uuid);
            toast({
                title: "Success",
                className: "text-textlight",
                description: "Status page deleted successfully",
            });
            loadData();
        } catch (e) {
            console.error(e);
            toast({
                title: "Error",
                className: "text-textlight",
                description: "Failed to delete status page",
                variant: "destructive",
            });
        }
    };

    const handleSetPath = async () => {
        setPathLoading(true);
        try {
            await setOrganizationStatusPagePath(pathInput);
            toast({
                title: "Success",
                className: "text-textlight",
                description: "Status page path updated successfully",
            });
            setPathDialogOpen(false);
            setPathInput("");
        } catch (e: any) {
            console.error(e);
            toast({
                title: "Error",
                className: "text-textlight",
                description: e.response?.data?.error || "Failed to update status page path",
                variant: "destructive",
            });
        } finally {
            setPathLoading(false);
        }
    };

    return (
        <div className="space-y-4 bg-backgroundlight border p-4 rounded-lg text-textmedium">
            <div className="flex justify-between items-center">
                <div>
                    <div className="flex items-center gap-2">
                        <LayoutDashboard className="w-6 h-6 text-primary" />
                        <h3 className="text-lg font-semibold text-textmedium">Status Pages</h3>
                    </div>
                    <p className="text-sm text-textdark">Create and manage public status pages for your services.</p>
                </div>
                <div className="flex gap-2">
                    {currentUser?.isAdmin && (
                        <>
                            <Dialog open={pathDialogOpen} onOpenChange={setPathDialogOpen}>
                                <DialogTrigger asChild>
                                    <Button className="bg-primarytransparent border border-primary text-textmedium">
                                        Set Status Page Path
                                    </Button>
                                </DialogTrigger>
                                <DialogContent className="max-w-md text-textmedium ">
                                    <DialogHeader>
                                        <DialogTitle>Set Status Page Path</DialogTitle>
                                    </DialogHeader>
                                    <div className="space-y-4 py-4">
                                        <div className="space-y-2">
                                            <Label>Path</Label>
                                            <Input
                                                value={pathInput}
                                                onChange={(e) => setPathInput(e.target.value)}
                                                placeholder="your-org-name"
                                                className="bg-backgrounddark"
                                            />
                                            <p className="text-sm text-textdark">
                                                This will be used in your status page URLs: /status/<span className="font-mono">{pathInput || "your-org-name"}</span>/page-name
                                            </p>
                                            <p className="text-sm text-textdark">
                                                Only letters, numbers, hyphens, and underscores are allowed.
                                            </p>
                                        </div>
                                        <Button
                                            onClick={handleSetPath}
                                            disabled={pathLoading || !pathInput}
                                            className="w-full"
                                        >
                                            {pathLoading ? <LoadingSpinner /> : "Save Path"}
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </>
                    )}
                    <Dialog open={openListModal} onOpenChange={setOpenListModal}>
                        <DialogTrigger asChild>
                            <Button className="bg-primarytransparent border border-primary text-textmedium">
                                View Status Pages
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-3xl max-h-[70%] overflow-y-auto">
                            <DialogHeader>
                                <DialogTitle>Status Pages</DialogTitle>
                            </DialogHeader>
                            {loading ? (
                                <div className="flex justify-center p-4">
                                    <LoadingSpinner/>
                                </div>
                            ) : (
                                <div className="mt-4 space-y-4 min-h-0">
                                    {statusPages.length === 0 ? (
                                        <p className="text-center text-sm text-textdark">No status pages configured</p>
                                    ) : (
                                        <div className="space-y-4 h-full overflow-y-auto">
                                            {statusPages.map((page) => (
                                                <div key={page.uuid} className="flex flex-col space-y-2 p-4 bg-backgroundmedium border rounded-lg">
                                                    <div className="flex justify-between items-start">
                                                        <div>
                                                            <h4 className="font-medium text-textmedium">{page.name}</h4>
                                                            <p className="text-sm text-textdark">{page.description}</p>
                                                            <p className="text-sm text-textdark mt-1">
                                                                URL: <a href={`/status/${orgPath}/${page.slug}`} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">/status/{orgPath}/{page.slug}</a>
                                                            </p>
                                                            <p className="text-sm text-textdark">
                                                                Visibility: {page.isPublic ? "Public" : "Private"}
                                                            </p>
                                                            <div className="text-sm text-textdark mt-2">
                                                                <p className="font-medium mb-1">Monitors:</p>
                                                                {page.monitorUuids.map(uuid => {
                                                                    const monitor = monitors.find(m => m.uuid === uuid);
                                                                    return monitor ? (
                                                                        <div key={uuid} className="ml-2">• {monitor.name}</div>
                                                                    ) : null;
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="flex gap-2">
                                                            {currentUser?.isAdmin && (
                                                                <>
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="sm"
                                                                        className="border border-primary text-textlight hover:text-white bg-primarytransparent"
                                                                        onClick={() => {
                                                                            setEditingStatusPage(page);
                                                                            setOpenListModal(false);
                                                                            setOpen(true);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="sm"
                                                                        className="border border-red-500 text-textlight hover:text-white bg-red-500/10"
                                                                        onClick={() => handleDelete(page.uuid)}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </DialogContent>
                    </Dialog>
                    {currentUser?.isAdmin && (
                        <Dialog open={open} onOpenChange={setOpen}>
                            <DialogTrigger asChild>
                                <Button className="text-textmedium bg-primarytransparent border border-primary hover:text-textlight">
                                    Create Status Page
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="max-w-5xl max-h-[98%] overflow-y-auto">
                                <DialogHeader>
                                    <DialogTitle>{editingStatusPage ? "Edit Status Page" : "Create Status Page"}</DialogTitle>
                                </DialogHeader>
                                <StatusPageForm
                                    initialData={editingStatusPage || undefined}
                                    monitors={monitors}
                                    onSubmit={handleSubmit}
                                    isEditing={!!editingStatusPage}
                                    orgPath={orgPath}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </div>
            </div>
        </div>
    );
} 