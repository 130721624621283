import {MathExpression} from "../../../pages/MetricsTest";
import React, {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {ChevronsUpDown, MinusIcon, PlusIcon, SquareFunctionIcon, X, XIcon} from "lucide-react";
import axios from "../../../utility/customAxios";
import {GroupByPill, Pill} from "../../Filter/Pill";
import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {Button} from "../../ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../ui/command";
import {cn} from "../../ui/lib/utils";
import {Input} from "../../ui/input";
import {AxiosPromise} from "axios";
import {FeedSearch, TracesSummaryResponse} from "../../../pages/Traces";
import {Attribute} from "../../../types/telemetry";
import {Formula} from "types/formula";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import {v4 as uuid} from 'uuid';
import {Tooltip, TooltipContent, TooltipTrigger} from "../../ui/tooltip";
import {DateTimePickerRange} from "../../ui/DateTimeSelector";
import {SetURLSearchParams} from "react-router-dom";
import {dashboardJsonReplacer, RuntimeVariable} from "../Dashboard";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../ui/select";
import {ChartType} from "../../Charts/MetoroChart";
import {
    MetricSpecifier,
    MetricType,
    MultiMetoroMetricsChart,
    MultiMetoroMetricsChartProps
} from "../../Charts/MultiMetoroMetricsCharts";
import {Label} from "../../ui/label";
import {Checkbox} from "../../ui/checkbox";
import ColorPicker from "../../ui/color-picker";

interface MetricSelectorProps {
    chartProps: MultiMetoroMetricsChartProps;
    setChartProps: Dispatch<SetStateAction<MultiMetoroMetricsChartProps>>
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setMetricAlertSelected?: Dispatch<SetStateAction<boolean>>;
    minimal?: boolean;
    setSearchParams?: SetURLSearchParams;
    variables?: RuntimeVariable[]
    hideAutoBucket?: boolean
}

const MultiMetricSelectorPanel = (props: MetricSelectorProps) => {
    const timeRange = useSelector(timerange.selectors.getTimeRange)

    const [chartProps, setChartProps] = React.useState<MultiMetoroMetricsChartProps>(props.chartProps || {
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
        metricSpecifiers: [{
            visualization: {
                displayName: "Tcp Connections"
            },
            metricName: "container_net_tcp_active_connections",
            filters: new Map(),
            splits: [],
            aggregation: "avg",
            metricType: MetricType.Metric,
            functions: [],
            bucketSize: 0 // Use chart props bucket size or default to 0 for auto
        }],
        type: ChartType.Bar,
    });

    useEffect(() => {
        if (props.setSearchParams !== undefined) {
            props.setSearchParams(prevSearchParams => {
                let urlSearchParams = new URLSearchParams(prevSearchParams);
                urlSearchParams.set("chart", JSON.stringify(chartProps, dashboardJsonReplacer))
                return urlSearchParams
            })
        }
    }, [chartProps]);

    return (
        <div
            className={cn("flex flex-col grow rounded bg-backgrounddark overflow-y-auto", props.minimal ? "" : " border")}>
            {!props.minimal && <div className={"flex-none flex justify-between border-b h-[32px]"}>
                <div className={"flex flex-col justify-center text-textdark ml-2 items-center text-center"}>
                    Chart Builder
                </div>
                <DateTimePickerRange/>
            </div>}
            <div className={cn("relative flex flex-col grow gap-8", props.minimal ? "" : " p-4")}>
                <MultiMetoroMetricsChart
                    {...chartProps}
                    variables={props.variables}
                    startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                    endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                    hideLegend={false}
                    className={"flex flex-grow h-[300px]"}
                />

                <TypeSelectorPanel type={chartProps.type} setType={(t: ChartType) => {
                    setChartProps((props: MultiMetoroMetricsChartProps) => {
                        return {...props, type: t}
                    })
                }}/>
                <MetricQuerySelectorPanel
                    metricSelectorDisplaySettings={defaultMetricQueryDisplaySettings}
                    variables={props.variables} chartProps={chartProps}
                    setChartProps={setChartProps} hideAutoBucket={props.hideAutoBucket}/>
                {!props.minimal && <SetTitlePanel title={chartProps.title} setTitle={(t: string) => {
                    setChartProps((props: MultiMetoroMetricsChartProps) => {
                        return {...props, title: t}
                    })
                }}/>}
                {/*    Done button */}
                {!props.minimal && <div className={"flex justify-center"}>
                    <div
                        onClick={() => {
                            props.setChartProps(chartProps)
                            props.setIsOpen(false)
                            if (props.setMetricAlertSelected !== undefined) {
                                props.setMetricAlertSelected(true)
                            }
                        }}
                        className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight p-2 rounded hover:bg-primarydark cursor-pointer"}>
                        Save
                    </div>
                </div>}
            </div>
        </div>
    )
}

interface Aggregation {
    name: string;
    value: string;
}

const metricAggregations: Aggregation[] = [
    {
        "name": "average",
        "value": "avg"
    },
    {
        "name": "sum",
        "value": "sum"
    },
    {
        "name": "min",
        "value": "min"
    },
    {
        "name": "max",
        "value": "max"
    }
]

const kubernetesAggregations: Aggregation[] = [
    {
        "name": "count",
        "value": "count"
    },
    {
        "name": "average",
        "value": "avg"
    },
    {
        "name": "sum",
        "value": "sum"
    },
    {
        "name": "min",
        "value": "min"
    },
    {
        "name": "max",
        "value": "max"
    },
]

const onlyTraceAggregations: Aggregation[] = [
    {
        "name": "request count",
        "value": "count"
    },
    {
        "name": "p50 latency",
        "value": "p50"
    },
    {
        "name": "p90 latency",
        "value": "p90"
    },
    {
        "name": "p95 latency",
        "value": "p95"
    },
    {
        "name": "p99 latency",
        "value": "p99"
    },
    {
        "name": "request size",
        "value": "requestSize"
    },
    {
        "name": "response size",
        "value": "responseSize"
    },
    {
        "name": "total size",
        "value": "totalSize"
    }
]

// Helper function to normalize bucket size to appropriate units
const normalizeBucketSize = (minutes: number): { size: number, unit: string } => {
    if (minutes == 0) {
        return {size: 0, unit: "auto"};
    }
    if (minutes >= 1440) { // 24 hours
        return {size: minutes / 1440, unit: "day"};
    } else if (minutes >= 60) {
        return {size: minutes / 60, unit: "hour"};
    }
    return {size: minutes, unit: "minute"};
};

const defaultMetricQueryDisplaySettings: MetricQueryDisplaySettings = {
    showFormulaSelector: true,
    showTypeSelector: true,
    showMetricName: true,
    showGroupBy: true,
    customGroupByString: undefined,
    showAggregation: true,
    showFilters: true,
    showJsonPath: true
}

export interface MetricQueryDisplaySettings {
    showFormulaSelector: boolean
    showTypeSelector: boolean
    showMetricName: boolean,
    showGroupBy: boolean,
    customGroupByString?: string
    showAggregation: boolean
    showFilters: boolean
    showJsonPath: boolean
    filterClause?: string
}

export function EmbedMultiMetricSelector(props: {
    chartProps: MultiMetoroMetricsChartProps,
    setChartProps: Dispatch<SetStateAction<MultiMetoroMetricsChartProps>>,
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    return <div className={"flex flex-col gap-8"}>
        <div className={"flex relative h-[300px]"}>
            <MultiMetoroMetricsChart
                className={"flex flex-grow"}
                startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                metricSpecifiers={
                    props.chartProps.metricSpecifiers
                }
                type={props.chartProps.type}
                threshold={props.chartProps.threshold}
                thresholdLabel={props.chartProps.thresholdLabel}
                hideLegend={false}
                timePeriodHighlight={props.chartProps.timePeriodHighlight}
            />
        </div>
        <div className={"flex flex-col"}>
            <div className={"flex justify-start"}>
                <div className={"flex gap-2 items-center grow"}>
                    <MultiMetricQuerySelector
                        displaySettings={{
                            ...defaultMetricQueryDisplaySettings,
                            showTypeSelector: false
                        }}
                        setChartProps={
                            (value: (((prevState: MetricSpecifier) => MetricSpecifier) | MetricSpecifier)) => {
                                props.setChartProps((prevState: MultiMetoroMetricsChartProps) => {
                                    return {
                                        ...prevState,
                                        // @ts-ignore
                                        metricSpecifiers: [value(prevState.metricSpecifiers[0])]
                                    }
                                })
                            }
                        } chartProps={props.chartProps.metricSpecifiers[0]} hideAutoBucket={true}/>
                </div>
            </div>
        </div>
    </div>
}

export function MultiMetricQuerySelector(props: {
    setChartProps: (value: (((prevState: MetricSpecifier) => MetricSpecifier) | MetricSpecifier)) => void,
    chartProps: MetricSpecifier
    removeAttributes?: string[]
    displaySettings?: MetricQueryDisplaySettings
    variables?: RuntimeVariable[]
    hideAutoBucket?: boolean
    index?: number
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [metricText, setMetricText] = React.useState<string>(props.chartProps.metricName);
    const [metricMatch, metricMatchSet] = React.useState<string[]>([]);
    const [openMetric, setOpenMetric] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openGroupBy, setOpenGroupBy] = React.useState(false);
    const [openAggregate, setOpenAggregate] = React.useState(false);
    const [openTypeSelector, setOpenTypeSelector] = React.useState(false);
    const [bucket, setBucket] = React.useState(false);
    // Initialize bucket size and unit based on normalized value
    const initialBucket = props.chartProps.bucketSize ? normalizeBucketSize(props.chartProps.bucketSize / 60) : {
        size: props.hideAutoBucket ? 1 : 0,
        unit: props.hideAutoBucket ? "minute" : "auto"
    };
    const [bucketSize, setBucketSize] = React.useState<number>(initialBucket.size);
    const [bucketUnit, setBucketUnit] = React.useState<string>(initialBucket.unit);
    const [bucketAuto, setBucketAuto] = React.useState<boolean>(!props.hideAutoBucket);
    const [attributes, setAttributes] = React.useState<Map<string, string[]>>(new Map());
    const [attributeKeyValuePair, setAttributeKeyValuePair] = React.useState<string[][]>([]);
    const [justAttributes, setJustAttributes] = React.useState<string[]>([]);
    const [openVariableNameTooltip, setOpenVariableNameTooltip] = React.useState(false);
    const aggregationToUse = props.chartProps.metricType === MetricType.Metric ? metricAggregations : props.chartProps.metricType === MetricType.Trace ? onlyTraceAggregations : kubernetesAggregations;
    const aggregationPlaceholder = aggregationToUse.find((agg) => agg.value === props.chartProps.aggregation)?.name

    useEffect(() => {
        const newValuePair = []
        const justAttributes = []

        for (const [key, value] of Array.from(attributes.entries())) {
            justAttributes.push(key);
            for (const v of value) {
                newValuePair.push([key, v]);
            }
        }
        setJustAttributes(justAttributes);
        setAttributeKeyValuePair(newValuePair);
    }, [attributes]);

    const [filterKeys, setFilterKeys] = React.useState<string[]>([]);

    let displaySettings = props.displaySettings
    if (props.displaySettings === undefined) {
        displaySettings = defaultMetricQueryDisplaySettings
    }

    function getBucketUnits() {
        const bucketUnits = [
            {name: "minute", value: "minute"},
            {name: "hour", value: "hour"},
            {name: "day", value: "day"},
        ];

        if (props.hideAutoBucket === undefined || !props.hideAutoBucket) {
            bucketUnits.push({name: "auto", value: "auto"});
        }

        return bucketUnits;
    }

    const bucketUnits = getBucketUnits();

    const getBucketSizeInSeconds = () => {
        if (bucketUnit == "auto") {

            return 0;
        }
        const multiplier = bucketUnit === "minute" ? 60 : bucketUnit === "hour" ? 3600 : 86400;
        return bucketSize * multiplier;
    };

    useEffect(() => {
        setBucketAuto(bucketUnit === "auto")
        props.setChartProps((prev) => ({
            ...prev,
            bucketSize: getBucketSizeInSeconds()
        }));
    }, [bucket, bucketUnit]); // bucketSize will change each time we type a new number in input field, we don't want to trigger a change on that


    useEffect(() => {
        if (props.chartProps.metricType === MetricType.Metric) {
            axios.post("/api/v1/metricTotalAttributes", {
                "metricName": props.chartProps.metricName,
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            }).then((response) => {
                let attributes: any[] = []
                attributes = attributes.concat(response.data.attributes);
                let filteredAttributes = []
                // HACK HACK: This lets you remove attributes from the filter list, e.g. its used in infrastructure view
                // to remove the non-label values. This is a hack and should be replaced with a better solution.
                for (const index in attributes) {
                    if (props.removeAttributes && props.removeAttributes.includes(attributes[index])) {
                        // Skip adding this attribute
                    } else {
                        filteredAttributes.push(attributes[index]);
                    }
                }
                setFilterKeys(filteredAttributes);
            })
        } else if (props.chartProps.metricType === MetricType.Trace) {
            axios.get("/api/v1/tracesSummaryAttributes").then((response) => {
                let attributes: any[] = []
                attributes = attributes.concat(response.data.attributes);
                setFilterKeys(attributes);
            })
        } else if (props.chartProps.metricType === MetricType.Kubernetes) {
            axios.get("/api/v1/kubernetesSummaryAttributes").then((response) => {
                let attributes: any[] = []
                attributes = attributes.concat(response.data.attributes);
                setFilterKeys(attributes);
            })
        }
    }, [props.chartProps.metricName, timeRange, props.chartProps.metricType]);

    useEffect(() => {
        async function fetchMetricAttributes() {
            if (metricText && metricText.length > 0) {
                const startEnd = timeRange.getStartEnd();
                const d = axios.post("/api/v1/metricAttributes", {
                    "startTime": Math.floor((startEnd[0].getTime() / 1000)),
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    "metricName": props.chartProps.metricName,
                    "filterAttributes": Object.fromEntries(props.chartProps.filters || new Map()),
                });
                let awaited = (await d).data;
                let newAttributes = new Map<string, string[]>();
                // HACK HACK: This lets you remove attributes from the group by list, e.g. its used in infrastructure view
                for (const attribute in awaited.attributes) {
                    if (props.removeAttributes && props.removeAttributes.includes(attribute)) {
                        // Skip adding this attribute
                    } else {
                        newAttributes.set(attribute, awaited.attributes[attribute]);
                    }
                }
                setAttributes(newAttributes);
            }
        }

        async function fetchTraceAttributes() {
            const startEnd = timeRange.getStartEnd();
            const d: AxiosPromise<TracesSummaryResponse> = axios.post("/api/v1/tracesSummary", {
                    "startTime": Math.floor((startEnd[1].getTime() / 1000) - (5 * 60)), // startTime is 5 minutes before endtime
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    "excludeFilters": Object.fromEntries(props.chartProps.excludeFilters || new Map()),
                    "filters": Object.fromEntries(props.chartProps.filters || new Map()),
                    "regexes": [],
                    "excludeRegexes": [],
                }
            )
            const awaited = (await d).data.attributes;
            let newAttributes = new Map<string, string[]>();
            for (const attribute in awaited) {
                const valueArray = (awaited as any)[attribute].map((v: Attribute) => v.value)
                newAttributes.set(attribute, valueArray);
            }
            setAttributes(newAttributes);
        }

        async function fetchKubernetesAttributes() {
            const startEnd = timeRange.getStartEnd();
            const d: AxiosPromise = axios.get("/api/v1/kubernetesSummaryAttributes")
            let axiosResponse = await d;
            console.log(axiosResponse)
            const awaited: string[] = axiosResponse.data.attributes;
            console.log(awaited)

            let newAttributes = new Map<string, string[]>();
            for (const [key, value] of Object.entries(awaited)) {
                console.log(value)
                newAttributes.set(value, [value]);
            }
            setAttributes(newAttributes);
        }


        try {
            if (props.chartProps.metricType == MetricType.Metric) {
                fetchMetricAttributes();
            } else if (props.chartProps.metricType == MetricType.Trace) {
                fetchTraceAttributes();
            } else if (props.chartProps.metricType == MetricType.Kubernetes) {
                fetchKubernetesAttributes();
            }
        } catch (e) {
            console.error(e);
        }
    }, [props, props.chartProps.metricName, timeRange]);

    useEffect(() => {
        async function fetchFuzzyMetricNames() {
            const d = axios.post("/api/v1/fuzzyMetricsNames", {
                "metricFuzzyMatch": metricText,
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            });
            let awaited = (await d).data;
            metricMatchSet(awaited.metrics);
        }

        try {
            if (props.chartProps.metricType === MetricType.Metric) {
                fetchFuzzyMetricNames();
            }
        } catch (e) {
            console.error(e);
        }
    }, [metricText, props.chartProps.metricType]);


    let pills: ReactElement[] = [];
    if (props.chartProps.filters !== undefined) {
        const entries = Array.from(props.chartProps.filters.entries());
        for (const [key, values] of entries) {
            if (values.length === 0) {
                continue;
            }
            const valuesString = values.join(" || ")
            pills.push(<Pill
                isEditable={true}
                key={key + valuesString}
                attributeKey={key}
                attributeValue={valuesString}
                filter={props.chartProps.filters}
                excludeFilter={props.chartProps.excludeFilters}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                setFilter={(filter) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, filters: filter}
                    })
                }}/>)
        }
    }

    if (props.chartProps.excludeFilters !== undefined) {
        const entries = Array.from(props.chartProps.excludeFilters.entries());
        for (const [key, values] of entries) {
            if (values.length === 0) {
                continue;
            }
            const valuesString = values.join(" || ")
            pills.push(<Pill
                isEditable={true}
                key={key + valuesString}
                isExclude={true}
                attributeKey={key}
                attributeValue={valuesString}
                filter={props.chartProps.filters!}
                excludeFilter={props.chartProps.excludeFilters}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                setFilter={(filter) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, filters: filter}
                    })
                }}/>)
        }
    }

    let groupByPills: ReactElement[] = [];
    if (props.chartProps.splits !== undefined) {
        for (const split of props.chartProps.splits) {
            groupByPills.push(<GroupByPill key={split} attributeKey={split}
                                           groupBy={props.chartProps.splits}
                                           setGroupBy={(groupBy) => {
                                               props.setChartProps((props: MetricSpecifier) => {
                                                   return {...props, splits: groupBy}
                                               })
                                           }}/>)
        }
    }


    let typeSelector = displaySettings!.showTypeSelector && <div
        className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r p-2"}>
        <Popover open={openTypeSelector} modal={true}>
            <PopoverTrigger asChild className={"flex grow"}>
                <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                     onClick={() => setOpenTypeSelector(!openTypeSelector)}>
                    <div className={"flex justify-between gap-2"}>
                        <div className={"ml-1 flex flex-col justify-center"}>
                            {props.chartProps.metricType}
                        </div>
                        <div className={"flex flex-col justify-center"}>
                            <ChevronsUpDown className={"h-4 w-4"}/>
                        </div>
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent side={"bottom"} avoidCollisions={true}
                            onFocusOutside={() => setOpenTypeSelector(false)}
                            onEscapeKeyDown={() => setOpenTypeSelector(false)}
                            onInteractOutside={() => setOpenTypeSelector(false)}
                            className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
            >
                <Command>
                    <CommandInput id={"free_text_input2"} placeholder={"Select an metric type..."}
                                  className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                    <CommandList className={"text-textlight"}>
                        <CommandEmpty>No types found.</CommandEmpty>
                        <CommandGroup>

                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                key={"metric"} onSelect={() => {
                                setOpenTypeSelector(false);
                                props.setChartProps((props: MetricSpecifier) => {
                                    return {
                                        ...props,
                                        metricType: MetricType.Metric,
                                        aggregation: "avg",
                                        filters: new Map(),
                                        splits: [],
                                        title: "Active TCP Connections",
                                    }
                                })
                            }}>
                                {"metric"}
                            </CommandItem>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                key={"metric"} onSelect={() => {
                                setOpenTypeSelector(false);
                                props.setChartProps((props: MetricSpecifier) => {
                                    return {
                                        ...props,
                                        metricType: MetricType.Trace,
                                        aggregation: "count",
                                        filters: new Map(),
                                        splits: [],
                                        title: "Number of requests",
                                    }
                                })
                            }}>
                                {"trace"}
                            </CommandItem>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                key={"metric"} onSelect={() => {
                                setOpenTypeSelector(false);
                                props.setChartProps((props: MetricSpecifier) => {
                                    return {
                                        ...props,
                                        metricType: MetricType.Kubernetes,
                                        aggregation: "count",
                                        filters: new Map(),
                                        splits: [],
                                        title: "Total number of resources",
                                    }
                                })
                            }}>
                                {"kubernetes_resource"}
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>;
    return <div className={"flex border min-h-[48px] rounded grow"}>
        {/* Remove the MetricType.Metric condition and show for all types */}
        <Tooltip>
            <TooltipTrigger
                onClick={() => {
                    props.setChartProps((prevState: MetricSpecifier) => {
                        return {
                            ...prevState,
                            shouldNotReturn: !prevState.shouldNotReturn
                        }
                    })
                }}
                className={cn(
                    "bg-backgrounddark flex justify-center items-center text-center text-textmedium px-2 border-r cursor-pointer transition-all",
                    "hover:bg-backgroundmedium hover:text-textlight",
                    props.chartProps.shouldNotReturn && "opacity-50"
                )}>
                <span className={cn(
                    "rounded-lg bg-secondarytransparenter px-2 py-1 text-xs font-semibold text-textmedium border border-secondary transition-all",
                    props.chartProps.shouldNotReturn && "line-through opacity-50"
                )}>
                    {String.fromCharCode(97 + (props.index ?? 0))}
                </span>
            </TooltipTrigger>
            <TooltipContent className={"bg-backgroundmedium border rounded px-1 py-2 text-textmedium w-[300px]"}>
                {props.chartProps.shouldNotReturn ?
                    "Click to show this metric in the chart" :
                    "Click to hide this metric from the chart (it will still be available for formulas)"}
                <br/>
                <span className="text-xs opacity-75">
                    This metric can be referenced in formulas using the variable '{String.fromCharCode(97 + (props.index ?? 0))}'
                </span>
            </TooltipContent>
        </Tooltip>
        {typeSelector}
        {displaySettings?.showMetricName && props.chartProps.metricType == MetricType.Metric && (
            <div className={"bg-backgroundmedium items-center flex flex-col grow justify-center border-r"}>
                <Popover open={openMetric} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={openMetric}
                            className="flex w-full justify-between text-textmedium px-2"
                            onClick={() => setOpenMetric(!openMetric)}
                        >
                            {props.chartProps.metricName}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenMetric(false)}
                                    onEscapeKeyDown={() => setOpenMetric(false)}
                                    onInteractOutside={() => setOpenMetric(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]">
                        <Command>
                            <CommandInput placeholder={"Search metrics..."} value={metricText} id={"free_text_input"}
                                          onChangeCapture={(e) => {
                                              setMetricText(e.currentTarget.value);
                                          }}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No metrics found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        metricMatch.map((match, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenMetric(false);
                                                setMetricText(match);
                                                props.setChartProps((props: MetricSpecifier) => {
                                                    return {...props, metricName: match}
                                                })
                                            }}>
                                                {match}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        )}
        {displaySettings?.showAggregation && props.chartProps.metricType == MetricType.Trace &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r p-2"}>
                <Popover open={openAggregate} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                             onClick={() => setOpenAggregate(!openGroupBy)}>
                            <div className={"flex justify-between gap-2"}>
                                <div className={"ml-1 flex flex-col justify-center"}>
                                    {aggregationPlaceholder != undefined && aggregationPlaceholder != "" ? aggregationPlaceholder : aggregationToUse[0].name}
                                </div>
                                <div className={"flex flex-col justify-center"}>
                                    <ChevronsUpDown className={"h-4 w-4"}/>
                                </div>
                            </div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenAggregate(false)}
                                    onEscapeKeyDown={() => setOpenAggregate(false)}
                                    onInteractOutside={() => setOpenAggregate(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Select an aggregation..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No aggregations found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        aggregationToUse.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenAggregate(false);

                                                props.setChartProps((props: MetricSpecifier) => {
                                                    return {...props, aggregation: kv.value}
                                                })
                                            }}>
                                                {kv.name}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>}
        {displaySettings?.showFilters && <div
            className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium p-2 border-r"}>
            {props.chartProps.metricType != MetricType.Trace ? (props.displaySettings && props.displaySettings?.filterClause ? props.displaySettings!.filterClause : "where") : "of"}
        </div>}
        {displaySettings?.showFilters && props.chartProps.metricType == MetricType.Kubernetes &&
            <FeedSearch
                isEmbedded={true}
                filter={props.chartProps.filters || new Map()}
                setFilter={(filter) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, filters: filter}
                    })
                }}
                excludeFilter={props.chartProps.excludeFilters || new Map()}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                regexes={[]}
                setRegexes={() => {
                }}
                excludeRegexes={[]}
                setExcludeRegexes={() => {
                }}
                filterAttributes={filterKeys}
                dropDownFunction={(key) => {
                    let filters = Object.fromEntries(props.chartProps.filters || new Map());
                    let excludeFilters = Object.fromEntries(props.chartProps.excludeFilters || new Map());
                    return axios.post("/api/v1/kubernetesSummaryIndividualAttribute", {
                            "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                            "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                            "excludeFilters": excludeFilters,
                            "filters": filters,
                            "attribute": key
                        }
                    ).then((response) => {
                        const attributes: Attribute[] = [];
                        if (props.variables !== undefined) {
                            for (let i = 0; i < props.variables.length; i++) {
                                attributes.push({
                                    "value": "$" + props.variables[i].name,
                                    "volume": 0
                                });
                            }
                        }
                        // Add null check for response.data.attribute
                        if (response.data && response.data.attribute && Array.isArray(response.data.attribute)) {
                            for (let i = 0; i < response.data.attribute.length; i++) {
                                if (response.data.attribute[i] && typeof response.data.attribute[i].value === 'string') {
                                    attributes.push({
                                        value: response.data.attribute[i].value,
                                        volume: response.data.attribute[i].volume || 0
                                    });
                                }
                            }
                        }
                        return attributes;
                    }).catch((error) => {
                        console.error('Error fetching kubernetes attributes:', error);
                        return [] as Attribute[]; // Return empty array on error with proper type
                    });
                }}
            />
        }

        {displaySettings?.showFilters && props.chartProps.metricType == MetricType.Trace &&
            <FeedSearch
                isEmbedded={true}
                filter={props.chartProps.filters || new Map()}
                setFilter={(filter) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, filters: filter}
                    })
                }}
                excludeFilter={props.chartProps.excludeFilters || new Map()}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                regexes={[]}
                setRegexes={() => {
                }}
                excludeRegexes={[]}
                setExcludeRegexes={() => {
                }}
                filterAttributes={filterKeys}
                dropDownFunction={(key) => {
                    let filters = Object.fromEntries(props.chartProps.filters || new Map());
                    let excludeFilters = Object.fromEntries(props.chartProps.excludeFilters || new Map());
                    return axios.post("/api/v1/tracesSummaryIndividualAttribute", {
                            "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                            "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                            // Removed because we want to keep everything for now
                            "excludeFilters": excludeFilters,
                            // Removed because we want to keep everything for now
                            "filters": filters,
                            // Removed because we want to keep everything for now
                            // "regexes": props.chartProps.regexes,
                            // Removed because we want to keep everything for now
                            // "excludeRegexes": props.chartProps.excludeRegexes,
                            // "environments": environments[0] === "" ? [] : environments,
                            "attribute": key
                        }
                    ).then((response) => {
                        let attributes = []
                        if (props.variables != undefined) {
                            for (let i = 0; i < props.variables.length; i++) {
                                attributes.push({
                                    "value": "$" + props.variables[i].name,
                                    "volume": 0
                                })
                            }
                        }
                        for (let i = 0; i < response.data.attribute.length; i++) {
                            attributes.push(response.data.attribute[i])
                        }
                        return attributes;
                    })
                }}
            />
        }
        {displaySettings?.showFilters && props.chartProps.metricType == MetricType.Metric &&
            <FeedSearch
                isEmbedded={true}
                filter={props.chartProps.filters || new Map()}
                setFilter={(filter) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, filters: filter}
                    })
                }}
                excludeFilter={props.chartProps.excludeFilters || new Map()}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetricSpecifier) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                regexes={[]}
                setRegexes={() => {
                }}
                excludeRegexes={[]}
                setExcludeRegexes={() => {
                }}
                filterAttributes={filterKeys}
                dropDownFunction={(key) => {
                    return axios.post("/api/v1/metricIndividualAttribute", {
                            "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                            "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                            "metricName": props.chartProps.metricName,
                            // Removed because we want to keep everything for now
                            // "excludeFilters": excludeFilters,
                            // Removed because we want to keep everything for now
                            // "filters": filters,
                            // Removed because we want to keep everything for now
                            // "regexes": props.chartProps.regexes,
                            // Removed because we want to keep everything for now
                            // "excludeRegexes": props.chartProps.excludeRegexes,
                            // "environments": environments[0] === "" ? [] : environments,
                            "attribute": key
                        }
                    ).then((response) => {
                        let attributes = []
                        if (props.variables != undefined) {
                            for (let i = 0; i < props.variables.length; i++) {
                                attributes.push({
                                    "value": "$" + props.variables[i].name,
                                    "volume": 0
                                })
                            }
                        }
                        for (let i = 0; i < response.data.attribute.length; i++) {
                            attributes.push(
                                {
                                    "value": response.data.attribute[i],
                                    "volume": 0
                                }
                            )
                        }
                        return attributes;
                    })
                }}
            />
        }
        {displaySettings?.showAggregation && props.chartProps.metricType == MetricType.Kubernetes &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r border-l p-2"}>
                <Popover open={openAggregate} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                             onClick={() => setOpenAggregate(!openGroupBy)}>
                            <div className={"flex justify-between gap-2"}>
                                <div className={"ml-1 flex flex-col justify-center"}>
                                    {aggregationPlaceholder != undefined && aggregationPlaceholder != "" ? aggregationPlaceholder : aggregationToUse[0].name}
                                </div>
                                <div className={"flex flex-col justify-center"}>
                                    <ChevronsUpDown className={"h-4 w-4"}/>
                                </div>
                            </div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenAggregate(false)}
                                    onEscapeKeyDown={() => setOpenAggregate(false)}
                                    onInteractOutside={() => setOpenAggregate(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Select an aggregation..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No aggregations found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        aggregationToUse.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenAggregate(false);
                                                props.setChartProps((props: MetricSpecifier) => {
                                                    if (kv.value == "count") {
                                                        return {
                                                            ...props,
                                                            aggregation: "count",
                                                            jsonPath: undefined
                                                        }
                                                    }
                                                    return {...props, aggregation: kv.value}
                                                })
                                            }}>
                                                {kv.name}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
        {
            displaySettings?.showJsonPath && props.chartProps.metricType == MetricType.Kubernetes && props.chartProps.aggregation !== "count" &&
            <div className={"flex grow shrink max-w-[180px] flex-col items-center"}>
                <Input
                    className={"flex grow shrink border-none text-center"}
                    placeholder={"JSON Path"}
                    value={props.chartProps.jsonPath}
                    onChange={(e) => {
                        props.setChartProps((props: MetricSpecifier) => {
                            return {
                                ...props,
                                jsonPath: e.target.value
                            }
                        })
                    }}
                />
            </div>
        }
        {displaySettings?.showAggregation && props.chartProps.metricType == MetricType.Metric &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r border-l p-2"}>
                <Popover open={openAggregate} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                             onClick={() => setOpenAggregate(!openGroupBy)}>
                            <div className={"flex justify-between gap-2"}>
                                <div className={"ml-1 flex flex-col justify-center"}>
                                    {aggregationPlaceholder != undefined && aggregationPlaceholder != "" ? aggregationPlaceholder : aggregationToUse[0].name}
                                </div>
                                <div className={"flex flex-col justify-center"}>
                                    <ChevronsUpDown className={"h-4 w-4"}/>
                                </div>
                            </div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenAggregate(false)}
                                    onEscapeKeyDown={() => setOpenAggregate(false)}
                                    onInteractOutside={() => setOpenAggregate(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Select an aggregation..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No aggregations found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        aggregationToUse.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenAggregate(false);

                                                props.setChartProps((props: MetricSpecifier) => {
                                                    return {...props, aggregation: kv.value}
                                                })
                                            }}>
                                                {kv.name}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
        {displaySettings?.showGroupBy &&
            <div
                className={cn("flex grow  bg-backgroundmedium", displaySettings?.showAggregation && props.chartProps.metricType == MetricType.Metric ? "" : "border-l")}>
                <Popover open={openGroupBy} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div
                            className={"flex p-2 flex-wrap gap-4 bg-backgroundmedium hover:cursor-pointer text-textdark items-center"}
                            onClick={() => setOpenGroupBy(!openGroupBy)}
                        >
                            {groupByPills.length == 0 && (props.displaySettings?.customGroupByString || "Group by...")}
                            {groupByPills.length != 0 && groupByPills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenGroupBy(false)}
                                    onEscapeKeyDown={() => setOpenGroupBy(false)}
                                    onInteractOutside={() => setOpenGroupBy(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Group by..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No attributes found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        justAttributes.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenGroupBy(false);

                                                props.setChartProps((props: MetricSpecifier) => {
                                                    const newGroupBy = props.splits ? [...props.splits] : [];
                                                    newGroupBy.push(kv);
                                                    return {...props, splits: newGroupBy}
                                                })
                                            }}>
                                                {kv}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
        {displaySettings?.showAggregation && (
            <div className={"flex"}>
                <Tooltip>
                    <TooltipTrigger
                        className={"bg-backgrounddark flex justify-center items-center text-center text-textmedium border-l border-r p-2"}>
                        bucket
                    </TooltipTrigger>
                    <TooltipContent className={"text-textmedium w-[400px]"}> A datapoint bucket defines the time
                        interval for data aggregation. For example, selecting a 5-minute bucket means that each
                        datapoint represents the aggregated value of data collected over a 5-minute period. By default,
                        its set to Auto, meaning
                        Metoro calculates the bucket size based on the time range selected.
                    </TooltipContent>
                </Tooltip>

                {!bucketAuto && <div className="flex items-center h-full">
                    <Input
                        type="number"
                        min="1"
                        value={bucketSize}
                        onChange={(e) => setBucketSize(parseInt(e.target.value))}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Escape") {
                                setBucket(!bucket);
                            }
                        }}
                        onBlur={() => setBucket(!bucket)}
                        className="w-8 border-none text-center px-1"
                    />
                    <Select value={bucketUnit} onValueChange={setBucketUnit}>
                        <SelectTrigger
                            className={"h-full text-textmedium border-none hover:text-textlight hover:cursor-pointer px-2"}>
                            <SelectValue className={"font-semibold"}/>
                        </SelectTrigger>
                        <SelectContent className={"bg-backgroundmedium border rounded text-textmedium"}>
                            {bucketUnits.map((unit) => (
                                <SelectItem key={unit.value} value={unit.value}
                                            className={"hover:border hover:border-secondary hover:bg-secondarytransparenter hover:cursor-pointer"}>
                                    {unit.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>}
                {bucketAuto &&
                    <Select value={bucketUnit} onValueChange={setBucketUnit}>
                        <SelectTrigger
                            className={"h-full text-textmedium border-none hover:text-textlight hover:cursor-pointer px-2"}>
                            <SelectValue className={"font-semibold"}/>
                        </SelectTrigger>
                        <SelectContent className={"bg-backgroundmedium border rounded text-textmedium"}>
                            {bucketUnits.map((unit) => (
                                <SelectItem key={unit.value} value={unit.value}
                                            className={"hover:border hover:border-secondary hover:bg-secondarytransparenter hover:cursor-pointer"}>
                                    {unit.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                }
            </div>
        )}
    </div>
}

function AddTimeSeriesButton(props: {
    setChartProps: (value: (((prevState: MultiMetoroMetricsChartProps) => MultiMetoroMetricsChartProps) | MultiMetoroMetricsChartProps)) => void
}) {
    return (
        <Button
            className={"bg-primarytransparent border border-primary rounded text-textmedium w-[212px]"}
            onClick={() => {
                props.setChartProps((prevState: MultiMetoroMetricsChartProps) => {
                    return {
                        ...prevState, metricSpecifiers: [...prevState.metricSpecifiers, {
                            visualization: {
                                displayName: "Tcp Connections"
                            },
                            metricName: "container_net_tcp_active_connections",
                            filters: new Map(),
                            splits: [],
                            aggregation: "avg",
                            metricType: MetricType.Metric,
                            functions: [],
                            bucketSize: 0 // Use chart props bucket size or default to 0 for auto
                        }]
                    }
                })
            }}>
            <PlusIcon className={"mr-2"}/>
            Add Time Series
        </Button>
    )
}

function DeleteTimeSeriesButton(props: {
    setChartProps: (value: (((prevState: MultiMetoroMetricsChartProps) => MultiMetoroMetricsChartProps) | MultiMetoroMetricsChartProps)) => void,
    index: number
}) {
    return <div
        className={"flex w-max border-red-500 bg-redtransparenter border rounded mt-2 gap-x-1 p-1 pr-2 text-textmedium hover:cursor-pointer hover:text-textlight"}
        onClick={() => {
            props.setChartProps((prevState: MultiMetoroMetricsChartProps) => {
                let newMetricSpecifiers = [...prevState.metricSpecifiers];
                newMetricSpecifiers.splice(props.index, 1);
                return {
                    ...prevState, metricSpecifiers: newMetricSpecifiers
                }
            })
        }}
    >
        <div className={"flex items-center justify-center"}>
            <MinusIcon className={"w-4 h-4"}></MinusIcon>
        </div>
        <div>Delete Metric</div>
    </div>
}

function EditVisualizationButton(props: {
    setChartProps: (value: (((prevState: MetricSpecifier) => MetricSpecifier) | MetricSpecifier)) => void,
    metricSpecifier: MetricSpecifier
}) {
    const [localDisplayName, setLocalDisplayName] = useState(props.metricSpecifier?.visualization?.displayName || "");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (localDisplayName !== props.metricSpecifier?.visualization?.displayName) {
                props.setChartProps((prev) => ({
                    ...prev,
                    visualization: {
                        ...prev.visualization,
                        displayName: localDisplayName || undefined
                    }
                }));
            }
        }, 500); // 500ms debounce delay

        return () => clearTimeout(timeoutId);
    }, [localDisplayName, props.metricSpecifier?.visualization?.displayName]);

    const updateChartProps = () => {
        if (localDisplayName !== props.metricSpecifier?.visualization?.displayName) {
            props.setChartProps((prev) => ({
                ...prev,
                visualization: {
                    ...prev.visualization,
                    displayName: localDisplayName || undefined
                }
            }));
        }
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div
                    className={"flex w-max bg-background border rounded mt-2 gap-x-1 p-1 pr-2 text-textmedium hover:cursor-pointer hover:text-textlight"}
                >
                    <div className={"flex items-center justify-center"}>
                        <PlusIcon className={"w-4 h-4"}></PlusIcon>
                    </div>
                    <div>Edit display settings</div>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-80 bg-backgroundmedium p-2 text-textmedium">
                <div className="grid gap-4">
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none">Display Settings</h4>
                        <p className="text-sm text-muted-foreground">
                            Customize how this metric appears in the chart
                        </p>
                    </div>
                    <div className="grid gap-2">
                        <div className="grid grid-cols-3 items-center gap-4">
                            <Label htmlFor="displayName">Name</Label>
                            <div className="col-span-2 flex gap-2 items-center">
                                <Input
                                    id="displayName"
                                    className="flex-1"
                                    value={localDisplayName}
                                    placeholder={props.metricSpecifier.metricName}
                                    onChange={(e) => setLocalDisplayName(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            updateChartProps();
                                        }
                                    }}
                                    onBlur={updateChartProps}/>
                                {props.metricSpecifier?.visualization?.displayName && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8"
                                        onClick={() => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                displayName: undefined
                                            }
                                        }))}
                                    >
                                        <XIcon className="h-4 w-4"/>
                                    </Button>
                                )}
                                {!props.metricSpecifier?.visualization?.displayName && (
                                    <span className="text-sm text-muted-foreground">(Default)</span>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 items-center gap-4">
                            <Label htmlFor="lineColor">Line Color</Label>
                            <div className="col-span-2 flex gap-2 items-center">
                                <ColorPicker value={props.metricSpecifier.visualization?.lineColor || "#000000"}
                                             onChange={
                                                 val => props.setChartProps((prev) => ({
                                                     ...prev,
                                                     visualization: {
                                                         ...prev.visualization,
                                                         lineColor: val
                                                     }
                                                 }))
                                             }>
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-8 h-8 rounded">
                                            <div className="w-6 h-6 rounded"
                                                 style={{backgroundColor: props.metricSpecifier?.visualization?.lineColor || "#000000"}}></div>
                                        </div>
                                        <span
                                            className="text-sm text-muted-foreground">{props.metricSpecifier?.visualization?.lineColor || ""}</span>
                                    </div>
                                </ColorPicker>
                                {props.metricSpecifier?.visualization?.lineColor && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8"
                                        onClick={() => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                lineColor: undefined
                                            }
                                        }))}
                                    >
                                        <XIcon className="h-4 w-4"/>
                                    </Button>
                                )}
                                {!props.metricSpecifier?.visualization?.lineColor && (
                                    <span className="text-sm text-muted-foreground">(Default)</span>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 items-center gap-4">
                            <Label htmlFor="dotColor">Fill Color</Label>
                            <div className="col-span-2 flex gap-2 items-center">
                                <ColorPicker value={props.metricSpecifier.visualization?.lineDotColor || "#000000"}
                                             onChange={
                                                 val => props.setChartProps((prev) => ({
                                                     ...prev,
                                                     visualization: {
                                                         ...prev.visualization,
                                                         lineDotColor: val
                                                     }
                                                 }))
                                             }>
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center justify-center w-8 h-8 rounded">
                                            <div className="w-6 h-6 rounded"
                                                 style={{backgroundColor: props.metricSpecifier?.visualization?.lineDotColor || "#000000"}}></div>
                                        </div>
                                        <span
                                            className="text-sm text-muted-foreground">{props.metricSpecifier?.visualization?.lineDotColor || ""}</span>
                                    </div>
                                </ColorPicker>
                                {props.metricSpecifier?.visualization?.lineDotColor && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8"
                                        onClick={() => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                lineDotColor: undefined
                                            }
                                        }))}
                                    >
                                        <XIcon className="h-4 w-4"/>
                                    </Button>
                                )}
                                {!props.metricSpecifier?.visualization?.lineDotColor && (
                                    <span className="text-sm text-muted-foreground">(Default)</span>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 items-center gap-4">
                            <Label htmlFor="dotSize">Dot Size</Label>
                            <div className="col-span-2 flex gap-2 items-center">
                                <Input
                                    id="dotSize"
                                    value={props.metricSpecifier?.visualization?.lineDotSize}
                                    className="flex-1"
                                    onChange={(e) => props.setChartProps((prev) => ({
                                        ...prev,
                                        visualization: {
                                            ...prev.visualization,
                                            lineDotSize: parseInt(e.target.value) || 0
                                        }
                                    }))}
                                />
                                {props.metricSpecifier?.visualization?.lineDotSize && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8"
                                        onClick={() => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                lineDotSize: undefined
                                            }
                                        }))}
                                    >
                                        <XIcon className="h-4 w-4"/>
                                    </Button>
                                )}
                                {!props.metricSpecifier?.visualization?.lineDotSize && (
                                    <span className="text-sm text-muted-foreground">(Default)</span>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-3 items-center gap-4">
                            <Label htmlFor="dashed">Dashed Line</Label>
                            <div className="col-span-2 flex gap-2 items-center">
                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id="dashed"
                                        checked={props.metricSpecifier?.visualization?.lineDash?.length && props.metricSpecifier?.visualization?.lineDash?.length > 0 || false}
                                        onCheckedChange={(checked) => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                lineDash: checked ? [10, 10] : undefined
                                            }
                                        }))}
                                    />
                                </div>
                                {props.metricSpecifier?.visualization?.lineDash && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8"
                                        onClick={() => props.setChartProps((prev) => ({
                                            ...prev,
                                            visualization: {
                                                ...prev.visualization,
                                                lineDash: undefined
                                            }
                                        }))}
                                    >
                                        <XIcon className="h-4 w-4"/>
                                    </Button>
                                )}
                                {!props.metricSpecifier?.visualization?.lineDash && (
                                    <span className="text-sm text-muted-foreground">(Default)</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Button
                        variant="outline"
                        className="mt-2"
                        onClick={() => props.setChartProps((prev) => ({
                            ...prev,
                            visualization: undefined
                        }))}
                    >
                        Reset to Default
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export function MetricQuerySelectorPanel(props: {
    setChartProps: (value: (((prevState: MultiMetoroMetricsChartProps) => MultiMetoroMetricsChartProps) | MultiMetoroMetricsChartProps)) => void,
    chartProps: MultiMetoroMetricsChartProps
    minimal?: boolean
    variables?: RuntimeVariable[]
    hideAutoBucket?: boolean
    disableMultipleMetrics?: boolean;
    disableVisualEditButton?: boolean;
    metricSelectorDisplaySettings?: MetricQueryDisplaySettings
    stepNumber?: number;
}) {

    let selectors = [];
    for (let i = 0; i < props.chartProps.metricSpecifiers.length; i++) {
        let setChartProps = (value: (((prevState: MetricSpecifier) => MetricSpecifier) | MetricSpecifier)) => {
            props.setChartProps((prevState: MultiMetoroMetricsChartProps) => {
                let newMetricSpecifiers = [...prevState.metricSpecifiers];
                // Check if value is a function
                if (typeof value === "function") {
                    newMetricSpecifiers[i] = value(prevState.metricSpecifiers[i]);
                } else {
                    newMetricSpecifiers[i] = value;
                }
                return {...prevState, metricSpecifiers: newMetricSpecifiers}
            })
        }
        selectors.push(
            <div className={"flex flex-col"}>
                <MultiMetricQuerySelector
                    key={i}
                    index={i}
                    setChartProps={setChartProps}
                    chartProps={props.chartProps.metricSpecifiers[i]}
                    variables={props.variables}
                    hideAutoBucket={props.hideAutoBucket}
                    displaySettings={props.metricSelectorDisplaySettings}
                />
                <div className={"flex justify-start"}>
                    <div className={"flex gap-2 items-center grow"}>
                        <div className={"flex flex-col"}>
                            {props.chartProps.metricSpecifiers[i].functions && props.chartProps.metricSpecifiers[i].functions.map((func, index) => {
                                return <MetricFunctionSelector metricFunction={func}
                                                               setChartProps={setChartProps}/>
                            })}
                            <div className={"flex gap-x-2"}>
                                <AddMetricFunctionButton setChartProps={setChartProps}/>
                                {!props.disableVisualEditButton &&
                                    <EditVisualizationButton setChartProps={setChartProps}
                                                             metricSpecifier={props.chartProps.metricSpecifiers[i]}/>}
                                {!props.disableMultipleMetrics &&
                                    <DeleteTimeSeriesButton index={i} setChartProps={props.setChartProps}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <div className={"flex flex-col gap-4"}>
        {(props.minimal === undefined || !props.minimal) && <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber ? props.stepNumber : 2}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Data
            </div>
        </div>}
        <div className={"flex flex-col gap-y-4"}>
            {selectors}
            <div className="flex gap-2">
                {!props.disableMultipleMetrics && <AddTimeSeriesButton setChartProps={props.setChartProps}/>}
                {props.metricSelectorDisplaySettings?.showFormulaSelector &&
                    <AddFormulaButton setChartProps={props.setChartProps}/>}
            </div>
            {props.chartProps.formulas && props.chartProps.formulas.length > 0 && (
                <div className="flex flex-col gap-4 mt-4">
                    <div className="text-textlight font-medium">Formulas</div>
                    {props.chartProps.formulas.map((formula, index) => (
                        <FormulaInput
                            key={index}
                            formula={formula}
                            index={index}
                            setChartProps={props.setChartProps}
                            metricIdentifiers={props.chartProps.metricSpecifiers.map((_, i) => String.fromCharCode(97 + i))}
                        />
                    ))}
                </div>
            )}
        </div>
    </div>
}

function AddMetricFunctionButton(props: {
    setChartProps: Dispatch<SetStateAction<MetricSpecifier>>
}) {
    return <div
        className={"flex w-max bg-background border rounded mt-2 gap-x-1 p-1 pr-2 text-textmedium hover:cursor-pointer hover:text-textlight"}
        onClick={() => props.setChartProps(
            (prev: MetricSpecifier) => {
                if (prev.functions === undefined) {
                    return {
                        ...prev,
                        functions: [{
                            id: uuid(),
                            functionType: MathExpressionFunctionType.CustomMathExpression
                        }]
                    }
                }
                return {
                    ...prev,
                    functions: [...prev.functions,
                        {id: uuid(), functionType: MathExpressionFunctionType.CustomMathExpression}]
                }
            }
        )}>

        <div className={"flex items-center justify-center"}>
            <PlusIcon className={"w-4 h-4"}></PlusIcon>
        </div>
        <div>Add function</div>
    </div>
}

function reverseLookupFunctionType(funcType: FunctionType | undefined): string {
    if (funcType === undefined) {
        return "Select a function";
    }
    for (const [key, value] of FunctionTypeToFunction.entries()) {
        if (value.includes(funcType)) {
            return key;
        }
    }
    return "Select a function";
}

function MetricFunctionSelector(props: {
    metricFunction: MetricFunction;
    setChartProps: Dispatch<SetStateAction<MetricSpecifier>>
}) {
    const [open, setOpen] = React.useState(false)
    const [expression, setExpression] = React.useState<string>(props.metricFunction.functionPayload == undefined ? "" : props.metricFunction.functionPayload!.expression)

    function submitMathExpression(val: string) {
        props.setChartProps((prev: MetricSpecifier) => {
            const mathExp = {
                variables: ["a"],
                expression: val
            }
            const updatedFunctions = prev.functions.map((func) => {
                if (func.id === props.metricFunction.id) {
                    return {
                        ...func,
                        functionPayload: mathExp
                    };
                }
                return func;
            });
            return {
                ...prev,
                functions: updatedFunctions
            }
        })
    }

    return <div className={"flex flex-col w-max"}>
        <div className={"bg-border h-4 w-[2px] ml-2"}></div>
        <div className={"group flex"}>
            <div className={"flex border min-h-[48px] rounded grow group-hover:border-y group-hover:border-l"}>
                <div
                    className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium p-2 border-r"}>
                    <SquareFunctionIcon className={"text-textmedium"}
                    />
                </div>
                <div
                    className="flex w-full flex-col items-start justify-between px-4 py-3 sm:flex-row sm:items-center bg-backgroundmedium hover:bg-backgroundlight hover:cursor-pointer">
                    <DropdownMenu open={open} onOpenChange={setOpen}>
                        <DropdownMenuTrigger asChild>
                            <p className="text-sm font-medium leading-none">
                    <span
                        className="mr-2 rounded-lg bg-secondarytransparenter px-2 py-1 text-xs text-textmedium border border-secondary">
                        {reverseLookupFunctionType(props.metricFunction.functionType)}
                    </span>
                                <span className="text-textmedium"> {props.metricFunction.functionType} </span>
                            </p>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end"
                                             className="w-[280px] mt-3 bg-background text-textmedium rounded">
                            <DropdownMenuGroup>
                                {Object.entries(Functions).map(([funcName, funcValue]) => {
                                    return <DropdownMenuSub>
                                        <DropdownMenuSubTrigger
                                            className={"hover:bg-backgroundlight"}>{funcName}</DropdownMenuSubTrigger>
                                        <DropdownMenuSubContent className="p-0 bg-background ml-1 rounded">
                                            <Command>
                                                <CommandList>
                                                    <CommandGroup>
                                                        {FunctionTypeToFunction.get(funcValue) !== undefined
                                                            && FunctionTypeToFunction.get(funcValue)!.map((funcType, index) => {
                                                                return <CommandItem
                                                                    className={"hover:bg-backgroundlight"}
                                                                    key={funcType}
                                                                    value={funcType}
                                                                    onSelect={(value) => {
                                                                        props.setChartProps((prev: MetricSpecifier) => {
                                                                            const updatedFunctions = prev.functions.map((func, index) => {
                                                                                if (index === prev.functions.findIndex((value, index) => value !== undefined && value.id == props.metricFunction.id)) {
                                                                                    return {
                                                                                        ...func,
                                                                                        functionType: value as FunctionType
                                                                                    };
                                                                                }
                                                                                return func;
                                                                            });
                                                                            return {
                                                                                ...prev,
                                                                                functions: updatedFunctions
                                                                            }
                                                                        })
                                                                        setOpen(false)
                                                                    }}
                                                                >
                                                                    {funcType}
                                                                </CommandItem>
                                                            })
                                                        }
                                                    </CommandGroup>
                                                </CommandList>
                                            </Command>
                                        </DropdownMenuSubContent>
                                    </DropdownMenuSub>
                                })}
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                {props.metricFunction !== undefined && props.metricFunction.functionType !== undefined && props.metricFunction.functionType == MathExpressionFunctionType.CustomMathExpression &&
                    <div
                        className={"text-center text-textmedium flex min-h-[48px] rounded grow border-l rounded-l-none"}>
                        <div className={"flex justify-start "}>
                            <Input id={"free_text_input"}
                                   value={expression}
                                   placeholder={"e.g. a / 60"}
                                   onBlur={(e) => {
                                       submitMathExpression(e.currentTarget.value)
                                       setExpression(e.currentTarget.value)
                                   }}

                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                           submitMathExpression(e.currentTarget.value)
                                           setExpression(e.currentTarget.value)
                                       }
                                   }}
                                   onChangeCapture={(e) => {
                                       setExpression(e.currentTarget.value)
                                   }}
                                   className={cn("h-12 grow text-textlight shadow-none w-max bg-backgroundmedium border-none")}/>
                        </div>
                    </div>}
            </div>
            <div className={"bg-background group-hover:flex group-hover:items-center"}>
                <div
                    onClick={() => {
                        props.setChartProps((prev: MetricSpecifier) => {
                            let prevFunctions = prev.functions;
                            let currFunctionIndex = prev.functions.findIndex((value, index) => value !== undefined && value.id == props.metricFunction.id);
                            if (currFunctionIndex == -1) {
                                return prev;
                            }
                            if (prevFunctions[currFunctionIndex] !== undefined) {
                                delete prevFunctions[currFunctionIndex];
                            }
                            prevFunctions = prevFunctions.filter((value) => value !== undefined);
                            return {...prev, functions: prevFunctions}
                        })
                    }}
                    className="h-full pt-[10px] hover:cursor-pointer hover:bg-primary hidden group-hover:block group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textmedium">
                    <XIcon/>
                </div>
            </div>
        </div>
    </div>
}

export interface MetricFunction {
    id: string;
    functionType: FunctionType;
    functionPayload?: MathExpression;
}

enum Functions {
    Rate = "rate",
    Arithmetic = "arithmetic",
    // Timeshift = "timeshift",
    // TODO: Add more function types like Arithmetic, Count etc..
}

// TODO: Add more function types such as  "| Timeshift | ... " function type in the future.
export type FunctionType = RateFunctionType | MathExpressionFunctionType

export enum MathExpressionFunctionType {
    CustomMathExpression = "customMathExpression",
    // Logarithm = "logarithm",
    // Exponential = "exponential"
    // TODO: Add more math expression functions like logarithm, exponential, etc.
}

export enum RateFunctionType {
    MonotonicDifference = "monotonicDifference",
    ValueDifference = "valueDifference",
    PerSecond = "perSecond",
    // PerMinute = "perMinute",
    // PerHour = "perHour",
    // TODO: Add more rate functions like time difference, etc.
}

const FunctionTypeToFunction: Map<Functions, FunctionType[]> = new Map<Functions, FunctionType[]>([
    [Functions.Rate, Object.values(RateFunctionType)],
    [Functions.Arithmetic, Object.values(MathExpressionFunctionType)],
    // TODO: Add more functions like [Functions.Timeshift, []],
]);

function SetTitlePanel(props: {
    setTitle: (t: string) => void, title
        :
        string | undefined
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                4
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Name chart
            </div>
        </div>
        <div className={"flex justify-start"}>
            <Input value={props.title} id={"free_text_input"} onChangeCapture={(e) => {
                props.setTitle(e.currentTarget.value);
            }}
                   className={cn("h-12 focus:ring-0 focus:border-border focus:ring-border grow text-textlight border border-border shadow-none w-max bg-backgroundmedium")}/>
        </div>
    </div>
}


function MetricTypeSelector(props: { metricType: MetricType, setMetricType: (t: MetricType) => void }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex grow justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                1
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Metric Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(MetricType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:border hover:border-secondary cursor-pointer px-4", props.metricType === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => props.setMetricType(type)}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function TypeSelectorPanel(props: { type: ChartType, setType: (t: ChartType) => void }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex grow justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                1
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Chart Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(ChartType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:border hover:border-secondary cursor-pointer px-4", props.type === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => props.setType(type)}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function AddFormulaButton(props: {
    setChartProps: (value: (((prevState: MultiMetoroMetricsChartProps) => MultiMetoroMetricsChartProps) | MultiMetoroMetricsChartProps)) => void
}) {
    return (
        <Button
            className={"bg-primarytransparent border border-primary rounded text-textmedium w-[212px]"}
            onClick={() => {
                props.setChartProps((prevState: MultiMetoroMetricsChartProps) => {
                    return {
                        ...prevState,
                        formulas: [...(prevState.formulas || []), {
                            formula: ""
                        }]
                    }
                })
            }}>
            <PlusIcon className={"mr-2"}/>
            Add Formula
        </Button>
    )
}

function FormulaInput(props: {
    formula: Formula;
    index: number;
    setChartProps: (value: (((prevState: MultiMetoroMetricsChartProps) => MultiMetoroMetricsChartProps) | MultiMetoroMetricsChartProps)) => void;
    metricIdentifiers: string[];
}) {
    const formulaMetricSpecifier: MetricSpecifier = {
        metricName: `Formula ${props.index + 1}`,
        visualization: props.formula.visualization,
        metricType: MetricType.Metric,
        aggregation: "sum", // Default aggregation
        functions: [], // No functions for formulas
        filters: new Map(), // Empty filters
        splits: [] // No splits for formulas
    };

    const setFormulaVisualization = (value: (((prevState: MetricSpecifier) => MetricSpecifier) | MetricSpecifier)) => {
        props.setChartProps((prev) => {
            const formulas = [...(prev.formulas || [])];
            const newVisualization = typeof value === 'function' ? value(formulaMetricSpecifier).visualization : value.visualization;
            formulas[props.index] = {
                ...formulas[props.index],
                visualization: newVisualization
            };
            return {
                ...prev,
                formulas
            };
        });
    };
    return (
        <div className="flex flex-col">
            <div className="flex border min-h-[48px] rounded grow">
                <div
                    className="bg-backgrounddark flex justify-center items-center text-center text-textmedium px-2 border-r">
                    <span
                        className="rounded-lg bg-secondarytransparenter px-2 py-1 text-xs font-semibold text-textmedium border border-secondary">
                        F{props.index + 1}
                    </span>
                </div>
                <div className="bg-backgroundmedium items-center flex grow justify-between">
                    <Input
                        className="flex-1 bg-backgroundmedium border-0"
                        placeholder="Enter formula (e.g., a / b * 100)"
                        value={props.formula.formula}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = e.target.value;
                            props.setChartProps((prev) => {
                                const formulas = [...(prev.formulas || [])];
                                formulas[props.index] = {
                                    ...formulas[props.index],
                                    formula: newValue
                                };
                                return {
                                    ...prev,
                                    formulas
                                };
                            })
                        }}
                    />
                    <div className="text-xs text-textmedium px-2">
                        (using: {props.metricIdentifiers.join(", ")})
                    </div>
                    <Button
                        variant="ghost"
                        size="icon"
                        className=" text-textmedium hover:bg-red-400/10 hover:text-red-400"
                        onClick={() => {
                            props.setChartProps((prev) => {
                                const formulas = [...(prev.formulas || [])];
                                formulas.splice(props.index, 1);
                                return {
                                    ...prev,
                                    formulas
                                };
                            });
                        }}
                    >
                        <X className="h-4 w-4"/>
                    </Button>
                </div>
            </div>
            <div className="flex items-center">
                <EditVisualizationButton
                    setChartProps={setFormulaVisualization}
                    metricSpecifier={formulaMetricSpecifier}
                />
            </div>
        </div>
    );
}

export {
    MultiMetricSelectorPanel
}