import {MetricSpecifier} from "./MultiMetoroMetricsCharts";
import {ReduceOption, StatWidgetMapping} from "../Dashboarding/internalwidgets";
import {useEffect, useState} from "react";
import {GetAggregateMetricEvaluation} from "../../clients/metoro/metrics";
import {WindowUnit} from "../../pages/alerts/MetricAlert";
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import {cn} from "../ui/lib/utils";
import {LoadingSpinner} from "../ui/customSpinner";
import {RuntimeVariable} from "../Dashboarding/Dashboard";
import {substituteVariablesInFilters} from "./utils";
import {GridStack} from "gridstack";
import {useSearchParams} from "react-router-dom";

const formatDuration = (nanoseconds: number): string => {
    if (nanoseconds < 1000) {
        return `${nanoseconds}ns`;
    } else if (nanoseconds < 1000000) {
        return `${(nanoseconds / 1000).toFixed(2)}µs`;
    } else if (nanoseconds < 1000000000) {
        return `${(nanoseconds / 1000000).toFixed(2)}ms`;
    } else {
        return `${(nanoseconds / 1000000000).toFixed(2)}s`;
    }
};

// Helper function to evaluate if a value matches a mapping
function doesValueMatchMapping(value: number, mapping: StatWidgetMapping): boolean {
    if (mapping.operator === "range") {
        return value >= (mapping.from ?? -Infinity) && value <= (mapping.to ?? Infinity);
    }

    switch (mapping.operator) {
        case ">":
            return value > mapping.value;
        case "<":
            return value < mapping.value;
        case "==":
            return value === mapping.value;
        case ">=":
            return value >= mapping.value;
        case "<=":
            return value <= mapping.value;
        default:
            return false;
    }
}

// Find the first matching mapping for a value
function findMatchingMapping(value: number, mappings: StatWidgetMapping[] | undefined): StatWidgetMapping | undefined {
    if (!mappings) return undefined;
    
    // First check exact matches
    const exactMatch = mappings.find(m => m.operator === "==" && m.value === value);
    if (exactMatch) return exactMatch;

    // Then check ranges and other operators
    return mappings.find(m => doesValueMatchMapping(value, m));
}

export function StatChart(props: {
    title?: string
    metricSpecifier: MetricSpecifier
    reduceOption: ReduceOption
    mappings?: StatWidgetMapping[]
    className?: string
    precision: number
    fontSize?: string
    runtimeVariables?: RuntimeVariable[]
    grids?: Map<string, GridStack>
    widgetId?: string
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [statValue, setStatValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const [environments, setEnvironments] = useState<string[]>([]);

    useEffect(() => {
        let environment = searchParams.get("environment");
        if (environment !== null && environment !== "") {
            setEnvironments([environment]);
        } else {
            setEnvironments([]);
        }
    }, [searchParams]);

    const getVisualStyles = () => {
        if (!props.mappings) return {};

        const matchingMapping = findMatchingMapping(statValue, props.mappings);
        if (matchingMapping) {
            return matchingMapping.type === "background"
                ? { backgroundColor: matchingMapping.color, padding: "0.5rem", borderRadius: "0.25rem" }
                : { color: matchingMapping.color };
        }

        return {};
    };

    const getDisplayValue = () => {
        const value = statValue;
        const aggregation = props.metricSpecifier.aggregation;
        const isTraceDuration = props.metricSpecifier.metricType === 'trace' &&  
        ['p50', 'p90', 'p95', 'p99'].includes(aggregation);

        // If it's a duration metric, format it as a duration
        if (isTraceDuration) {
            const formattedDuration = formatDuration(value);
            if (!props.mappings) return formattedDuration;
            
            const matchingMapping = findMatchingMapping(value, props.mappings);
            if (matchingMapping && matchingMapping.textMapping) {
                return matchingMapping.textMapping.replace('$value', formattedDuration);
            }

            return formattedDuration;
        }

        // For non-duration metrics, use the original formatting
        const formatValue = (val: number) => val.toFixed(props.precision ?? 2);

        if (!props.mappings) return formatValue(value);

        const matchingMapping = findMatchingMapping(value, props.mappings);
        if (matchingMapping && matchingMapping.textMapping) {
            return matchingMapping.textMapping.replace('$value', formatValue(value));
        }

        return formatValue(value);
    };

    const calculateWindowParams = (startTime: number, endTime: number): { windowSize: number, windowUnit: WindowUnit } => {
        const timeRangeInSeconds = endTime - startTime;
        const timeRangeInMinutes = timeRangeInSeconds / 60;
        const timeRangeInHours = timeRangeInMinutes / 60;
        const timeRangeInDays = timeRangeInHours / 24;

        if (timeRangeInDays > 7) {
            // For ranges > 7 days, use days
            return {
                windowSize: Math.max(1, Math.floor(timeRangeInDays / 60)),
                windowUnit: WindowUnit.Days
            };
        } else if (timeRangeInHours > 24) {
            // For ranges > 24 hours, use hours
            return {
                windowSize: Math.max(1, Math.floor(timeRangeInHours / 60)),
                windowUnit: WindowUnit.Hours
            };
        } else {
            // For ranges <= 24 hours, use minutes
            return {
                windowSize: Math.max(1, Math.floor(timeRangeInMinutes / 60)),
                windowUnit: WindowUnit.Minutes
            };
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const startTime = Math.floor(timeRange.getStartEnd()[0].getTime() / 1000);
                const endTime = Math.floor(timeRange.getStartEnd()[1].getTime() / 1000);
                
                const { windowSize, windowUnit } = calculateWindowParams(startTime, endTime);

                const processedFilters = substituteVariablesInFilters(props.metricSpecifier.filters || new Map(), props.runtimeVariables);
                const processedExcludeFilters = substituteVariablesInFilters(props.metricSpecifier.excludeFilters || new Map(), props.runtimeVariables);


                // Add environment to filters if it's not already set
                if (environments.length > 0 && !processedFilters.has("environment")) {
                    processedFilters.set("environment", environments);
                }

                // Create request for metric aggregate evaluation
                const request = {
                    ...props.metricSpecifier,
                    type: props.metricSpecifier.metricType,
                    startTime: startTime,
                    endTime: endTime,
                    filters: processedFilters,
                    excludeFilters: processedExcludeFilters,
                    splits: props.metricSpecifier.splits || [],
                    aggregateParams: {
                        evaluationFunction: props.reduceOption,
                        window: windowSize,
                        windowUnit: windowUnit,
                        evaluationSplits: [],
                    },
                    limitResults: true,
                };

                const response = await GetAggregateMetricEvaluation(request);
                if (response.metric.timeSeries && response.metric.timeSeries.length > 0 && response.metric.timeSeries[0].data.length > 0) {
                    setStatValue(response.metric.timeSeries[0].data[0].value);
                }
            } catch (error) {
                console.error("Error fetching metric data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [
        props.metricSpecifier.metricName,
        props.metricSpecifier.metricType,
        props.metricSpecifier.aggregation,
        props.metricSpecifier.filters,
        props.metricSpecifier.splits,
        props.reduceOption,
        timeRange,
        props.runtimeVariables,
        props.grids,
        props.widgetId,
        environments
    ]);

    return (
        <div className={cn("w-full h-full flex items-center justify-center", props.className)}>
            {isLoading ? (
                <LoadingSpinner className="h-6 w-6" />
            ) : (
                <div className={cn("font-bold px-6", props.fontSize || "text-2xl")} style={getVisualStyles()}>
                    {getDisplayValue()}
                </div>
            )}
        </div>
    )
}