export const ServiceCPUTemplate =
`
{
      "widgetType": "Group",
      "title": "Service CPU Overview",
      "position": {
        "x": 0,
        "y": 0,
        "w": 12,
        "h": 10
      },
      "children": [
        {
          "widgetType": "Markdown",
          "content": "### CPU Usage\\nThis template serves as a starting point to look into cpu usage across your containers. Adapt this to your own needs!\\n\\n\\nCpu Usage - This shows the number of cores used across the service, the aggregates across pods in the service and the pods themselves",
          "position": {
            "x": 0,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "position": {
            "x": 3,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_usage_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [
                {
                  "id": "1",
                  "functionType": "perSecond"
                }
              ],
              "aggregation": "sum",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Total CPU Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_limit_cores",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "sum",
              "visualization": {
                "displayName": "CPU Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            }
          ],
          "title": "Total Service CPU Usage",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 6,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_usage_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [
                {
                  "id": "1",
                  "functionType": "perSecond"
                }
              ],
              "aggregation": "min",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Min Pod CPU Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_usage_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [
                {
                  "id": "1",
                  "functionType": "perSecond"
                }
              ],
              "aggregation": "max",
              "visualization": {
                "lineColor": "#3793ff",
                "lineDotColor": "#3793ff33",
                "displayName": "Max Pod CPU Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_usage_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [
                {
                  "id": "1",
                  "functionType": "perSecond"
                }
              ],
              "aggregation": "avg",
              "visualization": {
                "lineColor": "#ffce56",
                "lineDotColor": "#ffce5633",
                "displayName": "Avg Pod CPU Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_limit_cores",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "displayName": "CPU Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Usage - Aggregates",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 9,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_limit_cores",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "displayName": "CPU Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            },
            {
              "visualization": {
                "displayName": "CPU Usage"
              },
              "metricName": "container_resources_cpu_usage_seconds_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ],
              "aggregation": "max",
              "metricType": "metric",
              "functions": [
                {
                  "id": "e54f36f4-3bb1-4494-bbb7-01f41691a05a",
                  "functionType": "perSecond"
                }
              ],
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Usage - Top pods",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "widgetType": "Markdown",
          "content": "### CPU Delay\\n\\nCpu Delay Seconds shows the number of seconds that a container was suspended for from a cpu due to **either** *cpu limits* or *resource starvation* on the node. So for example, if the throttled seconds is 30, that means that the application wanted to use more cpu for 30 seconds in that time period but was denied it. \\n\\nThis is particularly bad in services the primarily serve requests as this manifests as increased latency.",
          "position": {
            "x": 0,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "position": {
            "x": 3,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_delay_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "sum",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Total Delay Seconds"
              },
              "bucketSize": 0
            }
          ],
          "title": "Total Service CPU Delay",
          "widgetType": "MetricChart",
          "type": "bar"
        },
        {
          "position": {
            "x": 6,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_delay_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "min",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Min Pod CPU Delay Seconds"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_delay_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "lineColor": "#3793ff",
                "lineDotColor": "#3793ff33",
                "displayName": "Max Pod CPU Delay Seconds"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_delay_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "avg",
              "visualization": {
                "lineColor": "#ffce56",
                "lineDotColor": "#ffce5633",
                "displayName": "Avg Pod CPU Delay Seconds"
              },
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Delay - Aggregates",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 9,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "visualization": {
                "displayName": "Cpu Delay Seconds"
              },
              "metricName": "container_resources_cpu_delay_seconds_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ],
              "aggregation": "max",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Delay - Top pods",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "widgetType": "Markdown",
          "content": "### CPU Throttling\\n\\nCpu Throttling Seconds shows the number of seconds that a container was suspended for from a cpu due to cpu limits. It a subset of cpu delay. So for example, if the throttled seconds is 30, that means that the application wanted to use more cpu for 30 seconds in that time period but was denied it because of kubernetes limits. \\n\\nThis might seem confusing at first if your cpu usage is under the limit. The default quota period in Kubernetes is 100 milliseconds. So if you define a CPU limit of 50 millicores for a pod, it sets up a linux cgroup CPU quota of 5ms for each 100ms period. So therefore if in one 100ms period, the container needs to use more than 5milliseconds of cpu, throttling will occur.\\n\\nThis is particularly bad in services the primarily serve requests as this manifests as increased latency.",
          "position": {
            "x": 0,
            "y": 6,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "position": {
            "x": 3,
            "y": 6,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_throttled_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "sum",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Total Throttled Seconds"
              },
              "bucketSize": 0
            }
          ],
          "title": "Total Service CPU Throttling",
          "widgetType": "MetricChart",
          "type": "bar"
        },
        {
          "position": {
            "x": 6,
            "y": 6,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_throttled_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "min",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Min Pod CPU Throttled Seconds"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_throttled_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "lineColor": "#3793ff",
                "lineDotColor": "#3793ff33",
                "displayName": "Max Pod CPU Throttled Seconds"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_cpu_throttled_seconds_total",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "avg",
              "visualization": {
                "lineColor": "#ffce56",
                "lineDotColor": "#ffce5633",
                "displayName": "Avg Pod CPU Throttled Seconds"
              },
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Throttling - Aggregates",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 9,
            "y": 6,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "visualization": {
                "displayName": "Cpu Throttled Seconds"
              },
              "metricName": "container_resources_cpu_throttled_seconds_total",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ],
              "aggregation": "max",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0
            }
          ],
          "title": "Service CPU Throttling - Top pods",
          "widgetType": "MetricChart",
          "type": "line"
        }
      ],
      "variables": [
        {
          "name": "environment",
          "key": "environment",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "service_name",
          "key": "service_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "pod_name",
          "key": "pod_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        }
      ]
    }
`