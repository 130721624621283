export const ServiceMemoryOverviewTemplate = `
{
      "widgetType": "Group",
      "title": "Service Memory Overview",
      "position": {
        "x": 0,
        "y": 0,
        "w": 12,
        "h": 7
      },
      "children": [
        {
          "widgetType": "Markdown",
          "content": "### Memory Usage\\nThis template serves as a starting point to look into memory usage across your containers. Adapt this to your own needs!\\n\\n\\nMemory Usage - This shows the number of cores used across the service, the aggregates across pods in the service and the pods themselves",
          "position": {
            "x": 0,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "position": {
            "x": 3,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_rss_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "sum",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Total Memory Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_limit_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "sum",
              "visualization": {
                "displayName": "Memory Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            }
          ],
          "title": "Total Service Memory Usage",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 6,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_rss_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "min",
              "visualization": {
                "lineColor": "#4bc0c0",
                "lineDotColor": "#4bc0c033",
                "displayName": "Min Pod Memory Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_rss_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "lineColor": "#3793ff",
                "lineDotColor": "#3793ff33",
                "displayName": "Max Pod Memory Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_rss_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "avg",
              "visualization": {
                "lineColor": "#ffce56",
                "lineDotColor": "#ffce5633",
                "displayName": "Avg Pod Memory Usage"
              },
              "bucketSize": 0
            },
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_limit_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "displayName": "Memory Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            }
          ],
          "title": "Service Memory Usage - Aggregates",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "position": {
            "x": 9,
            "y": 0,
            "w": 3,
            "h": 3
          },
          "metricSpecifiers": [
            {
              "metricType": "metric",
              "metricName": "container_resources_memory_limit_bytes",
              "splits": [],
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "functions": [],
              "aggregation": "max",
              "visualization": {
                "displayName": "CPU Limit",
                "lineDash": [
                  10,
                  10
                ],
                "lineColor": "#ff6384",
                "lineDotColor": "#ff638400",
                "lineDotSize": 0
              },
              "bucketSize": 0
            },
            {
              "visualization": {
                "displayName": "CPU Usage"
              },
              "metricName": "container_resources_memory_rss_bytes",
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ],
              "aggregation": "max",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0
            }
          ],
          "title": "Service Memory Usage - Top pods",
          "widgetType": "MetricChart",
          "type": "line"
        },
        {
          "widgetType": "Markdown",
          "content": "### OOM Kills\\nThere are several reasons why the OOM killer mechanism might terminate a process:\\n\\n- If a container has reached its memory limit.\\n- If a top-level cgroup has reached its memory limit. For example, Kubernetes limits the kubepods cgroup to the size of allocatable memory on the node, so a container can be killed even if it has no memory limit.\\n- If a node has run out of memory.",
          "position": {
            "x": 0,
            "y": 3,
            "w": 3,
            "h": 3
          },
          "children": []
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_oom_kills_total",
              "aggregation": "sum",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0,
              "visualization": {},
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ]
                ]
              }
            }
          ],
          "type": "bar",
          "widgetType": "MetricChart",
          "title": "Total Service OOM Kills",
          "position": {
            "x": 3,
            "y": 3,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_oom_kills_total",
              "aggregation": "sum",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0,
              "visualization": {},
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ]
                ]
              },
              "splits": [
                "pod_name"
              ]
            }
          ],
          "type": "bar",
          "widgetType": "MetricChart",
          "title": "Top OOM Kills by Pod",
          "position": {
            "x": 6,
            "y": 3,
            "w": 3,
            "h": 3
          }
        },
        {
          "metricSpecifiers": [
            {
              "metricName": "container_oom_kills_total",
              "aggregation": "sum",
              "label": "Active Connections",
              "metricType": "metric",
              "functions": [],
              "bucketSize": 0,
              "visualization": {},
              "filters": {
                "dataType": "Map",
                "value": [
                  [
                    "service_name",
                    [
                      "$service_name"
                    ]
                  ],
                  [
                    "pod_name",
                    [
                      "$pod_name"
                    ]
                  ],
                  [
                    "environment",
                    [
                      "$environment"
                    ]
                  ]
                ]
              },
              "splits": [
                "node.label.kubernetes.io/hostname"
              ]
            }
          ],
          "type": "bar",
          "widgetType": "MetricChart",
          "title": "Top OOM Kills by Node",
          "position": {
            "x": 9,
            "y": 3,
            "w": 3,
            "h": 3
          }
        }
      ],
      "variables": [
        {
          "name": "environment",
          "key": "environment",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "service_name",
          "key": "service_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        },
        {
          "name": "pod_name",
          "key": "pod_name",
          "defaultType": "metric",
          "defaultMetricName": "container_resources_cpu_usage_seconds_total",
          "defaultValue": "*",
          "isOverrideable": true
        }
      ]
    }`