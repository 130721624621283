import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import {ChevronDown} from "lucide-react";
import {useEffect, useState} from "react";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";

export function NamespaceSelector() {
    const [searchParams, setSearchParams] = useSearchParams();
    let params = new URLSearchParams(searchParams);
    const [namespaces, setNamespaces] = useState<string[]>([])
    const [selectedNamespace, setSelectedNamespace] = useState<string>(params.get("namespace") || "")
    const [environment, setEnvironment] = useState<string>(params.get("environment") || "")

    useEffect(() => {
        setEnvironment(params.get("environment") || "")
    }, [searchParams]);

    useEffect(() => {
        // Fetch namespaces
        axios.get("/api/v1/namespaces?environment=" + environment).then((response) => {
            let namespacesToUse = response.data.namespaces;
            // Sort alphabetically a-z
            namespacesToUse.sort();
            setNamespaces(namespacesToUse)
        }).catch((e) => {
            console.error(e)
        })
    }, [environment])

    useEffect(() => {
        setSearchParams(prev => {
            prev.set("namespace", selectedNamespace);
            return prev;
        });
    }, [selectedNamespace])

    return (
        <DropdownMenu >
            <DropdownMenuTrigger asChild>
                <div
                    className={"flex rounded border border-border bg-backgroundmedium justify-center items-center hover:cursor-pointer hover:border-primary h-12"}>
                    <div
                        className={"text-textlight px-2 py-3 border border-secondary bg-secondarytransparenter"}>Namespace
                    </div>
                    <div className={"border-l flex bg-backgroundlight justify-center items-center px-2 h-full"}>
                        <div
                            className={"text-textmedium bg-backgroundlight pr-2"}>
                            {selectedNamespace === "" ? "All Namespaces" : selectedNamespace}
                        </div>
                        <ChevronDown
                            className={"bg-backgroundlight text-textmedium h-4 w-4"}/>
                    </div>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-backgroundmedium rounded">
                <DropdownMenuLabel className={"text-textmedium"}>Namespaces</DropdownMenuLabel>
                <DropdownMenuSeparator className={"bg-border"}/>
                <DropdownMenuGroup>
                    <DropdownMenuItem key={"all"} className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"}
                                      onClick={() => {
                                          setSelectedNamespace("")}}>
                        All Namespaces
                    </DropdownMenuItem>
                    {namespaces.filter((cluster) => cluster !== "").map((cluster) => (
                        <DropdownMenuItem key={cluster} className={"text-textmedium hover:bg-backgroundlight hover:text-primary hover:cursor-pointer"
                            + (selectedNamespace === cluster ? " bg-backgroundlight" : "")}
                                          onClick={() => setSelectedNamespace(cluster)}>
                            {cluster}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
