import React, {ReactElement, useEffect, useState} from "react";
import {MultiMetricChartWidget, OldMetricChartWidget} from "../Dashboarding/internalwidgets";
import {DashboardSearchResponse} from "../../pages/DashboardSearch";
import {DropDownItem, MultiSelectorDropDown} from "../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {cn, usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import axios from "../../utility/customAxios";
import {Dialog, DialogContent, DialogTitle} from "../ui/dialog";
import {Button} from "../ui/button";
import {Link, useSearchParams} from "react-router-dom";
import {Bar, Line} from "react-chartjs-2";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/dropdown-menu";
import {AudioLinesIcon, BellIcon, Columns2Icon, EllipsisIcon, ExpandIcon, InfoIcon} from "lucide-react";
import {dashboardJsonReplacer} from "../Dashboarding/Dashboard";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartTypeRegistry,
    Legend,
    LinearScale,
    LineElement,
    Point,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    TooltipModel,
    ChartOptions,
    CoreScaleOptions,
    Scale,
    Tick,
    Plugin,
    Chart,
    BubbleDataPoint
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import {TimeRange} from "../../types/time";
import {useDispatch, useSelector} from "react-redux";
import timerange, {set} from "../../store/reducers/timerange";
import {DateTimePrecision, getPreferredFormatString} from "../../utility/displayDateTime";
import {shortEnglishHumanizer} from "../ServicePanel/ServicePanel";
import {generatePreservedPath, openInNewTab} from "../../pages/BaseView";
import {TbLayoutDashboard} from "react-icons/tb";

interface ExtendedChart extends ChartJS {
    crosshairX?: number;
}

const crosshairPlugin: Plugin = {
    id: 'crosshair',
    beforeDatasetsDraw(chart: ChartJS) {
        const extChart = chart as ExtendedChart;
        const {ctx, chartArea} = chart;
        if (!chartArea) return;
        const {top, bottom} = chartArea;

        if (!extChart.crosshairX) return;

        ctx.save();

        // Draw vertical line
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(45, 216, 129, 0.6)'; // Secondary transparent color
        ctx.setLineDash([]);
        ctx.moveTo(extChart.crosshairX, top);
        ctx.lineTo(extChart.crosshairX, bottom);
        ctx.stroke();

        ctx.restore();
    }
};

// Register all plugins
ChartJS.register(
    zoomPlugin,
    BarController,
    PointElement,
    LineElement,
    Legend,
    TimeScale,
    BarElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    ChartDataLabels,
    annotationPlugin,
    TimeSeriesScale,
    crosshairPlugin
);

function createDashboardUrl(dashboardId: string, newMetricChartWidgetProps: MultiMetricChartWidget): string {
    return "/dashboard?dashboardId=" + dashboardId + "&addChart=" + encodeURIComponent(JSON.stringify(newMetricChartWidgetProps, dashboardJsonReplacer));
}

function AddChartToDialogComponent(props: {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ChartWidget?: MultiMetricChartWidget,
}) {
    const [dashboards, setDashboards] = React.useState<DashboardSearchResponse | undefined>(undefined);
    const [dashboardSelected, setDashboardSelected] = React.useState<DropDownItem | undefined>();
    const navigate = usePreserveQueryParamsNavigate();

    const selectedItems = dashboardSelected ? [dashboardSelected] : []

    useEffect(() => {
        if (!props.isOpen) {
            return
        }
        axios.get<DashboardSearchResponse>("/api/v1/dashboardSearch").then((response) => {
            setDashboards(response.data)
        })
    }, [props.isOpen])

    return <Dialog open={props.isOpen} onOpenChange={props.setIsOpen}>
        <DialogContent
            className={"text-textmedium w-1/3 min-w-[400px]"}
            onInteractOutside={() =>
                props.setIsOpen(false)
            }>
            <DialogTitle>
                Add chart to dashboard
            </DialogTitle>
            Select a dashboard to add the chart to:
            <MultiSelectorDropDown selectorSingular={"Dashboard"}
                                   possibleItems={
                                       dashboards?.results.map((dashboard) => {
                                           return {
                                               displayName: dashboard.name,
                                               value: dashboard.id
                                           }
                                       }) || []
                                   }
                                   selectedItems={selectedItems}
                                   setSelectedItems={
                                       (selected) => {
                                           if (selected.length > 0) {
                                               setDashboardSelected(selected[0])
                                           } else {
                                               setDashboardSelected(undefined)
                                           }
                                       }
                                   } isSingleSelect={true}/>
            <Button
                className={"border border-primary bg-primarytransparent"}
                disabled={dashboardSelected === undefined}
                onClick={() => {
                    if (dashboardSelected === undefined) {
                        return
                    }
                    const url = createDashboardUrl(dashboardSelected.value, props.ChartWidget!)
                    // If we're currently on the dashboard, we need to refresh the page when we navigate to the new dashboard
                    if (window.location.pathname === "/dashboard") {
                        window.location.href = url
                    } else {
                        navigate(url)
                    }
                }}>
                Add to dashboard
            </Button>
        </DialogContent>
    </Dialog>
}


interface ChartStyling {
    borderless?: boolean;
}

enum ChartType {
    Bar = "bar",
    Line = "line",
}

function ChartContainer(props: {
    createAlertURL?: string,
    viewTracesURL?: string,
    editDialog?: ReactElement,
    styling?: ChartStyling,
    className?: string,
    title?: string,
    children: any,
    dataUsed?: any,
    chartType?: ChartType,
    chartOptions?: any,
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MultiMetricChartWidget | undefined
    setComparisonDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const [selected, setSelected] = useState(props.dataUsed !== undefined && props.dataUsed.datasets !== undefined ? props.dataUsed.datasets : []);
    const [chart, setChart] = useState(props.children);
    const [openAddToDashboardDialog, setOpenAddToDashboardDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();


    useEffect(() => {
        if (props.dataUsed !== undefined && props.dataUsed.datasets !== undefined) {
            setSelected(props.dataUsed.datasets)
        }
    }, [props.dataUsed]);

    useEffect(() => {
        if (selected == null) {
            setChart(props.children)
        } else {
            const selectedData = {datasets: selected}
            switch (props.chartType) {
                case ChartType.Line:
                    setChart(<Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                   data={selectedData} options={props.chartOptions}/>)
                    break;
                case ChartType.Bar:
                    setChart(<Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                  data={selectedData} options={props.chartOptions}/>)
                    break;
            }
        }

    }, [selected, props.children]);

    function getColor(currColor: string, item: any, type: string) {
        if (selected.includes(item)) {
            return currColor
        } else {
            if (type === "bg") {
                return "transparent"
            } else {
                return "rgb(30 41 59)"
            }
        }
    }

    let showLegend = props.hideLegend === undefined ? true : !props.hideLegend;
    // handle edge cases for showing legend
    if (props.dataUsed && props.dataUsed.datasets) {
        if (props.dataUsed.datasets.length === 0) {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label == "") {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label != "") {
            const item = props.dataUsed.datasets[0].label
            const splits = item.split("=").filter((d: string) => d !== "")
            if (splits.length < 2) {
                showLegend = false
            }
        }
    }

    let createUrlElipsis =
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <EllipsisIcon className={"hover:bg-primary rounded hover:cursor-pointer"}
                              size={16}/>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-backgroundmedium rounded">
                {props.createAlertURL && <DropdownMenuItem
                    className="text-textmedium hover:bg-backgroundlight hover:cursor-pointer hover:text-primary">
                    <a
                        onClick={(e) => {
                            navigate(props.createAlertURL!)
                            e.preventDefault()
                        }}
                        href={props.createAlertURL}>
                        <div className={"flex items-center gap-2"}><BellIcon className={"w-4 h-4"}/>
                            Create alert
                        </div>

                    </a>
                </DropdownMenuItem>}
                {props.viewTracesURL &&
                    <DropdownMenuItem
                        className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer hover:text-primary">
                        <Link
                            to={generatePreservedPath(props.viewTracesURL!, searchParams)}
                            onClick={(event) => {
                                if (event.metaKey || event.ctrlKey) {
                                    event.preventDefault(); // Prevent default to allow new tab
                                    openInNewTab(props.viewTracesURL!);
                                }
                            }}
                            onAuxClick={(event) => {
                                if (event.button === 1) { // middle mouse button
                                    event.preventDefault();
                                    openInNewTab(props.viewTracesURL!);
                                }
                            }}
                            className="justify-center items-center flex"
                        >
                            <div className={"flex items-center gap-2"}><AudioLinesIcon className={"w-4 h-4"}/>
                                View Traces
                            </div>
                        </Link>
                    </DropdownMenuItem>
                }
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer hover:text-primary"
                    onClick={() => {
                        setOpenAddToDashboardDialog(true)
                    }}
                >
                    <div className={"flex items-center gap-2"}><TbLayoutDashboard className={"w-4 h-4"}/>
                        Add to dashboard
                    </div>
                </DropdownMenuItem>}
                {props.metricChartWidget && <DropdownMenuItem
                    className="text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer hover:text-primary"
                    onClick={() => {
                        setSearchParams(prev => {
                            let existing = new URLSearchParams(window.location.search)
                            existing.set("fullscreenChart", encodeURIComponent(JSON.stringify(props.metricChartWidget, dashboardJsonReplacer)))
                            return existing
                        })
                    }}
                >
                    <div className={"flex items-center gap-2"}><ExpandIcon className={"w-4 h-4"}/>
                        Expand
                    </div>
                </DropdownMenuItem>}
                {
                    props.setComparisonDialogOpen &&
                    <DropdownMenuItem
                        className={"text-textmedium hover:text-textlight hover:bg-backgroundlight hover:cursor-pointer hover:text-primary"}
                        onClick={() => {
                            if (props.setComparisonDialogOpen != undefined) {
                                props.setComparisonDialogOpen(true)
                            }
                        }}
                    >
                        <div className={"flex items-center gap-2"}><Columns2Icon className={"w-4 h-4"}/>
                            Compare time periods
                        </div>
                    </DropdownMenuItem>
                }
            </DropdownMenuContent>
        </DropdownMenu>

    return (
        <div
            className={cn("flex flex-col justify-center min-w-0 min-h-0 grow shrink", props.className)}
            // ↓ Add this mouse leave handler
            onMouseLeave={() => {
                // Dispatch the event to clear crosshairs on all charts
                window.dispatchEvent(new CustomEvent("metoro-chart-leave"));
            }}
        >
            <AddChartToDialogComponent isOpen={openAddToDashboardDialog} setIsOpen={setOpenAddToDashboardDialog}
                                       ChartWidget={props.metricChartWidget}/>
            <div className={"flex justify-center min-w-0 min-h-0 grow shrink"}>
                <div className={"flex-none flex flex-col min-w-0 min-h-0 grow shrink"}>
                    {((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div
                            className={cn("min-w-0 min-h-0 h-[24px] bg-backgroundmedium border rounded-t text-sm font-medium text-textmedium leading-[14px] px-2 py-1 truncate flex justify-between draggablehandle", props.styling?.borderless ? "border-none" : "")}>
                            {
                                props.title !== undefined && props.title !== "" &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.title}
                                </div>
                            }
                            {
                                props.editDialog &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.editDialog}
                                </div>
                            }
                            {
                                // Settings drop down if needed
                                props.createAlertURL &&
                                createUrlElipsis
                            }
                        </div>
                    }
                    {
                        !((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div className={"absolute right-4 mt-1 text-textmedium z-10 mr-1"}>
                            {createUrlElipsis}
                        </div>
                    }
                    <div
                        className={cn((props.title !== undefined && props.title !== "") ? "border-b border-l border-r rounded-b" : "border rounded", "p-1 flex flex-col min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium", props.styling?.borderless ? "border-none" : "", props.className)}
                    >
                        {props.resultsLimited !== undefined && props.resultsLimited && props.actualResultLen !== undefined && props.actualResultLen !== 0 &&
                            <div
                                className={"flex justify-end text-textdark mr-2 hover:cursor-text shrink min-w-0 max-w-max"}>
                                <div className={"flex items-center gap-1 mr-6"}>
                                    <InfoIcon className={"w-4 h-4"}/>
                                    {props.showAll !== undefined && !props.showAll &&
                                        <div className={"text-sm rounded"}>Showing top 10
                                            of {props.actualResultLen} results.</div>}
                                    {props.showAll !== undefined && !props.showAll && <div
                                        className={"text-sm rounded underline hover:text-primary hover:cursor-pointer"}
                                        onClick={() => {
                                            if (props.setShowAll !== undefined) props.setShowAll(true)
                                        }}>Show all</div>
                                    }
                                </div>
                            </div>}
                        <div
                            className={"p-1 flex min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium border-none"}>
                            {chart}
                        </div>
                        {props.dataUsed && props.dataUsed.datasets && showLegend &&
                            <div id="legend-container"
                                 className={"max-h-[50px] overflow-y-auto ml-4 my-2 no-scrollbar"}>
                                <ul className={"justify-start items-center gap-x-2 inline-flex flex-wrap"}>
                                    {/* Sort by the biggest to smallest*/}
                                    {props.dataUsed.datasets.sort((a: any, b: any) => {
                                        let totalA = a.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        let totalB = b.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        return totalB - totalA
                                    }).map((item: any, index: number) => {
                                        if (item.label === "") {
                                            return null;
                                        }

                                        // const splits = item.label.split("=").filter((d: string) => d !== "")
                                        // if (splits.length < 2) {
                                        //     item.label += "[not set]"
                                        // }
                                        return (<li key={index}
                                                    className={"flex items-center hover:cursor-pointer hover:bg-backgroundlight hover:text-textlight text-textmedium"}
                                        >
                                        <span className={"border mr-1 w-[13px] h-[13px] inline-block hover:border-2"}
                                              onClick={() => {
                                                  if (selected.includes(item)) {
                                                      setSelected(selected.filter((d: any) => {
                                                          return d !== item
                                                      }))
                                                  } else {
                                                      setSelected([...selected, item])
                                                  }
                                              }}
                                              style={{
                                                  backgroundColor: getColor(item.backgroundColor, item, "bg"),
                                                  borderColor: getColor(item.borderColor, item, "border")
                                              }}></span>
                                            <div className={cn(`text-xs overflow-x-auto text-nowrap no-scrollbar`)}
                                                 onClick={() => {
                                                     if (selected.length === 1 && selected.includes(item)) {
                                                         setSelected(props.dataUsed.datasets)
                                                     } else {
                                                         setSelected([item])
                                                     }
                                                 }}
                                            >{item.label}</div>
                                        </li>)
                                    })}
                                </ul>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export function getTimeseriesXAxisUnit(startTime: number, endTime: number) {
    let timeRange = (endTime - startTime) / 1000 // ms -> seconds

    if (timeRange < 86400) { // 24 hours
        return "" // default
    }

    if (timeRange < 86400 * 30) { // 30 days
        return "day"
    }

    if (timeRange < 86400 * 30 * 12) { // 1 year
        return "month"
    }

    return "year"
}

function MetoroChart(props: {
    createAlertURL?: string,
    hideOnNoData?: boolean,
    editDialog?: ReactElement,
    className?: string,
    isDuration?: boolean,
    type
        :
        ChartType,
    dataToUse
        :
        any,
    title?: string,
    styling?: ChartStyling,
    annotations?: any[],
    hideLegend?: boolean,
    resultsLimited?: boolean,
    actualResultLen?: number,
    showAll?: boolean,
    setShowAll?: React.Dispatch<React.SetStateAction<boolean>>,
    metricChartWidget?: MultiMetricChartWidget,
    timeUnit?: string,
    timeUnitDisplay?: string,
    setComparisonDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>,
    setTimeRange?: React.Dispatch<React.SetStateAction<TimeRange>>,
}) {
    const dispatch = useDispatch();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [timeUnitToUse, setTimeUnitToUse] = useState(props.timeUnit ? props.timeUnit : '');
    const dayTimeFormatterString = getPreferredFormatString([DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes])

    // Add logic to construct traces URL
    const getTracesURL = () => {
        if (!props.metricChartWidget || !props.metricChartWidget.metricSpecifiers || props.metricChartWidget.metricSpecifiers.length === 0) {
            return undefined;
        }

        const metricSpec = props.metricChartWidget.metricSpecifiers[0];
        if (metricSpec.metricType !== "trace") {
            return undefined;
        }

        const params = new URLSearchParams();

        // Add filters
        if (metricSpec.filters && metricSpec.filters.size > 0) {
            params.set('filter', JSON.stringify(Object.fromEntries(metricSpec.filters)));
        }

        // Add exclude filters
        if (metricSpec.excludeFilters && metricSpec.excludeFilters.size > 0) {
            params.set('excludeFilter', JSON.stringify(Object.fromEntries(metricSpec.excludeFilters)));
        }

        return `/traces?${params.toString()}`;
    };

    // Get the traces URL
    const viewTracesURL = getTracesURL();

    // Add a unique ID for each chart instance
    const tooltipId = React.useId();

    useEffect(() => {
        if (props.timeUnit !== undefined && props.timeUnit !== "") {
            setTimeUnitToUse(props.timeUnit!)
        } else {
            setTimeUnitToUse(getTimeseriesXAxisUnit(timeRange.getStartEnd()[0].getTime(), timeRange.getStartEnd()[1].getTime()))
        }
    }, [timeRange]);

    // Cleanup tooltips when component unmounts
    useEffect(() => {
        return () => {
            const tooltipEl = document.getElementById(`chartjs-tooltip-${tooltipId}`);
            if (tooltipEl?.parentElement) {
                tooltipEl.parentElement.removeChild(tooltipEl);
            }
        };
    }, [tooltipId]);

    const options: ChartOptions<'line' | 'bar'> = {
        maintainAspectRatio: false,
        color: "#C6D3E2",
        plugins: {
            annotation: {
                annotations: Array.isArray(props.annotations) ? props.annotations : []
            },
            zoom: {
                limits: {
                    x: {min: 'original', max: 'original', minRange: 1000}
                },
                zoom: {
                    wheel: {
                        enabled: false
                    },
                    pinch: {
                        enabled: false
                    },
                    drag: {
                        enabled: true,
                        backgroundColor: '#ff638433',
                        borderColor: '#ff6384',
                        borderWidth: 1,
                    },
                    mode: 'x',
                    onZoomComplete: function ({chart}: any) {
                        const {min, max} = chart.scales.x;
                        if (props.setTimeRange !== undefined) {
                            props.setTimeRange(new TimeRange(undefined, new Date(min), new Date(max)))
                        } else {
                            dispatch(set(new TimeRange(undefined, new Date(min), new Date(max))))
                        }
                    }
                },
                pan: {
                    enabled: false,
                    mode: 'x'
                }
            },
            tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
                borderColor: "#334670",
                borderWidth: 1,
                cornerRadius: 2,
                backgroundColor: "#151B2E",
                titleColor: "#C6D3E2",
                bodyColor: "#C6D3E2",
                titleAlign: "center",
                bodyAlign: "center",
                displayColors: false,
                padding: 8,
                position: 'nearest',
                external: function (context: {
                    chart: ChartJS;
                    tooltip: TooltipModel<"bar" | "line">;
                    native?: MouseEvent;
                }) {
                    try {
                        // Safely remove any existing tooltip with same ID
                        const existingTooltip = document.getElementById(`chartjs-tooltip-${tooltipId}`);
                        if (existingTooltip && existingTooltip.parentElement !== context.chart.canvas.parentElement) {
                            existingTooltip.parentElement?.removeChild(existingTooltip);
                        }

                        // Get or create tooltip element
                        let tooltipEl = document.getElementById(`chartjs-tooltip-${tooltipId}`);

                        // Create tooltip DOM element if it doesn't exist
                        if (!tooltipEl) {
                            const newTooltip = document.createElement('div');
                            newTooltip.id = `chartjs-tooltip-${tooltipId}`;
                            newTooltip.style.background = '#151B2E';
                            newTooltip.style.borderRadius = '3px';
                            newTooltip.style.color = '#C6D3E2';
                            newTooltip.style.border = '1px solid #334670';
                            newTooltip.style.position = 'fixed';
                            newTooltip.style.pointerEvents = 'none';
                            newTooltip.style.padding = '8px';
                            newTooltip.style.zIndex = '9999';
                            newTooltip.style.minWidth = '200px';
                            newTooltip.style.opacity = '0';

                            // Append to document.body instead of chart parent
                            document.body.appendChild(newTooltip);
                            tooltipEl = newTooltip;
                        }

                        const tooltipModel = context.tooltip;

                        // Safely handle tooltip model
                        if (!tooltipModel?.opacity || !tooltipEl) {
                            if (tooltipEl) tooltipEl.style.opacity = '0';
                            return;
                        }

                        // Set Text
                        if (tooltipModel.body && Array.isArray(tooltipModel.body)) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map((b: { lines?: string[] }) => b.lines || []).flat();
                            const colors = tooltipModel.labelColors || [];

                            let innerHtml = '';

                            titleLines.forEach((title: string) => {
                                innerHtml += `<div style="text-align: left; font-weight: 600; margin-bottom: 8px; color: #C6D3E2;">${title}</div>`;
                            });

                            // Parse and sort the body lines
                            const sortedLines = bodyLines.map((body: string, i: number) => {
                                const line = body.toString();
                                const parts = line.split(':');
                                if (parts.length === 2) {
                                    const label = parts[0];
                                    const valueStr = parts[1].trim();
                                    // Convert value to number for sorting
                                    const value = parseFloat(valueStr.replace(/,/g, ''));
                                    return {
                                        label,
                                        valueStr,
                                        value,
                                        backgroundColor: colors[i].backgroundColor,
                                        borderColor: colors[i].borderColor
                                    };
                                }
                                return {
                                    label: line,
                                    valueStr: '',
                                    value: -Infinity,
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent'
                                };
                            }).sort((a, b) => b.value - a.value); // Sort in descending order

                            // Generate HTML for sorted lines
                            sortedLines.forEach(({label, valueStr, backgroundColor, borderColor}) => {
                                if (valueStr) {
                                    innerHtml += `
                                        <div style="text-align: left; margin-bottom: 4px; display: flex; justify-content: space-between; align-items: center;">
                                            <div style="display: flex; align-items: center; gap: 4px;">
                                                <span style="width: 12px; height: 12px; display: inline-block; border: 1px solid ${borderColor}; background-color: ${backgroundColor};"></span>
                                                <span style="color: #8B9AB7; font-size: 11px;">${label}:</span>
                                            </div>
                                            <span style="font-weight: 600; color: #C6D3E2; font-size: 11px;">${valueStr}</span>
                                        </div>`;
                                } else {
                                    innerHtml += `<div style="text-align: left; font-size: 11px;">${label}</div>`;
                                }
                            });

                            tooltipEl.innerHTML = innerHtml;
                        }

                        // Position relative to viewport
                        const chartRect = context.chart.canvas.getBoundingClientRect();
                        if (!chartRect) return;

                        // Get mouse position from event for more accurate positioning
                        const event = context.native;
                        const x = event?.clientX ?? (chartRect.left + tooltipModel.caretX);
                        const y = event?.clientY ?? (chartRect.top + tooltipModel.caretY);

                        tooltipEl.style.opacity = '1';

                        let offsetX = 10;
                        let offsetY = 10;

                        // Check bounds relative to viewport
                        if (tooltipEl.offsetWidth && x + tooltipEl.offsetWidth + offsetX > window.innerWidth - 20) {
                            offsetX = -tooltipEl.offsetWidth - 10;
                        }

                        if (tooltipEl.offsetHeight && y + tooltipEl.offsetHeight + offsetY > window.innerHeight - 20) {
                            offsetY = -tooltipEl.offsetHeight - 10;
                        }

                        // Position relative to viewport
                        tooltipEl.style.left = `${x + offsetX}px`;
                        tooltipEl.style.top = `${y + offsetY}px`;
                    } catch (error) {
                        console.error('Error in tooltip handling:', error);
                        const tooltipEl = document.getElementById(`chartjs-tooltip-${tooltipId}`);
                        if (tooltipEl) tooltipEl.style.opacity = '0';
                    }
                },
            },
            datalabels: {
                display: false,
            },
            legend: {
                display: false, // we use custom HTML Legends
            }
        },
        responsive: true,
        events: ['mousemove', 'mouseout'],
        onHover(event: any, elements: any, chart: ChartJS) {
            if (!event?.native) return;

            const extChart = chart as ExtendedChart;
            const xScale = chart.scales.x;
            if (!xScale) return;

            // Get mouse position and chart area
            const rect = event.native.target.getBoundingClientRect();
            const mouseX = event.native.offsetX;
            const mouseY = event.native.offsetY;
            const {left, right, top, bottom} = chart.chartArea;

            // Check if mouse is within chart area
            const isInChartArea = mouseX >= left && mouseX <= right && mouseY >= top && mouseY <= bottom;

            // Clear crosshair and tooltips if mouse is outside chart area or on mouseout
            if (!isInChartArea || event.type === 'mouseout') {
                if (extChart.crosshairX !== undefined) {
                    extChart.crosshairX = undefined;
                    chart.tooltip?.setActiveElements([], {x: 0, y: 0});
                    window.dispatchEvent(new CustomEvent('metoro-chart-leave'));
                    chart.update('none'); // Use 'none' mode for faster updates
                }
                return;
            }

            // Convert to timestamp
            const timestamp = xScale.getValueForPixel(mouseX);

            // Store pixel position for this chart
            extChart.crosshairX = mouseX;

            // Broadcast the timestamp for other charts
            window.dispatchEvent(new CustomEvent('metoro-chart-hover', {
                detail: {timestamp}
            }));

            chart.draw();
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    color: "#334670"
                },
                time: {
                    unit: timeUnitToUse as 'second' | 'minute' | 'hour' | 'day',
                    displayFormats: {
                        minute: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                        second: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                        hour: props.timeUnitDisplay ? props.timeUnitDisplay : 'HH:mm:ss',
                        day: props.timeUnitDisplay ? props.timeUnitDisplay : dayTimeFormatterString,
                    },
                },
                ticks: {
                    color: "#C6D3E2",
                    autoSkip: true,
                    maxTicksLimit: 4,
                },
                type: 'timeseries',
                stacked: props.type === ChartType.Bar,
            },
            y: {
                border: {
                    display: false,
                },
                display: true,
                grid: {
                    drawTicks: true,
                    color: "#334670"
                },
                ticks: {
                    callback: function (this: Scale<CoreScaleOptions>, tickValue: number | string, index: number, ticks: Tick[]) {
                        const value = typeof tickValue === 'string' ? parseFloat(tickValue) : tickValue;
                        return props.isDuration ? shortEnglishHumanizer(value) : value;
                    },
                    color: "#C6D3E2",
                    maxTicksLimit: 5,
                    autoSkip: true,
                },
                stacked: props.type === ChartType.Bar,
                beginAtZero: true,
            }
        }
    };

    useEffect(() => {
        // Listen for hover events from other charts
        const handleChartHover = (event: CustomEvent) => {
            // Get all chart instances
            const chartInstances = Object.values(ChartJS.instances);
            // Update all charts
            chartInstances.forEach((chart) => {
                const extChart = chart as ExtendedChart;
                const xScale = chart.scales.x;
                if (!xScale) return;

                // Convert timestamp to pixel position for this specific chart
                const pixelX = xScale.getPixelForValue(event.detail.timestamp);

                // Only update if position changed
                if (extChart.crosshairX !== pixelX) {
                    extChart.crosshairX = pixelX;
                    chart.draw();
                }
            });
        };

        // Listen for leave events
        const handleChartLeave = () => {
            // Get all chart instances
            const chartInstances = Object.values(ChartJS.instances);
            // Remove crosshairs and tooltips from all charts
            chartInstances.forEach((chart) => {
                const extChart = chart as ExtendedChart;
                if (extChart.crosshairX !== undefined) {
                    extChart.crosshairX = undefined;
                    chart.tooltip?.setActiveElements([], {x: 0, y: 0});
                    chart.update();
                }
            });
        };

        window.addEventListener('metoro-chart-hover', handleChartHover as EventListener);
        window.addEventListener('metoro-chart-leave', handleChartLeave as EventListener);
        return () => {
            window.removeEventListener('metoro-chart-hover', handleChartHover as EventListener);
            window.removeEventListener('metoro-chart-leave', handleChartLeave as EventListener);
        };
    }, []);

    let inner = <div></div>
    if (props.dataToUse === undefined || props.dataToUse.datasets.length === 0) {
        if (props.hideOnNoData) {
            return null;
        }
        inner = <div className={"flex justify-center w-full h-full"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex justify-center text-textmedium text-sm"}>
                    No data to display
                </div>
            </div>
        </div>
    } else {
        switch (props.type) {
            case ChartType.Line:
                inner = <Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                              data={props.dataToUse} options={options}/>
                break;
            case ChartType.Bar:
                inner = <Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                    // @ts-ignore
                             data={props.dataToUse} options={options}/>
                break;
        }
    }


    return <ChartContainer
        metricChartWidget={props.metricChartWidget}
        createAlertURL={props.createAlertURL}
        viewTracesURL={viewTracesURL}
        editDialog={props.editDialog} styling={props.styling} className={props.className}
        showAll={props.showAll}
        setShowAll={props.setShowAll}
        resultsLimited={props.resultsLimited}
        actualResultLen={props.actualResultLen}
        dataUsed={props.dataToUse}
        chartType={props.type}
        chartOptions={options}
        hideLegend={props.hideLegend}
        title={props.title}
        setComparisonDialogOpen={props.setComparisonDialogOpen}
        children={
            inner
        }/>
}

export {
    MetoroChart
}
export type {
    ChartStyling
}
export {
    ChartType
};