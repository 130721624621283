import React, {Dispatch, SetStateAction, useState} from "react";
import {Widget} from "./internalwidgets";
import {addWidget} from "./utils";
import {Button} from "../ui/button";
import {MetricType} from "../../pages/MetricsTest";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import {ScrollArea} from "../ui/scroll-area";
import {Input} from "../ui/input";
import {
    Activity,
    BarChart3,
    ChevronRight,
    Cpu,
    Database as Memory,
    FileDigit,
    FileText,
    Network,
    Search,
    Server
} from "lucide-react";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,} from "../ui/dropdown-menu";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "../ui/card";
import {groupTemplates} from './templates';
import {RateFunctionType} from "./widgets/MetricSelector";

const markdownPlaceholderText = `### Hi
This is a **markdown** note.
`;

interface AddNewWidgetDrawerProps {
    setChildren: Dispatch<SetStateAction<Widget[]>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface CategoryCardProps {
    title: string;
    description: string;
    icon: React.ElementType;
    items: Array<{ name: string; config: any }>;
    onSelect: (config: any) => void;
}

interface WidgetCardProps {
    title: string;
    description: string;
    icon: React.ElementType;
    onClick: () => void;
}

interface TemplateCardProps {
    title: string;
    description: string;
    icon: React.ElementType;
    onClick: () => void;
}

const CategoryCard = ({title, description, icon: Icon, items, onSelect}: CategoryCardProps) => (
    <Card className="bg-backgroundmedium border-border">
        <CardHeader>
            <div className="flex items-center gap-2">
                <Icon className="w-5 h-5 text-primary"/>
                <CardTitle className="text-textlight">{title}</CardTitle>
            </div>
            <CardDescription className="text-textdark">{description}</CardDescription>
        </CardHeader>
        <CardContent>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button className="m-4 justify-between text-textmedium border border-primary bg-primarytransparent">
                        <div>Select a widget</div>
                        <ChevronRight className="h-4 w-4"/>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[300px] bg-backgroundmedium text-textmedium">
                    {items.map((item, index) => (
                        <DropdownMenuItem
                            key={index}
                            onClick={() => onSelect(item.config)}
                            className="cursor-pointer hover:border hover:border-primary hover:bg-primarytransparent"
                        >
                            {item.name}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </CardContent>
    </Card>
);

const WidgetCard = ({title, description, icon: Icon, onClick}: WidgetCardProps) => (
    <Card
        className="bg-background border border-border hover:border-primary cursor-pointer transition-all duration-200 group"
        onClick={onClick}
    >
        <CardHeader>
            <div className="flex items-center gap-3">
                <div className="p-2 rounded-md bg-primary/10 group-hover:bg-primary/20">
                    <Icon className="w-5 h-5 text-primary"/>
                </div>
                <CardTitle className="text-textlight text-lg">{title}</CardTitle>
            </div>
            <CardDescription className="text-textmedium mt-2 text-sm">{description}</CardDescription>
        </CardHeader>
    </Card>
);

const TemplateCard = ({title, description, icon: Icon, onClick}: TemplateCardProps) => (
    <Card
        className="bg-background border border-border hover:border-primary cursor-pointer transition-all duration-200 group"
        onClick={onClick}
    >
        <CardHeader>
            <div className="flex items-center gap-3">
                <div className="p-2 rounded-md bg-primary/10 group-hover:bg-primary/20">
                    <Icon className="w-5 h-5 text-primary"/>
                </div>
                <CardTitle className="text-textlight text-lg">{title}</CardTitle>
            </div>
            <CardDescription className="text-textmedium mt-2 text-sm">{description}</CardDescription>
        </CardHeader>
    </Card>
);

export const AddNewWidgetDrawer = (props: AddNewWidgetDrawerProps) => {
    const [selectedTab, setSelectedTab] = useState("widgets");
    const [searchQuery, setSearchQuery] = useState("");

    const handleAddChart = (chartConfig: any) => {
        addWidget(chartConfig, props.setChildren);
        props.setIsOpen(false);
    };

    const handleAddGroup = (groupConfig: any) => {
        addWidget(groupConfig, props.setChildren);
        props.setIsOpen(false);
    };

    const filteredTemplates = Object.entries(groupTemplates).filter(([key, group]) =>
        group.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        key.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-full bg-backgrounddark">
            <div className="text-textlight text-xl font-medium px-4 py-3 border-b border-border bg-background">
                Add a widget
            </div>
            <Tabs defaultValue="widgets">
                <TabsList className="w-full grid grid-cols-2 p-0 bg-background border-b border-border rounded-none">
                    <TabsTrigger value="widgets">
                        Widgets
                    </TabsTrigger>
                    <TabsTrigger value="templates">
                        Templates
                    </TabsTrigger>
                </TabsList>

                <ScrollArea className="h-[calc(100vh-120px)]">
                    <div className="p-4">
                        <TabsContent value="widgets" className="m-0 space-y-3">
                            <WidgetCard
                                title="Chart"
                                description="Add a new metric chart to monitor specific metrics"
                                icon={BarChart3}
                                onClick={() => handleAddChart({
                                    metricSpecifiers: [{
                                        metricName: "container_net_tcp_active_connections",
                                        aggregation: "avg",
                                        label: "Active Connections",
                                        metricType: MetricType.Metric,
                                        functions: []
                                    }],
                                    type: "line",
                                    widgetType: "MetricChart",
                                    title: "New Chart",
                                    position: {x: undefined, y: undefined, w: 6, h: 2}
                                })}
                            />

                            <WidgetCard
                                title="Stat"
                                description="Add a new stat chart to monitor a specific value"
                                icon={FileDigit}
                                onClick={() => handleAddChart({
                                    metricSpecifier: {
                                        metricName: "node_resources_cpu_usage_seconds_total",
                                        aggregation: "sum",
                                        label: "Cores Used",
                                        metricType: MetricType.Metric,
                                        functions: [
                                            {
                                                id: "1",
                                                functionType: RateFunctionType.PerSecond
                                            }
                                        ]
                                    },
                                    widgetType: "Stat",
                                    reduceOption: "max",
                                    precision: 0,
                                    position: {x: undefined, y: undefined, w: 2, h: 1},
                                    mappings: [{
                                        operator: ">",
                                        value: 5,
                                        color: "#d92e48",
                                        type: "text",
                                        textMapping: "$value cores used"
                                    },
                                        {
                                            operator: "<",
                                            value: 5,
                                            color: "#2DD881",
                                            type: "text",
                                            textMapping: "$value cores used"
                                        }
                                    ]
                                })}
                            />

                            <WidgetCard
                                title="Group"
                                description="Create a new group to organize multiple widgets"
                                icon={Server}
                                onClick={() => handleAddGroup({
                                    widgetType: "Group",
                                    title: "New Group",
                                    position: {x: undefined, y: undefined, w: 6, h: 4},
                                    children: []
                                })}
                            />

                            <WidgetCard
                                title="Markdown Note"
                                description="Add documentation or notes to your dashboard"
                                icon={FileText}
                                onClick={() => handleAddChart({
                                    widgetType: "Markdown",
                                    content: markdownPlaceholderText,
                                    position: {x: undefined, y: undefined, w: 6, h: 2},
                                    children: []
                                })}
                            />
                        </TabsContent>

                        <TabsContent value="templates" className="m-0 space-y-4">
                            <div className="flex items-center gap-2 px-1">
                                <div className="relative flex-1">
                                    <Search
                                        className="w-4 h-4 text-textdark absolute left-3 top-1/2 transform -translate-y-1/2"/>
                                    <Input
                                        placeholder="Search templates..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="bg-background border-border text-textmedium pl-9"
                                    />
                                </div>
                            </div>

                            <div className="space-y-3">
                                {filteredTemplates.map(([key, group]) => (
                                    <TemplateCard
                                        key={key}
                                        title={group.name}
                                        description={`A collection of ${key} related metrics`}
                                        icon={
                                            key === 'cpu' ? Cpu :
                                                key === 'memory' ? Memory :
                                                    key === 'network' ? Network :
                                                        key === 'application' ? Activity : Server
                                        }
                                        onClick={() => handleAddGroup(group.config)}
                                    />
                                ))}

                                {filteredTemplates.length === 0 && searchQuery && (
                                    <div className="text-center text-textdark py-8">
                                        No templates found matching "{searchQuery}"
                                    </div>
                                )}
                            </div>
                        </TabsContent>
                    </div>
                </ScrollArea>
            </Tabs>
        </div>
    );
};