import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {CopyIcon, XIcon} from "lucide-react";
import {MdModeEdit} from "react-icons/md";
import {v4 as uuidv4} from "uuid";
import {StatWidget, Widget} from "../internalwidgets";
import {ButtonWithTooltip, RuntimeVariable} from "../Dashboard";
import {cn} from "../../ui/lib/utils";
import {cloneWidget, removeWidget} from "../utils";
import {Dialog, DialogContent} from "../../ui/dialog";
import {StatChart} from "../../Charts/StatChart";
import {determineFullWidth, findParentGrid, getVariableResolvedValuesAtGroup} from "../utils/grid";
import {StatSelectorPanel} from "./StatSelectorPanel";
import {substituteVariablesInText} from "../../Charts/utils";


export function StatChartWidget(
    props: {
        editable: boolean,
        id?: string,
        widget: StatWidget,
        grids: Map<string, GridStack>,
        setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
        widgets: Map<string, Widget>,
        setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
        updateParentOfMount?: (id: string) => void
        ref?: React.MutableRefObject<any>
        setRootChildren: Dispatch<SetStateAction<Widget[]>>
        runtimeVariables: Map<string, RuntimeVariable[]>
    }
) {
    const [id] = React.useState<string>(props.id ?? "id-" + uuidv4())
    const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
    const [widget, setWidget] = React.useState<StatWidget>(props.widget)
    const [resolvedVariables, setResolvedVariables] = React.useState<RuntimeVariable[]>(() => {
        let variableResolvedValuesAtGroup1 = getVariableResolvedValuesAtGroup(id, props.grids, props.runtimeVariables);
        return variableResolvedValuesAtGroup1
    })
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    // When we update the variables, we need to calculate new resolved values
    useEffect(() => {
        let variableResolvedValuesAtGroup = getVariableResolvedValuesAtGroup(id, props.grids, props.runtimeVariables);
        setResolvedVariables(variableResolvedValuesAtGroup)
    }, [props.runtimeVariables, props.grids])

    useEffect(() => {
        const {element, grid} = findParentGrid(id, props.grids);
        const fullWidth = determineFullWidth(element, grid, props.widget.position ?? {});
        setIsFullWidth(fullWidth);
    }, [id, props.grids, props.widget.position, props]);

    useEffect(() => {
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, props.widget)
            return newWidgets
        })

        if (props.updateParentOfMount !== undefined) {
            props.updateParentOfMount(id)
        }
    }, []);

    let leftMargin = "pl-2"
    if (props.widget.position?.x === 0) {
        leftMargin = "pl-4"
    }
    let rightMargin = "pr-2"
    if (isFullWidth) {
        rightMargin = "pr-4"
    }
    let topMargin = "pt-2"
    if (props.widget.position?.y === 0) {
        topMargin = "pt-4"
    }
    let bottomMargin = "pb-2"
    return (
        <div id={id} className={cn(id, "flex flex-col grow")}>
            <div className={cn("flex grow max-w-full", leftMargin, topMargin, rightMargin, bottomMargin)}>
                <div className={cn("border flex flex-col grow h-full rounded max-w-full")}>
                    <div
                        className={cn("flex draggablehandle justify-between max-w-full bg-backgroundmedium", widget.title && widget.title?.length > 0 ? "border-b px-2 h-8": "")}>
                        {widget.title && widget.title?.length > 0 ?
                            <div className={"text-textmedium flex-col text-center items-center justify-center mt-[4px] truncate"}>
                                {props.editable ? widget.title : substituteVariablesInText(widget.title || "", resolvedVariables)}
                            </div> : <div></div>}
                        <div className={"flex gap-2"}>
                            {
                                props.editable &&
                                <div className={"flex flex-col justify-center"}>
                                    <ButtonWithTooltip
                                        icon={CopyIcon}
                                        tooltipText="Duplicate widget"
                                        onClick={() => {
                                            cloneWidget(widget, props.setRootChildren)
                                        }}
                                    />
                                </div>
                            }
                            {props.editable && (
                                <>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={MdModeEdit}
                                            tooltipText="Edit widget"
                                            onClick={() => setIsDialogOpen(true)}
                                        />
                                    </div>
                                    <Dialog modal={false} open={isDialogOpen}>
                                        <DialogContent
                                            onInteractOutside={() => setIsDialogOpen(false)}
                                            onAbort={() => setIsDialogOpen(false)}
                                            onFocusOutside={() => setIsDialogOpen(false)}
                                            className={"w-screen h-screen m-0 p-0 bg-black/80 flex items-center justify-center border-none"}
                                            onClick={(e) => {
                                                if (e.target === e.currentTarget) {
                                                    setIsDialogOpen(false);
                                                }
                                            }}
                                        >
                                            <div className="w-[85vw] h-[85vh] flex">
                                                <StatSelectorPanel
                                                    widget={widget as StatWidget}
                                                    setIsDialogOpen={setIsDialogOpen}
                                                    runtimeVariables={resolvedVariables}
                                                    onSave={(updatedWidget) => {
                                                        props.setWidgets((prev) => {
                                                            let newWidgets = new Map<string, Widget>(prev)
                                                            newWidgets.set(id, updatedWidget)
                                                            return newWidgets
                                                        });
                                                        setWidget(updatedWidget);
                                                    }}
                                                />
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={XIcon}
                                            tooltipText="Delete widget"
                                            onClick={() => removeWidget(id, props.grids, props.setGrids, props.widgets, props.setWidgets)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={"flex grow max-w-full"}>
                        <div className={cn("flex grow max-w-full bg-backgroundmedium")}>
                            <StatChart
                                title={(widget as StatWidget).title}
                                metricSpecifier={(widget as StatWidget).metricSpecifier}
                                reduceOption={(widget as StatWidget).reduceOption}
                                mappings={(widget as StatWidget).mappings}
                                precision={(widget as StatWidget).precision || 0}
                                fontSize={(widget as StatWidget).fontSize}
                                className="!p-0"
                                runtimeVariables={resolvedVariables}
                                grids={props.grids}
                                widgetId={id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}