"use client"

import * as React from "react"

import {cn} from "components/ui/lib/utils"
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import useAuth from "hooks/useAuth";
import {EmailAuthLogin} from "./EmailAuthLogin";
import {useCookies} from "react-cookie";
import {Dialog, DialogContent} from "../ui/dialog";
import {Button} from "@mui/material";
import {InviteExistsDialog} from "./InviteExistsDialog";

interface UserAuthFormLoginProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function UserAuthFormLogin({className, ...props}: UserAuthFormLoginProps) {
    const {isLoggedIn, loginGoogleSSO} = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [cookies, setCookie] = useCookies(['aws_marketplace_customer_id']);
    const awsCustomerIdToken = cookies.aws_marketplace_customer_id ? cookies.aws_marketplace_customer_id as string: "";
    const [inviteExistsDialog, setInviteExistsDialog] = React.useState<boolean>(false);
    const [forceNewOrg, setForceNewOrg] = React.useState<boolean | undefined>(undefined);
    const [lastCredentialResponse, setLastCredentialResponse] = React.useState<any>(null);

    async function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()
        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    const handleGoogleLogin = async (credentialResponse: any) => {
        try {
            setLastCredentialResponse(credentialResponse);
            await loginGoogleSSO(credentialResponse, "", awsCustomerIdToken, forceNewOrg, setInviteExistsDialog);
        } catch (err: any) {
            alert("Login unsuccessful. Please try again.")
        }
    };

    const handleCreateNewOrg = async () => {
        if (!lastCredentialResponse) return;
        setForceNewOrg(true);
        try {
            await loginGoogleSSO(lastCredentialResponse, "", awsCustomerIdToken, true, setInviteExistsDialog);
        } catch (err: any) {
            alert("Login unsuccessful. Please try again.")
        }
    };

    return (
        <div className={cn("w-full grid gap-6", className)} {...props}>
            <GoogleOAuthProvider
                clientId="822301410763-mlt2jbo7blucoek4d1vbm4efuc30gd16.apps.googleusercontent.com"
            >
                <GoogleLogin
                    containerProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                    text="signin_with"
                    logo_alignment={"left"}
                    theme="outline"
                    size="medium"
                    useOneTap={false}
                    type={"standard"}
                    onSuccess={handleGoogleLogin}
                    onError={() => {
                        console.log('Login Failed');
                        alert("Login unsuccessful. Please try again.")
                    }}
                />
            </GoogleOAuthProvider>
            <div className="relative">
                <div className="absolute inset-0 flex items-center text-textmedium">
                    <span className="w-full border-t"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase text-textmedium">
                    <span className="px-2 text-muted-foreground bg-background text-textmedium">
                        Or
                    </span>
                </div>
            </div>
            <EmailAuthLogin/>
            <InviteExistsDialog 
                open={inviteExistsDialog}
                onOpenChange={setInviteExistsDialog}
                onCreateNewOrg={handleCreateNewOrg}
            />
        </div>
    )
}