import React from "react";
import {Dialog, DialogContent} from "../ui/dialog";

interface InviteExistsDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onCreateNewOrg: () => Promise<void>;
}

export function InviteExistsDialog({open, onOpenChange, onCreateNewOrg}: InviteExistsDialogProps) {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className={"text-textmedium w-1/2 bg-backgrounddark"}>
                <div className={"text-textlight font-semibold text-xl mb-4"}>
                    There is an existing invite for this user to join an organization.
                </div>
                <div className={"space-y-4"}>
                    <div>Please check your email for the invitation link.</div>
                    <div className={"text-sm text-textmedium"}>
                        If you can't find the email, please contact the administrator of your organization to send you a new link.
                    </div>
                    <div className={"text-sm text-red-500"}>
                        If you want to create a new account with a new organization, this will be a new separate account and linking your account later will require you to contact Metoro support.
                    </div>
                    <div className={"flex w-full gap-4 justify-end mt-6"}>
                        <div
                            className={"px-4 py-2 border rounded border-red-500 bg-red-500/10 hover:cursor-pointer hover:text-textlight transition-colors"}
                            onClick={async () => {
                                await onCreateNewOrg();
                                onOpenChange(false);
                            }}
                        >
                            Create a new organization anyway
                        </div>
                        <div
                            className={"px-4 py-2 border rounded border-primary bg-primarytransparent hover:cursor-pointer hover:text-textlight transition-colors"}
                            onClick={() => onOpenChange(false)}
                        >
                            I will check my email
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
} 