import {MarkdownWidget, MetricChartWidget, Widget} from "../internalwidgets";
import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {cn} from "../../ui/lib/utils";
import Markdown from 'react-markdown'
import {ButtonWithTooltip} from "../Dashboard";
import {CopyIcon, XIcon} from "lucide-react";
import {cloneWidget, removeWidget} from "../utils";
import {MdModeEdit} from "react-icons/md";
import {Dialog, DialogContent} from "../../ui/dialog";
import {Textarea} from "../../ui/textarea";
import {Button} from "../../ui/button";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";

export const MarkdownNote = (props: {
    editable: boolean,
    id?: string,
    widget: MarkdownWidget,
    grids: Map<string, GridStack>,
    setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
    updateParentOfMount?: (id: string) => void
    ref?: React.MutableRefObject<any>
    setRootChildren: Dispatch<SetStateAction<Widget[]>>
}) => {
    const [id] = React.useState<string>(props.id ?? "id-" + uuidv4())
    const [widget, setWidget] = React.useState<Widget>(props.widget)
    const [markdownContent, setMarkdownContent] = React.useState<string>(props.widget.content)
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    useEffect(() => {
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, props.widget)
            return newWidgets
        })

        if (props.updateParentOfMount !== undefined) {
            props.updateParentOfMount(id)
        }
    }, [])

    useEffect(() => {
        let updatedWidget = {
            ...props.widget,
            content: markdownContent,
        } as MarkdownWidget
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, updatedWidget)
            return newWidgets
        })
        setWidget(updatedWidget)
    }, [markdownContent])

    let leftMargin = "pl-2"
    if (props.widget.position?.x === 0) {
        leftMargin = "pl-4"
    }
    let rightMargin = "pr-2"
    if (props.widget.position?.x && props.widget.position.w &&
        props.widget.position.x + props.widget.position.w === 12) {
        rightMargin = "pr-4"
    }
    let topMargin = "pt-2"
    if (props.widget.position?.y === 0) {
        topMargin = "pt-4"
    }
    let bottomMargin = "pb-2"


    return (
        <div className={cn(id, "flex flex-col grow")}>
            <div className={cn("flex grow shrink max-h-full", leftMargin, topMargin, rightMargin, bottomMargin)}>
                <div className={cn("border flex flex-col grow h-full rounded max-w-full bg-backgroundmedium")}>
                    <div
                        className={"flex draggablehandle justify-end px-2 h-8 max-w-full bg-backgroundmedium"}>
                        <div className={"flex gap-2"}>
                            {props.editable &&
                                <div className={"flex flex-col justify-center"}>
                                    <ButtonWithTooltip
                                        icon={CopyIcon}
                                        tooltipText="Duplicate widget"
                                        onClick={() => {
                                            cloneWidget(widget, props.setRootChildren)
                                        }}
                                    />
                                </div>
                            }
                            {props.editable && (
                                <>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={MdModeEdit}
                                            tooltipText="Edit widget"
                                            onClick={() => setIsDialogOpen(true)}
                                        />
                                    </div>
                                    <Dialog open={isDialogOpen}>
                                        <DialogContent
                                            onInteractOutside={() => setIsDialogOpen(false)}
                                            onAbort={() => setIsDialogOpen(false)}
                                            onFocusOutside={() => setIsDialogOpen(false)}
                                            className={"border p-4 bg-none w-[80vw] h-[80vh] m-0 flex flex-col"}
                                        >
                                                <div className={"flex text-textmedium font-semibold text-lg"}> Markdown Editor
                                                </div>
                                            <div className={"flex grow shrink min-h-0 min-w-0"}>
                                                <div className={"grid grid-cols-2 grow shrink"}>
                                                    <div className={"border border-border h-full"}>
                                                        <Textarea
                                                            value={markdownContent}
                                                            onChange={(e) => {
                                                                setMarkdownContent(e.target.value)
                                                            }}
                                                            className={"resize-none h-full text-textlight overflow-y-auto overflow-x-auto"}
                                                            placeholder={"This is a new note"}
                                                        />
                                                    </div>
                                                    <div className={"border-t border-b border-r border-border px-4 overflow-y-auto overflow-x-auto "}>
                                                        <MarkdownRenderer markdown={markdownContent}
                                                                          className={"text-textmedium w-full h-full m-4"}/>
                                                    </div>
                                                </div>
                                            </div>
                                                <Button
                                                    onClick={() => setIsDialogOpen(false)}
                                                    className={"bg-primarytransparent border border-primary w-full px-2 py-1 text-textmedium hover:cursor-pointer hover:text-textlight"}>Save</Button>
                                        </DialogContent>
                                    </Dialog>
                                    <div className={"flex flex-col items-center justify-center"}>
                                        <ButtonWithTooltip
                                            icon={XIcon}
                                            tooltipText="Delete widget"
                                            onClick={() => removeWidget(id, props.grids, props.setGrids, props.widgets, props.setWidgets)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={"px-4 overflow-y-auto max-h-full"}>
                        <MarkdownRenderer markdown={markdownContent}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MarkdownRenderer(props: { markdown: string, className?: string }) {
    return <div className={"text-textmedium markdown"}>
        <Markdown rehypePlugins={[rehypeHighlight]} remarkPlugins={[remarkGfm]}>{props.markdown}</Markdown>
    </div>
}