import React from "react";
import {BaseView} from "./BaseView";
import {Dashboard} from "../components/Dashboarding/Dashboard";
import {substituteVariablesInText} from "../components/Charts/utils";

const DashboardView = () => {
    const [dashboardTitle, setDashboardTitle] = React.useState<string>("Dashboard");
    const [runtimeVariables, setRuntimeVariables] = React.useState<Map<string, { name: string, key: string, value: string }[]>>(new Map());

    return (
        <BaseView title={substituteVariablesInText(dashboardTitle, runtimeVariables.get("id-root"))}>
            <div className={"flex grow shrink overflow-y-auto"}>
                <Dashboard 
                    dashboardTitle={dashboardTitle} 
                    setDashboardTitle={setDashboardTitle}
                    onRuntimeVariablesChange={(vars) => setRuntimeVariables(vars)}
                />
            </div>
        </BaseView>
    )
}

export default DashboardView;