import {BaseView} from "./BaseView";
import React, {useEffect} from "react";
import {Input} from "../components/ui/input";
import {MailIcon, SearchIcon} from "lucide-react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {AiOutlineKubernetes} from "react-icons/ai";
import {FaSlack} from "react-icons/fa";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";


function AlertSearchBar(props: {
    search: string,
    setSearch: (value: (((prevState: string) => string) | string)) => void
}) {
    const navigate = usePreserveQueryParamsNavigate()
    return <div className={"flex grow h-12 gap-4"}>
        <div
            className={"bg-backgroundmedium flex-none grow h-12 px-[7px] py-[9px] focus-within:border-primary hover:cursor-pointer hover:border-primary rounded border justify-between items-center gap-2 inline-flex"}>
            <Input
                placeholder={"Search for alerts"}
                className={"bg-transparent focus-visible:ring-0 border-none border-0 ring-0 outline-0 text-textmedium"}
                value={props.search} onChange={(e) => {
                props.setSearch(e.target.value)
            }}/>
            <SearchIcon className={"text-textdark"}/>
        </div>
        <div className={""}>
            <Button className={"h-12 px-4 bg-secondarytransparenter border border-secondary text-textmedium"}
                    onClick={() => {
                        navigate("/new-alert")
                    }
                    }>Create Alert</Button>
        </div>
    </div>

}

function AlertSearchResults(props: { alerts: AlertSearchResult[] }) {
    const navigate = usePreserveQueryParamsNavigate()

    return <div
        className="mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
        <div
            className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
            <div
                className="h-full flex-none w-[300px] font-normal font-['Inter'] leading-8 text-textmedium text-xl">Name
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Status
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Type
            </div>
            <div className="h-full flex-none w-[128px] font-normal leading-8 text-textmedium text-xl">Destination
            </div>
            <div
                className="h-full flex grow shrink font-normal leading-8 text-textmedium text-xl max-[975px]:hidden">Description
            </div>
        </div>
        <div className="flex flex-col grow shrink">
            {
                props.alerts.length === 0 ? <div className={"flex grow shrink flex-col justify-center"}>
                    <div
                        className={"flex justify-center items-center text-textmedium text-lg text-center"}>No alerts
                        found
                    </div>
                </div> : null
            }
            {props.alerts.map((alert) => {
                return <div
                    onClick={() => {
                        navigate(`/alert?alertId=${alert.uuid}`)
                    }}
                    key={alert.uuid}
                    className="w-full h-16 px-4 py-2 border-b border-backgroundmedium hover:cursor-pointer hover:bg-backgrounddark flex justify-start items-center gap-4">
                    <div
                        className="h-full flex-none w-[300px] font-normal text-textmedium line-clamp-2">{alert.name}</div>
                    <div
                        className="h-full flex-none w-[128px] items-center font-normal leading-8 text-white">{
                        alert.status === "FIRING" ? <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-red-500">Firing</div>
                            :
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-secondaryTransparent">Ok</div>
                    }</div>
                    <div
                        className="h-full flex-none items-center w-[128px] font-normal leading-8 text-white">
                        {alert.type === "Kubernetes" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2">
                            <AiOutlineKubernetes className={"text-white h-4 w-4"}/>Kubernetes
                        </div>}
                        {alert.type === "Log" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                            Log</div>}
                        {alert.type === "Metric" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                            Metric</div>}
                        {alert.type === "Trace" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 bg-blue-500 rounded">
                            Trace</div>}
                    </div>

                    <div
                        className="h-full flex-none items-center w-[128px] font-normal font-['Inter'] leading-8 text-white">
                        {alert.destination === "Slack" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-purple-500 gap-2">
                            <FaSlack
                                className={"text-white h-4 w-4"}/>Slack
                        </div>}
                        {alert.destination === "PagerDuty" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-[#06AC38] gap-2">
                            PagerDuty
                        </div>}
                        {alert.destination === "Email" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-red-400 gap-2">
                            <MailIcon
                                className={"text-white h-4 w-4"}/>Email
                        </div>}
                        {
                            alert.destination === "None" && <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-gray-500 gap-2">
                               None
                            </div>
                        }
                    </div>
                    <p className="h-full font-normal text-textmedium line-clamp-2 max-[975px]:hidden">{alert.description}</p>
                </div>
            })}
        </div>
    </div>
}

interface AlertSearchResult {
    uuid: string
    name: string
    description: string
    status: string
    type: string
    destination: string
}

function AlertsComponent() {
    const [search, setSearch] = React.useState<string>("")
    const [alerts, setAlerts] = React.useState<AlertSearchResult[]>([])

    useEffect(() => {
        let searchParams = ""
        if (search) {
            searchParams = `?query=${search}`
        }
        axios.get(`/api/v1/searchAlerts${searchParams}`).then((response) => {
            setAlerts(response.data.alerts)
        })
    }, [search])

    return <div className={"flex flex-col grow shrink p-4 overflow-y-auto"}>
        <div className={"h-max"}>
            <AlertSearchBar search={search} setSearch={setSearch}/>
        </div>
        <div className={"flex grow shrink min-h-0"}>
            <AlertSearchResults alerts={alerts}/>
        </div>
    </div>

}

const Alerts = () => {
    return <BaseView title={"Alerts"} disableClusterSelector={true}>
        <AlertsComponent/>
    </BaseView>
}


export {
    Alerts
}