export const HTTPServerTemplate = `{
  "widgetType": "Group",
  "title": "HTTP Server Template",
  "position": {
    "x": 0,
    "y": 0,
    "w": 12,
    "h": 12
  },
  "children": [
    {
      "widgetType": "Markdown",
      "content": "### HTTP Response Metrics\\nThis section shows total number of http responses serverd by the server broken down in different ways",
      "position": {
        "x": 0,
        "y": 0,
        "w": 3,
        "h": 2
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ]
            ]
          },
          "splits": [],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "All HTTP Responses",
      "position": {
        "x": 3,
        "y": 0,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": []
          },
          "splits": [
            "server.host.availability_zone"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Number of responses by availability zone",
      "position": {
        "x": 6,
        "y": 0,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "http.status_code.bucket",
                [
                  "2XX"
                ]
              ]
            ]
          },
          "splits": [
            "http.path"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "Top HTTP Paths",
      "position": {
        "x": 9,
        "y": 0,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "http.status_code.bucket",
                [
                  "2XX"
                ]
              ]
            ]
          },
          "splits": [
            "http.status_code"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "2XX Responses",
      "position": {
        "x": 0,
        "y": 2,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "http.status_code.bucket",
                [
                  "4XX"
                ]
              ]
            ]
          },
          "splits": [
            "http.status_code"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "4XX Responses",
      "position": {
        "x": 3,
        "y": 2,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "http.status_code.bucket",
                [
                  "5XX"
                ]
              ]
            ]
          },
          "splits": [
            "http.status_code"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "5XX Responses",
      "position": {
        "x": 6,
        "y": 2,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "count",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {},
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ],
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ]
            ]
          },
          "splits": [
            "http.status_code"
          ],
          "title": "Number of requests",
          "excludeFilters": {
            "dataType": "Map",
            "value": [
              [
                "http.status_code.bucket",
                [
                  "2XX",
                  "4XX",
                  "5XX"
                ]
              ]
            ]
          }
        }
      ],
      "type": "bar",
      "widgetType": "MetricChart",
      "title": "5XX Responses",
      "position": {
        "x": 9,
        "y": 2,
        "w": 3,
        "h": 2
      }
    },
    {
      "widgetType": "Markdown",
      "content": "### HTTP Latency Metrics\\nThis section shows the latency of http requests made to the server",
      "position": {
        "x": 0,
        "y": 4,
        "w": 3,
        "h": 2
      },
      "children": []
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p50",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P50"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [],
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P90"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [],
          "aggregation": "p90",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P95"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [],
          "aggregation": "p95",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P99"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [],
          "aggregation": "p99",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Overall Latency",
      "position": {
        "x": 3,
        "y": 4,
        "w": 6,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p50",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P50"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "server.host.availability_zone"
          ],
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P90"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "server.host.availability_zone"
          ],
          "aggregation": "p90",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P95"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "server.host.availability_zone"
          ],
          "aggregation": "p95",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        },
        {
          "visualization": {
            "displayName": "P99"
          },
          "metricName": "container_net_tcp_active_connections",
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "server.host.availability_zone"
          ],
          "aggregation": "p99",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Overall Latency by availability zone",
      "position": {
        "x": 9,
        "y": 4,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p50",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P50"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "http.path"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Longest p50s by path",
      "position": {
        "x": 0,
        "y": 6,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p90",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P90"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "http.path"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Longest p90s by path",
      "position": {
        "x": 3,
        "y": 6,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p95",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P95"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "http.path"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Longest p95s by path",
      "position": {
        "x": 6,
        "y": 6,
        "w": 3,
        "h": 2
      }
    },
    {
      "metricSpecifiers": [
        {
          "metricName": "container_net_tcp_active_connections",
          "aggregation": "p99",
          "label": "Active Connections",
          "metricType": "trace",
          "functions": [],
          "bucketSize": 0,
          "visualization": {
            "displayName": "P99"
          },
          "filters": {
            "dataType": "Map",
            "value": [
              [
                "server.service.name",
                [
                  "$service_name"
                ]
              ],
              [
                "environment",
                [
                  "$environment"
                ]
              ],
              [
                "server.container.id",
                [
                  "$container_id"
                ]
              ]
            ]
          },
          "splits": [
            "http.path"
          ],
          "title": "Number of requests"
        }
      ],
      "type": "line",
      "widgetType": "MetricChart",
      "title": "Longest p99s by path",
      "position": {
        "x": 9,
        "y": 6,
        "w": 3,
        "h": 2
      }
    }
  ],
  "variables": [
    {
      "name": "service_name",
      "key": "service_name",
      "defaultType": "metric",
      "defaultMetricName": "container_resources_cpu_usage_seconds_total",
      "defaultValue": "*",
      "isOverrideable": true
    },
    {
      "name": "environment",
      "key": "environment",
      "defaultType": "metric",
      "defaultMetricName": "container_resources_cpu_usage_seconds_total",
      "defaultValue": "*",
      "isOverrideable": true
    },
    {
      "name": "container_id",
      "key": "container_id",
      "defaultType": "metric",
      "defaultMetricName": "container_resources_cpu_usage_seconds_total",
      "defaultValue": "*",
      "isOverrideable": true
    }
  ]
}`