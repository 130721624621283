import React from 'react';
import { Alert, AlertDescription } from "components/ui/alert";
import { useQuery } from '@tanstack/react-query';
import axios from 'utility/customAxios';

interface InviteOrgBannerProps {
    inviteToken: string;
}

interface InviteOrgDetailsResponse {
    organizationName: string;
}

export function InviteOrgBanner({ inviteToken }: InviteOrgBannerProps) {
    const { data, isLoading, error } = useQuery<InviteOrgDetailsResponse>({
        queryKey: ['inviteOrgDetails', inviteToken],
        queryFn: async () => {
            const response = await axios.get(`/api/v1/account/invite/org?inviteToken=${inviteToken}`);
            return response.data;
        },
        enabled: !!inviteToken,
    });

    if (!inviteToken || isLoading || error || !data) {
        return null;
    }

    return (
        <Alert className="mb-6 border rounded border-secondary bg-secondarytransparenter">
            <AlertDescription className={"text-textmedium gap-1 flex justify-center"}>
                You are joining <span className="font-semibold"> {data.organizationName}</span>
            </AlertDescription>
        </Alert>
    );
} 