import {BaseView} from "./BaseView";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import React, {useContext, useEffect} from "react";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {cn} from "../components/ui/lib/utils";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "../components/ui/dialog";
import {Input} from "../components/ui/input";
import {Checkbox} from "../components/ui/checkbox";
import {capitalize} from "@mui/material";
import AuthContext from "../contexts/JWTContext";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {KnownService, PagerDutyService} from "./AlertCreation";
import {XIcon} from "lucide-react";
import slackLogo from "../assets/images/slackLogo.png"
import pagerDuty from "../assets/images/pagerDuty.png"
import {PagerDutyServices} from "./PagerDutyRedirect";
import {OnboardingFlow} from "./Onboarding";
import SyntaxHighlighter from "react-syntax-highlighter";
import {customTheme} from "../components/K8sInfo/YamlViewer";
import {
    DropDownItem,
    dropDownItemArrayToStringArray,
    MultiSelectorDropDown,
    stringArrayToDropDownItemArray
} from "../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";


function Integrations() {
    return <div className={"m-4"}>
        <h2 className={"text-lg font-bold"}>Integrations</h2>
        <SlackIntegration/>
        <PagerDutyIntegration/>
    </div>
}

function SlackIntegration() {
    const [slackIntegrationExists, setSlackIntegrationExists] = React.useState(true);
    const [slackShareToken, setSlackShareToken] = React.useState("")

    let host = window.location.host

    useEffect(() => {
        if (slackIntegrationExists) return
        axios.get("/api/v1/slack/shareToken").then((response) => {
            setSlackShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])


    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=slack").then((response) => {
            setSlackIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    return (

        <div className={"mt-4 p-4 bg-backgroundlight border rounded"}>
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <div className={"flex col"}>
                        <img src={slackLogo} className={"w-8 h-6"}/>
                        <h3 className={"text-lg font-bold"}>Slack</h3>
                    </div>
                    <p className={"text-sm text-textdark"}>Integrate with Slack to allow Metoro to post to slack rooms
                        when alerts
                        fire.</p>
                </div>
                <div className={"h-full"}>
                    {
                        !slackIntegrationExists &&
                        <div className={"justify-center h-full"}>
                            <a href={"https://slack.com/oauth/v2/authorize?client_id=5445777126257.5568817569717&scope=channels:read,chat:write,chat:write.public&user_scope=&redirect_uri=https://" + host + "/slack-redirect&state=" + slackShareToken}
                               className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}
                            >Add to slack</a>
                        </div>
                    }
                    {
                        slackIntegrationExists &&
                        <Button
                            onClick={() => {
                                axios.post("/api/v1/integration/remove?type=slack").then(() => {
                                    setSlackIntegrationExists(false)
                                }).catch((e) => {
                                    console.error(e)
                                })
                            }}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Disconnect</Button>
                    }
                </div>
            </div>
        </div>
    );
}

function PagerDutyIntegration() {
    const [pagerDutyIntegrationExists, setPagerDutyIntegrationExists] = React.useState(true);
    const [pagerDutyShareToken, setPagerDutyShareToken] = React.useState("")
    const [disconnect, setDisconnect] = React.useState(false)
    const [isEditing, setIsEditing] = React.useState(false)
    const [openViewServices, setOpenViewServices] = React.useState(false)
    const [selectedServices, setSelectedServices] = React.useState<PagerDutyService[]>([])
    const [allServices, setAllServices] = React.useState<PagerDutyService[]>([])
    const [popoverOpen, setPopoverOpen] = React.useState(false)
    const [isSaved, setIsSaved] = React.useState(false)

    let host = window.location.host

    useEffect(() => {
        if (!isEditing) {
            return
        }
        axios.get("/api/v1/pagerDuty/services").then((response) => {
            setAllServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [isEditing]);

    useEffect(() => {
        if (!isSaved) {
            return
        }
        if (selectedServices.length > 0) {
            axios.post("/api/v1/pagerDuty/selectedServices",
                {services: selectedServices}
            ).then((response) => {
                // Redirect to the dashboard
                setOpenViewServices(false)
                setIsSaved(false)
                setIsEditing(false)
                setPopoverOpen(false)
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [isSaved]);

    useEffect(() => {
        if (pagerDutyIntegrationExists) return
        axios.get("/api/v1/pagerDuty/shareToken").then((response) => {
            setPagerDutyShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [pagerDutyIntegrationExists])


    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=pagerDuty").then((response) => {
            setPagerDutyIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    useEffect(() => {
        axios.get("/api/v1/pagerDuty/selectedServices")
            .then((response) => {
                setSelectedServices(response.data.services)
            }).catch((e) => {
            console.error(e)
        })
    }, []);

    let protocol = window.location.protocol
    let pagerDutyClientId = "4f78f15d-8395-4302-9706-50e177dd75e7" //for production and dev.
    if (host.includes("localhost")) {
        pagerDutyClientId = "f583ce7e-d07b-4a24-95e9-b2c26a06a725"
    }

    return (
        <div className={"mt-4 p-4 bg-backgroundlight border rounded"}>
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <div className={"flex col"}>
                        <img src={pagerDuty} className={"w-7 h-7 mr-1"}/>
                        <h3 className={"text-lg font-bold"}>PagerDuty</h3>
                    </div>
                    <p className={"text-sm text-textdark"}>Integrate with PagerDuty to receive pages for Metoro
                        alerts.</p>
                </div>
                <div className={"h-full"}>
                    {
                        !pagerDutyIntegrationExists &&
                        <a href={"https://identity.pagerduty.com/oauth/authorize?client_id=" + pagerDutyClientId + "&response_type=code&scope=write&redirect_uri=" + protocol + "//" + host + "/pagerduty-redirect&state=" + pagerDutyShareToken}
                           className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Connect
                            to PagerDuty</a>
                    }
                    {
                        pagerDutyIntegrationExists &&
                        <div className={"flex gap-2 justify-center items-center"}>
                            <Dialog open={openViewServices}>
                                <DialogTrigger asChild>
                                    <Button
                                        onClick={() => setOpenViewServices(true)}
                                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded hover:border-primaryhover"}>
                                        View Services
                                    </Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[825px] bg-background"
                                               onInteractOutside={() => setOpenViewServices(false)}
                                               onKeyDown={(e) => {
                                                   if (e.key === "Escape") {
                                                       setOpenViewServices(false)
                                                   }
                                               }}>
                                    <DialogHeader>
                                        <div className={"flex justify-between items-center mb-4"}>
                                            <DialogTitle
                                                className={"text-textmedium font-normal text-left items-center"}>
                                                Integrated PagerDuty Services
                                            </DialogTitle>
                                            <XIcon
                                                className={"text-textdark hover:cursor-pointer hover:text-primary justify-end"}
                                                onClick={() => setOpenViewServices(false)}/>
                                        </div>
                                    </DialogHeader>
                                    <div className={"flex justify-between items-center"}>
                                        <DialogDescription className={"text-textdark"}>
                                            These are the PagerDuty services Metoro can send pages to.
                                        </DialogDescription>
                                        {!isEditing && <Button type="submit"
                                                               className={"flex flex-col bg-primarytransparent h-full border hover:border-primaryhover border-primary text-white justify-center items-center rounded"}
                                                               onClick={() => {
                                                                   setIsEditing(true)
                                                               }}>Add Service</Button>}
                                        {isEditing && <Button type="submit"
                                                              className={"flex flex-col bg-primarytransparent h-full border hover:border-primaryhover border-primary text-white justify-center items-center rounded"}
                                                              onClick={() => {
                                                                  setIsSaved(true)
                                                                  setIsEditing(false)
                                                              }}>Save</Button>}
                                    </div>
                                    <div
                                        className="min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col grow shrink overflow-y-auto">
                                        <div
                                            className="w-full h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
                                            <div
                                                className="h-full w-[100px] font-normal leading-8 text-textmedium text-xl">ID
                                            </div>
                                            <div
                                                className="h-full w-[100px] font-normal leading-8 text-textmedium text-xl">Name
                                            </div>
                                        </div>
                                        {selectedServices.map((service, index) => {
                                            return <div className={"flex justify-end mr-4"}>
                                                <div key={index}
                                                     className="w-full px-4 py-2 border-b border-backgroundmedium hover:bg-backgrounddark flex justify-start items-center gap-4">
                                                    <div
                                                        className="h-full w-[100px] font-normal leading-8 text-textmedium">{service.id}</div>
                                                    <div
                                                        className="h-full w-[100px] font-normal leading-8 text-textmedium">{service.name}</div>
                                                </div>
                                                <div className={"flex items-center justify-center"}>
                                                    <Button onClick={() => {
                                                        setIsEditing(true)
                                                        setSelectedServices((prev) => {
                                                            return prev.filter((value) => value.id !== service.id)
                                                        })
                                                    }}
                                                            className="flex flex-col bg-red-500/20 border border-red-500 text-white justify-center items-center rounded">Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    {isEditing && <PagerDutyServices allServices={allServices} open={popoverOpen}
                                                                     setOpen={setPopoverOpen}
                                                                     selectedServices={selectedServices}
                                                                     setSelectedServices={setSelectedServices}/>}
                                </DialogContent>
                            </Dialog>
                            <Dialog open={disconnect}>
                                <DialogTrigger asChild>
                                    <Button onClick={() => setDisconnect(true)}
                                            className={"bg-red-500/20 h-full border border-red-500 text-white flex flex-col justify-center p-2 rounded"}>Disconnect</Button>
                                </DialogTrigger>
                                <DialogContent className="sm:max-w-[825px] bg-background"
                                               onBlur={() => setDisconnect(false)}>
                                    <DialogHeader>

                                        <div className={"flex justify-between items-center mb-4"}>
                                            <DialogTitle className={"text-textmedium font-normal text-center"}>
                                                Are you sure you want to delete PagerDuty integration?
                                            </DialogTitle>
                                            <XIcon
                                                className={"text-textdark hover:cursor-pointer hover:text-primary justify-end"}
                                                onClick={() => setDisconnect(false)}/>
                                        </div>
                                    </DialogHeader>
                                    <DialogDescription className={"text-textdark"}>
                                        You will need to re-add the integration to receive pages for Metoro alerts. If
                                        you
                                        have any alerts that are configured to send pages to PagerDuty, they will no
                                        longer be sent.
                                    </DialogDescription>
                                    <Button type="submit"
                                            className={"flex flex-col bg-red-500/20 h-full border border-red-500 text-textlight justify-end rounded"}
                                            onClick={() => {
                                                axios.post("/api/v1/integration/remove?type=pagerDuty").then(() => {
                                                    setPagerDutyIntegrationExists(false)
                                                    setDisconnect(false)
                                                }).catch((e) => {
                                                    console.error(e)
                                                })
                                            }}>Delete Integration</Button>
                                </DialogContent>
                            </Dialog>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

interface Exclusion {
    id: string
    type: string
    exclusion: string
    serviceName: string
    isGlobal: boolean
}


interface NamespaceFilterArgs {
    namespaces: string[]
    regex: string
}

interface ServiceFilterArgs {
    services: string[]
    regex: string
}

enum FilterType {
    Namespace = "namespace",
    Service = "service"
}

enum IncludeOrExclude {
    Include = "include",
    Exclude = "exclude"
}

// A filter is defined by the following
interface Filter {
    id: string
    // Empty means all environments
    environments: string[]
    // Whether the exclusion is inclusive or exclusive
    inclusionType: IncludeOrExclude
    // The type of exclusion
    filterType: FilterType
    // The args if the exclusion type is a namespace
    namespaceFilterArgs: NamespaceFilterArgs | undefined
    // The args if the exclusion type is a service
    serviceFilterArgs: ServiceFilterArgs | undefined
}

function NewLogFilterModal(
    props: {
        openAddModal: boolean,
        setOpenAddModal: (open: boolean) => void,
        setLogFilters: (logFilters: Filter[]) => void,
        setTriggerReload: () => void
    }
) {
    const [openServiceFilter, setOpenServiceFilter] = React.useState(false)
    const [services, setServices] = React.useState<KnownService[]>([])
    const [namespaces, setNamespaces] = React.useState<string[]>([])

    const [logFilter, setLogFilter] = React.useState<Filter>({
        id: "",
        environments: [],
        inclusionType: IncludeOrExclude.Exclude,
        filterType: FilterType.Service,
        namespaceFilterArgs: undefined,
        serviceFilterArgs: {
            services: [],
            regex: ".*"
        },
    })

    // Services
    const [filters, setFilters] = React.useState<Filter[]>([])

    const [environments, setEnvironments] = React.useState<DropDownItem[]>([])
    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(stringArrayToDropDownItemArray(response.data.environments))
        }).catch((e) => {
            console.error(e)
        })
    }, [])


    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: logFilter.environments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    // Get the namespaces
    useEffect(() => {
        let url = "/api/v1/namespaces"
        if (logFilter.environments.length > 0) {
            url = url + "?environments=" + logFilter.environments.join(",")
        }
        axios.get(url).then((response) => {
            setNamespaces(response.data.namespaces)
        }).catch((e) => {
            console.error(e)
        })
    }, [logFilter.environments])


    return (
        <Dialog open={props.openAddModal}>
            <DialogContent className={"w-1/2"} onInteractOutside={() => props.setOpenAddModal(false)}>
                <div className={"flex flex-col gap-4 text-textmedium"}>
                    <h3 className={"text-lg font-bold"}>Add a log filter</h3>
                    {/* Button group for inclusion and exclusion*/}
                    <p>
                        1. Select whether you want to include or exclude log lines that match the filter pattern.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Exclude ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Exclude
                                return {...prev}
                            })}>
                            Exclude</Button>
                        <Button
                            className={
                                cn("border", logFilter.inclusionType === IncludeOrExclude.Include ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                prev.inclusionType = IncludeOrExclude.Include
                                return {...prev}
                            })}>
                            Include</Button>
                    </div>
                    <p>
                        2. Select the environments that you want this filter to apply to:
                    </p>
                    <MultiSelectorDropDown
                        selectorSingular={"Environment"}
                        possibleItems={environments}
                        selectedItems={stringArrayToDropDownItemArray(logFilter.environments)}
                        setSelectedItems={(selectedItems) => {
                            setLogFilter((prev) => {
                                prev.environments = dropDownItemArrayToStringArray(selectedItems)
                                return {...prev}
                            })
                        }}
                    />
                    <p>
                        3. Select whether you want to match all services in a set of namespaces or a specific set of
                        services.
                    </p>
                    <div className={"flex gap-4"}>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Service ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Service) {
                                    return prev
                                }

                                prev.filterType = FilterType.Service
                                prev.serviceFilterArgs = {
                                    services: [],
                                    regex: prev.namespaceFilterArgs?.regex || ".*"
                                }
                                prev.namespaceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Services</Button>
                        <Button
                            className={
                                cn("border", logFilter.filterType === FilterType.Namespace ? "bg-secondarytransparenter border-secondary" : "border-primary bg-primarytransparent",)
                            }
                            onClick={() => setLogFilter((prev) => {
                                if (prev.filterType === FilterType.Namespace) {
                                    return prev
                                }
                                prev.filterType = FilterType.Namespace
                                prev.namespaceFilterArgs = {
                                    namespaces: [],
                                    regex: prev.serviceFilterArgs?.regex || ".*"
                                }
                                prev.serviceFilterArgs = undefined
                                return {...prev}
                            })}>
                            Namespaces</Button>
                    </div>
                    <div className={"text-textmedium"}>
                        <div>
                            4. Select the {logFilter.filterType === FilterType.Service ? "services" : "namespaces"} that
                            you want this exclusion pattern to be applied to:
                        </div>
                    </div>
                    {
                        logFilter.filterType === FilterType.Service &&
                        <MultiSelectorDropDown
                            selectorSingular={"Service"}
                            possibleItems={
                                services.map((service) => {
                                    return {
                                        displayName: service.displayName,
                                        value: service.serviceName
                                    }
                                })
                            }
                            selectedItems={stringArrayToDropDownItemArray(logFilter.serviceFilterArgs?.services || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.services = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }
                    {
                        logFilter.filterType === FilterType.Namespace &&
                        <MultiSelectorDropDown
                            selectorSingular={"Namespace"}
                            possibleItems={stringArrayToDropDownItemArray(namespaces)}
                            selectedItems={stringArrayToDropDownItemArray(logFilter.namespaceFilterArgs?.namespaces || [])}
                            setSelectedItems={(selectedItems) => {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.namespaces = dropDownItemArrayToStringArray(selectedItems)
                                    return {...prev}
                                })
                            }}
                        />
                    }

                    <p className={"text-sm"}>5. Enter the exclusion pattern that will be used to exclude
                        matching log lines from ingestion: </p>
                    <Input
                        value={logFilter.filterType == FilterType.Namespace ? logFilter.namespaceFilterArgs?.regex : logFilter.serviceFilterArgs?.regex}
                        onChange={(e) => {
                            if (logFilter.filterType == FilterType.Namespace) {
                                setLogFilter((prev) => {
                                    prev.namespaceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                            if (logFilter.filterType == FilterType.Service) {
                                setLogFilter((prev) => {
                                    prev.serviceFilterArgs!.regex = e.target.value
                                    return {...prev}
                                })
                            }
                        }}
                        type={"text"} placeholder={"Exclusion regex pattern"}
                        className={"border border-primary rounded p-2"}/>
                    <div className={"flex justify-end gap-4"}>
                        <Button
                            onClick={() => props.setOpenAddModal(false)}
                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                        <Button
                            onClick={() => {
                                axios.post("/api/v1/logFilter", {
                                    ...logFilter,
                                }).then(() => {
                                    props.setTriggerReload()
                                    props.setOpenAddModal(false)
                                }).catch((e) => {
                                    console.error(e)
                                })

                            }}
                            className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Save</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}


function LogSettings() {
    const [logFilters, setLogFilters] = React.useState<Filter[]>([]);
    const context = useContext(AuthContext);

    // Delete modal
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([])

    const [triggerReload, setTriggerReload] = React.useState(false)

    const [openAddModal, setOpenAddModal] = React.useState(false)

    function getServiceDisplayName(path: string): string {
        // Extract the service name from the path
        const segments = path.split('/');
        const serviceName = segments[segments.length - 1];

        // Capitalize the first letter of each word if there are hyphens, otherwise capitalize the single word
        const formattedServiceName = serviceName.includes('-')
            ? serviceName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-')
            : serviceName.charAt(0).toUpperCase() + serviceName.slice(1);

        return formattedServiceName;
    }

    useEffect(() => {
        axios.get("/api/v1/logFilters").then((response) => {
            setLogFilters(response.data.logFilters)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload])

    return (
        <div className={"m-4"}>
            <div className={"flex justify-between"}>
                <h2 className={"text-lg font-bold"}>Filters</h2>
                <div className={"flex flex-col justify-center"}>
                    <NewLogFilterModal
                        openAddModal={openAddModal}
                        setOpenAddModal={setOpenAddModal}
                        setLogFilters={setLogFilters}
                        setTriggerReload={() => {
                            setTriggerReload(prev => !prev)
                        }}
                    />
                    <Button
                        onClick={() => setOpenAddModal(true)}
                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Add
                        Filter</Button>
                </div>
            </div>
            {logFilters !== undefined && logFilters.length > 0 &&
                <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
                    <div
                        className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                        <div
                            className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Type
                        </div>
                        <div
                            className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Environments
                        </div>
                        <div
                            className={`w-[180px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Class
                        </div>
                        <div
                            className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Entities
                        </div>
                        <div
                            className={`flex grow shrink h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>
                            Regex
                        </div>
                    </div>
                    <div className={"bg-backgroundlight"}>
                        {
                            logFilters.map((filter, index) => {
                                return <div
                                    className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-start items-start gap-4 flex grow shrink`, index === logFilters.length - 1 ? "border-b" : "")}>
                                    <div
                                        className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{filter.inclusionType || "Unknown"}
                                    </div>
                                    <div
                                        className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-md truncate`}>
                                        {filter.environments.length > 0 ? filter.environments.join(", ") : "All"}
                                    </div>
                                    <div
                                        className={`w-[180px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>
                                        {filter.filterType}
                                    </div>
                                    <div
                                        className={`w-[360px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{filter.filterType === FilterType.Service ? (filter.serviceFilterArgs != undefined && filter.serviceFilterArgs?.services.length > 0 ? filter.serviceFilterArgs?.services.join(",") : "All") : (filter.namespaceFilterArgs !== undefined && filter.namespaceFilterArgs.namespaces.length > 0 ? filter.namespaceFilterArgs?.namespaces.join(", ") : "All")}
                                    </div>
                                    <div className={"flex grow shrink"}>
                                        {filter.filterType === FilterType.Service ? filter.serviceFilterArgs?.regex : filter.namespaceFilterArgs?.regex}
                                    </div>
                                    <div className={"flex flex-col justify-center"}>
                                        <Dialog open={openDeleteModal[index]}>
                                            <DialogContent className={"w-1/3"}
                                            >
                                                <div className={"flex flex-col gap-4 text-textmedium"}>
                                                    <h3 className={"text-lg font-bold"}>Remove filter</h3>
                                                    <div className={"text-textmedium"}>Are you sure you want to
                                                        remove filter ?
                                                    </div>
                                                    <div className={"flex justify-end gap-4"}>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenDeleteModal(prev => {
                                                                    prev[index] = false
                                                                    return [...prev]
                                                                })
                                                            }}
                                                            className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                        <Button
                                                            onClick={() => {
                                                                axios.delete("/api/v1/logFilter?id=" + filter.id).then(() => {
                                                                    setTriggerReload(prev => !prev)
                                                                }).catch(e => console.error(e))
                                                                setOpenDeleteModal(prev => {
                                                                    prev[index] = false
                                                                    return [...prev]
                                                                })
                                                            }}
                                                            className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                        <Button
                                            onClick={() => {
                                                setOpenDeleteModal(prev => {
                                                    prev[index] = true
                                                    return [...prev]
                                                })
                                            }}
                                            className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>}
            {logFilters.length <= 0 && <div
                className="w-full flex-none mt-4 p-4 rounded bg-backgroundlight border flex grow shrink">
                Add a log filter to prevent Metoro from exporting logs that match specific conditions.
            </div>}
        </div>
    );
}


interface User {
    name: string
    email: string
    isAdmin: string
    status: string
}


//
function Users() {
    const context = useContext(AuthContext);
    const [users, setUsers] = React.useState<User[]>([])

    // Invite modal
    const [openInviteModal, setOpenInviteModal] = React.useState(false)
    const [inviteEmail, setInviteEmail] = React.useState("")
    const [isNewUserAdmin, setIsNewUserAdmin] = React.useState(false)

    const [triggerReload, setTriggerReload] = React.useState(false)
    // Delete modal
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean[]>([])


    useEffect(() => {
        axios.get("/api/v1/account/organization").then((response) => {
            setUsers(response.data.organizationMembers)
            setOpenDeleteModal(new Array(response.data.organizationMembers.length).fill(false))
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload])


    return (
        <div className={"m-4"}>
            <div className={"flex justify-between"}>
                <h2 className={"text-lg font-bold"}>Users</h2>
                <div className={"flex flex-col justify-center"}>
                    <Dialog open={openInviteModal}>
                        <DialogContent className={"w-1/3"} onInteractOutside={() => setOpenInviteModal(false)}>
                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                <h3 className={"text-lg font-bold"}>Invite a user</h3>
                                <div className={"text-textmedium"}>Enter the email of the user you want to invite.</div>
                                <Input
                                    value={inviteEmail}
                                    onChange={(e) => setInviteEmail(e.target.value)}
                                    type={"email"} placeholder={"Email"}
                                    className={"border border-primary rounded p-2"}/>
                                <div className={"flex gap-4"}>
                                    Should this user be an admin?
                                    <div className={"flex flex-col justify-center"}>
                                        <Checkbox
                                            className={"rounded"}
                                            checked={isNewUserAdmin}
                                            onCheckedChange={(checked) => setIsNewUserAdmin(prev => !prev)}/>
                                    </div>
                                </div>
                                <div className={"flex justify-end gap-4"}>
                                    <Button
                                        onClick={() => setOpenInviteModal(false)}
                                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                    <Button
                                        onClick={() => {
                                            axios.post("/api/v1/account/organization/invite", {
                                                newUserEmail: inviteEmail,
                                                isAdmin: isNewUserAdmin
                                            }).then(r => {
                                                setInviteEmail("")
                                                setIsNewUserAdmin(false)
                                                setTimeout(() => {
                                                    setTriggerReload(prev => !prev)
                                                }, 1000)
                                            }).catch(e => console.error(e))
                                            setOpenInviteModal(false)
                                        }}
                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Invite</Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Button
                        onClick={() => setOpenInviteModal(true)}
                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Invite</Button>
                </div>
            </div>
            <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
                <div
                    className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                    <div
                        className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>User
                    </div>
                    <div
                        className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>Email
                    </div>
                    <div
                        className={`font-normal w-[160px] font-['Inter'] leading-8 text-textmedium text-xl`}>Role
                    </div>
                    <div
                        className={`font-normal w-[120px] font-['Inter'] leading-8 text-textmedium text-xl`}>Status
                    </div>
                </div>
                <div className={"bg-backgroundlight"}>
                    {
                        users.map((user, index) => {
                            user.status = capitalize(user.status)
                            let indicatorColor = user.status === "Active" ? "bg-green-600" : "bg-blue-500"
                            return <div
                                className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-start items-start gap-4 flex grow shrink`, index === users.length - 1 ? "border-b" : "")}>
                                <div
                                    className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{user.name || "Unknown"}
                                </div>
                                <div
                                    className={`w-[360px] h-full  font-normal font-['Inter'] leading-8 text-textmedium text-md truncate`}>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            {user.email}
                                        </TooltipTrigger>
                                        <TooltipContent className={"bg-backgroundmedium border rounded"}>
                                            <div className={"p-2"}>
                                                {user.email}
                                            </div>
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                                <div
                                    className={`font-normal w-[160px] font-['Inter'] leading-8 text-textmedium text-md`}>{user.isAdmin ? "Admin" : "User"}
                                </div>
                                <div
                                    className={`font-normal w-[120px] font-['Inter'] leading-8 text-textmedium text-md`}>
                                    <div className={"w-max h-[32px] justify-center flex-none flex flex-col"}>
                                        <div className={`px-2 py-2 ${indicatorColor} rounded justify-center flex`}>
                                            <div
                                                className="text-textlight text-sm font-medium">{user.status}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex grow shrink"}/>
                                <div className={"flex flex-col justify-center"}>
                                    <Dialog open={openDeleteModal[index]}>
                                        <DialogContent className={"w-1/3"}
                                        >
                                            <div className={"flex flex-col gap-4 text-textmedium"}>
                                                <h3 className={"text-lg font-bold"}>Remove user</h3>
                                                <p className={"text-sm"}>Are you sure you want to
                                                    remove {user.name}?</p>
                                                <div className={"flex justify-end gap-4"}>
                                                    <Button
                                                        onClick={() => setOpenDeleteModal(prev => {
                                                            prev[index] = false
                                                            return [...prev]
                                                        })}
                                                        className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                    <Button
                                                        onClick={() => {
                                                            axios.delete("/api/v1/user?email=" + user.email).then(() => {
                                                                setTriggerReload(prev => !prev)
                                                            }).catch(e => console.error(e))
                                                            setOpenDeleteModal(prev => {
                                                                prev[index] = false
                                                                return [...prev]
                                                            })
                                                        }}
                                                        className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <Button
                                        disabled={user.email === context?.user?.email}
                                        onClick={() => {
                                            setOpenDeleteModal(prev => {
                                                prev[index] = true
                                                return [...prev]
                                            })
                                        }}
                                        className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

interface Cluster {
    name: string,
    addedOn: string,
}

function ClusterSettings() {
    const [clusters, setClusters] = React.useState<string[]>([]);
    const [openAddClusterModal, setOpenAddClusterModal] = React.useState(false)
    const [newClusterName, setNewClusterName] = React.useState("")
    const [triggerReload, setTriggerReload] = React.useState(false)
    const [openDeleteClusterModal, setOpenDeleteClusterModal] = React.useState<boolean[]>([])


    useEffect(() => {
        // Call backend to get the clusters
        axios.get("/api/v1/environments").then((response) => {
            setClusters(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [triggerReload]);

    const deletionCommands = `
    $ helm uninstall -n metoro metoro-exporter
    `
    return <div className={"m-4"}>
        <div className={"flex justify-between"}>
            <h2 className={"text-lg font-bold"}>Your Clusters</h2>
            <div className={"flex flex-col justify-center"}>
                <Dialog open={openAddClusterModal}>
                    <DialogContent className={"w-3/4"}
                                   onInteractOutside={() => setOpenAddClusterModal(false)}
                                   onEscapeKeyDown={() => setOpenAddClusterModal(false)}
                        // onBlur={() => setOpenAddClusterModal(false)}
                    >
                        <div
                            className={"blur-none flex min-w-0 min-h-0 grow shrink justify-center"}>
                            <OnboardingFlow addCluster={true}/>
                        </div>
                    </DialogContent>
                </Dialog>

                <Button
                    onClick={() => setOpenAddClusterModal(true)}
                    className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Add
                    Cluster</Button>
            </div>
        </div>
        <div className={"border-l border-r border-t mt-4 rounded-t bg-backgroundlight"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                <div
                    className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-xl`}>Cluster
                    Name
                </div>
            </div>
            <div className={"bg-backgroundlight"}>
                {
                    clusters.map((cluster, index) => {
                        return <div
                            className={cn(`w-full flex-none h-[48px] px-4 py-2 border-t justify-between items-start gap-4 flex grow shrink`, index === clusters.length - 1 ? "border-b" : "")}>
                            <div
                                className={`w-[152px] h-full font-normal font-['Inter'] leading-8 text-textmedium text-md`}>{cluster || "Unknown"}
                            </div>
                            <div className={"flex flex-col justify-center"}>
                                <Dialog open={openDeleteClusterModal[index]}>
                                    <DialogContent className={"w-max"}
                                    >
                                        <div className={"flex flex-col gap-4 text-textmedium"}>
                                            <h3 className={"text-lg font-bold"}>Remove cluster</h3>
                                            <p className={"text-sm"}>If you would like to remove this cluster, please
                                                run the following commands to uninstall Metoro from your cluster: </p>
                                            <div className={"border flex min-h-0 min-w-0 grow shrink rounded"}>

                                                <SyntaxHighlighter
                                                    language="bash" customStyle={{width: "100%"}}
                                                    // @ts-ignore
                                                    style={customTheme}>
                                                    {deletionCommands}
                                                </SyntaxHighlighter>
                                            </div>

                                            <div className={"flex justify-end gap-4"}>
                                                <Button
                                                    onClick={() => setOpenDeleteClusterModal(prev => {
                                                        prev[index] = false
                                                        return [...prev]
                                                    })}
                                                    className={"bg-primarytransparent h-full border border-primary text-white flex flex-col justify-center p-2 rounded"}>Cancel</Button>
                                                <Button
                                                    onClick={() => {
                                                        axios.delete("/api/v1/environment?envName=" + cluster).then(() => {
                                                            setTriggerReload(prev => !prev)
                                                        }).catch(e => console.error(e))
                                                        setOpenDeleteClusterModal(prev => {
                                                            prev[index] = false
                                                            return [...prev]
                                                        })
                                                    }}
                                                    className={"bg-primary h-full text-white flex flex-col justify-center p-2 rounded"}>Remove</Button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                                <Button
                                    onClick={() => {
                                        setOpenDeleteClusterModal(prev => {
                                            prev[index] = true
                                            return [...prev]
                                        })
                                    }}
                                    className={"bg-primarytransparent h-[32px] border border-primary text-white flex flex-col justify-center p-2 rounded"}>Remove
                                </Button>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function Settings() {
    return (
        <BaseView title={"Settings"} disableClusterSelector={true} disableTimeRange>
            <div className={"m-4 border bg-backgroundmedium flex-col grow shrink text-textmedium overflow-y-auto"}>
                <Tabs defaultValue="users" className="grow shrink">
                    <TabsList className={"grow shrink flex p-1 border-b"}>
                        <TabsTrigger className={"grow shrink"} value="users">Users</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="integrations">Integrations</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="logSettings">Log Settings</TabsTrigger>
                        <TabsTrigger className={"grow shrink"} value="clusterSettings">Cluster Settings</TabsTrigger>
                    </TabsList>
                    <TabsContent value="users">
                        <Users/>
                    </TabsContent>
                    <TabsContent value="integrations">
                        <Integrations/>
                    </TabsContent>
                    <TabsContent value="logSettings">
                        <LogSettings/>
                    </TabsContent>
                    <TabsContent value="clusterSettings">
                        <ClusterSettings/>
                    </TabsContent>
                </Tabs>
            </div>
        </BaseView>
    )
        ;
}


export {
    Settings
}