import {
    AppearanceProps,
    ChartType,
    MathExpression,
    MetoroMetricsChart,
    MetoroMetricsChartProps,
    MetricType
} from "../../../pages/MetricsTest";
import React, {Dispatch, ReactElement, SetStateAction, useEffect} from "react";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {ChevronsUpDown, PlusIcon, SquareFunctionIcon, XIcon} from "lucide-react";
import axios from "../../../utility/customAxios";
import {GroupByPill, Pill} from "../../Filter/Pill";
import {Popover, PopoverContent, PopoverTrigger} from "../../ui/popover";
import {Button} from "../../ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../../ui/command";
import {cn} from "../../ui/lib/utils";
import {Input} from "../../ui/input";
import {AxiosPromise} from "axios";
import {FeedSearch, TracesSummaryResponse} from "../../../pages/Traces";
import {Attribute} from "../../../types/telemetry";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from "../../ui/dropdown-menu";
import {v4 as uuid} from 'uuid';
import {Tooltip, TooltipContent, TooltipTrigger} from "../../ui/tooltip";
import {DateTimePickerRange} from "../../ui/DateTimeSelector";
import {SetURLSearchParams} from "react-router-dom";
import {dashboardJsonReplacer} from "../Dashboard";

interface MetricSelectorProps {
    chartProps: MetoroMetricsChartProps;
    setChartProps: Dispatch<SetStateAction<MetoroMetricsChartProps>>
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setMetricAlertSelected?: Dispatch<SetStateAction<boolean>>;
    minimal?: boolean;
    setSearchParams?: SetURLSearchParams
}

const MetricSelectorPanel = (props: MetricSelectorProps) => {
    const timeRange = useSelector(timerange.selectors.getTimeRange)

    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>(props.chartProps || {
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
        metricName: "container_net_tcp_active_connections",
        filters: new Map(),
        splits: [],
        aggregation: "avg",
        type: ChartType.Bar,
        metricType: MetricType.Metric,
        functions: [],
    });

    useEffect(() => {
        if (props.setSearchParams !== undefined) {
            props.setSearchParams(prevSearchParams => {
                let urlSearchParams = new URLSearchParams(prevSearchParams);
                urlSearchParams.set("chart", JSON.stringify(chartProps, dashboardJsonReplacer))
                return urlSearchParams
            })
        }
    }, [chartProps]);

    return (
        <div
            className={cn("flex flex-col grow rounded bg-backgrounddark overflow-y-auto", props.minimal ? "" : " border")}>
            {!props.minimal && <div className={"flex-none flex justify-between border-b h-[32px]"}>
                <div className={"flex flex-col justify-center text-textdark ml-2 items-center text-center"}>
                    Chart Builder
                </div>
                <DateTimePickerRange/>
            </div>}
            <div className={cn("relative flex flex-col grow gap-8", props.minimal ? "" : " p-4")}>
                <MetoroMetricsChart
                    {...chartProps}
                    startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                    endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                    hideLegend={false}
                    className={"flex flex-grow h-[300px]"}
                />
                <MetricTypeSelector metricType={chartProps.metricType} setMetricType={(t: MetricType) => {
                    setChartProps((props: MetoroMetricsChartProps) => {
                        return {
                            ...props,
                            metricType: t,
                            aggregation: t === MetricType.Metric ? "avg" : "count",
                            filters: new Map(),
                            splits: [],
                            title: t === MetricType.Metric ? "Active TCP Connections" : "Total Number of Requests"
                        }
                    })
                }}/>
                <TypeSelectorPanel type={chartProps.type} setType={(t: ChartType) => {
                    setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, type: t}
                    })
                }}/>
                <MetricQuerySelectorPanel chartProps={chartProps} setChartProps={setChartProps}/>
                {!props.minimal && <SetTitlePanel title={chartProps.title} setTitle={(t: string) => {
                    setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, title: t}
                    })
                }}/>}
                {/*    Done button */}
                {!props.minimal && <div className={"flex justify-center"}>
                    <div
                        onClick={() => {
                            props.setChartProps(chartProps)
                            props.setIsOpen(false)
                            if (props.setMetricAlertSelected !== undefined) {
                                props.setMetricAlertSelected(true)
                            }
                        }}
                        className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight p-2 rounded hover:bg-primarydark cursor-pointer"}>
                        Save
                    </div>
                </div>}
            </div>
        </div>
    )
}

export function EmbedMetricSelector(props: {
    chartProps: MetoroMetricsChartProps,
    setChartProps: Dispatch<SetStateAction<MetoroMetricsChartProps>>,
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    return <div className={"flex flex-col gap-8"}>
        <div className={"flex relative h-[300px]"}>
            <MetoroMetricsChart
                className={"flex flex-grow"}
                startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                metricName={props.chartProps.metricName}
                aggregation={props.chartProps.aggregation}
                type={props.chartProps.type}
                filters={props.chartProps.filters}
                excludeFilters={props.chartProps.excludeFilters}
                splits={props.chartProps.splits}
                threshold={props.chartProps.threshold}
                thresholdLabel={props.chartProps.thresholdLabel}
                metricType={props.chartProps.metricType}
                functions={props.chartProps.functions}
                hideLegend={false}
                timePeriodHighlight={props.chartProps.timePeriodHighlight}
            />
        </div>
        <div className={"flex flex-col"}>
            <div className={"flex justify-start"}>
                <div className={"flex gap-2 items-center grow"}>
                    <MetricQuerySelector setChartProps={props.setChartProps} chartProps={props.chartProps}/>
                </div>
            </div>
            <div className={"flex justify-start"}>
                <div className={"flex gap-2 items-center grow"}>
                    <div className={"flex flex-col"}>
                        {props.chartProps.functions.map((func, index) => {
                            return <MetricFunctionSelector metricFunction={func}
                                                           setChartProps={props.setChartProps}/>
                        })}
                        <AddMetricFunctionButton setChartProps={props.setChartProps}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


interface Aggregation {
    name: string;
    value: string;
}

const metricAggregations: Aggregation[] = [
    {
        "name": "average",
        "value": "avg"
    },
    {
        "name": "sum",
        "value": "sum"
    },
    {
        "name": "min",
        "value": "min"
    },
    {
        "name": "max",
        "value": "max"
    }
]

const onlyTraceAggregations: Aggregation[] = [
    {
        "name": "request count",
        "value": "count"
    },
    {
        "name": "p50 latency",
        "value": "p50"
    },
    {
        "name": "p90 latency",
        "value": "p90"
    },
    {
        "name": "p95 latency",
        "value": "p95"
    },
    {
        "name": "p99 latency",
        "value": "p99"
    },
    {
        "name": "request size",
        "value": "requestSize"
    },
    {
        "name": "response size",
        "value": "responseSize"
    },
    {
        "name": "total size",
        "value": "totalSize"
    }
]

export function MetricQuerySelector(props: {
    setChartProps: (value: (((prevState: MetoroMetricsChartProps) => MetoroMetricsChartProps) | MetoroMetricsChartProps)) => void,
    chartProps: MetoroMetricsChartProps
    removeAttributes?: string[]
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [metricText, setMetricText] = React.useState<string>(props.chartProps.metricName);
    const [metricMatch, metricMatchSet] = React.useState<string[]>([]);
    const [openMetric, setOpenMetric] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openGroupBy, setOpenGroupBy] = React.useState(false);
    const [openAggregate, setOpenAggregate] = React.useState(false);
    const [attributes, setAttributes] = React.useState<Map<string, string[]>>(new Map());
    const [attributeKeyValuePair, setAttributeKeyValuePair] = React.useState<string[][]>([]);
    const [justAttributes, setJustAttributes] = React.useState<string[]>([]);
    const [openVariableNameTooltip, setOpenVariableNameTooltip] = React.useState(false);
    const aggregationToUse = props.chartProps.metricType === MetricType.Metric ? metricAggregations : onlyTraceAggregations;
    const aggregationPlaceholder = aggregationToUse.find((agg) => agg.value === props.chartProps.aggregation)?.name

    const [filterKeys, setFilterKeys] = React.useState<string[]>([]);

    useEffect(() => {
        if (props.chartProps.metricType === MetricType.Metric) {
            axios.post("/api/v1/metricTotalAttributes", {
                "metricName": props.chartProps.metricName,
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            }).then((response) => {
                let attributes = response.data.attributes;
                let filteredAttributes = []
                // HACK HACK: This lets you remove attributes from the filter list, e.g. its used in infrastructure view
                // to remove the non-label values. This is a hack and should be replaced with a better solution.
                for (const index in attributes) {
                    if (props.removeAttributes && props.removeAttributes.includes(attributes[index])) {
                        // Skip adding this attribute
                    } else {
                        filteredAttributes.push(attributes[index]);
                    }
                }
                setFilterKeys(filteredAttributes);
            })
        } else {
            axios.get("/api/v1/tracesSummaryAttributes").then((response) => {
                let attributes = response.data.attributes;
                setFilterKeys(attributes);
            })
        }

    }, [props.chartProps.metricName, timeRange, props.chartProps.metricType]);

    const apperance = props.chartProps.apperance

    useEffect(() => {
        const newValuePair = []
        const justAttributes = []

        for (const [key, value] of Array.from(attributes.entries())) {
            justAttributes.push(key);
            for (const v of value) {
                newValuePair.push([key, v]);
            }
        }
        setJustAttributes(justAttributes);
        setAttributeKeyValuePair(newValuePair);
    }, [attributes]);

    useEffect(() => {
        async function fetchMetricAttributes() {
            if (metricText && metricText.length > 0) {
                const startEnd = timeRange.getStartEnd();
                const d = axios.post("/api/v1/metricAttributes", {
                    "startTime": Math.floor((startEnd[0].getTime() / 1000)),
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    "metricName": props.chartProps.metricName,
                    "filterAttributes": Object.fromEntries(props.chartProps.filters || new Map()),
                });
                let awaited = (await d).data;
                let newAttributes = new Map<string, string[]>();
                // HACK HACK: This lets you remove attributes from the group by list, e.g. its used in infrastructure view
                for (const attribute in awaited.attributes) {
                    if (props.removeAttributes && props.removeAttributes.includes(attribute)) {
                        // Skip adding this attribute
                    } else {
                        newAttributes.set(attribute, awaited.attributes[attribute]);
                    }
                }
                setAttributes(newAttributes);
            }
        }

        async function fetchTraceAttributes() {
            const startEnd = timeRange.getStartEnd();
            const d: AxiosPromise<TracesSummaryResponse> = axios.post("/api/v1/tracesSummary", {
                    "startTime": Math.floor((startEnd[1].getTime() / 1000) - (5 * 60)), // startTime is 5 minutes before endtime
                    "endTime": Math.floor(startEnd[1].getTime() / 1000),
                    "excludeFilters": Object.fromEntries(props.chartProps.excludeFilters || new Map()),
                    "filters": Object.fromEntries(props.chartProps.filters || new Map()),
                    "regexes": [],
                    "excludeRegexes": [],
                }
            )
            const awaited = (await d).data.attributes;
            let newAttributes = new Map<string, string[]>();
            for (const attribute in awaited) {
                const valueArray = (awaited as any)[attribute].map((v: Attribute) => v.value)
                newAttributes.set(attribute, valueArray);
            }
            setAttributes(newAttributes);
        }


        try {
            if (props.chartProps.metricType == MetricType.Metric) {
                fetchMetricAttributes();
            } else {
                fetchTraceAttributes();
            }
        } catch (e) {
            console.error(e);
        }
    }, [props, props.chartProps.metricName, timeRange]);

    useEffect(() => {
        async function fetchFuzzyMetricNames() {
            const d = axios.post("/api/v1/fuzzyMetricsNames", {
                "metricFuzzyMatch": metricText,
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            });
            let awaited = (await d).data;
            metricMatchSet(awaited.metrics);
        }

        try {
            if (props.chartProps.metricType === MetricType.Metric) {
                fetchFuzzyMetricNames();
            }
        } catch (e) {
            console.error(e);
        }
    }, [metricText, props.chartProps.metricType]);

    let pills: ReactElement[] = [];
    if (props.chartProps.filters !== undefined) {
        const entries = Array.from(props.chartProps.filters.entries());
        for (const [key, values] of entries) {
            if (values.length === 0) {
                continue;
            }
            const valuesString = values.join(" || ")
            pills.push(<Pill
                isEditable={true}
                key={key + valuesString}
                attributeKey={key}
                attributeValue={valuesString}
                filter={props.chartProps.filters}
                excludeFilter={props.chartProps.excludeFilters}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                setFilter={(filter) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, filters: filter}
                    })
                }}/>)
        }
    }

    if (props.chartProps.excludeFilters !== undefined) {
        const entries = Array.from(props.chartProps.excludeFilters.entries());
        for (const [key, values] of entries) {
            if (values.length === 0) {
                continue;
            }
            const valuesString = values.join(" || ")
            pills.push(<Pill
                isEditable={true}
                key={key + valuesString}
                isExclude={true}
                attributeKey={key}
                attributeValue={valuesString}
                filter={props.chartProps.filters!}
                excludeFilter={props.chartProps.excludeFilters}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                setFilter={(filter) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, filters: filter}
                    })
                }}/>)
        }
    }

    let groupByPills: ReactElement[] = [];
    if (props.chartProps.splits !== undefined) {
        for (const split of props.chartProps.splits) {
            groupByPills.push(<GroupByPill key={split} attributeKey={split}
                                           groupBy={props.chartProps.splits}
                                           setGroupBy={(groupBy) => {
                                               props.setChartProps((props: MetoroMetricsChartProps) => {
                                                   return {...props, splits: groupBy}
                                               })
                                           }}/>)
        }
    }

    function shouldShow(appearance: AppearanceProps | undefined, appearancePropToCheck: boolean | undefined) {
        return appearance === undefined || appearancePropToCheck === undefined || !appearancePropToCheck;
    }


    return <div className={"flex border min-h-[48px] rounded grow"}>
        {shouldShow(apperance, apperance ? apperance.hideMetricSelector : undefined) && props.chartProps.metricType == MetricType.Metric &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium p-2 border-r"}>
                metric
            </div>}
        {shouldShow(apperance, apperance ? apperance.hideMetricSelector : undefined) && props.chartProps.functions && props.chartProps.functions.find((value, index) => value !== undefined && value.functionType == MathExpressionFunctionType.CustomMathExpression) !== undefined &&
            <Tooltip open={openVariableNameTooltip}>
                <TooltipTrigger
                    onMouseLeave={() => setOpenVariableNameTooltip(false)}
                    onMouseEnter={() => setOpenVariableNameTooltip(true)}
                    onClick={() => setOpenVariableNameTooltip(!openVariableNameTooltip)}>
                    <div
                        className={"bg-backgroundmedium flex flex-col justify-center items-center text-center text-textmedium pl-2"}>
                        <span
                            className=" rounded-lg bg-secondarytransparenter px-2 py-1 text-xs font-semibold text-textmedium border border-secondary">
                            a</span>
                    </div>
                </TooltipTrigger>
                <TooltipContent
                    className={"bg-backgroundmedium border rounded px-1 py-2 text-textmedium w-[300px]"}> Use 'a' as the
                    variable name in custom math expression to represent the metric. </TooltipContent>
            </Tooltip>
        }
        {shouldShow(apperance, apperance ? apperance.hideMetricSelector : undefined) && props.chartProps.metricType == MetricType.Metric &&
            <div className={"bg-backgroundmedium items-center flex flex-col grow justify-center border-r"}>
                <Popover open={openMetric} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={openMetric}
                            className="flex w-full justify-between text-textmedium px-2"
                            onClick={() => setOpenMetric(!openMetric)}
                        >
                            {props.chartProps.metricName}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenMetric(false)}
                                    onEscapeKeyDown={() => setOpenMetric(false)}
                                    onInteractOutside={() => setOpenMetric(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]">
                        <Command>
                            <CommandInput placeholder={"Search metrics..."} value={metricText} id={"free_text_input"}
                                          onChangeCapture={(e) => {
                                              setMetricText(e.currentTarget.value);
                                          }}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No metrics found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        metricMatch.map((match, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenMetric(false);
                                                setMetricText(match);
                                                props.setChartProps((props: MetoroMetricsChartProps) => {
                                                    return {...props, metricName: match}
                                                })
                                            }}>
                                                {match}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
        {shouldShow(apperance, apperance ? apperance.hideAggregationSelector : undefined) && props.chartProps.metricType == MetricType.Trace &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r p-2"}>
                <Popover open={openAggregate} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                             onClick={() => setOpenAggregate(!openGroupBy)}>
                            <div className={"flex justify-between gap-2"}>
                                <div className={"ml-1 flex flex-col justify-center"}>
                                    {aggregationPlaceholder != undefined && aggregationPlaceholder != "" ? aggregationPlaceholder : aggregationToUse[0].name}
                                </div>
                                <div className={"flex flex-col justify-center"}>
                                    <ChevronsUpDown className={"h-4 w-4"}/>
                                </div>
                            </div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenAggregate(false)}
                                    onEscapeKeyDown={() => setOpenAggregate(false)}
                                    onInteractOutside={() => setOpenAggregate(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Select an aggregation..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No aggregations found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        aggregationToUse.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenAggregate(false);

                                                props.setChartProps((props: MetoroMetricsChartProps) => {
                                                    return {...props, aggregation: kv.value}
                                                })
                                            }}>
                                                {kv.name}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>}
        {shouldShow(apperance, apperance ? apperance.hideFilterSelector : undefined) && <div
            className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium p-2 border-r"}>
            {props.chartProps.metricType == MetricType.Metric ? "where" : "of"}
        </div>}
        {shouldShow(apperance, apperance ? apperance.hideFilterSelector : undefined) && props.chartProps.metricType == MetricType.Trace &&
            <FeedSearch
                isEmbedded={true}
                filter={props.chartProps.filters || new Map()}
                setFilter={(filter) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, filters: filter}
                    })
                }}
                excludeFilter={props.chartProps.excludeFilters || new Map()}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                regexes={[]}
                setRegexes={() => {
                }}
                excludeRegexes={[]}
                setExcludeRegexes={() => {
                }}
                filterAttributes={filterKeys}
                dropDownFunction={(key) => {
                    let filters = Object.fromEntries(props.chartProps.filters || new Map());
                    let excludeFilters = Object.fromEntries(props.chartProps.excludeFilters || new Map());
                    return axios.post("/api/v1/tracesSummaryIndividualAttribute", {
                            "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                            "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                            // Removed because we want to keep everything for now
                            "excludeFilters": excludeFilters,
                            // Removed because we want to keep everything for now
                            "filters": filters,
                            // Removed because we want to keep everything for now
                            // "regexes": props.chartProps.regexes,
                            // Removed because we want to keep everything for now
                            // "excludeRegexes": props.chartProps.excludeRegexes,
                            // "environments": environments[0] === "" ? [] : environments,
                            "attribute": key
                        }
                    ).then((response) => {
                        return response.data.attribute;
                    })
                }}
            />
        }
        {shouldShow(apperance, apperance ? apperance.hideFilterSelector : undefined) && props.chartProps.metricType == MetricType.Metric &&
            <FeedSearch
                isEmbedded={true}
                filter={props.chartProps.filters || new Map()}
                setFilter={(filter) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, filters: filter}
                    })
                }}
                excludeFilter={props.chartProps.excludeFilters || new Map()}
                setExcludeFilter={(exclude) => {
                    props.setChartProps((props: MetoroMetricsChartProps) => {
                        return {...props, excludeFilters: exclude}
                    })
                }}
                regexes={[]}
                setRegexes={() => {
                }}
                excludeRegexes={[]}
                setExcludeRegexes={() => {
                }}
                filterAttributes={filterKeys}
                dropDownFunction={(key) => {
                    return axios.post("/api/v1/metricIndividualAttribute", {
                            "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                            "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                            "metricName": props.chartProps.metricName,
                            // Removed because we want to keep everything for now
                            // "excludeFilters": excludeFilters,
                            // Removed because we want to keep everything for now
                            // "filters": filters,
                            // Removed because we want to keep everything for now
                            // "regexes": props.chartProps.regexes,
                            // Removed because we want to keep everything for now
                            // "excludeRegexes": props.chartProps.excludeRegexes,
                            // "environments": environments[0] === "" ? [] : environments,
                            "attribute": key
                        }
                    ).then((response) => {
                        return response.data.attribute.map((key: string) => {
                            return {
                                value: key,
                                volume: 0
                            }
                        })
                    })
                }}
            />
        }
        {shouldShow(apperance, apperance ? apperance.hideAggregationSelector : undefined) && props.chartProps.metricType == MetricType.Metric &&
            <div
                className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium border-r border-l p-2"}>
                <Popover open={openAggregate} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div className={"flex flex-wrap gap-4 hover:cursor-pointer"}
                             onClick={() => setOpenAggregate(!openGroupBy)}>
                            <div className={"flex justify-between gap-2"}>
                                <div className={"ml-1 flex flex-col justify-center"}>
                                    {aggregationPlaceholder != undefined && aggregationPlaceholder != "" ? aggregationPlaceholder : aggregationToUse[0].name}
                                </div>
                                <div className={"flex flex-col justify-center"}>
                                    <ChevronsUpDown className={"h-4 w-4"}/>
                                </div>
                            </div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenAggregate(false)}
                                    onEscapeKeyDown={() => setOpenAggregate(false)}
                                    onInteractOutside={() => setOpenAggregate(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Select an aggregation..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No aggregations found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        aggregationToUse.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenAggregate(false);

                                                props.setChartProps((props: MetoroMetricsChartProps) => {
                                                    return {...props, aggregation: kv.value}
                                                })
                                            }}>
                                                {kv.name}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
        {shouldShow(apperance, apperance ? apperance.hideGroupBySelector : undefined) &&
            <div className={"flex grow  bg-backgroundmedium border-l"}>
                <Popover open={openGroupBy} modal={true}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div
                            className={"flex p-2 flex-wrap gap-4 bg-backgroundmedium hover:cursor-pointer text-textdark items-center"}
                            onClick={() => setOpenGroupBy(!openGroupBy)}
                        >
                            {groupByPills.length == 0 && "Group by..."}
                            {groupByPills.length != 0 && groupByPills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenGroupBy(false)}
                                    onEscapeKeyDown={() => setOpenGroupBy(false)}
                                    onInteractOutside={() => setOpenGroupBy(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"} placeholder={"Group by..."}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No attributes found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        justAttributes.map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenGroupBy(false);

                                                props.setChartProps((props: MetoroMetricsChartProps) => {
                                                    const newGroupBy = props.splits ? [...props.splits] : [];
                                                    newGroupBy.push(kv);
                                                    return {...props, splits: newGroupBy}
                                                })
                                            }}>
                                                {kv}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        }
    </div>
}

function MetricQuerySelectorPanel(props: {
    setChartProps: (value: (((prevState: MetoroMetricsChartProps) => MetoroMetricsChartProps) | MetoroMetricsChartProps)) => void,
    chartProps: MetoroMetricsChartProps
    minimal?: boolean
}) {
    return <div className={"flex flex-col gap-4"}>
        {(props.minimal === undefined || !props.minimal) && <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                3
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Data
            </div>
        </div>}
        <div className={"flex flex-col"}>
            <div className={"flex justify-start"}>
                <div className={"flex gap-2 items-center grow"}>
                    <MetricQuerySelector setChartProps={props.setChartProps} chartProps={props.chartProps}/>
                </div>
            </div>
            <div className={"flex justify-start"}>
                <div className={"flex gap-2 items-center grow"}>
                    <div className={"flex flex-col"}>
                        {props.chartProps.functions && props.chartProps.functions.map((func, index) => {
                            return <MetricFunctionSelector metricFunction={func}
                                                           setChartProps={props.setChartProps}/>
                        })}
                        <AddMetricFunctionButton setChartProps={props.setChartProps}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function AddMetricFunctionButton(props: {
    setChartProps: Dispatch<SetStateAction<MetoroMetricsChartProps>>
}) {
    return <div
        className={"flex w-max bg-background border rounded mt-4 gap-x-1 p-1 pr-2 text-textmedium hover:cursor-pointer hover:text-textlight"}
        onClick={() => props.setChartProps(
            (prev: MetoroMetricsChartProps) => {
                if (prev.functions === undefined) {
                    return {
                        ...prev,
                        functions: [{
                            id: uuid(),
                            functionType: MathExpressionFunctionType.CustomMathExpression
                        }]
                    }
                }
                return {
                    ...prev,
                    functions: [...prev.functions,
                        {id: uuid(), functionType: MathExpressionFunctionType.CustomMathExpression}]
                }
            }
        )}>

        <div className={"flex items-center justify-center"}>
            <PlusIcon className={"w-4 h-4"}></PlusIcon>
        </div>
        <div>Add function</div>
    </div>
}

function reverseLookupFunctionType(funcType: FunctionType | undefined): string {
    if (funcType === undefined) {
        return "Select a function";
    }
    for (const [key, value] of FunctionTypeToFunction.entries()) {
        if (value.includes(funcType)) {
            return key;
        }
    }
    return "Select a function";
}

function MetricFunctionSelector(props: {
    metricFunction: MetricFunction;
    setChartProps: Dispatch<SetStateAction<MetoroMetricsChartProps>>
}) {
    const [open, setOpen] = React.useState(false)
    const [expression, setExpression] = React.useState<string>(props.metricFunction.functionPayload == undefined ? "" : props.metricFunction.functionPayload!.expression)

    function submitMathExpression(val: string) {
        props.setChartProps((prev: MetoroMetricsChartProps) => {
            const mathExp = {
                variables: ["a"],
                expression: val
            }
            let currFunctionIndex = prev.functions.findIndex((value, index) => value !== undefined && value.id == props.metricFunction.id);
            if (currFunctionIndex == -1) {
                return prev;
            }
            let prevFunc = prev.functions
            prevFunc[currFunctionIndex].functionPayload = mathExp;
            return {
                ...prev, functions: prevFunc
            }
        })
    }

    return <div className={"flex flex-col w-max"}>
        <div className={"bg-border h-4 w-[2px] ml-2"}></div>
        <div className={"group flex"}>
            <div className={"flex border min-h-[48px] rounded grow group-hover:border-y group-hover:border-l"}>
                <div
                    className={"bg-backgrounddark flex flex-col justify-center items-center text-center text-textmedium p-2 border-r"}>
                    <SquareFunctionIcon className={"text-textmedium"}
                    />
                </div>
                <div
                    className="flex w-full flex-col items-start justify-between px-4 py-3 sm:flex-row sm:items-center bg-backgroundmedium hover:bg-backgroundlight hover:cursor-pointer">
                    <DropdownMenu open={open} onOpenChange={setOpen}>
                        <DropdownMenuTrigger asChild>
                            <p className="text-sm font-medium leading-none">
                    <span
                        className="mr-2 rounded-lg bg-secondarytransparenter px-2 py-1 text-xs text-textmedium border border-secondary">
                        {reverseLookupFunctionType(props.metricFunction.functionType)}
                    </span>
                                <span className="text-textmedium"> {props.metricFunction.functionType} </span>
                            </p>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end"
                                             className="w-[280px] mt-3 bg-background text-textmedium rounded">
                            <DropdownMenuGroup>
                                {Object.entries(Functions).map(([funcName, funcValue]) => {
                                    return <DropdownMenuSub>
                                        <DropdownMenuSubTrigger
                                            className={"hover:bg-backgroundlight"}>{funcName}</DropdownMenuSubTrigger>
                                        <DropdownMenuSubContent className="p-0 bg-background ml-1 rounded">
                                            <Command>
                                                <CommandList>
                                                    <CommandGroup>
                                                        {FunctionTypeToFunction.get(funcValue) !== undefined
                                                            && FunctionTypeToFunction.get(funcValue)!.map((funcType, index) => {
                                                                return <CommandItem
                                                                    className={"hover:bg-backgroundlight"}
                                                                    key={funcType}
                                                                    value={funcType}
                                                                    onSelect={(value) => {
                                                                        props.setChartProps((prev: MetoroMetricsChartProps) => {
                                                                            let currFunctionIndex = prev.functions.findIndex(
                                                                                (value, index) => value !== undefined && value.id == props.metricFunction.id);
                                                                            if (currFunctionIndex == -1) {
                                                                                return prev;
                                                                            }
                                                                            let prevFunc = prev.functions
                                                                            prevFunc[currFunctionIndex].functionType = value as FunctionType;
                                                                            return {...prev, functions: prevFunc}
                                                                        })
                                                                        setOpen(false)
                                                                    }}
                                                                >
                                                                    {funcType}
                                                                </CommandItem>
                                                            })
                                                        }
                                                    </CommandGroup>
                                                </CommandList>
                                            </Command>
                                        </DropdownMenuSubContent>
                                    </DropdownMenuSub>
                                })}
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                {props.metricFunction !== undefined && props.metricFunction.functionType !== undefined && props.metricFunction.functionType == MathExpressionFunctionType.CustomMathExpression &&
                    <div
                        className={"text-center text-textmedium flex min-h-[48px] rounded grow border-l"}>
                        <div className={"flex justify-start"}>
                            <Input id={"free_text_input"}
                                   value={expression}
                                   placeholder={"e.g. a / 60"}
                                   onBlur={(e) => {
                                       submitMathExpression(e.currentTarget.value)
                                       setExpression(e.currentTarget.value)
                                   }}

                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                           submitMathExpression(e.currentTarget.value)
                                           setExpression(e.currentTarget.value)
                                       }
                                   }}
                                   onChangeCapture={(e) => {
                                       setExpression(e.currentTarget.value)
                                   }}
                                   className={cn("h-12 grow text-textlight shadow-none w-max bg-backgroundmedium")}/>
                        </div>
                    </div>}
            </div>
            <div className={"bg-background group-hover:flex group-hover:items-center"}>
                <div
                    onClick={() => {
                        props.setChartProps((prev: MetoroMetricsChartProps) => {
                            let prevFunctions = prev.functions;
                            let currFunctionIndex = prev.functions.findIndex((value, index) => value !== undefined && value.id == props.metricFunction.id);
                            if (currFunctionIndex == -1) {
                                return prev;
                            }
                            if (prevFunctions[currFunctionIndex] !== undefined) {
                                delete prevFunctions[currFunctionIndex];
                            }
                            prevFunctions = prevFunctions.filter((value) => value !== undefined);
                            return {...prev, functions: prevFunctions}
                        })
                    }}
                    className="h-full pt-[10px] hover:cursor-pointer hover:bg-primary hidden group-hover:block group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textmedium">
                    <XIcon/>
                </div>
            </div>
        </div>
    </div>
}

export interface MetricFunction {
    id: string;
    functionType: FunctionType;
    functionPayload?: MathExpression;
}

enum Functions {
    Rate = "rate",
    Arithmetic = "arithmetic",
    // Timeshift = "timeshift",
    // TODO: Add more function types like Arithmetic, Count etc..
}

// TODO: Add more function types such as  "| Timeshift | ... " function type in the future.
export type FunctionType = RateFunctionType | MathExpressionFunctionType

export enum MathExpressionFunctionType {
    CustomMathExpression = "customMathExpression",
    // Logarithm = "logarithm",
    // Exponential = "exponential"
    // TODO: Add more math expression functions like logarithm, exponential, etc.
}

export enum RateFunctionType {
    MonotonicDifference = "monotonicDifference",
    ValueDifference = "valueDifference"
    // PerSecond = "perSecond",
    // PerMinute = "perMinute",
    // PerHour = "perHour",
    // TODO: Add more rate functions like time difference, etc.
}

const FunctionTypeToFunction: Map<Functions, FunctionType[]> = new Map<Functions, FunctionType[]>([
    [Functions.Rate, Object.values(RateFunctionType)],
    [Functions.Arithmetic, Object.values(MathExpressionFunctionType)],
    // TODO: Add more functions like [Functions.Timeshift, []],
]);

function SetTitlePanel(props: {
    setTitle: (t: string) => void, title
        :
        string | undefined
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                4
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Name chart
            </div>
        </div>
        <div className={"flex justify-start"}>
            <Input value={props.title} id={"free_text_input"} onChangeCapture={(e) => {
                props.setTitle(e.currentTarget.value);
            }}
                   className={cn("h-12 focus:ring-0 focus:border-border focus:ring-border grow text-textlight border border-border shadow-none w-max bg-backgroundmedium")}/>
        </div>
    </div>
}


function MetricTypeSelector(props: { metricType: MetricType, setMetricType: (t: MetricType) => void }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex grow justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                1
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Metric Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(MetricType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:border hover:border-secondary cursor-pointer px-4", props.metricType === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => props.setMetricType(type)}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function TypeSelectorPanel(props: { type: ChartType, setType: (t: ChartType) => void }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex grow justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Chart Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(ChartType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:border hover:border-secondary cursor-pointer px-4", props.type === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => props.setType(type)}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    MetricSelectorPanel
}