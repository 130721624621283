import {AlarmCondition, MonitorEvaluation, monitorEventStaticThreshold} from "./MetricAlert";
import {MetricFunction} from "../../components/Dashboarding/widgets/MetricSelector";

const defaultTraceAlert = {
    filters: {
        filters: new Map(),
        excludeFilters: new Map(),
        splits: [],
        aggregation: "count",
        functions: []
    },
    eventPayload: {
        condition: "GreaterThan",
        threshold: 0,
    },
    monitorEvaluation: monitorEventStaticThreshold
}

interface TraceFilter {
    filters: Map<string, string[]>
    excludeFilters: Map<string, string[]>
    splits: string[],
    aggregation: string,
    functions: MetricFunction[]
}

interface TraceAlert {
    filters: TraceFilter,
    monitorEvaluation: MonitorEvaluation
    eventPayload: AlarmCondition
}

export type {
    TraceAlert
}

export {
    defaultTraceAlert,
}

