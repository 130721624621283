import {BaseView} from "./BaseView";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    ChartType,
    createAlertUrlFromAlert,
    GetAllTraceMetrics,
    GetAllTraceMetricsResponse,
    MetoroChart,
    MetricToChartData,
    MetricType
} from "./MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {TimeRange} from "../types/time";
import Traces from "./Traces";
import Logs from "./Logs";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import {KubernetesView} from "../components/K8sInfo/KubernetesView";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import axios from "../utility/customAxios";
import {ServiceSummaries} from "./ServiceCatalogue";
import {AlertDestinationType, AlertType, ApiServerAlert, ThresholdComparator} from "./AlertCreation";
import {EvalType} from "./alerts/MetricAlert";
import {MetricChartWidget} from "../components/Dashboarding/internalwidgets";
import {ServiceMap} from "./ServiceMap";
import {FlameChartNode, FlameChartNodes, FlameChartPlugin, TimeframeSelectorPlugin} from "../components/flame-chart-js";
import {
    FlameChartContainerComponent
} from "../components/flame-chart-js/wrappers/react/flame-chart-container-component";
import {Input} from "../components/ui/input";
import {useDebouncedCallback} from "use-debounce";
import {QuestionMarkCircledIcon} from "@radix-ui/react-icons";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {DropDownItem, MultiSelectorDropDown} from "../components/Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {K8sEvents} from "./K8sEvents";

function TraceAndChartEmbeds(props: { serviceName: string }) {
    const navigate = usePreserveQueryParamsNavigate();


    return <div className={"columns-2 flex max-h-full min-h-0 gap-4 min-w-0 max-w-full grow shrink"}>
        <Traces service={props.serviceName} removeService={true} justTraceFeed={true}/>
        <div className={"min-w-0 min-h-0 w-full h-full relative flex flex-col"}>
            <ServiceMap
                startServices={[props.serviceName]}
                endServices={[props.serviceName]}
                justTraceMap={true}/>
        </div>
    </div>
}

function MainPanel(props: { metrics?: GetAllTraceMetricsResponse, serviceName: string }) {
    return <div className={"grow shrink min-h-0 min-w-0"}>
        <div className={"flex h-1/2"}>
            <REDMetrics metrics={props.metrics} serviceName={props.serviceName}/>
        </div>
        <div className={"h-1/2 flex"}>
            <TraceAndChartEmbeds serviceName={props.serviceName}/>
        </div>
    </div>
}

function createAlertForServiceTraces(serviceName: string): ApiServerAlert {
    let filters = new Map<string, string[]>();
    filters.set("server.service.name", [serviceName]);
    return {
        uuid: "",
        name: "Monitor",
        description: "Describe why you want to monitor",
        destination: {
            type: AlertDestinationType.Slack,
            slackDestination: {
                channel: "help"
            },
        },
        type: AlertType.Trace,
        traceAlert: {
            filters: {
                // @ts-ignore
                filters: filters,
                // @ts-ignore
                excludeFilters: Object.fromEntries(new Map<string, string[]>()),
                splits: [],
                functions: [],
                aggregation: "change_me",
            },
            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        },
    };
}

function getAlertUrlsForTraceMetrics(metrics: GetAllTraceMetricsResponse, serviceName: string): Map<string, string> {
    if (!metrics || !metrics.metrics) {
        return new Map<string, string>();
    }
    let alertUrls = new Map<string, string>();
    metrics.metrics.forEach((metric) => {
        let alert = createAlertForServiceTraces(serviceName);
        if (metric.name === "NumRequests") {
            alert.traceAlert!.filters.aggregation = "count";
        } else if (metric.name === "TwoHundreds") {
            alert.traceAlert!.filters.aggregation = "count";
            alert.traceAlert!.filters.filters.set("http.status_code.bucket", ["2XX"]);
        } else if (metric.name === "ThreeHundreds") {
            alert.traceAlert!.filters.aggregation = "count";
            alert.traceAlert!.filters.filters.set("http.status_code.bucket", ["3XX"]);
        } else if (metric.name === "FourHundreds") {
            alert.traceAlert!.filters.aggregation = "count";
            alert.traceAlert!.filters.filters.set("http.status_code.bucket", ["4XX"]);
        } else if (metric.name === "FiveHundreds") {
            alert.traceAlert!.filters.aggregation = "count";
            alert.traceAlert!.filters.filters.set("http.status_code.bucket", ["5XX"]);
        } else if (metric.name === "P50") {
            alert.traceAlert!.filters.aggregation = "p50";
        } else if (metric.name === "P90") {
            alert.traceAlert!.filters.aggregation = "p90";
        } else if (metric.name === "P95") {
            alert.traceAlert!.filters.aggregation = "p95";
        } else if (metric.name === "P99") {
            alert.traceAlert!.filters.aggregation = "p99";
        }
        // @ts-ignore
        alert.traceAlert!.filters.filters = Object.fromEntries(alert.traceAlert!.filters.filters);
        alertUrls.set(metric.name, createAlertUrlFromAlert(alert));
    })
    return alertUrls;
}

function createMetricWidgetForServiceTraces(serviceName: string): MetricChartWidget {
    let filters = new Map<string, string[]>();
    filters.set("server.service.name", [serviceName]);
    return {
        widgetType: "MetricChart",
        metricName: "",
        filters: filters,
        excludeFilters: new Map<string, string[]>(),
        splits: [],
        aggregation: "count",
        title: "Total Number of Requests",
        type: ChartType.Bar,
        metricType: MetricType.Trace,
        functions: [],
        position: {
            x: undefined,
            y: undefined,
            w: 6,
            h: 2
        }
    }
}

function getMetricsWidgets(getAllTraceMetricsResponse: GetAllTraceMetricsResponse, serviceName: string): Map<String, MetricChartWidget> {
    if (!getAllTraceMetricsResponse || !getAllTraceMetricsResponse.metrics) {
        return new Map<String, MetricChartWidget>();
    }
    let alertMetricsWidgets = new Map<String, MetricChartWidget>();
    getAllTraceMetricsResponse.metrics.forEach((metric) => {
        let metricWidget = createMetricWidgetForServiceTraces(serviceName);
        if (metric.name === "NumRequests") {
            metricWidget.aggregation = "count";
        } else if (metric.name === "TwoHundreds") {
            metricWidget.aggregation = "count";
            metricWidget.filters!.set("http.status_code.bucket", ["2XX"]);
            metricWidget.title = "Number of 2XXs";
        } else if (metric.name === "ThreeHundreds") {
            metricWidget.aggregation = "count";
            metricWidget.filters!.set("http.status_code.bucket", ["3XX"]);
            metricWidget.title = "Number of 3XXs";
        } else if (metric.name === "FourHundreds") {
            metricWidget.aggregation = "count";
            metricWidget.filters!.set("http.status_code.bucket", ["4XX"]);
            metricWidget.title = "Number of 4XXs";
        } else if (metric.name === "FiveHundreds") {
            metricWidget.aggregation = "count";
            metricWidget.filters!.set("http.status_code.bucket", ["5XX"]);
            metricWidget.title = "Number of 5XXs";
        } else if (metric.name === "P50") {
            metricWidget.aggregation = "p50";
            metricWidget.title = "P50 Latency";
            metricWidget.type = ChartType.Line;
        } else if (metric.name === "P90") {
            metricWidget.aggregation = "p90";
            metricWidget.type = ChartType.Line;
            metricWidget.title = "P90 Latency";
        } else if (metric.name === "P95") {
            metricWidget.aggregation = "p95";
            metricWidget.title = "P95 Latency";
            metricWidget.type = ChartType.Line;
        } else if (metric.name === "P99") {
            metricWidget.aggregation = "p99";
            metricWidget.title = "P99 Latency";
            metricWidget.type = ChartType.Line;
        }
        alertMetricsWidgets.set(metric.name, metricWidget);
    })
    return alertMetricsWidgets;
}

function REDMetrics(props: { metrics?: GetAllTraceMetricsResponse, serviceName: string }) {
    let numRequests = props.metrics && props.metrics.metrics.find((metric) => metric.name === "NumRequests");
    let twoHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "TwoHundreds");
    let threeHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "ThreeHundreds");
    let fourHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "FourHundreds");
    let fiveHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "FiveHundreds");
    let p50 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P50");
    let p90 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P90");
    let p95 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P95");
    let p99 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P99");
    let alertUrls = getAlertUrlsForTraceMetrics(props.metrics!, props.serviceName);
    let metricsWidgets = getMetricsWidgets(props.metrics!, props.serviceName);

    return <div className={"grow shrink gap-4 min-h-0 min-w-0 grid grid-rows-1 grid-cols-2"}>
        <div
            className={"flex flex-col bg-backgroundmedium justify-between space-y-4 p-4 border rounded grow shrink min-w-0 min-h-0"}>
            <div className={"text-xl font-semibold text-textmedium h-8"}>
                Request Volume
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <div className={"flex grow shrink min-w-0 min-h-0"}>
                    <MetoroChart
                        metricChartWidget={metricsWidgets.get("NumRequests")}
                        createAlertURL={alertUrls.get("NumRequests")}
                        title={"Total Number of Requests"}
                        type={ChartType.Bar}
                        dataToUse={MetricToChartData(numRequests, false)}
                        hideLegend={true}/>
                </div>
                <div className={"flex grow shrink min-w-0 min-h-0"}>
                    <MetoroChart
                        metricChartWidget={metricsWidgets.get("TwoHundreds")}
                        createAlertURL={alertUrls.get("TwoHundreds")}
                        title={"Number of 2XXs"}
                        type={ChartType.Bar}
                        dataToUse={MetricToChartData(twoHundreds, false)}
                        hideLegend={true}/>
                </div>
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("FourHundreds")}
                    createAlertURL={alertUrls.get("FourHundreds")}
                    title={"Number of 4XXs"}
                    type={ChartType.Bar}
                    dataToUse={MetricToChartData(fourHundreds, false, "rgba(255, 206, 86, 0.2)", "rgb(255, 206, 86)")}
                    hideLegend={true}/>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("FiveHundreds")}
                    createAlertURL={alertUrls.get("FiveHundreds")}
                    title={"Number of 5XXs"}
                    type={ChartType.Bar}
                    dataToUse={MetricToChartData(fiveHundreds, false, "rgba(255, 99, 132, 0.2)", "rgb(255, 99, 132)")}
                    hideLegend={true}/>
            </div>
        </div>
        <div
            className={"flex flex-col bg-backgroundmedium justify-between space-y-4 p-4 border rounded grow shrink min-w-0"}>
            <div className={"text-xl font-semibold text-textmedium h-8"}>
                Latency
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("P50")}
                    createAlertURL={alertUrls.get("P50")}
                    isDuration={true}
                    title={"P50 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p50, true)}
                    hideLegend={true}/>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("P90")}
                    createAlertURL={alertUrls.get("P90")}
                    isDuration={true}
                    title={"P90 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p90, true)}
                    hideLegend={true}/>

            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("P95")}
                    createAlertURL={alertUrls.get("P95")}
                    isDuration={true}
                    title={"P95 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p95, true)}
                    hideLegend={true}/>
                <MetoroChart
                    metricChartWidget={metricsWidgets.get("P99")}
                    createAlertURL={alertUrls.get("P99")}
                    isDuration={true}
                    title={"P99 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p99, true)}
                    hideLegend={true}/>
            </div>
        </div>
    </div>
}


function DeploymentTimeline() {
    return <div
        className={"grow max-w-[200px] shrink h-full min-h-0 border rounded bg-backgroundmedium flex flex-col justify-center p-4"}>
        <div className={"text-xl w-full text-center flex justify-center font-semibold text-textdark h-8"}>
            Deployment Timeline coming soon...
        </div>
    </div>;
}

function setHiglightedSearch(flameChartNode: FlameChartNode, searchString: string) {
    flameChartNode.color = searchString === "" ? "#435b94" : flameChartNode.name.toLowerCase().includes(searchString.toLowerCase()) ? "#3793FF" : "#435b94";
    flameChartNode.children?.forEach((child) => {
        setHiglightedSearch(child, searchString);
    });
}

function setHighlightedSearch(searchString: string, flameChartNodes: FlameChartNodes, setFlameChartNodes: React.Dispatch<React.SetStateAction<FlameChartNodes>>) {
    if (flameChartNodes.length === 0) {
        setFlameChartNodes([]);
        return;
    }
    setFlameChartNodes(() => {
        flameChartNodes.forEach((node) => {
            setHiglightedSearch(node, searchString);
        })
        return [...flameChartNodes];
    })
}


function ProfilingView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [flameChartNodes, setFlameChartNodes] = useState<FlameChartNodes>([]);
    const [highlightedChartNodes, setHighlightedChartNodes] = useState<FlameChartNodes>([]);
    const debouncedSearchHighlight = useDebouncedCallback(setHighlightedSearch, 100);
    const [queryCompleted, setQueryCompleted] = useState<boolean>(false);
    const [abortContoller, setAbortController] = useState<AbortController | undefined>();
    const [containerNames, setContainerNames] = useState<string[]>([]);
    const [selectedContainerNames, setSelectedContainerNames] = useState<DropDownItem[]>([]);

    useEffect(() => {
        axios.post("/api/v1/profile/containerNames",
            {
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                "serviceName": searchParams.get("service")
            }
        ).then((response) => {
            setContainerNames(response.data.containerNames);
        }).catch((e) => {
            console.error(e);
        });
    }, [searchParams.get("search"), searchParams.get("service"), timeRange]);

    const [flameChartPlugins, setFlameChartPlugins] = useState<any[]>(
        () => {
            let flameChartPlugin = new FlameChartPlugin({
                name: "flameChart",
                data: [],
            });

            let timeframeSelectorPlugin = new TimeframeSelectorPlugin({
                name: "timeframeSelector",
                flameChartNodes: [],
                settings: {
                    styles: {
                        //         backgroundColor: "#151f32",
                        //         fontColor: "#EBF1F7",
                        //         font: "8px Inter",
                        graphStrokeColor: "#3793FF",
                        //         graphFillColor: "",
                    },
                },
            });
            return [timeframeSelectorPlugin, flameChartPlugin];
        }
    );

    useEffect(() => {
        debouncedSearchHighlight(searchParams.get("search") || "", flameChartNodes, setHighlightedChartNodes);
    }, [searchParams.get("search"), searchParams.get("service"), flameChartNodes]);

    useEffect(() => {
        if (abortContoller) {
            abortContoller.abort();
        }
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        axios.post("/api/v1/profiles", {
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                "serviceName": searchParams.get("service"),
                "containerNames": selectedContainerNames.map(x => x.value),
            }, {
                signal: newAbortController.signal
            }
        ).then((response) => {
            setFlameChartNodes([response.data]);
        }).catch((e) => {
            if (e.code === "ERR_CANCELED") {
                return;
            }
            if (e.response.status === 404) {
                console.log("No profiling data available");
                setFlameChartNodes([]);
            }
        }).finally(() => {
            setTimeout(() => {
                setQueryCompleted(true);
            }, 100);
        });
    }, [timeRange, selectedContainerNames, searchParams.get("service")]);

    useEffect(() => {
        if (highlightedChartNodes.length === 0) {
            return;
        }
        flameChartPlugins[0].setFlameChartNodes(highlightedChartNodes);
        flameChartPlugins[1].setData(highlightedChartNodes);
    }, [highlightedChartNodes]);

    return <div className={"flex flex-col grow shrink min-w-0 min-h-0 overflow-y-auto"}>
        <div className={"w-full h-[48px] mb-4 flex justify-between"}>
            <div className={"flex gap-2"}>
                <Tabs defaultValue={"on-cpu"}>
                    <TabsList className="grid grid-cols-2 grid-rows-1 border px-0.5">
                        <TabsTrigger value="on-cpu">On-Cpu</TabsTrigger>
                        <Tooltip delayDuration={10}>
                            <TooltipTrigger>
                                <TabsTrigger disabled={true} value="off-cpu">Off-cpu</TabsTrigger>
                            </TooltipTrigger>
                            <TooltipContent>
                                Off-cpu profiling coming soon...
                            </TooltipContent>
                        </Tooltip>
                    </TabsList>
                </Tabs>
                <div className={"flex flex-col justify-center"}>
                    <Tooltip delayDuration={10}>
                        <TooltipTrigger>
                            <QuestionMarkCircledIcon onClick={
                                () => {
                                    window.open("https://metoro.io/docs/profiling", "_blank")
                                }
                            } className={"w-6 h-6 text-primary cursor-pointer"}/>
                        </TooltipTrigger>
                        <TooltipContent>
                            View profiling documentation
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
            <div className={"flex gap-2"}>
                <MultiSelectorDropDown selectorSingular={"container"} possibleItems={containerNames.map(x => {
                    return {displayName: x, value: x}
                })} selectedItems={selectedContainerNames} setSelectedItems={setSelectedContainerNames}/>
                <Input className={"w-[256px]"} placeholder={"Search function..."}
                       value={searchParams.get("search") || ""}
                       onChange={(e) => {
                           setSearchParams(prev => {
                               prev.set("search", e.target.value);
                               return prev;
                           });
                       }}
                />
            </div>
        </div>
        {highlightedChartNodes.length === 0 && queryCompleted &&
            <div className={"flex justify-center items-center h-full w-full"}>
                <div className={"text-2xl font-semibold text-textmedium"}>
                    No profiling data available
                </div>
            </div>}
        {highlightedChartNodes.length > 0 &&
            <FlameChartContainerComponent
                settings={{
                    styles: {
                        main: {

                            backgroundColor: "#151f32",
                            fontColor: "#EBF1F7",
                        }
                    },
                }}
                plugins={flameChartPlugins}
                className={"w-full flex grow shrink min-w-0 min-h-0"}
            />
        }
    </div>
}

function Service() {
    const [searchParams, setSearchParams] = useSearchParams();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [redMetrics, setRedMetrics] = React.useState<GetAllTraceMetricsResponse>();
    const [redMetricsAbortController, setRedMetricsAbortController] = React.useState<AbortController>(new AbortController());
    const [currentTab, setCurrentTab] = React.useState<string>(searchParams.get("tab") || "apm");
    const [environments, setEnvironments] = useState<string[]>([]);
    const [serviceSummariesData, setServiceSummariesData] = useState<ServiceSummaries>();

    const [serviceName, setServiceName] = useState<string | undefined>(searchParams.get("service") || undefined);
    useEffect(() => {
        let service = searchParams.get("service");
        if (service !== serviceName) {
            setServiceName(service || undefined)
        }
    }, [searchParams])

    useEffect(() => {
        let environments = searchParams.get("environment") || "";
        if (environments !== "") {
            setEnvironments([environments])
        }
    }, [searchParams])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const startEnd = timeRange.getStartEnd()
                const d = axios.post("/api/v1/serviceSummaries", {
                        "startTime": Math.floor((startEnd[1].getTime() / 1000) - 120),
                        "endTime": Math.floor(startEnd[1].getTime() / 1000),
                        "environments": environments
                    }
                );
                setServiceSummariesData((await d).data);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [timeRange, environments]);

    useEffect(() => {
        let tab = searchParams.get("tab");
        if (currentTab !== tab) {
            setSearchParams(prev => {
                prev.set("tab", currentTab);
                return prev;
            });
        }
    }, [currentTab]);

    async function fetchMetrics(serviceName: string | undefined, timeRange: TimeRange, abortController: AbortController,
                                setRedMetricsAbortController: React.Dispatch<React.SetStateAction<AbortController>>
    ) {
        let startEnd = timeRange.getStartEnd();
        redMetricsAbortController.abort();
        abortController = new AbortController();
        setRedMetricsAbortController(abortController);
        await GetAllTraceMetrics({
            serviceNames: serviceName ? [serviceName] : [],
            startTime: Math.floor(startEnd[0].getTime() / 1000),
            endTime: Math.floor(startEnd[1].getTime() / 1000),
            environments: environments,
        }, abortController).then((response) => {
            setRedMetrics(response)
        }).catch((e) => {
            if (e.code === "ERR_CANCELED") {
                return;
            }
            console.error(e);
        })
    }

    useEffect(() => {
        fetchMetrics(serviceName, timeRange, redMetricsAbortController, setRedMetricsAbortController);
    }, [serviceName, timeRange, environments]);

    if (!serviceName) {
        return <BaseView title={"Service"}>
            <div className={"flex justify-center items-center h-full w-full"}>
                <div className={"text-2xl font-semibold text-textmedium"}>
                    No service selected
                </div>
            </div>
        </BaseView>
    }

    let displayServiceName = serviceName
    if (displayServiceName.includes("/")) {
        let strings = displayServiceName.split("/");
        displayServiceName = strings[strings.length - 1]
    }

    displayServiceName = displayServiceName.charAt(0).toUpperCase() + displayServiceName.slice(1)

    function findService(serviceName: string, serviceSummaries: ServiceSummaries) {
        return serviceSummaries.serviceSummaries.find((service) => service.serviceName === serviceName)
    }

    return <BaseView enableServiceDropdown={true} title={`${displayServiceName} Dashboard`}>
        <div className={"m-4 min-h-0 flex min-w-0 bg-backgrounddark flex-col grow shrink text-textmedium"}>
            <Tabs value={currentTab} onValueChange={(val) => setCurrentTab(val)} defaultValue="apm"
                  className="flex flex-col min-h-0 min-w-0 grow shrink">
                <div className={"flex flex-none justify-center min-h-0 min-w-0 mb-4"}>
                    <TabsList className="grid grid-cols-5 grid-rows-1 border">
                        <TabsTrigger value="apm" className={"grow shrink text-lg"}>APM</TabsTrigger>
                        <TabsTrigger value="k8s" className={"grow shrink text-lg"}>Kubernetes</TabsTrigger>
                        <TabsTrigger value="logs" className={"grow shrink text-lg"}>Logs</TabsTrigger>
                        <TabsTrigger value="events" className={"grow shrink text-lg"}>Events</TabsTrigger>
                        <TabsTrigger value="profiling" className={"grow shrink text-lg"}>Profiling</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value="apm" className={"flex grow shrink flex-col min-h-0 min-w-0"}>
                    <MainPanel metrics={redMetrics} serviceName={serviceName}/>
                </TabsContent>
                <TabsContent value="k8s" className={"flex grow shrink min-h-0 min-w-0"}>
                    <div className={"flex gap-4 min-w-0 min-h-0 grow shrink"}>
                        <KubernetesView serviceName={serviceName}/>
                    </div>
                </TabsContent>
                <TabsContent value="logs" className={"flex grow shrink min-h-0 min-w-0"}>
                    <div className={"flex gap-4 min-w-0 min-h-0 grow shrink"}>
                        <Logs noBaseView={true} service={serviceName}/>
                    </div>
                </TabsContent>
                <TabsContent value="events" className={"flex grow shrink min-h-0 min-w-0"}>
                    <div className={"flex gap-4 min-w-0 min-h-0 grow shrink"}>
                        <K8sEvents noBaseView={true} service={serviceName}/>
                    </div>
                </TabsContent>
                <TabsContent value="profiling" className={"flex grow shrink min-h-0 min-w-0"}>
                    <div className={"flex gap-4 min-w-0 min-h-0 grow shrink"}>
                        <ProfilingView/>
                    </div>
                </TabsContent>
            </Tabs>
        </div>
    </BaseView>
}

export {
    Service
}