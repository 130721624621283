import React, {useEffect, useState, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Badge} from '../ui/badge';
import {Button} from '../ui/button';
import {CheckCircle, XCircle} from 'lucide-react';
import {DataTable} from '../ui/datatable/datatable';
import {ColumnDef} from '@tanstack/react-table';
import {DataTableColumnHeader} from '../ui/datatable/datatablecolumnheader';
import axios from '../../utility/customAxios';
import { IssueDetailsDrawerButton} from './IssueDetailsDrawer';
import prettyBytes from "pretty-bytes";

export interface Issue {
    uuid: string;
    title: string;
    description: string;
    attributes: Map<string, string>
    measurements: Map<string, any>
    severity: string
    type: string
    workflowUuid: string;
    workflowRunUuid: string;
    organizationUuid: string;
    muted: string;
    open: string;
}


interface ListIssuesResponse {
    issues: Issue[];
    total: number;
}

function getMutedBadgeColor(muted: string) {
    return muted === "true" ? "bg-red-500" : "bg-primary";
}

function getColorForType(type: string) {
    switch (type) {
        case "rightsizing":
            return "bg-purple-500";
        default:
            return "bg-primary";
    }
}

function getSeverityBadgeColor(severity: string) {
    switch (severity) {
        case "high":
            return "bg-red-500";
        case "medium":
            return "bg-yellow-600";
        case "low":
            return "bg-primary";
        default:
            return "bg-primary";
    }
}

function getStatusBadgeColor(open: boolean) {
    return open ? "bg-red-500" : "bg-primary";
}

const filters: any = [
    {
        filteringColumn: 'severity',
        title: 'Severity',
    },
    {
        filteringColumn: 'type',
        title: 'Type',
    },
    {
        filteringColumn: 'muted',
        title: 'Muted',
        defaultValues: ['false']
    },
    {
        filteringColumn: 'open',
        title: 'Open',
        defaultValues: ['true']
    },
];

export function IssuesTable() {
    const {data, isLoading, error} = useQuery<ListIssuesResponse>({
        queryKey: ['issues'],
        queryFn: async () => {
            const response = await axios.post('/api/v1/issues/list', {
                offset: 0,
                limit: 1000000,
                returnMuted: true
                // Optional filters can be added here:
                // workflowUuid: string
                // open: boolean
                // attributes: json.RawMessage
            });
            response.data.issues = response.data.issues.map((issue: any) => ({
                ...issue,
                muted: issue.muted ? "true" : "false",
                open: issue.open ? "true" : "false",
                attributes: new Map(Object.entries(issue.attributes || {})),
                measurements: new Map(Object.entries(issue.latestMeasurements || {}))
            }))
            return response.data
        },
    });

    const baseColumns: ColumnDef<Issue>[] = [
        {
            id: 'title',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Title"/>
            ),
            accessorKey: 'title',
            cell: ({row}) => (
                <div>
                    <p className="font-medium leading-none">{row.original.title}</p>
                </div>

            ),
        },
        {
            id: 'open',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Status"/>
            ),
            // @ts-ignore
            show: false,
            accessorKey: 'open',
            cell: ({row}) => (
                <div>
                    {row.original.open == "true" ? (
                        <Badge className={`${getStatusBadgeColor(true)} text-white rounded flex items-center gap-1 max-w-max`}>
                            Open
                        </Badge>
                    ) : (
                        <Badge className={`${getStatusBadgeColor(false)} text-white rounded flex items-center gap-1 max-w-max`}>
                            Closed
                        </Badge>
                    )}
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            },
        },
        {
            id: 'severity',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Severity"/>
            ),
            // @ts-ignore
            sort: "desc",
            accessorKey: 'severity',
            cell: ({row}) => (
                // Badge for severity
                <div>
                    <Badge className={`${getSeverityBadgeColor(row.original.severity)} text-white rounded flex items-center gap-1 max-w-max`}>
                        {row.original.severity}
                    </Badge>
                </div>
            ),
            sortingFn: (rowA, rowB) => {
                // high > medium > low
                const a = rowB.original.severity;
                const b = rowA.original.severity;
                if (a === b) {
                    return 0;
                }
                if (a === "high") {
                    return -1;
                }
                if (b === "high") {
                    return 1;
                }
                if (a === "medium") {
                    return -1;
                }
                if (b === "medium") {
                    return 1;
                }
                return 0;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            }
        },
        {
            id: 'type',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Type"/>
            ),
            accessorKey: 'type',
            cell: ({row}) => (
                // Badge for type
                <div>
                    <Badge className={`${getColorForType(row.original.type)} text-white rounded flex items-center gap-1 max-w-max`}>
                        {row.original.type}
                    </Badge>
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            }
        },
        {
            id: 'muted',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Muted"/>
            ),
            accessorKey: 'muted',
            // @ts-ignore
            show: false,
            cell: ({row}) => (
                // Badge for muted
                <div>
                    <Badge className={`${getMutedBadgeColor(row.original.muted)} text-white rounded flex items-center gap-1 max-w-max`}>
                        {row.original.muted}
                    </Badge>
                </div>
            ),
            filterFn: (row, id, value) => {
                return value.includes(row.getValue(id));
            }
        },
        {
            id: 'actions',
            header: ({column}) => (
                <DataTableColumnHeader column={column} title="Actions"/>
            ),
            cell: ({row}) => (
                <IssueDetailsDrawerButton issue={row.original}/>

            ),
        },
    ];

    const columns = useMemo<ColumnDef<Issue>[]>(() => {
        let cols = [...baseColumns];

        if (data?.issues) {
            cols = addAttributesToColumns(cols, data.issues);
            cols = addMeasurementsToColumns(cols, data.issues);
        }

        return cols;
    }, [data?.issues]);

    const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (data) {
        }
    }, [data]);

    if (isLoading) {
        return <div className="flex justify-center items-center h-64">Loading issues...</div>;
    }

    if (error) {
        return <div className="text-red-500">Error loading issues: {error.message}</div>;
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={data?.issues || []}
                textFilteringColumnName="title"
                textFilteringPlaceholder="Filter by title..."
                filters={filters}
                showPagination={true}
                tableTitle="Issues"
                isLoading={isLoading}
            />
        </>
    );
}

function addAttributesToColumns(columns: ColumnDef<Issue>[], issues: Issue[]) : ColumnDef<Issue>[] {
    const newColumns = [...columns];

    for (const issue of issues) {
        for (const [key, value] of issue.attributes.entries()) {
            // Does the column already exist?
            const existingColumn = newColumns.find((column) => column.id === key);
            if (existingColumn) {
                continue;
            }
            newColumns.push({
                id: key,
                sortUndefined: 'last',
                header: ({column}) => (
                    <DataTableColumnHeader column={column} title={capitalizeFirstLetter(key)}/>
                ),
                filterFn: (row, id, value) => {
                    return value.includes(row.getValue(id));
                },
                accessorFn: (row) => row.attributes.get(key),
                enableResizing: true,
                // We bundle this in
                // @ts-ignore
                show: false,
                cell: ({row}) => (
                    <div>
                        {row.original.attributes.get(key)}
                    </div>
                ),
            });
        }
    }

    return newColumns
}

function addMeasurementsToColumns(columns: ColumnDef<Issue>[], issues: Issue[]) : ColumnDef<Issue>[] {
    const newColumns = [...columns];

    for (const issue of issues) {
        if (!issue.measurements) continue;
        
        // Convert measurements object to Map if it's not already a Map
        const measurementsMap = issue.measurements instanceof Map ? 
            issue.measurements : 
            new Map(Object.entries(issue.measurements as Record<string, any>));

        for (const [key, value] of measurementsMap.entries()) {
            // Does the column already exist?
            const existingColumn = newColumns.find((column) => column.id === key);
            if (existingColumn) {
                continue;
            }
            newColumns.push({
                id: key,
                sortUndefined: 'last',
                header: ({column}) => (
                    <DataTableColumnHeader column={column} title={capitalizeFirstLetter(key)}/>
                ),
                filterFn: (row, id, value) => {
                    const cellValue = row.getValue(id);
                    if (typeof cellValue === 'number') {
                        // For numeric values, check if it's within the range
                        const [min, max] = value;
                        return cellValue >= min && cellValue <= max;
                    }
                    // For string values, use includes
                    return value.includes(cellValue);
                },
                accessorFn: (row) => {
                    if (!row.measurements) return undefined;
                    const measurements = row.measurements instanceof Map ? 
                        row.measurements : 
                        new Map(Object.entries(row.measurements as Record<string, any>));
                    return measurements.get(key);
                },
                enableResizing: true,
                // We bundle this in
                // @ts-ignore
                show: false,
                cell: ({row}) => {
                    if (!row.original.measurements) return null;
                    const measurements = row.original.measurements instanceof Map ? 
                        row.original.measurements : 
                        new Map(Object.entries(row.original.measurements as Record<string, any>));
                    const value = measurements.get(key);
                    if (typeof value === 'number') {
                        // Format number with 2 decimal places if it's a float
                        if (key.includes("bytes")) {
                            return <div>{prettyBytes(value)}</div>;
                        }
                        return <div>{Number.isInteger(value) ? value : value.toFixed(2)}</div>;
                    }
                    return <div>{value}</div>;
                },
            });
        }
    }

    return newColumns;
}

function capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}
