import { MetricType } from "../../pages/MetricsTest";
import {ServiceCPUTemplate} from "./templates/cpu-template";
import {dashboardJsonReviver} from "./Dashboard";
import {HTTPServerTemplate} from "./templates/http-server";
import {ServiceMemoryOverviewTemplate} from "./templates/service-memory-overview";
import {ServiceNetworkTemplate} from "./templates/network-template";
import {NodeTemplate} from "./templates/node-template";

interface MetricSpecifierConfig {
    metricName: string;
    aggregation: string;
    label?: string;
    metricType: MetricType;
    functions: any[];
}

interface ChartTemplate {
    name: string;
    config: {
        metricSpecifiers: MetricSpecifierConfig[];
        type: string;
        widgetType: string;
        title: string;
        position: {
            x: undefined;
            y: undefined;
            w: number;
            h: number;
        };
    };
}

// Predefined chart templates
export const chartTemplates: { [key: string]: ChartTemplate[] } = {
    cpu: [
        {
            name: "CPU Usage",
            config: {
                metricSpecifiers: [{
                    metricName: "container_cpu_usage_seconds_total",
                    aggregation: "rate",
                    label: "CPU Usage",
                    metricType: MetricType.Metric,
                    functions: []
                }],
                type: "line",
                widgetType: "MetricChart",
                title: "CPU Usage",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        },
        {
            name: "CPU Metrics Combined",
            config: {
                metricSpecifiers: [
                    {
                        metricName: "container_cpu_usage_seconds_total",
                        aggregation: "rate",
                        label: "CPU Usage",
                        metricType: MetricType.Metric,
                        functions: []
                    },
                    {
                        metricName: "container_cpu_throttling_seconds_total",
                        aggregation: "rate",
                        label: "CPU Throttling",
                        metricType: MetricType.Metric,
                        functions: []
                    }
                ],
                type: "line",
                widgetType: "MetricChart",
                title: "CPU Metrics",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        }
    ],
    memory: [
        {
            name: "Memory Usage",
            config: {
                metricSpecifiers: [{
                    metricName: "container_memory_usage_bytes",
                    aggregation: "avg",
                    label: "Memory Usage",
                    metricType: MetricType.Metric,
                    functions: []
                }],
                type: "line",
                widgetType: "MetricChart",
                title: "Memory Usage",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        },
        {
            name: "Memory Metrics Combined",
            config: {
                metricSpecifiers: [
                    {
                        metricName: "container_memory_usage_bytes",
                        aggregation: "avg",
                        label: "Memory Usage",
                        metricType: MetricType.Metric,
                        functions: []
                    },
                    {
                        metricName: "container_memory_working_set_bytes",
                        aggregation: "avg",
                        label: "Memory Working Set",
                        metricType: MetricType.Metric,
                        functions: []
                    }
                ],
                type: "line",
                widgetType: "MetricChart",
                title: "Memory Metrics",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        }
    ],
    network: [
        {
            name: "Network I/O",
            config: {
                metricSpecifiers: [
                    {
                        metricName: "container_network_transmit_bytes_total",
                        aggregation: "rate",
                        label: "Transmit",
                        metricType: MetricType.Metric,
                        functions: []
                    },
                    {
                        metricName: "container_network_receive_bytes_total",
                        aggregation: "rate",
                        label: "Receive",
                        metricType: MetricType.Metric,
                        functions: []
                    }
                ],
                type: "line",
                widgetType: "MetricChart",
                title: "Network I/O",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        },
        {
            name: "Active Connections",
            config: {
                metricSpecifiers: [{
                    metricName: "container_net_tcp_active_connections",
                    aggregation: "avg",
                    label: "Active Connections",
                    metricType: MetricType.Metric,
                    functions: []
                }],
                type: "line",
                widgetType: "MetricChart",
                title: "Active TCP Connections",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        }
    ],
    disk: [
        {
            name: "Disk I/O",
            config: {
                metricSpecifiers: [
                    {
                        metricName: "container_fs_reads_bytes_total",
                        aggregation: "rate",
                        label: "Read Bytes",
                        metricType: MetricType.Metric,
                        functions: []
                    },
                    {
                        metricName: "container_fs_writes_bytes_total",
                        aggregation: "rate",
                        label: "Write Bytes",
                        metricType: MetricType.Metric,
                        functions: []
                    }
                ],
                type: "line",
                widgetType: "MetricChart",
                title: "Disk I/O",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        }
    ],
    application: [
        {
            name: "HTTP Metrics Combined",
            config: {
                metricSpecifiers: [
                    {
                        metricName: "http_requests_total",
                        aggregation: "rate",
                        label: "Request Rate",
                        metricType: MetricType.Metric,
                        functions: []
                    },
                    {
                        metricName: "http_request_duration_seconds",
                        aggregation: "avg",
                        label: "Response Time",
                        metricType: MetricType.Metric,
                        functions: []
                    }
                ],
                type: "line",
                widgetType: "MetricChart",
                title: "HTTP Metrics",
                position: { x: undefined, y: undefined, w: 6, h: 2 }
            }
        }
    ]
};

// Predefined group templates
export const groupTemplates: { [key: string]: { name: string; config: any } } = {
    cpu: {
        name: "Service CPU Overview",
        config: JSON.parse(ServiceCPUTemplate, dashboardJsonReviver)
    },
    memory: {
        name: "Service Memory Overview",
        config: JSON.parse(ServiceMemoryOverviewTemplate, dashboardJsonReviver)
    },
    network: {
        name: "Service Network Overview",
        config: JSON.parse(ServiceNetworkTemplate, dashboardJsonReviver)
    },
    httpserver: {
        name: "HTTP Server Overview",
        config: JSON.parse(HTTPServerTemplate, dashboardJsonReviver)
    },
    node: {
        name: "Node Overview",
        config: JSON.parse(NodeTemplate, dashboardJsonReviver)
    }
};