import React, {useState} from "react";
import {DataTable} from "../ui/datatable/datatable";
import {columns, PodData, filters} from "./PodsTableColumns";
import {K8sPodDetails} from "./model";
import {GetPodsResponse} from "./KubernetesView";
import {V1ContainerState} from "@kubernetes/client-node";
import {SquareIcon} from "lucide-react";
import {Tooltip, TooltipContent, TooltipTrigger} from "components/ui/tooltip";
import {V1ContainerStatus} from "@kubernetes/client-node/dist/gen/model/v1ContainerStatus";
import hdate from "human-date";
import yaml from "js-yaml";
import {Drawer} from "vaul";
import {PodItemSideView} from "./PodItemSideView";
import {LoadingSpinner} from "../ui/customSpinner";

function statusBadgeColour(status: string) {
    if (status === "Running") {
        return "bg-secondarytransparent"
    } else if (status === "Waiting") {
        return "bg-amber-500"
    } else if (status === "Succeeded") {
        return "bg-primary"
    } else if (status === "Failed" || status === "Terminated") {
        return "bg-red-500"
    } else if (status === "Not Running") {
        return "bg-gray-500"
    } else if (status === "Unknown") {
        return "bg-gray-500"
    } else {
        return "bg-amber-500"
    }
}

function formatAge(startTime: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - startTime.getTime();
    const minutes = Math.floor(diffMs / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days === 1 ? '' : 's'}`;
    if (hours > 0) return `${hours} hr${hours === 1 ? '' : 's'}`;
    if (minutes > 0) return `${minutes} min${minutes === 1 ? '' : 's'}`;
    return `${Math.floor(diffMs / 1000)} seconds`;
}

function ContainerRepIcon(podState: string, state: V1ContainerState | undefined) {
    let dotColor = "gray-500"
    // Running state properties
    let stateString = ""
    let startedAt = ""
    // Waiting and Terminated state properties
    let reason = ""
    let message = ""
    // Terminated state properties
    let exitCode = ""
    let finishedAt = ""
    let signal = ""
    let containerID = ""

    // If pod is deleted then we cannot use the state object as its outdated.
    if (podState === "Not Running") {
        stateString = "Not Running"
        dotColor = "gray-500"
    } else {
        if (state === undefined) {
            stateString = "Unknown"
            // If the state is undefined, the pod is in an unknown state
        } else {
            if (state.running) {
                stateString = "Running"
                dotColor = "secondary"
                startedAt = hdate.prettyPrint(state.running.startedAt, {showTime: true})
            }
            if (state.waiting) {
                stateString = "Waiting"
                dotColor = "amber-500"
                reason = state.waiting.reason ? state.waiting.reason : ""
                message = state.waiting.message ? state.waiting.message : ""
            }
            if (state.terminated) {
                stateString = "Terminated"
                dotColor = "gray-500"
                reason = state.terminated.reason ? state.terminated.reason : ""
                message = state.terminated.message ? state.terminated.message : ""
                exitCode = state.terminated.exitCode ? state.terminated.exitCode.toString() : ""
                finishedAt = hdate.prettyPrint(state.terminated.finishedAt, {showTime: true})
                signal = state.terminated.signal ? state.terminated.signal.toString() : ""
                containerID = state.terminated.containerID ? state.terminated.containerID : ""
            }
        }
    }

    return <div>
        <Tooltip delayDuration={10}>
            <TooltipTrigger asChild>
                <SquareIcon className={`rounded bg-${dotColor} text-${dotColor} w-[12px] h-[12px] stroke-0`}/>
            </TooltipTrigger>
            <TooltipContent side={"bottom"} className={"m-4 p-4 bg-backgrounddark border rounded"}>
                <div className={"flex flex-col space-y-2"}>
                    <div className={"flex flex-row"}>
                        <div className={"text-textmedium font-semibold"}>State</div>
                        <div className={"text-textlight font-normal"}>: {stateString}</div>
                    </div>
                    {startedAt !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Started at</div>
                            <div className={"text-textlight font-normal"}>: {startedAt}</div>
                        </div>
                    }
                    {finishedAt !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Finished at</div>
                            <div className={"text-textlight font-normal"}>: {finishedAt}</div>
                        </div>
                    }
                    {containerID !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Container ID</div>
                            <div className={"text-textlight font-normal"}>: {containerID}</div>
                        </div>
                    }
                    {signal !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Signal</div>
                            <div className={"text-textlight font-normal"}>: {signal}</div>
                        </div>
                    }
                    {exitCode !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Exit code</div>
                            <div className={"text-textlight font-normal"}>: {exitCode}</div>
                        </div>
                    }
                    {reason !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Reason</div>
                            <div className={"text-textlight font-normal"}>: {reason}</div>
                        </div>
                    }
                    {message !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Message</div>
                            <div className={"text-textlight font-normal"}>: {message}</div>
                        </div>
                    }
                </div>
            </TooltipContent>
        </Tooltip>

    </div>
}

function getContainerRepresentation(podState: string, podYaml: string) {
    try {
        const pod = yaml.load(podYaml) as any;
        return (
            <div className="flex flex-row gap-1">
                {pod?.status?.containerStatuses?.map((container: V1ContainerStatus) => {
                    return ContainerRepIcon(podState, container.state)
                })}
            </div>
        )
    } catch (e) {
        console.error("Error parsing pod yaml:", e);
        return null;
    }
}

function transformPodData(podDetail: K8sPodDetails): PodData {
    try {
        const podYaml = podDetail.yaml;
        const pod = yaml.load(podYaml) as any;
        const status = podDetail.status || "Unknown";

        const creationTimestamp = pod?.metadata?.creationTimestamp;
        const startTime = creationTimestamp ? new Date(creationTimestamp) : new Date();
        const now = new Date();
        const ageInMs = now.getTime() - startTime.getTime();

        return {
            id: podDetail.uuid,
            name: podDetail.name,
            restarts: pod?.status?.containerStatuses?.reduce((acc: number, curr: V1ContainerStatus) => acc + (curr.restartCount || 0), 0) || 0,
            age: {
                display: creationTimestamp ? formatAge(startTime) : "",
                ageInMs: ageInMs
            },
            status: {
                value: status,
                display: status
            },
            containers: getContainerRepresentation(status, podYaml),
            created: {
                display: creationTimestamp ? hdate.prettyPrint(startTime, {showTime: true}) : "",
                timestamp: startTime.getTime()
            },
            environment: podDetail.environment,
            rawPod: pod,
            originalPodDetail: podDetail
        };
    } catch (e) {
        console.error("Error transforming pod data:", e);
        return {
            id: podDetail.uuid,
            name: podDetail.name,
            restarts: 0,
            age: {
                display: "",
                ageInMs: 0
            },
            status: {
                value: podDetail.status || "Unknown",
                display: podDetail.status || "Unknown"
            },
            created: {
                display: "",
                timestamp: 0
            },
            environment: podDetail.environment,
            rawPod: null,
            originalPodDetail: podDetail
        };
    }
}

export function PodsTable(props: {
    podResponse?: GetPodsResponse
    serviceName?: string
    nodeName?: string
}) {
    const [selectedPod, setSelectedPod] = useState<K8sPodDetails | null>(null);
    const transformedData: PodData[] = props.podResponse?.pods?.map(detail =>
        transformPodData(detail)
    ) || [];

    const onRowClick = (podData: PodData) => {
        setSelectedPod(podData.originalPodDetail);
    };

    return (
        <>
            <Drawer.Root
                modal={false}
                direction="right"
                open={selectedPod !== null}
                onOpenChange={(open) => !open && setSelectedPod(null)}
                dismissible={true}
            >
                <DataTable
                    tableTitle={"Pods"}
                    columns={columns(onRowClick, !props.nodeName)}
                    data={transformedData}
                    textFilteringColumnName={"name"}
                    textFilteringPlaceholder={"Filter pods by name..."}
                    filters={filters}
                    showPagination={true}
                    isLoading={!props.podResponse}
                />
                <Drawer.Portal>
                    <Drawer.Content
                        onInteractOutside={() => {
                            // let params = new URLSearchParams(searchParams);
                            // if (params.get("pod") === props.podDetail.name) {
                            //     params.delete("pod");
                            //     setSearchParams(params);
                            // }
                            setSelectedPod(null)
                        }}
                        className="select-text flex flex-col rounded-t-[10px] h-full md:w-2/3 lg:w-2/3 w-3/4 mt-12 fixed bottom-0 right-0">
                        {selectedPod && <PodItemSideView
                            podDetails={selectedPod}
                            indicatorColor={statusBadgeColour(selectedPod.status)}
                            serviceName={props.serviceName}
                            nodeName={props.nodeName}
                            podStatus={selectedPod.status}
                        />}
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
        </>
    );
}