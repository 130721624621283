import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UptimeMonitorChart } from '../components/Charts/UptimeMonitorChart';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { toast } from '../components/ui/use-toast';
import { PublicStatusPageResponse, getPublicStatusPage } from '../clients/metoro/status_page';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import logo from '../assets/images/logoColour.png';
import JWTContext from '../contexts/JWTContext';
import { JWTContextType } from '../types/auth';

interface TimeRange {
    label: string;
    shortLabel: string;
    value: string;
    duration: number; // in seconds
}

const TIME_RANGES: TimeRange[] = [
    { label: 'Last hour', shortLabel: '1h', value: '1h', duration: 60 * 60 },
    { label: 'Last 6 hours', shortLabel: '6h', value: '6h', duration: 6 * 60 * 60 },
    { label: 'Last 24 hours', shortLabel: '1d', value: '24h', duration: 24 * 60 * 60 },
    { label: 'Last 2 days', shortLabel: '2d', value: '2d', duration: 2 * 24 * 60 * 60 },
    { label: 'Last week', shortLabel: '7d', value: '7d', duration: 7 * 24 * 60 * 60 },
];

export default function PublicStatusPage() {
    const { org, slug } = useParams<{ org: string, slug: string }>();
    const [loading, setLoading] = useState(true);
    const [statusPage, setStatusPage] = useState<PublicStatusPageResponse | null>(null);
    const [selectedRange, setSelectedRange] = useState<string>('6h');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
    const { isLoggedIn } = useContext(JWTContext) as JWTContextType;
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 640);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Calculate time ranges
    const now = Date.now();
    const selectedDuration = TIME_RANGES.find(r => r.value === selectedRange)?.duration || 24 * 60 * 60;
    const startTime = now - selectedDuration * 1000;

    useEffect(() => {
        loadData();
    }, [slug, selectedRange]);

    const loadData = async () => {
        if (!slug || !org) return;

        setLoading(true);
        try {
            const response = await getPublicStatusPage(org, slug);
            setStatusPage(response);
        } catch (e: any) {
            console.error(e);
            // If we get a 404 and the user is not authenticated, redirect to login
            if (e.response?.status === 404 && !isLoggedIn) {
                toast({
                    title: "Authentication Required",
                    description: "Please log in to view this status page",
                    variant: "destructive",
                });
                // Store the current URL to redirect back after login
                localStorage.setItem('redirectAfterLogin', window.location.pathname);
                navigate('/login');
                return;
            }
            
            toast({
                title: "Error",
                description: "Failed to load status page",
                variant: "destructive",
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-background">
                <div className="container mx-auto p-6 flex justify-center">
                    <LoadingSpinner />
                </div>
                <div className="container mx-auto p-4">
                    <div className="flex justify-center items-center text-sm text-textdark">
                        Powered by <a href="https://metoro.io" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary/90 ml-1">Metoro</a>
                    </div>
                </div>
            </div>
        );
    }

    if (!statusPage) {
        return (
            <div className="min-h-screen bg-background">
                <div className="container mx-auto p-6">
                    <h1 className="text-2xl font-bold text-textlight">Status Page Not Found</h1>
                </div>
                <div className="container mx-auto p-4">
                    <div className="flex justify-center items-center text-sm text-textdark">
                        Powered by <a href="https://metoro.io" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary/90 ml-1">Metoro</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-background relative">
            <div className="hidden lg:flex items-center gap-2 fixed top-6 left-6">
                <a href="https://metoro.io" target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 hover:opacity-90">
                    <img src={logo} alt="Metoro" className="w-8 h-8" />
                    <span className="text-textlight font-medium">Metoro</span>
                </a>
            </div>

            <div className="w-[95%] sm:container mx-auto lg:max-w-[60%]">
                <div className="p-2 sm:p-6">
                    <div className="mb-6">
                        <div className="flex justify-between items-center">
                            <h1 className="text-2xl font-bold text-textlight">{statusPage.name}</h1>
                            <div className="ml-4">
                                <Select value={selectedRange} onValueChange={setSelectedRange}>
                                    <SelectTrigger className="w-[70px] sm:w-[180px] bg-backgroundlight border-border text-textmedium">
                                        <SelectValue placeholder="Select time range">
                                            {isMobile 
                                                ? TIME_RANGES.find(r => r.value === selectedRange)?.shortLabel
                                                : TIME_RANGES.find(r => r.value === selectedRange)?.label
                                            }
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent className="bg-backgroundlight border-border">
                                        {TIME_RANGES.map((range) => (
                                            <SelectItem 
                                                key={range.value} 
                                                value={range.value}
                                                className="text-textmedium hover:bg-backgroundmedium cursor-pointer"
                                            >
                                                {isMobile ? range.shortLabel : range.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        {statusPage.description && (
                            <p className="text-textdark mt-2">{statusPage.description}</p>
                        )}
                    </div>

                    <div className="space-y-6">
                        {statusPage.monitors.map((monitor) => (
                            <UptimeMonitorChart
                                key={monitor.uuid}
                                title={monitor.name}
                                statusMetricName={monitor.statusMetricName}
                                responseTimeMetricName={monitor.responseTimeMetricName}
                                startTime={Math.floor(startTime / 1000)}
                                endTime={Math.floor(now / 1000)}
                                org={statusPage.organizationUuid}
                            />
                        ))}
                    </div>
                </div>

                <div className="p-4 border-t border-border mt-8">
                    <div className="flex justify-center items-center text-sm text-textdark">
                        Powered by <a href="https://metoro.io" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary/90 ml-1">Metoro</a>
                    </div>
                </div>
            </div>
        </div>
    );
} 