import React from 'react'
import {Provider} from 'react-redux'
import RootComponent from './RootComponent'
import {store} from './store/reducers/store'
import {JWTProvider as AuthProvider} from 'contexts/JWTContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './styles/visavail.css';

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <RootComponent />
                </AuthProvider>
            </QueryClientProvider>
        </Provider>
    )
}

export default App
