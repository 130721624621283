import React, {useEffect, useState} from 'react'
import {GridStack} from "gridstack";
import {Button} from "../ui/button";
import {MultiMetricChartWidget, OldMetricChartWidget, Widget} from "./internalwidgets";
import type {GroupWidget} from "./internalwidgets";
import {recursiveSave} from "./utils";
import {Group} from "./widgets/Group";
import {URLSearchParamsInit, useNavigate, useSearchParams} from "react-router-dom";
import axios from "../../utility/customAxios";
import {v4 as uuidv4} from 'uuid';
import {MdContentCopy, MdModeEdit, MdOutlineSave, MdSettings} from "react-icons/md";
import {useDebouncedCallback} from "use-debounce";
import {NavigateOptions} from "react-router/dist/lib/context";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "../ui/dialog";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../ui/tooltip";
import {cn} from "../ui/lib/utils";
import {useDispatch} from 'react-redux'
import {set} from '../../store/reducers/timerange'
import {TimeRange} from "../../types/time"
import {SingleSelectDropdown} from "../Input/SingleSelectDropdown";
import {DropDownItem} from "../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import {Download, Eye, Info, SaveIcon} from "lucide-react";
import {useToast} from "../ui/use-toast";
import {Input} from "../ui/input";
import {boolean, string} from "yup";
import {usePreserveQueryParamsNavigate} from "../ui/lib/utils";
import {Textarea} from "../ui/textarea";
import {toast} from "../ui/use-toast";
import {Label} from "../ui/label";
import { convertGrafanaDashboardToMetoro } from './migrations/grafanaToMetoro';

interface DashboardProps {
    setDashboardTitle?: (title: string) => void
    dashboardTitle?: string
    onRuntimeVariablesChange?: (variables: Map<string, RuntimeVariable[]>) => void
}

const validWidgetTypes = ['MetricChart', 'LogChart', 'TraceChart', 'Group', 'Markdown'] as const;
type ValidWidgetType = typeof validWidgetTypes[number];

interface ImportJsonDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onImport: (parsedJson: any) => void;
}

function updateDashboard(searchParams: URLSearchParams,
                         setSearchParams: (nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void,
                         setInitialWidget: (value: (((prevState: (GroupWidget | undefined)) => (GroupWidget | undefined)) | GroupWidget | undefined)) => void,
                         setEditable: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                         setShowNewChartInjectedDialog: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                         setSelectedDefaultTimeRange: (value: (((prevState: string | undefined) => string | undefined) | string | undefined)) => void,
) {
    if (!searchParams.has("dashboardId")) {
        setSearchParams((prev) => {
            let urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set("dashboardId", uuidv4())
            return urlSearchParams
        })
    }
    const injectedChartJson = searchParams.get("addChart")
    const injectedChart = injectedChartJson ? JSON.parse(injectedChartJson, dashboardJsonReviver) as MultiMetricChartWidget : undefined

    if (searchParams.get("dashboardId") !== "new") {
        axios.get(`/api/v1/dashboard?dashboardId=${searchParams.get("dashboardId")}`).then((response) => {
            const data = JSON.parse(response.data.dashboardJson, dashboardJsonReviver) as GroupWidget
            if (data.widgetType === undefined) {
                toast({
                    className: "bg-red-500/20 border border-red-500 text-textmedium rounded",
                    title: "Invalid Dashboard Configuration",
                    description: "Dashboard widget type is undefined. Defaulting to an empty dashboard configuration",
                });
                throw new Error("Dashboard widget type is undefined");
            }
            
            if (!validWidgetTypes.includes(data.widgetType as ValidWidgetType)) {
                toast({
                    className: "bg-red-500/20 border border-red-500 text-textmedium rounded",
                    title: "Invalid Dashboard Configuration",
                    description: `Invalid widget type: ${data.widgetType}. Defaulting to an empty dashboard configuration`,
                });
                throw new Error(`Invalid widget type: ${data.widgetType}`);
            }
            // If we have an injected chart, add it to the root group
            if (injectedChart) {
                data.children.push(injectedChart)
                setEditable(true)
                setShowNewChartInjectedDialog(true)
            }
            // Set default time range from API response
            if (response.data.defaultTimeRange) {
                setSelectedDefaultTimeRange(response.data.defaultTimeRange)
            }
            setInitialWidget(data)
            // Remove the injected chart from the URL
            setSearchParams((prev) => {
                let urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.delete("addChart")
                return urlSearchParams
            })
        }).catch((e) => {
            setInitialWidget({
                widgetType: "Group",
                title: "New Dashboard",
                position: {
                    "x": 0,
                    "y": 0,
                    "w": 12,
                    "h": 12
                },
                children: []
            } as GroupWidget)
        })
    }
}

export function ButtonWithTooltip({icon: Icon, tooltipText, onClick, highlightColour}: {
    icon: React.ElementType,
    tooltipText: string,
    onClick: () => void,
    highlightColour?: string
}) {
    return (
        <TooltipProvider>
            <Tooltip delayDuration={10}>
                <TooltipTrigger asChild>
                    <Button
                        className={cn(`flex items-center justify-center text-textmedium hover:cursor-pointer`, "hover:text-secondary", highlightColour ? `hover:text-${highlightColour}` : "")}
                        variant={"ghost"}
                        size={"icon"}
                        onClick={onClick}>
                        <Icon className={`w-4 h-4 text`}/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent className={"bg-backgroundmedium border rounded text-textmedium p-1"}>
                    <p className="text-textmedium">{tooltipText}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}


export interface RuntimeVariable {
    name: string,
    key: string,
    value: string
}

function CloneDashboardDialog(props: {
    open: boolean,
    onOpenChange: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    cloneDashboard?: (dashboardName: string) => void
}) {
    let [newDashboardName, setNewDashboardName] = React.useState<string>("")
    return <Dialog open={props.open} onOpenChange={props.onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle className="text-textlight">Clone Dashboard</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <div className="col-span-4">
                        <Input
                            placeholder="Enter new dashboard name"
                            value={newDashboardName}
                            onChange={(e) => setNewDashboardName(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button
                    className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover"}
                    onClick={() => {
                        if (props.cloneDashboard) {
                            props.cloneDashboard(newDashboardName)
                        }
                    }}>
                    Clone
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>;
}

function Dashboard(props: DashboardProps) {
    const [grids, setGrids] = React.useState<Map<string, GridStack>>(new Map<string, GridStack>())
    const [widgets, setWidgets] = React.useState<Map<string, Widget>>(new Map<string, Widget>())
    const [runtimeVariables, setRuntimeVariables] = React.useState<Map<string, RuntimeVariable[]>>(new Map<string, RuntimeVariable[]>())
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialWidget, setInitialWidget] = React.useState<GroupWidget>()
    const [editable, setEditable] = React.useState<boolean>(false)
    const [showNewChartInjectedDialog, setShowNewChartInjectedDialog] = React.useState<boolean>(false)
    const [showSettingsDialog, setShowSettingsDialog] = React.useState<boolean>(false)
    const [selectedDefaultTimeRange, setSelectedDefaultTimeRange] = React.useState<string | undefined>()
    const [showCloneDashboardDialog, setShowCloneDashboardDialog] = React.useState<boolean>(false)
    const [showJsonPreview, setShowJsonPreview] = useState(false);
    const [initialDashboardJson, setInitialDashboardJson] = useState<string>("");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [showImportDialog, setShowImportDialog] = useState(false);
    const debouncedUpdateDasboard = useDebouncedCallback(updateDashboard, 10)
    const [gridStyle, setGridStyle] = useState({})
    const dispatch = useDispatch()
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const {toast} = useToast()
    const navigate = usePreserveQueryParamsNavigate();

    // Notify parent component when runtime variables change
    useEffect(() => {
        if (props.onRuntimeVariablesChange) {
            props.onRuntimeVariablesChange(runtimeVariables);
        }
    }, [runtimeVariables, props.onRuntimeVariablesChange]);

    const handleImport = (parsedJson: any) => {
        setInitialWidget(parsedJson);
        setHasUnsavedChanges(true);
        setShowSettingsDialog(false);
        setEditable(true);
        toast({
            title: "Dashboard imported successfully",
            description: "The dashboard has been imported. Don't forget to save your changes.",
            duration: 3000,
        });
    };

    const timeRangeOptions: DropDownItem[] = [
        {displayName: "Last 15 Minutes", value: "15m"},
        {displayName: "Last 30 Minutes", value: "30m"},
        {displayName: "Last 1 Hour", value: "1h"},
        {displayName: "Last 3 Hours", value: "3h"},
        {displayName: "Last 6 Hours", value: "6h"},
        {displayName: "Last 12 Hours", value: "12h"},
        {displayName: "Last 24 Hours", value: "24h"},
        {displayName: "Last 2 Days", value: "2d"},
        {displayName: "Last 3 Days", value: "3d"},
        {displayName: "Last 7 Days", value: "7d"},
        {displayName: "Last 30 Days", value: "30d"},
        {displayName: "No Default", value: ""}
    ];

    // When the dashboard editing mode changes, update the gridstacks so that they can be edited / resized / moved
    useEffect(() => {
        grids.forEach((grid) => {
            grid.setStatic(!editable)
        })
    }, [editable])

    useEffect(() => {
        const gridStyleToSet = {
            backgroundColor: 'var(--background-dark)',
            backgroundSize: `calc(100% / 12) 128px`, // Hardcoded cell height for the grid cells.
            backgroundImage: `
      linear-gradient(to right, var(--primary-transparent) 1px, transparent 1px),
      linear-gradient(to bottom, var(--primary-transparent) 1px, transparent 1px)
    `,
        };
        if (editable) {
            setGridStyle(gridStyleToSet)
        } else {
            setGridStyle({})
        }
    }, [editable]);

    useEffect(() => {
        if (props.setDashboardTitle) {
            let title = (widgets.get("id-root") as GroupWidget)?.title
            if (title) {
                props.setDashboardTitle(title);
            }
        }
    }, [widgets])

    // Pull the configuration from the backend
    useEffect(() => {
        debouncedUpdateDasboard(searchParams, setSearchParams, setInitialWidget, setEditable, setShowNewChartInjectedDialog, setSelectedDefaultTimeRange);
    }, [searchParams.get("dashboardId")])

    // Store initial dashboard JSON when it's loaded
    useEffect(() => {
        if (initialWidget && widgets.has("id-root")) {
            const initialJson = JSON.stringify(initialWidget, dashboardJsonReplacer);
            setInitialDashboardJson(initialJson);
            setIsInitialLoadComplete(true);
        }
    }, [initialWidget, widgets]);

    // Reset initial load state when dashboard changes
    useEffect(() => {
        setIsInitialLoadComplete(false);
    }, [searchParams.get("dashboardId")]);

    // Check for unsaved changes whenever widgets or grids change
    useEffect(() => {
        if (isInitialLoadComplete && initialDashboardJson && widgets.has("id-root")) {
            const currentWidget = recursiveSave("id-root", widgets, grids);
            const currentJson = JSON.stringify(currentWidget, dashboardJsonReplacer);
            setHasUnsavedChanges(currentJson !== initialDashboardJson);
        }
    }, [widgets, grids, initialDashboardJson, isInitialLoadComplete]);

    // Update selected time range item when initialWidget changes
    useEffect(() => {
        // Show toast notification when time range is set and update the current time
        if (selectedDefaultTimeRange) {
            dispatch(set(new TimeRange(selectedDefaultTimeRange, undefined, undefined)))
            toast({
                className: "text-textlight",
                title: "Time Range Updated",
                description: `Dashboard time range has been set to the default dashboard time range: ${selectedDefaultTimeRange}`,
                duration: 3000, // 3 seconds
            })
        }
    }, [selectedDefaultTimeRange])

    const handleSaveSettings = () => {
        if (initialWidget) {
            // Update the widget in the backend
            axios.post(`/api/v1/dashboard`, {
                id: searchParams.get("dashboardId"),
                name: props.dashboardTitle,
                dashboardJson: JSON.stringify(initialWidget, dashboardJsonReplacer),
                defaultTimeRange: selectedDefaultTimeRange,
            }).then(() => {
                setShowSettingsDialog(false)
            })
        }
    }

    let cloneDashboardFunction = (dashboardName: string) => {
        (widgets.get("id-root") as GroupWidget).title = dashboardName
        let widget = recursiveSave("id-root", widgets, grids);
        let dashboardJson = JSON.stringify(widget, dashboardJsonReplacer);
        let newUuid = uuidv4();
        axios.post(`/api/v1/dashboard`, {
            name: dashboardName,
            id: newUuid,
            dashboardJson: dashboardJson,
            defaultTimeRange: selectedDefaultTimeRange
        }).then(() => {
            navigate(`/dashboard?dashboardId=${newUuid}`)
        }).catch((e) => {
            console.error(e);
        });
    }

    // Update initial JSON after saving
    const handleSave = () => {
        let widget = recursiveSave("id-root", widgets, grids);
        let dashboardJson = JSON.stringify(widget, dashboardJsonReplacer);
        axios.post(`/api/v1/dashboard`, {
            name: props.dashboardTitle,
            id: searchParams.get("dashboardId"),
            dashboardJson: dashboardJson,
            defaultTimeRange: selectedDefaultTimeRange
        }).then(() => {
            setEditable(false);
            setInitialDashboardJson(dashboardJson); // Update initial JSON after successful save
            setHasUnsavedChanges(false);
            // We do this so that newly placed charts update their runtime variables with the correct
            // parent group chart
            setTimeout(() => {
                setRuntimeVariables(prev => new Map(prev))
            }, 1000)
        }).catch((e) => {
            console.error(e);
        });
    };

    let saveButton = (
        <div className="flex gap-2 items-center">
            <ButtonWithTooltip
                icon={MdOutlineSave}
                tooltipText="Save current dashboard configuration"
                onClick={handleSave}
            />
            <ButtonWithTooltip
                icon={MdSettings}
                tooltipText="Dashboard Settings"
                onClick={() => setShowSettingsDialog(true)}
            />
        </div>
    );

    let editButton = (
        <ButtonWithTooltip
            icon={MdModeEdit}
            tooltipText={editable ? "Stop Editing" : "Edit Dashboard"}
            onClick={() => {
                setEditable(!editable);
            }}
            highlightColour={editable ? "red-500" : undefined}
        />
    );

    let cloneDashboardButton = (
        <ButtonWithTooltip
            icon={MdContentCopy}
            tooltipText="Clone Dashboard"
            onClick={() => {
                setShowCloneDashboardDialog(true);
            }}
        />
    );

    if (!initialWidget) {
        return <div className={"text-textdark"}>Loading...</div>
    }

    let settingsDialog = <Dialog open={showSettingsDialog} onOpenChange={setShowSettingsDialog}>
        <DialogContent className="sm:max-w-[800px] max-h-[80vh] overflow-y-auto">
            <DialogHeader>
                <DialogTitle className="text-2xl font-normal text-textlight">Dashboard Settings</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col space-y-6 py-4">
                <div className="bg-backgroundmedium rounded border border-border p-6">
                    <div className="flex flex-col gap-4">
                        <div className="flex justify-between items-center">
                            <div className={"flex flex-col gap-2"}>
                                <div className="text-textmedium text-lg">Export Dashboard Json</div>
                                <div className="text-textdark mr-4">Export the current dashboard configuration as a JSON file. This exported JSON can be imported into another dashboard.</div>
                            </div>
                            <div className="flex gap-2">
                                <Button
                                    onClick={() => {
                                        setShowJsonPreview(!showJsonPreview);
                                    }}
                                    className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 h-9 flex gap-2 items-center"
                                >
                                    <Eye className="h-4 w-4" />
                                    {showJsonPreview ? 'Hide JSON' : 'View JSON'}
                                </Button>
                                <Button
                                    onClick={() => {
                                        let widget = recursiveSave("id-root", widgets, grids);
                                        const blob = new Blob([JSON.stringify(widget, dashboardJsonReplacer, 2)], { type: 'application/json' });
                                        const url = window.URL.createObjectURL(blob);
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.download = `${props.dashboardTitle?.toLowerCase().replace(/\s+/g, '-')}-dashboard.json`;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                    }}
                                    className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 h-9 flex gap-2 items-center"
                                >
                                    <Download className="h-4 w-4" />
                                    Export JSON
                                </Button>
                            </div>
                        </div>
                        {showJsonPreview && (
                            <div className="mt-4">
                                <Textarea
                                    value={JSON.stringify(recursiveSave("id-root", widgets, grids), dashboardJsonReplacer, 2)}
                                    readOnly
                                    className="min-h-[300px] max-h-[500px] font-mono text-sm bg-background border border-border text-textmedium p-4 overflow-y-auto"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-backgroundmedium rounded border border-border p-6">
                    <div className="flex justify-between items-center">
                        <div className={"flex flex-col gap-2"}>
                            <div className="text-textmedium text-lg">Import Dashboard Json</div>
                            <div className="text-textdark mr-4">Import a dashboard configuration from a JSON file.</div>
                        </div>
                        <Button
                            onClick={() => setShowImportDialog(true)}
                            className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 h-9"
                        >
                            Import JSON
                        </Button>
                    </div>
                </div>

                <div className="bg-backgroundmedium rounded border border-border p-6">
                    <div className="flex justify-between items-center">
                        <div className="flex flex-col gap-2">
                            <span className="text-textmedium text-lg">Set Default Time Range</span>
                            <div className={"text-textdark mr-4"}>Set the default time range for this dashboard when it
                                is first loaded</div>
                        </div>
                        <div className="w-max">
                            <SingleSelectDropdown
                                selectedItemTitle="Time Range"
                                selectedItem={timeRangeOptions.find(item => item.value === selectedDefaultTimeRange) || timeRangeOptions.find(item => item.value === "") || timeRangeOptions[0]}
                                possibleItems={timeRangeOptions}
                                setSelectedItem={(item) => {
                                    setSelectedDefaultTimeRange(item.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button
                    className={"bg-primarytransparent border border-primary text-textlight hover:border-primaryhover"}
                    onClick={handleSaveSettings}
                >
                    Save Changes
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>;

    let injectedChartDialog = <Dialog open={showNewChartInjectedDialog}>
        <DialogContent
            className={"w-1/3 text-textmedium"}
            onInteractOutside={() => setShowNewChartInjectedDialog(false)}>
            <DialogTitle>
                You added a new chart
            </DialogTitle>
            <DialogDescription>
                The chart has been added to the bottom of the dashboard. Make sure you save to keep it.
            </DialogDescription>
        </DialogContent>
    </Dialog>;

    let cloneDashboardDialog = <CloneDashboardDialog
        cloneDashboard={cloneDashboardFunction}
        open={showCloneDashboardDialog}
        onOpenChange={setShowCloneDashboardDialog}/>;

    return (
        <div className="flex flex-col grow overflow-y-auto">
            {isInitialLoadComplete && hasUnsavedChanges && (
                <div className="bg-amber-500/10 border border-amber-500/50 px-4 py-2 mx-4 mt-4 flex items-center justify-center gap-2">
                    <Info className="h-4 w-4 text-amber-500" />
                    <div className={"flex gap-1 items-center"}>
                        <div className="text-amber-500 text-sm">You have unsaved changes. Please use
                            the </div>
                        <SaveIcon className={"w-4 h-4 text-amber-500 text-sm"}/>
                        <div className={"text-amber-500 text-sm"}>icon on the right to save.</div>
                    </div>
                </div>
            )}
            <Group
                stylesheet={gridStyle}
                editable={editable}
                editButton={editButton}
                saveButton={editable ? saveButton : undefined}
                className={"w-full h-full"}
                id={"id-root"} 
                widget={initialWidget}
                variables={initialWidget.variables}
                runtimeVariables={runtimeVariables}
                setRuntimeVariables={setRuntimeVariables}
                grids={grids} 
                setGrids={setGrids} 
                widgets={widgets} 
                setWidgets={setWidgets}
                cloneDashboardButton={editable ? cloneDashboardButton : undefined}
                setEdit={setEditable}
            />
            {settingsDialog}
            {cloneDashboardDialog}
            {injectedChartDialog}
            <ImportJsonDialog 
                open={showImportDialog} 
                onOpenChange={setShowImportDialog}
                onImport={handleImport}
            />
        </div>
    )
}

function ImportJsonDialog({ open, onOpenChange, onImport }: ImportJsonDialogProps) {
    const [jsonInput, setJsonInput] = useState<string>("");
    const [jsonError, setJsonError] = useState<string | null>(null);
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const [lineCount, setLineCount] = useState<number>(1);
    const lineNumbersRef = React.useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const [importType, setImportType] = useState<'metoro' | 'grafana'>('metoro');

    const handleJsonInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setJsonInput(newValue);
        updateLineCount(newValue);

        if (newValue.trim() === '') {
            setJsonError(null);
            return;
        }

        try {
            JSON.parse(newValue);
            setJsonError(null);
        } catch (e) {
            if (e instanceof Error) {
                setJsonError(e.message);
            } else {
                setJsonError("Invalid JSON format");
            }
        }
    };

    const validateGrafanaJson = (json: any): boolean => {
        if (!json.panels || !Array.isArray(json.panels)) {
            setJsonError("Invalid Grafana dashboard: Missing or invalid 'panels' array");
            return false;
        }
        return true;
    };

    const handleImportJson = () => {
        try {
            const parsedJson = JSON.parse(jsonInput);
            const currentDashboardId = searchParams.get("dashboardId");
            
            // Validate Grafana JSON if that's the selected type
            if (importType === 'grafana' && !validateGrafanaJson(parsedJson)) {
                return;
            }

            // Convert Grafana JSON if needed
            let finalJson;
            try {
                finalJson = importType === 'grafana' ? 
                    convertGrafanaDashboardToMetoro(parsedJson) : 
                    parsedJson;
            } catch (e) {
                setJsonError("Failed to convert Grafana dashboard: " + (e instanceof Error ? e.message : "Unknown error"));
                return;
            }
            
            const dashboardConfig = {
                id: currentDashboardId,
                name: finalJson.title || "New Dashboard",
                dashboardJson: JSON.stringify(finalJson, dashboardJsonReplacer),
                defaultTimeRange: "1h" // Default time range for new dashboards
            };

            axios.post('/api/v1/dashboard', dashboardConfig)
                .then(() => {
                    setJsonInput("");
                    setJsonError(null);
                    onOpenChange(false);
                    toast({
                        title: "Dashboard imported successfully",
                        description: `Successfully imported ${importType === 'grafana' ? 'Grafana' : 'Metoro'} dashboard.`,
                        className: "bg-green-500/20 border border-green-500 text-textmedium rounded",
                    });
                    // Reload the page to fetch fresh dashboard data
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Failed to import dashboard:', error);
                    setJsonError("Failed to import dashboard: " + (error.response?.data?.message || "Unknown error"));
                });
        } catch (e) {
            setJsonError("Failed to import dashboard: " + (e instanceof Error ? e.message : "Invalid JSON"));
        }
    };

    const updateLineCount = (text: string) => {
        const lines = text.split('\n').length;
        setLineCount(Math.max(lines, 1));
    };

    const handleScroll = () => {
        if (lineNumbersRef.current && textareaRef.current) {
            lineNumbersRef.current.scrollTop = textareaRef.current.scrollTop;
        }
    };

    const formatJson = () => {
        try {
            if (!jsonInput.trim()) return;
            const parsed = JSON.parse(jsonInput);
            const formatted = JSON.stringify(parsed, null, 2);
            setJsonInput(formatted);
            updateLineCount(formatted);
            setJsonError(null);

            // Scroll both containers to the top
            if (textareaRef.current) {
                textareaRef.current.scrollTop = 0;
            }
            if (lineNumbersRef.current) {
                lineNumbersRef.current.scrollTop = 0;
            }
        } catch (e) {
            if (e instanceof Error) {
                setJsonError(e.message);
            } else {
                setJsonError("Invalid JSON format");
            }
        }
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[800px] max-h-[80vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-normal text-textlight">Import Dashboard</DialogTitle>
                    <DialogDescription className="text-textmedium">
                        Paste your dashboard JSON configuration below.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4">
                    <div className="flex items-center gap-4">
                        <Label className={"text-textdark"}>Import Type</Label>
                        <div className="flex gap-4">
                            <Button
                                onClick={() => setImportType('metoro')}
                                className={cn(
                                    "bg-primarytransparent border text-textmedium hover:text-textlight rounded px-4 h-9",
                                    importType === 'metoro' ? "border-primary" : "border-border"
                                )}
                            >
                                Metoro JSON
                            </Button>
                            <Button
                                onClick={() => setImportType('grafana')}
                                className={cn(
                                    "bg-primarytransparent border text-textmedium hover:text-textlight rounded px-4 h-9",
                                    importType === 'grafana' ? "border-primary" : "border-border"
                                )}
                            >
                                Grafana JSON
                            </Button>
                        </div>
                    </div>
                    {importType === 'grafana' && (
                        <div className="flex items-center gap-2 p-2 bg-amber-500/10 border border-amber-500/50 rounded">
                            <Info className="h-4 w-4 text-amber-500" />
                            <p className="text-amber-500 text-sm">
                                Grafana import is in Alpha. It provides a best-effort conversion that should give you a good starting point, but manual adjustments may be needed.
                            </p>
                        </div>
                    )}
                    <div className="flex justify-between items-center">
                        <Label className={"text-textdark"}>Dashboard JSON</Label>
                        <Button
                            onClick={formatJson}
                            disabled={!jsonInput.trim()}
                            className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 h-9"
                        >
                            Format JSON
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex relative h-[300px]">
                            <div
                                ref={lineNumbersRef}
                                className="line-numbers font-mono text-sm text-textdark bg-backgroundmedium border border-r-0 border-border rounded-l overflow-y-hidden"
                                style={{
                                    padding: "16px",
                                    lineHeight: "21px",
                                    height: "100%",
                                    minWidth: "40px"
                                }}
                            >
                                {Array.from({length: lineCount}, (_, i) => (
                                    <div key={i + 1} className="select-none h-[21px]">
                                        {i + 1}
                                    </div>
                                ))}
                            </div>
                            <Textarea
                                ref={textareaRef}
                                datatype={"json"}
                                placeholder={`Paste your ${importType === 'metoro' ? 'Metoro' : 'Grafana'} dashboard JSON here`}
                                value={jsonInput}
                                onChange={handleJsonInputChange}
                                onScroll={handleScroll}
                                className={"json-textarea border border-border text-textmedium font-mono rounded flex-1 overflow-y-auto resize-none"}
                                style={{
                                    padding: "16px",
                                    lineHeight: "21px",
                                    fontSize: "14px",
                                    height: "100%"
                                }}
                            />
                        </div>
                        {jsonError && (
                            <div className="text-red-500 text-sm mt-2">{jsonError}</div>
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        onClick={handleImportJson}
                        disabled={!!jsonError || !jsonInput.trim()}
                        className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded px-4 h-9"
                    >
                        Import {importType === 'metoro' ? 'Metoro' : 'Grafana'} JSON
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

// @ts-ignore
export function dashboardJsonReplacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

// @ts-ignore
export function dashboardJsonReviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}


export {Dashboard};
export type {GroupWidget};
