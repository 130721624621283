import {MetricFunction} from "../Dashboarding/widgets/MetricSelector";
import {RuntimeVariable} from "../Dashboarding/Dashboard";
import React, {useEffect, useRef, useState} from "react";
import {TimeRange} from "../../types/time";
import {ChartStyling, ChartType, MetoroChart} from "./MetoroChart";
import {
    GetKubernetesMetricsRequest,
    GetMetricRequest,
    GetMultiMetrics,
    GetTraceMetricRequest,
    Metric,
    MetricVisualization,
    SingleMetricRequest
} from "../../clients/metoro/metrics";
import {formatFilterValues} from "../Filter/Filter";
import {ThresholdComparator} from "../Alert/AlertMetricSelector";
import {substituteVariablesInFilters} from "./utils";
import {AlertType, ApiServerAlert} from "../../pages/AlertCreation";
import {AggregationFunction, EvalType, MissingDatapointBehavior, WindowUnit} from "../../pages/alerts/MetricAlert";
import {useDebouncedCallback} from "use-debounce";
import {ChartComparisonDialog} from "../ChartComparisonDialog";
import {LoadingSpinner} from "../ui/customSpinner";
import isEqual from "lodash/isEqual";
import {MultiMetricChartWidget} from "../Dashboarding/internalwidgets";
import {Formula} from "../../types/formula";
import {toast} from "../../components/ui/use-toast";


interface MetricSpecifier {
    metricName: string;
    filters?: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits?: string[];
    aggregation: string;
    bucketSize?: number;
    functions: MetricFunction[];
    metricType: MetricType;
    visualization?: MetricVisualization
    jsonPath?: string
    shouldNotReturn?: boolean;
}

export interface MultiMetoroMetricsChartProps {
    // The epoch time in seconds of the start of the time range
    startTime: number;
    // The epoch time in seconds of the end of the time range
    endTime: number;
    // The title of the chart
    title?: string;
    // The actual metrics to show on the chart
    metricSpecifiers: MetricSpecifier[];
    // The type of the chart
    type: ChartType;
    // Hide on no data
    hideOnNoData?: boolean;
    // Extra styling options for the chart
    styling?: ChartStyling;
    // Class name overrides for the chart container on the inside
    className?: string;
    // threshold if defined
    threshold?: Threshold;
    // threshold label if defined
    thresholdLabel?: string;
    // highlight time with vertical line annotations
    timePeriodHighlight?: TimePeriod;
    // hide the legend
    hideLegend?: boolean;
    // Any variables that are in scope, this will be used to substitute the variables in the filters
    variables?: RuntimeVariable[];
    setTimeRange?: React.Dispatch<React.SetStateAction<TimeRange>>;
    // Formulas to apply to the metrics
    formulas?: Formula[];
}

function isMulti(obj: any): obj is MultiMetoroMetricsChartProps {
    return obj["metricSpecifiers"] !== undefined;
}


export interface MathExpression {
    variables: string[];
    expression: string;
}

// A variable in a math expression
interface MathExpressionVariable {
    // variable name, e.g. a, b, c, etc.
    name: string;
    // value is the name of the metric this variable represents
    // e.g. a / 60 where a = requests_total metric.
    value: string;
}

interface ColorPairing {
    backgroundColor: string;
    borderColor: string;
}

const colorings: ColorPairing[] = [
    {
        borderColor: "#3793ff",
        backgroundColor: "#3793ff33",
    },
    {
        borderColor: "#ff6384",
        backgroundColor: "#ff638433",
    },
    {
        borderColor: "#ffce56",
        backgroundColor: "#ffce5633",
    },
    {
        borderColor: "#4bc0c0",
        backgroundColor: "#4bc0c033",
    },
    {
        borderColor: "#ff9f40",
        backgroundColor: "#ff9f4033",
    },
    {
        borderColor: "#ff66ff",
        backgroundColor: "#ff66ff33",
    },
    {
        borderColor: "#ffcd56",
        backgroundColor: "#ffcd5633",
    },

    {
        borderColor: "#36a2eb",
        backgroundColor: "#36a2eb33",
    },
]

function MetricsToChartData(metrics: Metric[] | undefined, isTime: boolean, colorBackground: string | undefined = undefined, colorBorder: string | undefined = undefined, customColours: Map<Record<string, string>, string[]> | undefined = undefined): any {
    if (metrics === undefined) {
        return undefined;
    }
    let data = [];
    let i = 0;
    for (let metric of metrics) {
        // Sorted by total value of the time series
        let sorted = metric.timeSeries.sort((a, b) => {
            let totalA = a.data.reduce((acc, curr) => {
                return acc + curr.value
            }, 0)
            let totalB = b.data.reduce((acc, curr) => {
                return acc + curr.value
            }, 0)
            return totalB - totalA
        })

        for (let timeSeries of sorted) {
            let label = metric.metricVisualization?.displayName ? metric.metricVisualization.displayName + " " : ""
            const entries = Object.entries(timeSeries.attributes)
            let customColourBackground = undefined;
            let customColourBorder = undefined;
            if (customColours !== undefined) {
                for (let [attributePair, colour] of customColours) {
                    for (let entry of entries) {
                        if (entry[0] === attributePair.key && entry[1] === attributePair.value) {
                            customColourBackground = colour[1];
                            customColourBorder = colour[0];
                            break;
                        }
                    }
                }
            }

            let addedEntries = entries.length > 0
            for (const entry of entries) {
                label += entry[0] + "=" + formatFilterValues(entry[0], entry[1] as string) + ", ";
            }
            if (addedEntries) {
                label = label.substring(0, label.length - 2);
            }
            let dataset = {
                label: label,
                data: timeSeries.data.map((dataPoint) => {
                    return {
                        x: dataPoint.time,
                        y: isTime ? dataPoint.value / 1_000_000 : dataPoint.value // nanoseconds to milliseconds
                    }
                }),
                borderWidth: 1,
                // borderSkipped: "middle",
                borderDash: metric.metricVisualization?.lineDash,
                pointRadius: metric.metricVisualization?.lineDotSize,
                backgroundColor:
                    metric.metricVisualization?.lineDotColor !== undefined ? metric.metricVisualization.lineDotColor :
                        customColourBackground !== undefined ? customColourBackground : colorBackground ? colorBackground : colorings[i % colorings.length].backgroundColor,
                borderColor:
                    metric.metricVisualization?.lineColor !== undefined ? metric.metricVisualization.lineColor :
                        customColourBorder !== undefined ? customColourBorder : colorBorder ? colorBorder : colorings[i % colorings.length].borderColor
            };
            data.push(dataset);
            i++;
        }
    }
    return {
        datasets: data,
    };
}

enum MetricType {
    Metric = "metric",
    Trace = "trace",
    Kubernetes = "kubernetes_resource"
}

export interface AppearanceProps {
    // If not set, its shown. If set to true, it hides the selector
    hideGroupBySelector?: boolean;
    hideFilterSelector?: boolean;
    hideMetricSelector?: boolean;
    hideAggregationSelector?: boolean;
}

export interface TimePeriod {
    start: number;
    end: number;
}

export interface Threshold {
    value: string;
    comparator?: ThresholdComparator;
}

interface MultiMetricRequest {
    metrics: SingleMetricRequest[];
    formulas?: Formula[];
}

async function updateChartMetrics(props: MultiMetoroMetricsChartProps,
                                  showAll: boolean,
                                  setMetric: (value: (((prevState: (Metric[] | undefined)) => (Metric[] | undefined)) | Metric[] | undefined)) => void,
                                  setResultsLimited: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                                  setActualResultLen: (value: (((prevState: number) => number) | number)) => void,
                                  abortController: AbortController,
                                  setAbortController: React.Dispatch<React.SetStateAction<AbortController>>,
                                  variables: RuntimeVariable[]
) {
    let singleMetricRequests: SingleMetricRequest[] = [];
    for (let i = 0; i < props.metricSpecifiers.length; i++) {
        const metric = props.metricSpecifiers[i];
        let filters = metric.filters;
        if (filters === undefined) {
            filters = new Map<string, string[]>();
        }
        // Substitute the variables in the filters
        filters = substituteVariablesInFilters(filters, variables);

        let splits = metric.splits;
        if (splits === undefined) {
            splits = [];
        }

        // Create the formula identifier (a, b, c, etc.)
        const formulaIdentifier = String.fromCharCode(97 + i);

        if (metric.metricType === MetricType.Trace) {
            const request: GetTraceMetricRequest = {
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: metric.excludeFilters,
                splits: metric.splits,
                aggregate: metric.aggregation,
                functions: metric.functions,
                limitResults: !showAll,
                bucketSize: metric.bucketSize
            };
            singleMetricRequests.push({
                type: MetricType.Trace,
                trace: request,
                formulaIdentifier,
                shouldNotReturn: metric.shouldNotReturn
            });
        } else if (metric.metricType === MetricType.Kubernetes) {
            const request: GetKubernetesMetricsRequest = {
                metricName: metric.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: metric.excludeFilters,
                splits: splits,
                aggregation: metric.aggregation,
                functions: metric.functions,
                limitResults: !showAll,
                bucketSize: metric.bucketSize,
                jsonPath: metric.jsonPath
            };
            singleMetricRequests.push({
                type: MetricType.Kubernetes,
                kubernetes: request,
                formulaIdentifier,
                shouldNotReturn: metric.shouldNotReturn
            })
        } else {
            const request: GetMetricRequest = {
                metricName: metric.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: metric.excludeFilters,
                splits: splits,
                aggregation: metric.aggregation,
                functions: metric.functions,
                limitResults: !showAll,
                bucketSize: metric.bucketSize
            };
            singleMetricRequests.push({
                type: MetricType.Metric,
                metric: request,
                formulaIdentifier,
                shouldNotReturn: metric.shouldNotReturn
            })
        }
    }

    // Request the Traces endpoint
    const awaitedMetrics = await GetMultiMetrics({
            metrics: singleMetricRequests,
            formulas: props.formulas?.map(f => ({
                ...f,
                formula: f.formula.trim() // Ensure we're not sending any whitespace
            })) || []
        },
        abortController,
        setAbortController);
    
    // Log formulas for debugging
    if (props.formulas) {
        console.log("Original formulas:", props.formulas.map(f => f.formula));
        console.log("Sent formulas:", props.formulas.map(f => f.formula.trim()));
    }
    if (awaitedMetrics.metrics.length > props.metricSpecifiers.length) {
        console.log("Formula results:", awaitedMetrics.metrics.slice(props.metricSpecifiers.length));
    }
    
    // Check for errors in responses
    const errorsFound = awaitedMetrics.metrics
        .filter(metric => metric.error)
        .map(metric => metric.error);
    
    if (errorsFound.length > 0) {
        // Show toast for each error
        errorsFound.forEach(error => {
            toast({
                title: "Error in metric query",
                description: error,
                variant: "destructive"
            });
        });
    }

    let metrics = awaitedMetrics.metrics
        .filter((metric) => metric !== undefined && !metric.error)
        .map((metric) => metric.metric!)
    let isResultLimited = awaitedMetrics.metrics.map(x => x.isResultLimited).reduce((a, b) => a || b) || false
    let resultLen = awaitedMetrics.metrics.filter(x => x.isResultLimited !== undefined).map(x => x.resultLen!).reduce((a, b) => a + b);

    // Add visualizations for both metrics and formulas
    let visibleMetricCount = 0;
    let formulaCount = 0;
    let metricIndex = 0;

    // First, count how many visible metrics we have
    const visibleMetrics = props.metricSpecifiers.filter(m => !m.shouldNotReturn).length;

    // Now process each metric in the results
    for (let i = 0; i < metrics.length; i++) {
        // If we've processed all visible metrics, this must be a formula
        if (visibleMetricCount >= visibleMetrics) {
            // This is a formula result
            metrics[i].metricVisualization = {
                ...props.formulas![formulaCount].visualization,
                displayName: props.formulas![formulaCount].visualization?.displayName || `F${formulaCount + 1}`
            };
            formulaCount++;
            continue;
        }

        // Skip hidden metrics
        while (metricIndex < props.metricSpecifiers.length && props.metricSpecifiers[metricIndex].shouldNotReturn) {
            metricIndex++;
        }

        // Apply visualization for visible metrics
        if (metricIndex < props.metricSpecifiers.length) {
            metrics[i].metricVisualization = props.metricSpecifiers[metricIndex].visualization;
            visibleMetricCount++;
            metricIndex++;
        }
    }

    setMetric(metrics);
    setResultsLimited(isResultLimited)
    setActualResultLen(resultLen);
}


const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any[] = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
};

export function createAlertUrlFromAlert(alert: ApiServerAlert): string {
    return "/new-alert?alertJson=" + encodeURIComponent(JSON.stringify(alert));
}

function createMetricChartWidgetFromMetricChartProps(props: MultiMetoroMetricsChartProps): MultiMetricChartWidget {
    return {
        position: {
            x: undefined,
            y: undefined,
            w: 6,
            h: 3,
        },
        metricSpecifiers: props.metricSpecifiers,
        title: props.title,
        widgetType: 'MetricChart',
        type: props.type,
        formulas: props.formulas
    };
}

function createAlertFromMetricChartProps(props: MultiMetoroMetricsChartProps): ApiServerAlert {
    // TODO HACK HACK, we pull the first metric for now
    let name = "";
    let splits: string[] = [];
    let filters = new Map<string, string[]>();
    let excludeFilters = new Map<string, string[]>();
    let functions: MetricFunction[] = [];
    let aggregation = "sum";
    let jsonPath = undefined;
    let type = MetricType.Metric;
    if (props.metricSpecifiers.length > 0) {
        const metricSpecifier = props.metricSpecifiers[0];
        name = metricSpecifier.metricName || "";
        splits = metricSpecifier.splits || [];
        filters = metricSpecifier.filters || new Map<string, string[]>();
        excludeFilters = metricSpecifier.excludeFilters || new Map<string, string[]>();
        functions = metricSpecifier.functions || [];
        aggregation = metricSpecifier.aggregation;
        type = metricSpecifier.metricType;
        jsonPath = metricSpecifier.jsonPath;
    }
    return {
        destinations: [],
        uuid: "",
        name: "Monitor for " + props,
        description: "Describe why you want to monitor " + name,
        type: type === MetricType.Metric ? AlertType.Metric : type === MetricType.Trace ? AlertType.Trace : AlertType.KubernetesResource,
        // @ts-ignore
        metricAlert: (type === MetricType.Metric ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(excludeFilters || new Map<string, string[]>()),
                splits: splits || [],
                metricName: name,
                functions: functions || [],
                aggregation: aggregation,
            },

            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
        // @ts-ignore
        traceAlert: (type === MetricType.Trace ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(excludeFilters || new Map<string, string[]>()),
                splits: splits || [],
                aggregation: aggregation,
                functions: functions || [],
            },
            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
        // @ts-ignore
        kubernetesResourceAlert: (type === MetricType.Kubernetes ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(excludeFilters || new Map<string, string[]>()),
                splits: splits || [],
                aggregation: aggregation,
                jsonPath: jsonPath,
                functions: functions || [],
            },
            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                },
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined)
    };
}

function MultiMetoroMetricsChart(props: MultiMetoroMetricsChartProps) {
    const [metrics, setMetrics] = React.useState<Metric[]>();
    const [resultsLimited, setResultsLimited] = React.useState<boolean>(false);
    const [actualResultLen, setActualResultLen] = React.useState<number>(0);
    const [showAll, setShowAll] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const debouncedUpdateChartMetrics = useDebouncedCallback(
        async (
            props: MultiMetoroMetricsChartProps,
            showAll: boolean,
            setMetrics: (value: (((prevState: (Metric[] | undefined)) => (Metric[] | undefined)) | Metric[] | undefined)) => void,
            setResultsLimited: (value: (((prevState: boolean) => boolean) | boolean)) => void,
            setActualResultLen: (value: (((prevState: number) => number) | number)) => void,
            abortController: AbortController,
            setAbortController: React.Dispatch<React.SetStateAction<AbortController>>,
            variables: RuntimeVariable[],
            setIsLoading: (value: boolean) => void
        ) => {
            try {
                await updateChartMetrics(props, showAll, setMetrics, setResultsLimited, setActualResultLen, abortController, setAbortController, variables).then(
                    () => {
                        setIsLoading(false);
                    }
                )
            } catch (e) {
                console.error(e);
            }
        },
        50
    );
    const [updateChartMetricsAbortController, setUpdateChartMetricsAbortController] = useState<AbortController>(new AbortController());
    const alert = createAlertFromMetricChartProps(props);
    const alertUrl = createAlertUrlFromAlert(alert)
    const [chartComparisonDialogOpen, setComparisonDialogOpen] = useState(false);

    const [propsState, setPropsState] = useState<MultiMetoroMetricsChartProps>(props);
    useEffect(() => {
        if (!isEqual(props, propsState)) {
            setPropsState(props);
        }
    }, [props]);

    let annotations: any[] = [];

    function getLineStyleForThreshold(threshold: Threshold): any {
        if (threshold.comparator != undefined && threshold.comparator == ThresholdComparator.GreaterThan || threshold.comparator == ThresholdComparator.LessThan) {
            return [5, 5]
        } else {
            return [];
        }
    }

    if (propsState.threshold != undefined && propsState.threshold.value != undefined) {
        // single line on the threshold
        annotations.push({
            type: 'line',
            mode: 'horizontal',
            yMin: propsState.threshold.value,
            yMax: propsState.threshold.value,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: getLineStyleForThreshold(propsState.threshold),
        });
        // shaded area on the threshold
        if (propsState.threshold.comparator != undefined) {
            if (propsState.threshold.comparator == ThresholdComparator.GreaterThan || propsState.threshold.comparator == ThresholdComparator.GreaterThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMin: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            } else if (propsState.threshold.comparator == ThresholdComparator.LessThan || propsState.threshold.comparator == ThresholdComparator.LessThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMax: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            }
        }
    } else if (props.timePeriodHighlight != undefined && !Number.isNaN(props.timePeriodHighlight.start) && !Number.isNaN(props.timePeriodHighlight.end)) {
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.start * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.end * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'box',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderWidth: 0,
        })
    }

    useEffect(() => {
        setShowAll(false)
    }, [propsState.metricSpecifiers]);

    useEffect(() => {
        setIsLoading(true);
        debouncedUpdateChartMetrics(
            propsState,
            showAll,
            setMetrics,
            setResultsLimited,
            setActualResultLen,
            updateChartMetricsAbortController,
            setUpdateChartMetricsAbortController,
            props.variables || [],
            setIsLoading
        );
    }, [propsState.metricSpecifiers, propsState.startTime, propsState.endTime, propsState.type, propsState.title, showAll, props.variables, propsState.formulas]);
    const durationAggregations = ["p50", "p90", "p95", "p99"];

    // If any of the timeseries are time, set isTime to true
    const isTime = propsState.metricSpecifiers.find(x => durationAggregations.includes(x.aggregation.toLowerCase())) != undefined

    const dataToUse = MetricsToChartData(metrics, isTime);

    if ((dataToUse === undefined || dataToUse.datasets === undefined || dataToUse.datasets.length === 0) && props.hideOnNoData) {
        return <></>
    }

    const metricChartWidget = createMetricChartWidgetFromMetricChartProps(propsState)

    return (
        <div className={"flex grow shrink relative max-w-full"}>
            <ChartComparisonDialog
                isOpen={chartComparisonDialogOpen}
                setOpen={setComparisonDialogOpen}
                chartProps={propsState}/>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-background/50 z-50">
                    <LoadingSpinner size={32}/>
                </div>
            )}
            <MetoroChart
                setTimeRange={props.setTimeRange}
                setComparisonDialogOpen={setComparisonDialogOpen}
                metricChartWidget={metricChartWidget}
                createAlertURL={alertUrl}
                className={propsState.className} hideOnNoData={propsState.hideOnNoData}
                styling={propsState.styling}
                type={propsState.type} dataToUse={dataToUse} title={propsState.title} annotations={annotations}
                hideLegend={propsState.hideLegend} isDuration={isTime} resultsLimited={resultsLimited}
                actualResultLen={actualResultLen} showAll={showAll} setShowAll={setShowAll}/>
        </div>
    );
}


export {
    MetricsToChartData,
    MultiMetoroMetricsChart,
    MetricType,
    isMulti
};

export type { MetricSpecifier };

