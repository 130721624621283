import React from "react";
import {cn} from "./ui/lib/utils";

export function LargeSelectorButton(props: {
    icon: React.ReactNode,
    iconPosition?: string,
    method: string,
    displayMethod: string,
    paragraph?: string,
    selectedMethod: string,
    setSelectedMethod: (value: (((prevState: string) => string) | string)) => void
}) {
    return (
        <div
            onClick={() => props.setSelectedMethod(props.method)}
            className={cn(props.selectedMethod === props.method ? "border-secondary" : "", "flex flex-col gap-2 p-2 min-w-0 min-h-0 grow shrink border rounded bg-backgroundmedium hover:border-primary hover:cursor-pointer")}>
            {props.iconPosition != undefined && props.iconPosition == "top" && <div
                className={cn("flex text-textmedium justify-center grow shrink hover:text-primary", props.selectedMethod === props.method ? "text-secondary" : "")}>
                {props.icon}
            </div>}
            <div className="flex flex-col gap-1 grow shrink">
                <div className="text-center text-textmedium text-base font-normal font-['Inter'] leading-normal">
                    {props.displayMethod}
                </div>
                {props.paragraph && (
                    <div className="text-center text-textdark text-sm font-normal font-['Inter'] leading-normal">
                        {props.paragraph}
                    </div>
                )}
            </div>
            {(props.iconPosition == undefined || props.iconPosition == "bottom") && <div
                className={cn("flex text-textmedium justify-center grow shrink hover:text-primary", props.selectedMethod === props.method ? "text-secondary" : "")}>
                {props.icon}
            </div>}
        </div>
    )
}