import React, {useEffect, useRef, useState} from "react";
import 'chartjs-adapter-date-fns';
import {Dashboard, RuntimeVariable} from "../components/Dashboarding/Dashboard";
import {AlertType, ApiServerAlert} from "./AlertCreation";
import {TimeRange} from "../types/time";
import {useDebouncedCallback} from "use-debounce";
import {MetricFunction} from "../components/Dashboarding/widgets/MetricSelector";
import {AggregationFunction, EvalType, MissingDatapointBehavior, WindowUnit} from "./alerts/MetricAlert";
import {formatFilterValues} from "../components/Filter/Filter";
import {MultiMetricChartWidget, OldMetricChartWidget} from "../components/Dashboarding/internalwidgets";
import isEqual from 'lodash/isEqual';
import {AlertDestinationType} from "../components/Alert/AlertDestination";
import {ChartComparisonDialog} from "../components/ChartComparisonDialog";
import {ThresholdComparator} from "../components/Alert/AlertMetricSelector";
import {LoadingSpinner} from "../components/ui/customSpinner";
import {substituteVariablesInFilters} from "../components/Charts/utils";
import {ChartStyling, ChartType, MetoroChart} from "../components/Charts/MetoroChart";
import {
    GetKubernetesMetric,
    GetKubernetesMetricsRequest,
    GetMetric,
    GetMetricRequest,
    GetTraceMetric,
    GetTraceMetricRequest,
    Metric
} from "../clients/metoro/metrics";
import {MetricSpecifier, MultiMetoroMetricsChartProps} from "../components/Charts/MultiMetoroMetricsCharts";
import {Formula} from "../types/formula";

export interface MathExpression {
    variables: string[];
    expression: string;
}

// A variable in a math expression
interface MathExpressionVariable {
    // variable name, e.g. a, b, c, etc.
    name: string;
    // value is the name of the metric this variable represents
    // e.g. a / 60 where a = requests_total metric.
    value: string;
}

interface ColorPairing {
    backgroundColor: string;
    borderColor: string;
}

const colorings: ColorPairing[] = [
    {
        borderColor: "#3793ff",
        backgroundColor: "#3793ff33",
    },
    {
        borderColor: "#ff6384",
        backgroundColor: "#ff638433",
    },
    {
        borderColor: "#ffce56",
        backgroundColor: "#ffce5633",
    },
    {
        borderColor: "#4bc0c0",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
    },
    {
        borderColor: "#ff9f40",
        backgroundColor: "#ff9f4033",
    },
    {
        borderColor: "#ff66ff",
        backgroundColor: "#ff66ff33",
    },
    {
        borderColor: "#ffcd56",
        backgroundColor: "#ffcd5633",
    },

    {
        borderColor: "#36a2eb",
        backgroundColor: "#36a2eb33",
    },
]

function MetricToChartData(metric: Metric | undefined, isTime: boolean, colorBackground: string | undefined = undefined, colorBorder: string | undefined = undefined, customColours: Map<Record<string, string>, string[]> | undefined = undefined): any {
    if (metric === undefined) {
        return undefined;
    }
    let data = [];
    let i = 0;
    // Sorted by total value of the time series
    let sorted = metric.timeSeries.sort((a, b) => {
        let totalA = a.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        let totalB = b.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        return totalB - totalA
    })

    for (let timeSeries of sorted) {
        let label = "";
        const entries = Object.entries(timeSeries.attributes)
        let customColourBackground = undefined;
        let customColourBorder = undefined;
        if (customColours !== undefined) {
            for (let [attributePair, colour] of customColours) {
                for (let entry of entries) {
                    if (entry[0] === attributePair.key && entry[1] === attributePair.value) {
                        customColourBackground = colour[1];
                        customColourBorder = colour[0];
                        break;
                    }
                }
            }
        }
        for (const entry of entries) {
            label += entry[0] + "=" + formatFilterValues(entry[0], entry[1] as string) + ", ";
        }
        if (label.length > 0) {
            label = label.substring(0, label.length - 2);
        }
        let dataset = {
            label: label,
            data: timeSeries.data.map((dataPoint) => {
                return {
                    x: dataPoint.time,
                    y: isTime ? dataPoint.value / 1_000_000 : dataPoint.value // nanoseconds to milliseconds
                }
            }),
            borderWidth: 1,
            // borderSkipped: "middle",
            backgroundColor: customColourBackground !== undefined ? customColourBackground : colorBackground ? colorBackground : colorings[i % colorings.length].backgroundColor,
            borderColor: customColourBorder !== undefined ? customColourBorder : colorBorder ? colorBorder : colorings[i % colorings.length].borderColor
        };
        data.push(dataset);
        i++;
    }
    return {
        datasets: data,
    };
}


const MetricsTest: React.FC = () => {
    return (
        <div className={"w-screen h-screen flex justify-center overflow-y-auto"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex flex-col justify-center h-screen w-screen"}>
                    {/*<MetricSelectorPanel/>*/}
                    <Dashboard/>
                </div>
            </div>
        </div>
    );
}

enum MetricType {
    Metric = "metric",
    Trace = "trace",
    Kubernetes = "kubernetes_resource"
}

interface MetoroMetricsChartProps {
    // The epoch time in seconds of the start of the time range
    startTime: number;
    // The epoch time in seconds of the end of the time range
    endTime: number;
    // The name of the metric
    metricName: string;
    // The filters to apply to the metric
    filters?: Map<string, string[]>;
    // The filters to exclude from the metric
    excludeFilters?: Map<string, string[]>;
    // The splits to apply to the metric
    splits?: string[];
    // The aggregation to apply to the metric
    aggregation: string;
    // The title of the chart
    title?: string;
    // The type of the chart
    type: ChartType;
    // Hide on no data
    hideOnNoData?: boolean;
    // Extra styling options for the chart
    styling?: ChartStyling;
    // Class name overrides for the chart container on the inside
    className?: string;
    // threshold if defined
    threshold?: Threshold;
    // threshold label if defined
    thresholdLabel?: string;
    // highlight time with vertical line annotations
    timePeriodHighlight?: TimePeriod;
    // hide the legend
    hideLegend?: boolean;
    // The type of metric
    metricType: MetricType;
    // The functions to apply to the metric
    functions: MetricFunction[];
    // Any variables that are in scope, this will be used to substitute the variables in the filters
    variables?: RuntimeVariable[];
    // The bucket size in seconds
    bucketSize?: number;
    // Whether to show the rate
    isRate?: boolean;
    // Formulas to apply to the metrics
    formulas?: Formula[];
    // JSON path for kubernetes metrics
    jsonPath?: string;
    // Set time range callback
    setTimeRange?: React.Dispatch<React.SetStateAction<TimeRange>>;
}

export type { MetoroMetricsChartProps };

export interface AppearanceProps {
    // If not set, its shown. If set to true, it hides the selector
    hideGroupBySelector?: boolean;
    hideFilterSelector?: boolean;
    hideMetricSelector?: boolean;
    hideAggregationSelector?: boolean;
}

export interface TimePeriod {
    start: number;
    end: number;
}

export interface Threshold {
    value: string;
    comparator?: ThresholdComparator;
}


async function updateChartMetrics(props: MetoroMetricsChartProps,
                                  showAll: boolean,
                                  setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void,
                                  setResultsLimited: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                                  setActualResultLen: (value: (((prevState: number) => number) | number)) => void,
                                  abortController: AbortController,
                                  setAbortController: React.Dispatch<React.SetStateAction<AbortController>>,
                                  variables: RuntimeVariable[]
) {
    let filters = props.filters;
    if (filters === undefined) {
        filters = new Map<string, string[]>();
    }
    let excludeFilters = props.excludeFilters;
    if (excludeFilters === undefined) {
        excludeFilters = new Map<string, string[]>();
    }
    // Substitute the variables in the filters
    filters = substituteVariablesInFilters(filters, variables);
    excludeFilters = substituteVariablesInFilters(excludeFilters, variables);

    let splits = props.splits;
    if (splits === undefined) {
        splits = [];
    }

    try {
        if (props.metricType === MetricType.Trace) {
            const request: GetTraceMetricRequest = {
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: props.splits,
                aggregate: props.aggregation,
                functions: props.functions,
                limitResults: !showAll,
                bucketSize: props.bucketSize
            };
            // Request the Traces endpoint
            const awaitedTraceMetrics = await GetTraceMetric(request);
            setMetric(awaitedTraceMetrics.metric)
            setResultsLimited(awaitedTraceMetrics.isResultLimited)
            setActualResultLen(awaitedTraceMetrics.resultLen);
        } else if (props.metricType === MetricType.Kubernetes) {
            const request: GetKubernetesMetricsRequest = {
                metricName: props.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: splits,
                aggregation: props.aggregation,
                isRate: props.isRate ? props.isRate : false,
                functions: props.functions,
                limitResults: !showAll,
                bucketSize: props.bucketSize
            };
            const awaitedMetrics = await GetKubernetesMetric(request, abortController, setAbortController);
            setMetric(awaitedMetrics.metric);
            setResultsLimited(awaitedMetrics.isResultLimited);
            setActualResultLen(awaitedMetrics.resultLen);
        } else {
            const request: GetMetricRequest = {
                metricName: props.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: splits,
                aggregation: props.aggregation,
                isRate: props.isRate ? props.isRate : false,
                functions: props.functions,
                limitResults: !showAll,
                bucketSize: props.bucketSize
            };
            const awaitedMetrics = await GetMetric(request, abortController, setAbortController);
            setMetric(awaitedMetrics.metric);
            setResultsLimited(awaitedMetrics.isResultLimited);
            setActualResultLen(awaitedMetrics.resultLen);
        }
    } catch (e) {
        console.error(e);
    }
}

const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any[] = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
};

export function createAlertUrlFromAlert(alert: ApiServerAlert): string {
    return "/new-alert?alertJson=" + encodeURIComponent(JSON.stringify(alert));
}


function createMetricChartWidgetFromMetricChartProps(props: MetoroMetricsChartProps): MultiMetricChartWidget {
    return {
        position: {
            x: undefined,
            y: undefined,
            w: 6,
            h: 3,
        },
        metricSpecifiers: [
            {
                filters: props.filters,
                excludeFilters: props.excludeFilters,
                splits: props.splits,
                aggregation: props.aggregation,
                functions: props.functions,
                metricName: props.metricName,
                metricType: props.metricType,
            }
        ],
        title: props.title,
        widgetType: 'MetricChart',
        type: props.type,
    };
}

export function createMetricChartPropsFromMetricChartWidget(widget: OldMetricChartWidget, startTime: number, endTime: number): MetoroMetricsChartProps | MultiMetoroMetricsChartProps {
    if ('metricSpecifiers' in widget) {
        return {
            startTime: startTime,
            endTime: endTime,
            metricSpecifiers: widget.metricSpecifiers as MetricSpecifier[],
            title: widget.title,
            type: widget.type,
        };
    }

    return {
        startTime: startTime,
        endTime: endTime,
        metricName: widget.metricName,
        filters: widget.filters,
        excludeFilters: widget.excludeFilters,
        splits: widget.splits,
        aggregation: widget.aggregation,
        functions: widget.functions,
        title: widget.title,
        type: widget.type,
        metricType: widget.metricType,
    };
}

function createAlertFromMetricChartProps(props: MetoroMetricsChartProps): ApiServerAlert {
    return {
        uuid: "",
        name: "Monitor for " + props.metricName,
        description: "Describe why you want to monitor " + props.metricName,
        destination: {
            type: AlertDestinationType.Slack,
            slackDestination: {
                channel: "help"
            },
        },
        type: props.metricType === MetricType.Metric ? AlertType.Metric : AlertType.Trace,
        // @ts-ignore
        metricAlert: (props.metricType === MetricType.Metric ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(props.filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(props.excludeFilters || new Map<string, string[]>()),
                splits: props.splits || [],
                metricName: props.metricName,
                functions: props.functions || [],
                aggregation: props.aggregation,
            },

            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
        // @ts-ignore
        traceAlert: (props.metricType === MetricType.Trace ? {
            filters: {
                // @ts-ignore
                filters: Object.fromEntries(props.filters || new Map<string, string[]>()),
                // @ts-ignore
                excludeFilters: Object.fromEntries(props.excludeFilters || new Map<string, string[]>()),
                splits: props.splits || [],
                aggregation: props.aggregation,
                functions: props.functions || [],
            },
            monitorEvaluation: {
                description: "Eval",
                monitorEvaluationType: EvalType.Static,
                monitorEvalutionPayload: {
                    evaluationFunction: AggregationFunction.Sum,
                    window: 1,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: [],
                    evaluationWindow: 1,
                    datapointsToAlarm: 1,
                    missingDatapointBehavior: MissingDatapointBehavior.NotBreaching
                }
            },
            alarmCondition: {
                condition: ThresholdComparator.GreaterThan,
                // @ts-ignore
                threshold: 0,
            },
        } : undefined),
    };
}

function MetoroMetricsChart(props: MetoroMetricsChartProps) {
    const [metric, setMetric] = React.useState<Metric>();
    const [resultsLimited, setResultsLimited] = React.useState<boolean>(false);
    const [actualResultLen, setActualResultLen] = React.useState<number>(0);
    const [showAll, setShowAll] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const debouncedUpdateChartMetrics = useDebouncedCallback(
        async (
            props: MetoroMetricsChartProps,
            showAll: boolean,
            setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void,
            setResultsLimited: (value: (((prevState: boolean) => boolean) | boolean)) => void,
            setActualResultLen: (value: (((prevState: number) => number) | number)) => void,
            abortController: AbortController,
            setAbortController: React.Dispatch<React.SetStateAction<AbortController>>,
            variables: RuntimeVariable[],
            setIsLoading: (value: boolean) => void
        ) => {
            try {
                await updateChartMetrics(props, showAll, setMetric, setResultsLimited, setActualResultLen, abortController, setAbortController, variables).then(
                    () => {
                        setIsLoading(false);
                    }
                )
            } catch (e) {
                console.error(e);
            }
        },
        50
    );
    const [updateChartMetricsAbortController, setUpdateChartMetricsAbortController] = useState<AbortController>(new AbortController());
    const alert = createAlertFromMetricChartProps(props);
    const alertUrl = createAlertUrlFromAlert(alert)
    const metricChartWidget = createMetricChartWidgetFromMetricChartProps(props);
    const [chartComparisonDialogOpen, setComparisonDialogOpen] = useState(false);

    const [propsState, setPropsState] = useState<MetoroMetricsChartProps>(props);
    useEffect(() => {
        if (!isEqual(props, propsState)) {
            setPropsState(props);
        }
    }, [props]);

    let annotations: any[] = [];

    function getLineStyleForThreshold(threshold: Threshold): any {
        if (threshold.comparator != undefined && threshold.comparator == ThresholdComparator.GreaterThan || threshold.comparator == ThresholdComparator.LessThan) {
            return [5, 5]
        } else {
            return [];
        }
    }

    if (propsState.threshold != undefined && propsState.threshold.value != undefined) {
        // single line on the threshold
        annotations.push({
            type: 'line',
            mode: 'horizontal',
            yMin: propsState.threshold.value,
            yMax: propsState.threshold.value,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: getLineStyleForThreshold(propsState.threshold),
        });
        // shaded area on the threshold
        if (propsState.threshold.comparator != undefined) {
            if (propsState.threshold.comparator == ThresholdComparator.GreaterThan || propsState.threshold.comparator == ThresholdComparator.GreaterThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMin: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            } else if (propsState.threshold.comparator == ThresholdComparator.LessThan || propsState.threshold.comparator == ThresholdComparator.LessThanOrEqual) {
                if (props.thresholdLabel && props.thresholdLabel != "") {
                    annotations.push({
                        type: 'label',
                        backgroundColor: 'rgba(255, 99, 132, 0.1)',
                        borderWidth: 0,
                        content: [props.thresholdLabel],
                        color: "rgb(239 68 68)",
                        yValue: propsState.threshold.value,
                        position: "end"
                    })
                }
                annotations.push({
                    type: 'box',
                    yMax: propsState.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0
                })
            }
        }
    } else if (props.timePeriodHighlight != undefined && !Number.isNaN(props.timePeriodHighlight.start) && !Number.isNaN(props.timePeriodHighlight.end)) {
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.start * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'line',
            mode: 'vertical',
            xMin: props.timePeriodHighlight.end * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: [],
        });
        annotations.push({
            type: 'box',
            xMin: props.timePeriodHighlight.start * 1000,
            xMax: props.timePeriodHighlight.end * 1000,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderWidth: 0,
        })
    }

    useEffect(() => {
        setShowAll(false)
    }, [propsState.metricName, propsState.splits, propsState.filters, propsState.excludeFilters, propsState.metricType]);

    useEffect(() => {
        setIsLoading(true);
        debouncedUpdateChartMetrics(
            propsState,
            showAll,
            setMetric,
            setResultsLimited,
            setActualResultLen,
            updateChartMetricsAbortController,
            setUpdateChartMetricsAbortController,
            props.variables || [],
            setIsLoading
        );
    }, [propsState.metricName, propsState.startTime, propsState.endTime, propsState.filters, propsState.excludeFilters, propsState.splits, propsState.aggregation, propsState.type, propsState.title, propsState.metricType, propsState.functions, showAll, props.variables, propsState.bucketSize]);
    const durationAggregations = ["p50", "p90", "p95", "p99"];
    const isTime = durationAggregations.includes(propsState.aggregation.toLowerCase());

    const dataToUse = MetricToChartData(metric, isTime);

    if ((dataToUse === undefined || dataToUse.datasets === undefined || dataToUse.datasets.length === 0) && props.hideOnNoData) {
        return <></>
    }

    return (
        <div className={"flex grow shrink relative"}>
            <ChartComparisonDialog
                isOpen={chartComparisonDialogOpen}
                setOpen={setComparisonDialogOpen}
                chartProps={propsState}/>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-background/50 z-50">
                    <LoadingSpinner size={32}/>
                </div>
            )}
            <MetoroChart
                setTimeRange={props.setTimeRange}
                setComparisonDialogOpen={setComparisonDialogOpen}
                metricChartWidget={metricChartWidget}
                createAlertURL={alertUrl}
                className={propsState.className} hideOnNoData={propsState.hideOnNoData}
                styling={propsState.styling}
                type={propsState.type} dataToUse={dataToUse} title={propsState.title} annotations={annotations}
                hideLegend={propsState.hideLegend} isDuration={isTime} resultsLimited={resultsLimited}
                actualResultLen={actualResultLen} showAll={showAll} setShowAll={setShowAll}/>
        </div>
    );
}


export {
    MetricToChartData,
    MetoroMetricsChart,
    MetricsTest,
    MetricType
};
