import {BaseView} from "./BaseView";
import {useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AlertType, ApiServerAlert} from "./AlertCreation";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {AiOutlineKubernetes} from "react-icons/ai";
import {FaSlack} from "react-icons/fa";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {GanttChart} from "../components/Gantt/GanttChart";
import {Dialog, DialogContent, DialogDescription, DialogTitle} from "../components/ui/dialog";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {MetoroMetricsChart, MetricType} from "./MetricsTest";
import {BellOffIcon, MailIcon, SmileIcon} from "lucide-react";
import {adjustAlertThreshold} from "./alerts/utils";
import {TimeRange} from "../types/time";
import {useDebouncedCallback} from "use-debounce";
import {
    AggregateMonitorEvaluationPayload,
    AggregationFunction,
    EvalType,
    MonitorEvaluation,
    StaticMonitorEvaluationPayload
} from "./alerts/MetricAlert";
import {DateTimePrecision, displayDateTimeWithSelectedFormat, getStartOfBucket} from "../utility/displayDateTime";
import {AlertDestinationType} from "../components/Alert/AlertDestination";
import {
    getGraphAnnotationProperties,
    getThresholdComparatorSymbol,
    ThresholdComparator
} from "../components/Alert/AlertMetricSelector";
import {ChartType} from "../components/Charts/MetoroChart";
import {MultiMetoroMetricsChart} from "../components/Charts/MultiMetoroMetricsCharts";

var hdate = require('human-date')


function AlertDetails(props: { alert: ApiServerAlert | undefined, setAlert: (alert: ApiServerAlert | undefined) => void }) {
    const navigate = usePreserveQueryParamsNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [muteDialogOpen, setMuteDialogOpen] = React.useState(false);

    function getMonitorType() {
        if (props.alert === undefined) {
            return ""
        }
        if ((props.alert.metricAlert != null) || (props.alert.traceAlert != null) || (props.alert.kubernetesResourceAlert != null)) {
            const typedAlert = props.alert.metricAlert || props.alert.traceAlert || props.alert.kubernetesResourceAlert
            if (typedAlert!.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
                return "Static Threshold"
            }
            if (typedAlert!.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
                return "Evaluate Aggregate Function"
            }
        }
        return "Unknown"
    }

    function getMissingDatapointTreatmentType() {
        if (props.alert === undefined) {
            return ""
        }
        const typedAlert = props.alert.metricAlert || props.alert.traceAlert || props.alert.kubernetesResourceAlert
        if (typedAlert != null && typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
            return "treat as " + typedAlert.monitorEvaluation.monitorEvalutionPayload.missingDatapointBehavior
        }
        return ""
    }

    function getConditionType() {
        if (props.alert === undefined) {
            return ""
        }

        // Get metric string e.g. average:cpu_usage_namespace
        let metricString = ""
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
            metricString = props.alert.metricAlert.filters.metricName
            metricString = props.alert.metricAlert.filters.aggregation + ":" + metricString
        }
        if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
            metricString = props.alert.traceAlert.filters.aggregation
        }
        if (props.alert.type === AlertType.KubernetesResource && props.alert.kubernetesResourceAlert != null) {
            metricString = props.alert.kubernetesResourceAlert.filters.aggregation
        }

        // Wrap metric string with functions e.g. monotonic_difference(average:cpu_usage_namespace)
        let functionsString = metricString
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null && props.alert.metricAlert.filters.functions != null) {
            props.alert.metricAlert.filters.functions.forEach((func) => {
                functionsString = func.functionType + "(" + functionsString + ")"
            })
        }
        if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null && props.alert.traceAlert.filters.functions != null) {
            props.alert.traceAlert.filters.functions.forEach((func) => {
                functionsString = func.functionType + "(" + functionsString + ")"
            })
        }
        if (props.alert.type === AlertType.KubernetesResource && props.alert.kubernetesResourceAlert != null && props.alert.kubernetesResourceAlert.filters.functions != null) {
            props.alert.kubernetesResourceAlert.filters.functions.forEach((func) => {
                functionsString = func.functionType + "(" + functionsString + ")"
            })
        }

        // Get threshold comparator string e.g. > 0.5
        let thresholdComparatorString = ""
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
            thresholdComparatorString = getThresholdComparatorSymbol(props.alert.metricAlert.alarmCondition.condition as ThresholdComparator) + " " + props.alert.metricAlert.alarmCondition.threshold
        } else if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
            thresholdComparatorString = getThresholdComparatorSymbol(props.alert.traceAlert.alarmCondition.condition as ThresholdComparator) + " " + props.alert.traceAlert.alarmCondition.threshold
        } else if (props.alert.type === AlertType.KubernetesResource && props.alert.kubernetesResourceAlert != null) {
            thresholdComparatorString = getThresholdComparatorSymbol(props.alert.kubernetesResourceAlert.alarmCondition.condition as ThresholdComparator) + " " + props.alert.kubernetesResourceAlert.alarmCondition.threshold
        }

        let typedAlert = props.alert.metricAlert || props.alert.traceAlert || props.alert.kubernetesResourceAlert
        if (typedAlert === undefined) {
            return ""
        }
        let evaluationString = ""
        if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
            const typedStaticEvaluationPayload = {
                datapointsToAlarm: typedAlert.monitorEvaluation.monitorEvalutionPayload.datapointsToAlarm ? typedAlert.monitorEvaluation.monitorEvalutionPayload.datapointsToAlarm : 1,
                evaluationWindow: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationWindow ? typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationWindow : 1
            } as StaticMonitorEvaluationPayload
            const datapointsToAlarm = typedStaticEvaluationPayload.datapointsToAlarm
            const evaluationWindow = typedStaticEvaluationPayload.evaluationWindow
            evaluationString += `${datapointsToAlarm} out of ${evaluationWindow} datapoints:` + functionsString
        } else if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
            const typedEvaluationPayload = {
                evaluationFunction: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction,
                evaluationSplits: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits,
                window: typedAlert.monitorEvaluation.monitorEvalutionPayload.window,
                windowUnit: typedAlert.monitorEvaluation.monitorEvalutionPayload.windowUnit
            } as AggregateMonitorEvaluationPayload
            if (typedEvaluationPayload === undefined) {
                console.log("typedEvaluationPayload is undefined")
                return ""
            }
            let aggregateFunction = typedEvaluationPayload.evaluationFunction.toString()
            if (typedEvaluationPayload.evaluationSplits != null) {
                typedEvaluationPayload.evaluationSplits.forEach((split) => {
                    aggregateFunction += " of " + split
                })
            }

            let typeName = ""
            if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
                typeName = "metric"
            }
            if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
                typeName = "trace"
            }
            if (props.alert.type === AlertType.KubernetesResource && props.alert.kubernetesResourceAlert != null) {
                typeName = "kubernetes resource"
            }
            aggregateFunction = aggregateFunction + "(every " + typedEvaluationPayload.window + typedEvaluationPayload.windowUnit.toString() + ")"
            evaluationString = aggregateFunction + " of " + typeName + " " + functionsString
        }
        return evaluationString + " " + thresholdComparatorString
    }

    const alert = props.alert;
    if (!alert) {
        return <div className={"flex justify-center items-center w-full h-full"}>
            <div className={"text-textmedium"}>No alert selected</div>
        </div>
    }
    return <div className={"flex grow flex-col gap-4 m-4"}>
        <div className={"flex justify-between"}>
            <div className={"text-lg font-semibold"}>
                Summary
            </div>
            <div className={"flex gap-4"}>
                <Button onClick={() => {
                    navigate(`/new-alert?alertId=${alert?.uuid}`)
                }} className={"bg-primarytransparent border border-primary h-full rounded"}>
                    Edit
                </Button>
                <Button onClick={() => {
                    // Create a copy of the alert with a cleared UUID
                    const clonedAlert = {...alert, uuid: "", name: `${alert?.name} (Copy)`};
                    const alertJson = JSON.stringify(clonedAlert);
                    navigate(`/new-alert?alertJson=${encodeURIComponent(alertJson)}`);
                }} className={"bg-primarytransparent border border-primary h-full rounded"}>
                    Clone
                </Button>
                {alert.muted ? <Button onClick={() => {
                        setMuteDialogOpen(true)
                    }} className={"bg-amber-500/20 border border-amber-500 h-full rounded"}>
                        Unmute
                    </Button>
                    :
                    <Button onClick={() => {
                        setMuteDialogOpen(true)
                    }} className={"bg-amber-500/20 border border-amber-500 h-full rounded"}>
                        Mute
                    </Button>
                }
                <Dialog open={muteDialogOpen}>
                    <DialogContent
                        onInteractOutside={() => setMuteDialogOpen(false)}
                        className={"w-[40vw] text-textmedium bg-backgrounddark"}>
                        <DialogTitle>Would you like to {alert.muted ? "unmute" : "mute"} this alert?</DialogTitle>
                        <DialogDescription>
                            {alert.muted ? "Unmuting this alert will resume notifications to your specified destinations for any new occurrences. Please note that ongoing alerts will not generate additional notifications after unmuting." : "Muting this alert will stop notifications from being sent to your specified destinations."}
                        </DialogDescription>
                        <div className={"flex py-4 gap-4"}>
                            <Button onClick={() => {
                                if (alert.muted) {
                                    axios.put(`/api/v1/alert/unmute`, {alertUUID: alert.uuid})
                                        .then(() => {
                                            setMuteDialogOpen(false)
                                            axios.get(`/api/v1/alert?alertId=${alert.uuid}`)
                                                .then((response) => {
                                                    props.setAlert(response.data)
                                                })
                                        })
                                } else {
                                    axios.put(`/api/v1/alert/mute`, {alertUUID: alert.uuid})
                                        .then(() => {
                                            setMuteDialogOpen(false)
                                            axios.get(`/api/v1/alert?alertId=${alert.uuid}`)
                                                .then((response) => {
                                                    props.setAlert(response.data)
                                                })
                                        })
                                }
                            }} className={"bg-amber-500/20 border border-amber-500 h-full flex grow shrink rounded"}>
                                {alert.muted ? "Unmute" : "Mute"}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
                <Button onClick={() => {
                    setDeleteDialogOpen(true)
                }} className={"bg-red-500/20 border border-red-500 h-full rounded"}>
                    Delete
                </Button>
                <Dialog open={deleteDialogOpen}>
                    <DialogContent
                        onInteractOutside={() => setDeleteDialogOpen(false)}
                        className={"w-[40vw] text-textmedium bg-backgrounddark"}>
                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                        <DialogDescription>
                            This action cannot be undone. This will permanently delete this alert
                            and all firing history associated with it.
                        </DialogDescription>
                        <div className={"flex py-4 gap-4"}>
                            <Button onClick={() => {
                                axios.delete(`/api/v1/alert?alertId=${alert?.uuid}`)
                                    .then(() => {
                                        navigate("/alerts")
                                    })
                            }} className={"bg-red-500/20 border border-red-500 h-full flex grow shrink"}>
                                Delete
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
        <div className={"grid grid-rows-1 grid-cols-2"}>
            <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Name</div>
                    <div className={"text-textmedium truncate pr-4"}>{alert.name}</div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Description</div>
                    <div className={"text-textmedium truncate pr-4"}>{alert.description}</div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Destinations</div>
                    <div className={"text-textmedium flex flex-wrap gap-2"}>
                        {alert.destinations.map((destination, index) => {
                            return <div key={index}>
                                {destination.type === AlertDestinationType.Slack && (
                                    <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-purple-500 gap-2">
                                        <FaSlack className={"text-white h-4 w-4"}/>Slack
                                    </div>
                                )}
                                {destination.type === AlertDestinationType.PagerDuty && (
                                    <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-textlight shadow hover:bg-primary/80 rounded bg-[#06AC38] gap-2">
                                        PagerDuty
                                    </div>
                                )}
                                {destination.type === AlertDestinationType.Email && (
                                    <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-red-400 gap-2">
                                        <MailIcon className={"text-white h-4 w-4"}/>Email
                                    </div>
                                )}
                                {destination.type === AlertDestinationType.Webhook && (
                                    <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-orange-500 gap-2">
                                        Webhook
                                    </div>
                                )}
                            </div>
                        })}
                        {(!alert.destinations || alert.destinations.length === 0) && (
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-gray-500 gap-2">
                                None
                            </div>
                        )}
                    </div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Status</div>
                    <div className={"text-textmedium"}>
                        {
                            alert.status === "FIRING" ? <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-red-500">Firing</div>
                                :
                                <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-secondaryTransparent">Ok</div>
                        }
                    </div>
                </div>

            </div>
            <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                    <div
                        className={`text-textmedium font-bold ${alert.type === "Metric" || alert.type === "Trace" || alert.type === "KubernetesResource" ? "w-[120px]" : "w-[80px]"}`}>Type
                    </div>
                    <div className={"text-textmedium"}>
                        {
                            alert.type === "Kubernetes" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                <AiOutlineKubernetes className={"text-white h-4 w-4"}/>Kubernetes
                            </div>
                        }
                        {
                            alert.type === "Log" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Log
                            </div>
                        }
                        {
                            alert.type === "Metric" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Metric
                            </div>
                        }
                        {
                            alert.type === "Trace" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Trace
                            </div>
                        }
                        {
                            alert.type === "KubernetesResource" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Kubernetes Resource
                            </div>
                        }
                    </div>
                </div>
                {
                    alert.type === "Kubernetes" ? <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Monitor</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.monitorEvent === "pod_restart" ? "Pod Restart" :
                                    alert.kubernetesAlert!.monitorEvent === "pod_crash" ? "Pod Crash" : "Unknown"
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Clusters</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.filters.clusters.length === 0 ? "All" : alert.kubernetesAlert!.filters.clusters.join(", ")
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Services</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.filters.services.length === 0 ? "All" : alert.kubernetesAlert!.filters.services.join(", ")
                            }</div>
                        </div>
                    </div> : null
                }
                {
                    alert.type === "Log" && <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Monitor</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.monitorEvent === "regex_match" ? "Regex Match" : "Unknown"
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Clusters</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.filters.clusters.length === 0 ? "All" : alert.logAlert!.filters.clusters.join(", ")
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Services</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.filters.services.length === 0 ? "All" : alert.logAlert!.filters.services.join(", ")
                            }</div>
                        </div>
                    </div>
                }
                {
                    alert.type === "Metric" && alert.metricAlert != null && <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Condition</div>
                            <div className={"text-textmedium line-clamp-3 break-all max-h-[90px] text-sm"}>{
                                getConditionType()
                            }</div>
                        </div>
                        {alert.metricAlert.monitorEvaluation.monitorEvaluationType == EvalType.Static &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Missing Data</div>
                                <div className={"text-textmedium"}>{getMissingDatapointTreatmentType()}</div>
                            </div>}
                        {alert.metricAlert.filters.filters && Object.entries(alert.metricAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && formatFilters(new Map(Object.entries(alert.metricAlert.filters.filters)))
                                }</div>
                            </div>}
                        {alert.metricAlert.filters.excludeFilters && Object.entries(alert.metricAlert.filters.excludeFilters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Exclude Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && formatFilters(new Map(Object.entries(alert.metricAlert.filters.excludeFilters)), true)
                                }</div>
                            </div>}
                        {alert.metricAlert.filters.splits && alert.metricAlert.filters.splits.length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Group by</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && alert.metricAlert.filters.splits.join(", ")
                                }</div>
                            </div>}
                    </div>
                }
                {
                    alert.type === "KubernetesResource" && alert.kubernetesResourceAlert != null &&
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Condition</div>
                            <div className={"text-textmedium line-clamp-3 break-all max-h-[90px] text-sm"}>{
                                getConditionType()
                            }</div>
                        </div>
                        {alert.kubernetesResourceAlert.monitorEvaluation.monitorEvaluationType == EvalType.Static &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Missing Data</div>
                                <div className={"text-textmedium"}>{getMissingDatapointTreatmentType()}</div>
                            </div>}
                        {alert.kubernetesResourceAlert.filters.filters && Object.entries(alert.kubernetesResourceAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.kubernetesResourceAlert.filters && formatFilters(new Map(Object.entries(alert.kubernetesResourceAlert.filters.filters)))
                                }</div>
                            </div>}
                        {alert.kubernetesResourceAlert.filters.excludeFilters && Object.entries(alert.kubernetesResourceAlert.filters.excludeFilters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Exclude Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.kubernetesResourceAlert.filters && formatFilters(new Map(Object.entries(alert.kubernetesResourceAlert.filters.excludeFilters)), true)
                                }</div>
                            </div>}
                        {alert.kubernetesResourceAlert.filters.splits && alert.kubernetesResourceAlert.filters.splits.length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Group by</div>
                                <div className={"text-textmedium"}>{
                                    alert.kubernetesResourceAlert.filters && alert.kubernetesResourceAlert.filters.splits.join(", ")
                                }</div>
                            </div>}
                    </div>
                }
                {
                    alert.type === "Trace" && alert.traceAlert != null && <div className={"flex flex-col gap-4"}>
                        {alert.traceAlert.monitorEvaluation.monitorEvaluationType == EvalType.Static &&
                            <div className={"flex gap-4"}>
                                <div className={"flex gap-4"}>
                                    <div className={"flex-none text-textmedium font-bold w-[120px]"}>Missing Data</div>
                                    <div className={"text-textmedium"}>{getMissingDatapointTreatmentType()}</div>
                                </div>
                            </div>}
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Condition</div>
                            <div className={"text-textmedium line-clamp-3 break-all max-h-[90px] text-sm"}>{
                                getConditionType()
                            }</div>
                        </div>
                        {alert.traceAlert.filters.filters && Object.entries(alert.traceAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && formatFilters(new Map(Object.entries(alert.traceAlert.filters.filters)))
                                }</div>
                            </div>}
                        {alert.traceAlert.filters.excludeFilters && Object.entries(alert.traceAlert.filters.excludeFilters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Exclude Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && formatFilters(new Map(Object.entries(alert.traceAlert.filters.excludeFilters || {})), true)
                                }</div>
                            </div>}
                        {alert.traceAlert.filters.splits && alert.traceAlert.filters.splits.length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Group by</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && alert.traceAlert.filters.splits.join(", ")
                                }</div>
                            </div>}
                    </div>
                }
            </div>
        </div>
    </div>
}

function formatFilters(filters: Map<string, string[]>, isExclude: boolean = false) {
    let formattedFilters = ""
    filters.forEach((value, key) => {
        formattedFilters += key + (isExclude ? " != " : " = ") + value.join(" || ") + " "
    })
    return formattedFilters
}

interface AlertFire {
    uuid: string;
    alertUuid: string;
    organizationUuid: string;
    startTime: number;
    endTime: number;
    message: string;
    deepLink: string;
    kubernetesEnvironment: string;
    kubernetesService: string;
    properties: MetricAlertFireProperties;
}

interface MetricAlertFireProperties {
    metricFilters: Map<string, string[]>;
    metricAttributes: Map<string, string>;
    metricName: string;
    aggregation: string;
    datapointBreaching: DataPoint;
}

interface DataPoint {
    time: number;
    value: number;
}

function AlertFiringHistoryList(props: {
    alertFires: AlertFire[],
    alertType: string,
    evaluationType: EvalType,
    evaluationFunction: AggregationFunction
}) {
    const isLogs = props.alertType == "Log";
    const isKubernetesResourceAlert = props.alertType == "KubernetesResource";
    const isKubernetesAlert = props.alertType == "Kubernetes";
    const isMetricAlert = props.alertType == "Metric";
    const isTraceAlert = props.alertType == "Trace";
    const isMetricAttributePropsSet = props.alertFires.find((alertFire) => {
        if (alertFire.properties && alertFire.properties.metricAttributes) {
            return new Map(Object.entries(alertFire.properties.metricAttributes)).size > 0
        } else {
            return false
        }
    }) != undefined;


    return <div className="min-w-0 bg-backgroundmedium border-b min-h-0 w-full flex flex-col grow shrink">
        <div
            className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
            <div className="h-full w-[240px] font-normal leading-8 text-textmedium text-xl">Start
            </div>
            <div
                className="h-full w-[240px] shrink font-normal  leading-8 text-textmedium text-xl">End
            </div>
            {(isLogs || isKubernetesAlert) &&
                <div className="h-full w-[160px] font-normal leading-8 text-textmedium text-xl">Cluster
                </div>}
            {(isLogs || isKubernetesAlert) &&
                <div className="h-full w-[360px] font-normal leading-8 text-textmedium text-xl">Service
                </div>}
            {(isMetricAlert || isTraceAlert || isKubernetesResourceAlert) && isMetricAttributePropsSet &&
                <div className="h-full w-[360px] font-normal leading-8 text-textmedium text-xl truncate">Attributes
                </div>}
            {(isMetricAlert || isTraceAlert || isKubernetesResourceAlert) &&
                <div className="h-full w-[160px] font-normal leading-8 text-textmedium text-xl truncate">Alert Message
                </div>}
        </div>
        <div className="flex flex-col grow shrink overflow-y-auto">
            {props.alertFires.length === 0 && <div className={"flex justify-center items-center text-textdark text-lg"}>
                <div className={"p-2"}>
                    No alerts fired yet
                </div>
                <SmileIcon/>
            </div>}
            {props.alertFires.length > 0 &&
                props.alertFires.map((alertFire) => {
                    let splitService: string[] = []
                    if (!isMetricAlert && !isTraceAlert) {
                        // If not a metric alert, then its a kubernetes or log alert so we can get the service.
                        splitService = alertFire.kubernetesService.split("/");
                    }
                    const metricAttributes = []
                    const metricAttributeMap = alertFire.properties && alertFire.properties.metricAttributes ? new Map(Object.entries(alertFire.properties.metricAttributes)) : new Map();
                    for (let key of metricAttributeMap.keys()) {
                        let value = metricAttributeMap.get(key);
                        metricAttributes.push(`${key}: ${value}`);
                    }
                    const unknownTimeStr = "unknown time"
                    const datapointBreachTime = alertFire.properties?.datapointBreaching?.time
                        ? displayDateTimeWithSelectedFormat(new Date(alertFire.properties.datapointBreaching.time), [DateTimePrecision.Month, DateTimePrecision.Day, DateTimePrecision.Hours, DateTimePrecision.Minutes, DateTimePrecision.Seconds, DateTimePrecision.Milliseconds])
                        : unknownTimeStr
                    let message;
                    const value = alertFire.properties?.datapointBreaching?.value

                    // Fill in missing value with "missing" string.
                    let metricValueString = value + " (missing)"
                    if (value !== null) {
                        metricValueString = value.toFixed(2)
                    }
                    let datapointBreachTimeString = " at " + datapointBreachTime
                    if (datapointBreachTime === unknownTimeStr) {
                        datapointBreachTimeString = ""
                    }

                    if (props.alertType == "Metric") {
                        message = "Metric " + alertFire.properties.metricName + " was " + metricValueString + datapointBreachTimeString
                        if (props.evaluationType === EvalType.Aggregate) {
                            message = props.evaluationFunction + " of metric " + alertFire.properties.metricName + " is " + metricValueString + datapointBreachTimeString
                        }
                    } else if (props.alertType == "Trace") {
                        message = alertFire.properties.aggregation + " was " + metricValueString + datapointBreachTimeString
                        if (props.evaluationType === EvalType.Aggregate) {
                            message = props.evaluationFunction + " of " + alertFire.properties.aggregation + " is " + metricValueString + datapointBreachTimeString
                        }
                    } else {
                        message = alertFire.message
                    }

                    return <div
                        className="w-full flex-none h-[64px] px-4 py-2 justify-start items-start gap-4 flex hover:bg-backgrounddark">
                        <div className="h-full w-[240px] font-normal leading-8 text-textmedium truncate">
                            {hdate.prettyPrint(new Date(alertFire.startTime * 1000), {showTime: true})}
                        </div>
                        <div
                            className="h-full w-[240px] shrink font-normal leading-8 text-textmedium truncate">
                            {
                                alertFire.endTime === null ? "Ongoing" :
                                    hdate.prettyPrint(new Date(alertFire.endTime * 1000), {showTime: true})
                            }
                        </div>
                        {(isLogs || isKubernetesAlert) &&
                            <div className="h-full w-[160px] font-normal leading-8 text-textmedium truncate">
                                {alertFire.kubernetesEnvironment}
                            </div>}
                        {(isLogs || isKubernetesAlert) &&
                            <div className="h-full w-[360px] font-normal leading-8 text-textmedium truncate">
                                {splitService[splitService.length - 1]}
                            </div>}
                        {(isMetricAlert || isTraceAlert || isKubernetesResourceAlert) && isMetricAttributePropsSet &&
                            <div className="h-full w-[360px] font-normal leading-tight text-textmedium text-wrap">
                                {metricAttributes.join(", ")}
                            </div>}
                        {(isMetricAlert || isTraceAlert || isKubernetesResourceAlert) &&
                            <div className="h-full font-normal leading-8 text-textmedium truncate">
                                {message}
                            </div>}
                    </div>
                })
            }
        </div>
    </div>
}

function alertFiresToGanttData(alertFires: AlertFire[], startTime: Date, endTime: Date) {
    let cells = alertFires.map((alertFire) => {
        // Use datapoint breach time if available and non-zero, otherwise fall back to alert start time
        const startTimeToUse = alertFire.properties?.datapointBreaching?.time && alertFire.properties.datapointBreaching.time !== 0
            ? new Date(alertFire.properties.datapointBreaching.time) // Convert from Unix timestamp
            : new Date(alertFire.startTime * 1000);
            
        return {
            startTime: startTimeToUse,
            endTime: alertFire.endTime === null ? new Date() : new Date(alertFire.endTime * 1000),
            text: "Alert",
            color: "rgb(239 68 68)",
            onClick: () => {
            },
        }
    });
    const startTimeAdjustedForBucket = new Date(getStartOfBucket(startTime.getTime(), DateTimePrecision.Minutes));
    let baseline = {
        startTime: startTimeAdjustedForBucket, endTime: endTime, text: "Ok", color: "rgb(34 197 94)", onClick: () => {
        }
    };
    cells.push(baseline);

    return {
        barThickness: 20,
        tooltipCallback: (tooltipItem: any) => {
            return hdate.prettyPrint(new Date(tooltipItem.raw[0]), {showTime: true}) + " - " + hdate.prettyPrint(new Date(tooltipItem.raw[1]), {showTime: true})
        },
        startTime: startTimeAdjustedForBucket,
        endTime: endTime,
        rows: [
            {
                label: "Status",
                cells: cells
            }
        ]
    }
}

function updateAlertFires(timeRange: TimeRange, alertId: string | null, setAlertFires: (value: (((prevState: AlertFire[]) => AlertFire[]) | AlertFire[])) => void) {
    // unix seconds
    const start = Math.floor(timeRange.getStartEnd()[0].getTime()! / 1000);
    const end = Math.floor(timeRange.getStartEnd()[1].getTime()! / 1000);

    axios.get(`/api/v1/alertFires?alertId=${alertId}&startTime=${start}&endTime=${end}`)
        .then((res) => setAlertFires(res.data.alertFires))
}

function updateAlert(alertId: string, setAlert: (value: (((prevState: (ApiServerAlert | undefined)) => (ApiServerAlert | undefined)) | ApiServerAlert | undefined)) => void) {
    axios.get<ApiServerAlert>(`/api/v1/alert?alertId=${alertId}`)
        .then((res) =>
            setAlert(adjustAlertThreshold(res.data))
        )
}

function extractMonitorEvaluationObj(alert: ApiServerAlert) {
    if ((alert && alert.type == "Metric" && alert.metricAlert) || (alert && alert.type == "Trace" && alert.traceAlert) || (alert && alert.type == "KubernetesResource" && alert.kubernetesResourceAlert)) {
        const typedAlert = alert.metricAlert! || alert.traceAlert! || alert.kubernetesResourceAlert!
        const monitorEvaluationObj = {
            monitorEvaluationType: typedAlert.monitorEvaluation.monitorEvaluationType,
            description: typedAlert.monitorEvaluation.description,
            monitorEvalutionPayload: typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Static ?
                {
                    evaluationWindow: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationWindow,
                    datapointsToAlarm: typedAlert.monitorEvaluation.monitorEvalutionPayload.datapointsToAlarm
                } as StaticMonitorEvaluationPayload
                : {
                    evaluationFunction: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction,
                    evaluationSplits: typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits,
                    window: typedAlert.monitorEvaluation.monitorEvalutionPayload.window,
                    windowUnit: typedAlert.monitorEvaluation.monitorEvalutionPayload.windowUnit
                } as AggregateMonitorEvaluationPayload,
        };
        return monitorEvaluationObj as MonitorEvaluation
    }
    return {} as MonitorEvaluation
}

function Alert() {
    const [searchParams, setSearchParams] = useSearchParams();
    const alertId = searchParams.get("alertId");
    const [alert, setAlert] = React.useState<ApiServerAlert>();
    const [alertFires, setAlertFires] = React.useState<AlertFire[]>([]);
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const debouncedUpdateAlertFires = useDebouncedCallback(updateAlertFires, 10);
    const debouncedUpdateAlert = useDebouncedCallback(updateAlert, 10);

    useEffect(() => {
        debouncedUpdateAlertFires(timeRange, alertId, setAlertFires);
    }, [alertId, timeRange]);


    useEffect(() => {
        if (alertId) {
            debouncedUpdateAlert(alertId, setAlert);
        }
    }, [alertId]);

    let alertEvaluationType = EvalType.Static
    let alertEvaluationFunction = AggregationFunction.Sum
    let annotationProps;
    if (alert && alert.type == "Metric" && alert.metricAlert) {
        const monitorEvaluationObj = extractMonitorEvaluationObj(alert);
        annotationProps = getGraphAnnotationProperties(
            monitorEvaluationObj,
            timeRange,
            parseFloat(alert.metricAlert.alarmCondition.threshold),
            alert.metricAlert.alarmCondition.condition
        )
        alertEvaluationType = alert.metricAlert.monitorEvaluation.monitorEvaluationType
        if (monitorEvaluationObj.monitorEvaluationType === EvalType.Aggregate) {
            alertEvaluationFunction = alert.metricAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction
        }
    } else if (alert && alert.type == "Trace" && alert.traceAlert) {
        const monitorEvaluationObj = extractMonitorEvaluationObj(alert)
        annotationProps = getGraphAnnotationProperties(
            monitorEvaluationObj,
            timeRange,
            parseFloat(alert.traceAlert.alarmCondition.threshold),
            alert.traceAlert.alarmCondition.condition
        )
        alertEvaluationType = alert.traceAlert.monitorEvaluation.monitorEvaluationType
        if (monitorEvaluationObj.monitorEvaluationType === EvalType.Aggregate) {
            alertEvaluationFunction = alert.traceAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction
        }
    } else {
        annotationProps = {thresholdToSet: 0, timePeriodToHighlight: [], annotationLabel: ""}
    }

    return <BaseView title={"Alert"} disableClusterSelector={true}>
        <div className={"flex flex-col overflow-y-auto gap-4 m-4 grow shrink min-h-0 min-w-0"}>
            {alert && alert.muted &&
                <div
                    className={"flex items-center justify-center gap-2 py-2 text-lg border bg-amber-500/10 border-amber-500 rounded text-amber-500"}>
                    <BellOffIcon className={"w-5 h-6"}/>
                    Muted -
                    <div className={"text-base"}> You will not get notified when this alert fires. </div>
                </div>}
            <div className={"text-textmedium border flex h-max bg-backgroundmedium rounded"}>
                <AlertDetails alert={alert} setAlert={setAlert}/>
            </div>
            {alert && alert.type == "Metric" && alert.metricAlert &&
                <div className={"flex flex-col"}>
                    <div className={"flex relative h-[300px]"}>
                        <MetoroMetricsChart
                            className={"flex flex-grow"}
                            startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                            endTime={Math.floor((timeRange.getStartEnd()[1].getTime()) / 1000)}
                            metricName={alert.metricAlert.filters.metricName}
                            aggregation={alert.metricAlert.filters.aggregation}
                            bucketSize={alert.metricAlert.filters.bucketSize}
                            type={ChartType.Line}
                            filters={new Map(Object.entries(alert.metricAlert.filters.filters))}
                            excludeFilters={new Map(Object.entries(alert.metricAlert.filters.excludeFilters || {}))}
                            splits={alert.metricAlert.filters.splits}
                            title={`${alert.metricAlert.filters.metricName}-${alert.metricAlert.filters.aggregation}`}
                            threshold={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.metricAlert.alarmCondition.threshold), alert.metricAlert.alarmCondition.condition).thresholdToSet}
                            thresholdLabel={annotationProps.annotationLabel}
                            timePeriodHighlight={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.metricAlert.alarmCondition.threshold), alert.metricAlert.alarmCondition.condition).timePeriodToHighlight}
                            metricType={MetricType.Metric}
                            hideLegend={false}
                            functions={alert.metricAlert.filters.functions}
                        />
                    </div>
                </div>}
            {alert && alert.type == "Trace" && alert.traceAlert &&
                <div className={"flex flex-col"}>
                    <div className={"flex relative h-[300px]"}>
                        <MetoroMetricsChart
                            className={"flex flex-grow"}
                            startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                            endTime={Math.floor((timeRange.getStartEnd()[1].getTime()) / 1000)}
                            metricName={""}
                            aggregation={alert.traceAlert.filters.aggregation}
                            bucketSize={alert.traceAlert.filters.bucketSize}
                            type={ChartType.Line}
                            filters={new Map(Object.entries(alert.traceAlert.filters.filters))}
                            excludeFilters={new Map(Object.entries(alert.traceAlert.filters.excludeFilters || {}))}
                            splits={alert.traceAlert.filters.splits}
                            title={`${alert.traceAlert.filters.aggregation}`}
                            threshold={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.traceAlert.alarmCondition.threshold), alert.traceAlert.alarmCondition.condition).thresholdToSet}
                            thresholdLabel={annotationProps.annotationLabel}
                            timePeriodHighlight={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.traceAlert.alarmCondition.threshold), alert.traceAlert.alarmCondition.condition).timePeriodToHighlight}
                            metricType={MetricType.Trace}
                            hideLegend={false}
                            functions={alert.traceAlert.filters.functions}
                        />
                    </div>
                </div>}
            {alert && alert.type == "KubernetesResource" && alert.kubernetesResourceAlert &&
                <div className={"flex flex-col"}>
                    <div className={"flex relative h-[300px]"}>
                        <MultiMetoroMetricsChart
                            className={"flex flex-grow"}
                            startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                            endTime={Math.floor((timeRange.getStartEnd()[1].getTime()) / 1000)}
                            metricSpecifiers={
                                [
                                    {
                                        metricName: "Kubernetes Resource",
                                        aggregation: alert.kubernetesResourceAlert.filters.aggregation,
                                        bucketSize: alert.kubernetesResourceAlert.filters.bucketSize,
                                        filters: new Map(Object.entries(alert.kubernetesResourceAlert.filters.filters)),
                                        excludeFilters: new Map(Object.entries(alert.kubernetesResourceAlert.filters.excludeFilters || {})),
                                        splits: alert.kubernetesResourceAlert.filters.splits,
                                        functions: alert.kubernetesResourceAlert.filters.functions,
                                        metricType: MetricType.Kubernetes,
                                        jsonPath: alert.kubernetesResourceAlert.filters.jsonPath
                                    }
                                ]
                            }
                            type={ChartType.Line}
                            title={`${alert.kubernetesResourceAlert.filters.aggregation}`}
                            threshold={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.kubernetesResourceAlert.alarmCondition.threshold), alert.kubernetesResourceAlert.alarmCondition.condition).thresholdToSet}
                            thresholdLabel={annotationProps.annotationLabel}
                            timePeriodHighlight={getGraphAnnotationProperties(extractMonitorEvaluationObj(alert), timeRange, parseFloat(alert.kubernetesResourceAlert.alarmCondition.threshold), alert.kubernetesResourceAlert.alarmCondition.condition).timePeriodToHighlight}
                            hideLegend={false}
                        />
                    </div>
                </div>}
            <div className={"text-textmedium border flex flex-none flex-col h-[128px] bg-backgroundmedium"}>
                <div className={"text-lg font-semibold p-4"}>
                    Firing History
                </div>
                <div className={"px-2"}>
                    <GanttChart
                        data={alertFiresToGanttData(alertFires, timeRange.getStartEnd()[0], timeRange.getStartEnd()[1])}/>
                </div>

            </div>
            {alert &&
                <div className={"text-textmedium border flex grow shrink bg-backgroundmedium min-h-[400px] min-w-0"}>
                    <AlertFiringHistoryList alertFires={alertFires} alertType={alert.type}
                                            evaluationType={alertEvaluationType}
                                            evaluationFunction={alertEvaluationFunction}/>
                </div>}
        </div>
    </BaseView>
}

export {
    Alert
}