import React, { useEffect, useRef } from 'react';
import { cn } from '../ui/lib/utils';
import { TimeSeries, Metric, GetMetric, GetMetricRequest } from '../../clients/metoro/metrics';
import * as d3 from 'd3';
import visavail from 'visavail';
import axios from "utility/customAxios";

interface UptimeMonitorChartProps {
    title: string;
    statusMetricName: string;
    responseTimeMetricName: string;
    startTime: number;
    endTime: number;
    onTimeRangeChange?: (startTime: number, endTime: number) => void;
    org: string;
}

interface DataPoint {
    time: number;
    value: number;
}

interface BucketStats {
    successCount: number;
    failureCount: number;
    averageLatency: number;
    timestamp: number;
    latencySum: number;
    latencyCount: number;
}

interface MetricTimeSeries {
    data: DataPoint[];
    attributes: {
        status: string;
    };
}

export function UptimeMonitorChart({
    title,
    statusMetricName,
    responseTimeMetricName,
    startTime,
    endTime,
    onTimeRangeChange,
    org
}: UptimeMonitorChartProps) {
    const [uptimePercentage, setUptimePercentage] = React.useState(100);
    const [isLoading, setIsLoading] = React.useState(true);
    const [lastUpdated, setLastUpdated] = React.useState<Date>(new Date());
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<any>(null);
    const chartId = `visavail_${title.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase()}`;
    const [responseTimeData, setResponseTimeData] = React.useState<Map<number, number>>(new Map());
    const [stats, setStats] = React.useState({
        successCount: 0,
        failureCount: 0,
        averageLatency: 0
    });
    const [bucketStats, setBucketStats] = React.useState<Map<number, BucketStats>>(new Map());
    const [hoverStats, setHoverStats] = React.useState<{
        timestamp: number;
        status: string;
        bucketStats?: BucketStats;
    } | null>(null);

    const getBucketSize = (startTime: number, endTime: number) => {
        const timeDiff = endTime - startTime;
        if (timeDiff <= 3600) { // 1 hour
            return 60;
        } else if (timeDiff <= 86400) { // 1 day
            return 300;
        } else {
            return 3600;
        }
    }

    const roundedStartTime = Math.floor(startTime / 60) * 60;
    const roundedEndTime = Math.floor(endTime / 60) * 60;

    const bucketSize = getBucketSize(startTime, endTime);

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const formatTimeLabel = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;

        return `${day}${getDaySuffix(day)} ${month}, ${formattedHours}:${minutes}${ampm}`;
    };

    const getDaySuffix = (day: number): string => {
        if (day >= 11 && day <= 13) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            // Calculate rounded times for consistent bucket alignment
            const roundedStartTime = Math.floor(startTime / bucketSize) * bucketSize;
            const roundedEndTime = Math.ceil(endTime / bucketSize) * bucketSize;

            // Fetch both status and response time metrics using the public endpoint
            const [statusResponse, responseTimeResponse] = await Promise.all([
                axios.post(`/api/v1/metric/${org}/${statusMetricName}`, {
                    metricName: statusMetricName,
                    startTime: roundedStartTime,
                    endTime: roundedEndTime,
                    aggregation: "sum",
                    splits: ["status"],
                    filters: new Map(),
                    functions: [],
                    limitResults: false,
                    bucketSize: bucketSize
                } as GetMetricRequest),
                axios.post(`/api/v1/metric/${org}/${responseTimeMetricName}`, {
                    metricName: responseTimeMetricName,
                    startTime: roundedStartTime,
                    endTime: roundedEndTime,
                    aggregation: "avg",
                    splits: [],
                    filters: new Map(),
                    functions: [],
                    limitResults: false,
                    bucketSize: bucketSize
                } as GetMetricRequest)
            ]);

            // Process response time data into a map for easy lookup
            const responseTimeMap = new Map();
            responseTimeResponse.data.metric.timeSeries[0]?.data.forEach((point: DataPoint) => {
                responseTimeMap.set(point.time, point.value);
            });
            setResponseTimeData(responseTimeMap);

            // Process the data for Visavail format
            const statusData = statusResponse.data.metric.timeSeries as unknown as MetricTimeSeries[];
            let totalUptime = 0;
            let totalTime = 0;

            // Find success and failure series
            const successSeries = statusData.find(series => series.attributes.status === "success");
            const failureSeries = statusData.find(series => series.attributes.status === "failure");

            if (!successSeries || !failureSeries) {
                console.error('Missing success or failure series');
                return false;
            }

            // Track bucket-level statistics
            const bucketStatsMap = new Map<number, BucketStats>();

            // Process each timestamp
            successSeries.data.forEach((successPoint: DataPoint, index: number) => {
                const failurePoint = failureSeries.data[index];
                if (!failurePoint || successPoint.time !== failurePoint.time) {
                    console.error('Mismatched timestamps between success and failure series');
                    return;
                }

                const timestamp = successPoint.time;
                const bucketStart = Math.floor(timestamp / 1000 / bucketSize) * bucketSize;
                const successCount = Math.round(successPoint.value);
                const failureCount = Math.round(failurePoint.value);
                const latency = responseTimeMap.get(timestamp);

                // Update bucket stats
                const existingBucketStats = bucketStatsMap.get(bucketStart) || {
                    successCount: 0,
                    failureCount: 0,
                    averageLatency: 0,
                    timestamp: bucketStart,
                    latencySum: 0,
                    latencyCount: 0
                };

                existingBucketStats.successCount += successCount;
                existingBucketStats.failureCount += failureCount;
                if (latency !== undefined) {
                    existingBucketStats.latencySum = (existingBucketStats.latencySum || 0) + latency;
                    existingBucketStats.latencyCount = (existingBucketStats.latencyCount || 0) + 1;
                    existingBucketStats.averageLatency = existingBucketStats.latencySum / existingBucketStats.latencyCount;
                }

                bucketStatsMap.set(bucketStart, existingBucketStats);

                // Update totals
                totalUptime += successCount;
                totalTime += successCount + failureCount;
            });

            setBucketStats(bucketStatsMap);

            // Calculate overall statistics
            let totalSuccessCount = 0;
            let totalFailureCount = 0;
            let totalLatency = 0;
            let latencyCount = 0;

            bucketStatsMap.forEach(stats => {
                totalSuccessCount += stats.successCount;
                totalFailureCount += stats.failureCount;
                if (stats.averageLatency > 0) {
                    totalLatency += stats.averageLatency;
                    latencyCount++;
                }
            });

            setStats({
                successCount: totalSuccessCount,
                failureCount: totalFailureCount,
                averageLatency: latencyCount > 0 ? totalLatency / latencyCount : 0
            });

            // Calculate uptime percentage
            const percentage = totalTime > 0 ? (totalUptime / totalTime) * 100 : 100;
            setUptimePercentage(percentage);

            // Convert to Visavail format
            const visavailData = successSeries.data.map((successPoint: DataPoint, index: number) => {
                const failurePoint = failureSeries.data[index];
                const timestamp = successPoint.time;
                // Consider the point successful if there are any successes and no failures
                const isSuccessful = Math.round(successPoint.value) > 0 && Math.round(failurePoint.value) === 0;

                return [
                    formatDate(timestamp),
                    isSuccessful ? 1 : 0,
                    formatDate(timestamp + Math.round(0.75 * bucketSize * 1000))
                ];
            });

            // Add dummy points at start and end if needed
            const firstPoint = visavailData[0];
            const lastPoint = visavailData[visavailData.length - 1];
            const paddedData = [];

            // Add start dummy point if needed
            if (firstPoint && new Date(firstPoint[0]).getTime() > roundedStartTime * 1000) {
                paddedData.push([
                    formatDate(roundedStartTime * 1000),
                    0,
                    formatDate(roundedStartTime * 1000 + 1000) // 1 second duration
                ]);
            }

            // Add actual data
            paddedData.push(...visavailData);

            // Add end dummy point if needed
            if (lastPoint && new Date(lastPoint[0]).getTime() < roundedEndTime * 1000) {
                paddedData.push([
                    formatDate(roundedEndTime * 1000 - 1000), // 1 second before end
                    0,
                    formatDate(roundedEndTime * 1000)
                ]);
            }

            // Update or create chart
            if (chartInstance.current) {
                chartInstance.current.instance.updateGraph([{
                    measure: "",
                    data: paddedData
                }]);
                chartInstance.current.data = [{
                    measure: "",
                    data: paddedData
                }];
                // Reattach hover handlers after updating the chart
                handleHover();
            } else {
                renderChart([{
                    measure: "",
                    data: paddedData
                }]);
            }

            // Check last actual datapoint for current status
            const isCurrentlyOperational = lastPoint ? lastPoint[1] === 1 : true;

            setLastUpdated(new Date());
            return isCurrentlyOperational;

        } catch (error) {
            console.error('Error fetching monitor data:', error);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const [isOperational, setIsOperational] = React.useState(true);

    useEffect(() => {
        fetchData().then(setIsOperational);
    }, [statusMetricName, responseTimeMetricName, roundedStartTime, roundedEndTime]);

    // Add refresh interval
    useEffect(() => {
        const interval = setInterval(() => {
            fetchData().then(setIsOperational);
        }, 60000); // Refresh every minute

        return () => clearInterval(interval);
    }, [startTime, endTime]);

    // Add window resize handler
    useEffect(() => {
        const handleResize = () => {
            if (!chartRef.current || !chartInstance.current?.data) {
                return;
            }

            // Clear existing chart
            const container = document.getElementById(`${chartId}_graph`);
            if (container) {
                container.innerHTML = '';
            }

            // Re-render with current data and new width
            const currentData = chartInstance.current.data;
            
            // Store the data temporarily and clear the instance
            const tempData = currentData;
            chartInstance.current = null;
            
            // Re-render with the stored data
            renderChart(tempData);
        };

        // Add resize observer for the chart container
        const resizeObserver = new ResizeObserver(() => {
            window.requestAnimationFrame(handleResize);
        });

        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }

        // Also handle window resize events
        window.addEventListener('resize', handleResize);

        return () => {
            // Clean up
            if (chartRef.current) {
                resizeObserver.unobserve(chartRef.current);
            }
            resizeObserver.disconnect();
            window.removeEventListener('resize', handleResize);
        };
    }, [bucketStats, bucketSize]);

    // Move handleHover outside of useEffect so it can be used by resize handler
    const handleHover = () => {
        const chart = d3.select(`#${chartId}_graph`);
        const rects = chart.selectAll('rect');
        const tooltipContainer = d3.select(`#${chartId}_tooltip`);

        rects.on('mouseover', function(event) {
            const rect = d3.select(this);
            const datum = rect.datum() as any;
            if (!datum || !datum[0]) return;

            const date = new Date(datum[0]);
            if (isNaN(date.getTime())) return; // Skip if date is invalid

            const timestamp = date.getTime() / 1000;
            const bucketStart = Math.floor(timestamp / bucketSize) * bucketSize;
            const bucketStat = bucketStats.get(bucketStart);
            const isOperational = datum[1] === 1;

            const tooltipContent = `
                <div class="flex flex-col gap-2 min-w-[300px]">
                    <div class="flex items-center justify-between gap-4">
                        <div class="text-textmedium text-sm">${formatTimeLabel(timestamp)}</div>
                        <div class="${isOperational ? 'text-green-500' : 'text-red-500'} text-sm font-medium">
                            ${isOperational ? 'Operational' : 'Degraded'}
                        </div>
                    </div>
                    ${bucketStat ? `
                        <div class="flex rounded bg-backgroundmedium border border-border">
                            <div class="flex-1 flex items-center justify-center py-1.5 px-4 border-r border-border">
                                <div class="flex items-center gap-2">
                                    <div class="text-textdark text-xs">Successes</div>
                                    <div class="text-green-500 font-medium">${bucketStat.successCount}</div>
                                </div>
                                <div class="mx-2 text-textdark">/</div>
                                <div class="flex items-center gap-2">
                                    <div class="text-textdark text-xs">Failures</div>
                                    <div class="text-red-500 font-medium">${bucketStat.failureCount}</div>
                                </div>
                            </div>
                            <div class="flex-1 flex items-center justify-center py-1.5 px-4 gap-2">
                                <div class="text-textdark text-xs">Latency</div>
                                <div class="text-textlight font-medium">${bucketStat.averageLatency.toFixed(2)}ms</div>
                            </div>
                        </div>
                    ` : ''}
                </div>
            `;

            tooltipContainer
                .style('display', 'block')
                .html(tooltipContent);
        });

        rects.on('mouseout', function() {
            tooltipContainer.style('display', 'none');
        });
    };

    // Update hover effect when chart instance changes
    useEffect(() => {
        if (chartInstance.current) {
            handleHover();
        }
    }, [chartInstance.current, bucketStats, bucketSize]);

    const renderChart = (data: any) => {
        const isMobile = window.innerWidth < 640; // sm breakpoint

        const options = {
            id_div_container: chartId,
            id_div_graph: `${chartId}_graph`,
            date_in_utc: true,
            line_spacing: 0,
            title: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            heading: {
                enabled: false
            },
            sub_heading: {
                enabled: false
            },
            y_axis_label: {
                enabled: false
            },
            custom_categories: {
                enabled: true,
                "0": { 
                    class: "rect_has_no_data",
                    tooltip_html: '<i class="tooltip_has_no_data"></i>'
                },
                "1": { 
                    class: "rect_has_data", 
                    tooltip_html: '<i class="tooltip_has_data"></i>'
                }
            },
            display_date_range: false,
            date_range: {
                enabled: false
            },
            info_text: {
                enabled: false
            },
            margin: {
                top: 16,
                bottom: 0,
                right: 0,
                left: 0
            },
            tooltip: {
                enabled: false
            },
            ticks_for_graph: 0,
            icon: {
                class_has_data: 'fas fa-fw fa-check',
                class_has_no_data: 'fas fa-fw fa-exclamation-circle'
            },
            responsive: {
                enabled: true
            },

            graph: {
                width: chartRef.current?.clientWidth || 800,
                height: isMobile ? 12 : 20,
                background_color: 'transparent',
                title: {
                    enabled: false
                },
                type: "bar" as "bar" | "rhombus" | "circle"
            },
            y_axis: {
                enabled: false,
                line: {
                    enabled: false
                }
            },
            y_percentage_padding: 0,
            defined_blocks: true,
            x_axis: {
                enabled: true,
                tick_format: "%H:%M",
                ticks_count: 7,
                grid: {
                    enabled: false
                },
                axis: {
                    enabled: false
                },
                ticks: {
                    enabled: false
                },
                line: {
                    enabled: false
                }
            },
            zoom: {
                enabled: !isMobile,
                onZoom: (e: [Date, Date]) => {
                    // Convert the dates to Unix timestamps in seconds
                    const newStartTime = Math.floor(e[0].getTime() / 1000);
                    const newEndTime = Math.floor(e[1].getTime() / 1000);
                    // Update the time range
                    if (onTimeRangeChange) {
                        onTimeRangeChange(newStartTime, newEndTime);
                    }
                }
            },
            onHover: (timestamp: string, hasData: boolean) => {
                if (!timestamp) {
                    setHoverStats(null);
                    return;
                }
                const date = new Date(timestamp);
                const unixTimestamp = Math.floor(date.getTime() / 1000);
                const bucketStart = Math.floor(unixTimestamp / bucketSize) * bucketSize;
                const bucketStat = bucketStats.get(bucketStart);
                setHoverStats({
                    timestamp: unixTimestamp,
                    status: hasData ? "Operational" : "Degraded",
                    bucketStats: bucketStat
                });
            }
        };

        // Store the data before generating the chart
        const chartData = Array.isArray(data) ? data : [data];
        chartInstance.current = {
            data: chartData,
            instance: visavail.generate(options, chartData)
        };
        
        // Attach hover handlers after chart is rendered
        handleHover();
    };

    return (
        <div className="flex flex-col gap-0 p-4 bg-backgroundlight rounded border">
            <div className="flex flex-col gap-4">
                {/* Title and Status Row */}
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold text-textmedium">{title}</h3>
                        <div className={cn(
                            "px-2 py-1 rounded text-sm font-medium whitespace-nowrap",
                            isOperational
                                ? "bg-green-500/10 text-green-500"
                                : "bg-red-500/10 text-red-500"
                        )}>
                            {isOperational ? "Operational" : "Degraded"}
                        </div>
                    </div>

                    {/* Stats Panel */}
                    <div className="w-full flex rounded bg-backgroundmedium border border-border">
                        <div className="flex-1 flex items-center justify-center py-1.5 px-4 border-r border-border gap-2">
                            <div className="text-textdark text-xs">Uptime</div>
                            <div className="text-textlight font-medium">{uptimePercentage.toFixed(2)}%</div>
                        </div>
                        <div className="flex-1 flex items-center justify-center py-1.5 px-4 border-r border-border gap-2">
                            <div className="text-textdark text-xs">
                                <span className="sm:hidden">Latency</span>
                                <span className="hidden sm:inline">Avg Latency</span>
                            </div>
                            <div className="text-textlight font-medium">{stats.averageLatency.toFixed(2)}ms</div>
                        </div>
                        <div className="hidden sm:flex flex-1 items-center justify-center py-1.5 px-4 gap-2">
                            <div className="text-textdark text-xs">Success/Failure</div>
                            <div className="text-textlight font-medium">
                                <span className="text-green-500">{stats.successCount}</span>
                                <span className="text-textdark mx-1">/</span>
                                <span className="text-red-500">{stats.failureCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Visavail Chart */}
            <div className="relative">
                {/* Chart container */}
                <div
                    style={{ width: "100%", overflow: "hidden" }}
                    className="visavail"
                    id={chartId}
                    ref={chartRef}
                >
                    <div id={`${chartId}_graph`} style={{ overflow: "hidden" }} />
                    {isLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-background/50">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                        </div>
                    )}
                </div>

                {/* Custom tooltip */}
                <div
                    id={`${chartId}_tooltip`}
                    className="absolute left-1/2 -translate-x-1/2 -bottom-28 hidden bg-background border border-border rounded shadow-lg p-2 pointer-events-none z-[9999]"
                />
            </div>

            {/* Time Labels */}
            <div className="flex flex-col gap-2 text-xs text-textdark">
                {/* Start/End times row */}
                <div className="flex justify-between items-center w-full sm:hidden">
                    <div>{formatTimeLabel(roundedStartTime)}</div>
                    <div>{formatTimeLabel(roundedEndTime)}</div>
                </div>
                
                {/* Mobile Updated time */}
                <div className="flex sm:hidden justify-center w-full">
                    <div className="px-2 py-1 rounded bg-primarytransparent text-primary border border-primary/20 flex items-center gap-2">
                        <div className="pulse-circle" />
                        Updated {new Date(lastUpdated).getHours() % 12 || 12}:{String(new Date(lastUpdated).getMinutes()).padStart(2, '0')}{new Date(lastUpdated).getHours() >= 12 ? 'pm' : 'am'}
                    </div>
                </div>

                {/* Desktop layout - all in one row */}
                <div className="hidden sm:flex sm:flex-row sm:justify-between sm:items-center w-full">
                    <div>{formatTimeLabel(roundedStartTime)}</div>
                    <div className="px-2 py-1 rounded bg-primarytransparent text-primary border border-primary/20 flex items-center gap-2">
                        <div className="pulse-circle" />
                        Updated {new Date(lastUpdated).getHours() % 12 || 12}:{String(new Date(lastUpdated).getMinutes()).padStart(2, '0')}{new Date(lastUpdated).getHours() >= 12 ? 'pm' : 'am'}
                    </div>
                    <div>{formatTimeLabel(roundedEndTime)}</div>
                </div>
            </div>
        </div>
    );
} 