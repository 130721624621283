import {RuntimeVariable} from "../Dashboarding/Dashboard";

function substituteVariablesInFilters(filters: Map<string, string[]>, variables: RuntimeVariable[] | undefined) {
    if (variables === undefined) {
        return filters;
    }
    // Look through all the values and swap it out with the resolved value of the variable
    let newFilters = new Map<string, string[]>();
    filters.forEach((values, key) => {
        let newValues = values.map(value => {
            let variable = variables.find(variable => "$" + variable.name === value);
            if (variable === undefined) {
                return value;
            }
            if (variable.value === "*") {
                return ""
            }
            return variable.value;
        })
        newFilters.set(key, newValues);
    })
    return newFilters
}

function substituteVariablesInText(text: string, variables: RuntimeVariable[] | undefined): string {
    if (!variables || !text) {
        return text;
    }

    let result = text;
    variables.forEach(variable => {
        const variablePattern = new RegExp('\\$' + variable.name, 'g');
        result = result.replace(variablePattern, variable.value === '*' ? 'All' : variable.value);
    });
    return result;
}

export {substituteVariablesInFilters, substituteVariablesInText}