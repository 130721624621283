import {GroupWidget, Widget} from "../internalwidgets";
import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import {cn, usePreserveQueryParamsNavigate} from "../../ui/lib/utils";
import {cloneWidget, recursiveSave, removeWidget} from "../utils";
import {WidgetDiv} from "./WidgetDiv";
import {CopyIcon, XIcon} from "lucide-react";
import {Button} from "../../ui/button";
import {MdOutlineAddchart} from "react-icons/md";
import {Drawer} from "vaul";
import {AddNewWidgetDrawer} from "../AddNewWidgetDrawer";
import {Input} from "../../ui/input";
import {Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle} from "../../ui/dialog";
import axios from "../../../utility/customAxios";
import {useSearchParams} from "react-router-dom";
import {ButtonWithTooltip} from "../Dashboard";

export const Group = (props: {
    stylesheet?: React.CSSProperties,
    editable: boolean,
    editButton?: React.ReactElement,
    saveButton?: React.ReactElement,
    fullScreen?: boolean,
    disallowDelete?: boolean,
    className?: string,
    id?: string,
    widget: GroupWidget,
    grids: Map<string, GridStack>,
    setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
    updateParentOfMount?: (id: string) => void
    ref?: React.MutableRefObject<any>
    setRootChildren?: Dispatch<SetStateAction<Widget[]>>
}) => {
    const [id] = React.useState<string>(props.id ?? "id-" + uuidv4())
    const [gridStack, setGridStack] = React.useState<GridStack>()
    const [children, setChildren] = React.useState<Widget[]>(props.widget.children)
    const [isAddWidgetOpen, setIsAddWidgetOpen] = React.useState<boolean>(false)
    const [title, setTitle] = React.useState<string>(props.widget.title ?? "Group")
    const [deleteDashboardDialogOpen, setDeleteDashboardDialogOpen] = React.useState<boolean>(false)
    const [searchParams] = useSearchParams();
    const navigate = usePreserveQueryParamsNavigate();

    useEffect(() => {
        let gridId = "grid-" + id
        let gStack = GridStack.init({
            draggable: {
                handle: '.draggablehandle'
            },
            animate: true,
            resizable: {
                handles: 's,e,w,se,sw'
            },
            subGridDynamic: true,
            acceptWidgets: true,
            float: true,
            subGridOpts: {
                column: 'auto',
            },
            column: "auto",
            cellHeight: "128px",
        }, "." + gridId);


        if (!props.editable) {
            gStack.setStatic(true)
        }

        setGridStack(gStack)

        props.setGrids((prev) => {
            let newGrids = new Map<string, GridStack>(prev)
            newGrids.set(gridId, gStack)
            return newGrids
        })

        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, props.widget)
            return newWidgets
        })

        if (props.updateParentOfMount !== undefined) {
            props.updateParentOfMount(id)
        }
    }, [])


    let leftMargin = "pl-2"
    if (props.widget.position?.x === 0) {
        leftMargin = "pl-4"
    }
    let rightMargin = "pr-2"
    if (props.widget.position?.x && props.widget.position.w &&
        props.widget.position.x + props.widget.position.w === 12) {
        rightMargin = "pr-4"
    }
    let topMargin = "pt-2"
    if (props.widget.position?.y === 0) {
        topMargin = "pt-4"
    }
    let bottomMargin = "pb-2"

    return (
        <div className={cn(id, "flex flex-col", props.className)}>
            <div className={cn("flex grow w-full h-full", leftMargin, topMargin, rightMargin, bottomMargin)}>
                <div className={cn("border flex flex-col grow shrink rounded w-full h-full")}>
                    <div className={"flex border-b draggablehandle justify-between px-2 h-8 bg-backgroundmedium"}>
                        <div
                            className={"flex text-textmedium grow shrink text-center items-center justify-center mt-[4px] truncate "}>
                            {
                                props.editable &&
                                <div className={"flex flex-col justify-center"}>
                                    <Input
                                        className={"h-4"}
                                        value={title}
                                        onChange={(e) => {
                                            props.setWidgets((prev) => {
                                                let newWidgets = new Map<string, Widget>(prev)
                                                let newWidget = {
                                                    ...props.widget,
                                                    title: e.target.value
                                                }
                                                setTitle(e.target.value)
                                                newWidgets.set(id, newWidget)
                                                return newWidgets
                                            })
                                        }}/>
                                </div>
                            }
                            {
                                !props.editable &&
                                title
                            }
                        </div>
                        <div className={"flex flex-none gap-2"}>
                            {
                                // Duplicate
                                props.editable && props.setRootChildren &&
                                <div className="flex flex-col justify-center">
                                    <ButtonWithTooltip
                                        icon={CopyIcon}
                                        tooltipText="Duplicate widget"
                                        onClick={() => {
                                            let widget = recursiveSave(id, props.widgets, props.grids);
                                            cloneWidget(widget, props.setRootChildren!)
                                        }}
                                    />
                                </div>
                            }
                            {props.editable &&
                                <div className="flex flex-col justify-center">
                                    <Drawer.Root direction="right" open={isAddWidgetOpen}>
                                        <ButtonWithTooltip
                                            icon={MdOutlineAddchart}
                                            tooltipText="Add widget"
                                            onClick={() => setIsAddWidgetOpen(true)}
                                        />
                                        <Drawer.Portal>
                                            <Drawer.Content
                                                data-vaul-no-drag
                                                draggable={false}
                                                onAbort={() => setIsAddWidgetOpen(false)}
                                                onDragEnd={() => setIsAddWidgetOpen(false)}
                                                onInteractOutside={() => setIsAddWidgetOpen(false)}
                                                className="bg-backgrounddark flex border-l flex-col h-full w-[240px] mt-24 fixed bottom-0 right-0 z-50">
                                                <AddNewWidgetDrawer setIsOpen={setIsAddWidgetOpen}
                                                                    setChildren={setChildren}/>
                                            </Drawer.Content>
                                        </Drawer.Portal>
                                    </Drawer.Root>
                                </div>
                            }
                            {!props.disallowDelete && props.editable &&
                                <div
                                    className={"flex flex-col justify-center items-center text-center grow shrink"}>
                                    <Dialog open={deleteDashboardDialogOpen}>
                                        <DialogContent className={"w-1/2 text-textmedium"}
                                                       onInteractOutside={() => setDeleteDashboardDialogOpen(false)}>
                                            <div className={"flex flex-row justify-between items-center grow"}>
                                                <div className={"flex flex-col justify-center"}>
                                                    <DialogTitle className={"text-textmedium"}>Are you
                                                        sure?</DialogTitle>
                                                </div>
                                                <div className={"flex flex-col justify-center"}>
                                                    <DialogClose asChild
                                                                 onClick={() => setDeleteDashboardDialogOpen(false)}>
                                                        <XIcon
                                                            className={"text-textdark hover:cursor-pointer hover:text-primary"}/>
                                                    </DialogClose>
                                                </div>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <div className={"flex flex-col"}>
                                                    <DialogDescription>
                                                        Are you sure you want to delete this dashboard?
                                                    </DialogDescription>
                                                </div>
                                                <div className={"flex flex-col mt-4"}>
                                                    <Button
                                                        className={"h-6 px-2 bg-primarytransparent border-primary rounder  border text-textmedium"}
                                                        onClick={() => {
                                                            axios.delete(`/api/v1/dashboard?dashboardId=${searchParams.get("dashboardId")}`).then(() => {
                                                                navigate("/dashboards")
                                                            }).catch((e) => {
                                                                console.error(e)
                                                            })
                                                        }}>
                                                        <div className={"flex"}>
                                                            <div className={"flex flex-col justify-center"}>
                                                                <XIcon className={"h-4 text-textmedium"}/>
                                                            </div>
                                                            <div className={"flex flex-col justify-center"}>Delete</div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    <ButtonWithTooltip
                                        icon={XIcon}
                                        tooltipText="Delete widget"
                                        onClick={() => {
                                            if (id === "root") {
                                                setDeleteDashboardDialogOpen(true)
                                                return
                                            }
                                            removeWidget(id, props.grids, props.setGrids, props.widgets, props.setWidgets);
                                        }}
                                    />
                                </div>
                            }
                            {
                                props.saveButton !== undefined && props.editable &&
                                <div
                                    className={"flex flex-col justify-center items-center text-center"}>
                                    {props.saveButton}
                                </div>
                            }
                            {
                                props.editButton !== undefined && !props.editable &&
                                <div
                                    className={"flex flex-col justify-center items-center text-center"}>
                                    {props.editButton}
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"w-full h-full overflow-auto"}>
                        <div className={cn("w-full min-h-full grow shrink grid-" + id)} style={props.stylesheet}>
                            {
                                gridStack !== undefined &&
                                children.map((child, i) =>
                                    WidgetDiv({
                                        stylesheet: props.stylesheet ?? {},
                                        setRootChildren: props.setRootChildren ?? setChildren,
                                        editable: props.editable,
                                        widget: child,
                                        parentGridStack: gridStack,
                                        grids: props.grids,
                                        setGrids: props.setGrids,
                                        widgets: props.widgets,
                                        setWidgets: props.setWidgets
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}