import {MonitorEvaluationPayload} from "../../pages/alerts/MetricAlert";
import {AxiosPromise} from "axios";
import axios from "../../utility/customAxios";
import {plainToInstance} from "class-transformer";
import {MetricFunction} from "../../components/Dashboarding/widgets/MetricSelector";
import React from "react";
import {dashboardJsonReplacer} from "../../components/Dashboarding/Dashboard";

export interface GetKubernetesMetricsRequest {
    metricName: string;
    startTime: number;
    endTime: number;
    filters: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits: string[];
    aggregation: string;
    isRate?: boolean;
    functions: MetricFunction[];
    limitResults: boolean;
    bucketSize?: number;
    jsonPath?: string;
}


export interface GetMetricRequest {
    metricName: string;
    startTime: number;
    endTime: number;
    filters: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits: string[];
    aggregation: string;
    isRate?: boolean;
    functions: MetricFunction[];
    limitResults: boolean;
    bucketSize?: number;
}

interface GetMetricAggregateEvaluationRequest extends GetMetricRequest {
    type: string;
    aggregateParams: MonitorEvaluationPayload;
}

export class GetMetricResponse {
    constructor(metric: Metric, isResultLimited: boolean, resultLen: number) {
        this.metric = metric;
        this.isResultLimited = isResultLimited;
        this.resultLen = resultLen;
    }

    metric: Metric;
    isResultLimited: boolean;
    resultLen: number;
}

interface MetricVisualization {
    displayName?: string
    lineDash?: number[]
    lineDotColor?: string,
    lineColor?: string,
    lineDotSize?: number
}

interface Metric {
    // The name of the metric
    name: string;
    // The time series that make up this metric
    timeSeries: TimeSeries[];
    // The type of the metric, e.g. "gauge", "counter", etc.
    type: string;
    // The attributes that are common to all time series
    attributes: Map<string, string[]>;
    metricVisualization?: MetricVisualization
}

export interface TimeSeries {
    data: DataPoint[];
    // The attributes that are specific to this time series
    attributes: Map<string, string>;
}

interface DataPoint {
    // The time at which the data point was recorded in milliseconds since epoch
    time: number;
    // The value of the data point
    value: number;
}

export type MetricType = "metric" | "trace" | "logs" | "kubernetes_resource";

export interface SingleMetricRequest {
    type: MetricType;
    metric?: GetMetricRequest;
    trace?: GetTraceMetricRequest;
    logs?: GetLogMetricsRequest;
    kubernetes?: GetKubernetesMetricsRequest;
    // If this is true then we won't return the result of this metric request, this is pretty much only useful when using a formula
    shouldNotReturn?: boolean;
    // FormulaIdentifier is a unique identifier for the result of this metric request.
    // For example, if this value is "a" then formulas can refer to the result of this request as "a"
    formulaIdentifier: string;
}

export interface MultiMetricRequest {
    metrics: SingleMetricRequest[];
    formulas?: Formula[];
}

export interface SingleMetricResponse {
    type: MetricType;
    metric?: Metric;
    isResultLimited?: boolean;
    resultLen?: number;
    error?: string;
}

export interface MultiMetricResponse {
    metrics: SingleMetricResponse[];
}

interface GetAllTraceMetricsRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    onlyNumRequests?: boolean;
    environments?: string[];
}

export interface GetTraceMetricRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
    aggregate: string;
    functions: MetricFunction[];
    limitResults: boolean;
    bucketSize?: number;
}

class GetAllTraceMetricsResponse {
    constructor(metrics: Metric[]) {
        this.metrics = metrics;
    }

    metrics: Metric[];
}

interface GetLogMetricsRequest {
    startTime: number;
    endTime: number;
    serviceName?: string;
    filters?: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
    abortController?: AbortController;
}

class GetLogMetricsResponse {
    constructor(metric: Metric) {
        this.metric = metric;
    }

    metric: Metric;
}

async function GetLogMetrics(request: GetLogMetricsRequest, abortController: AbortController): Promise<GetLogMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/logMetrics", transformed, {
            signal: abortController.signal
        }
    )
    let awaited = (await d).data;
    return plainToInstance(GetLogMetricsResponse, awaited);
}

async function GetAllTraceMetrics(request: GetAllTraceMetricsRequest, abortController: AbortController): Promise<GetAllTraceMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetrics", transformed, {
            signal: abortController.signal
        }
    )
    let awaited = (await d).data;
    return plainToInstance(GetAllTraceMetricsResponse, awaited);
}

async function GetTraceMetric(request: GetTraceMetricRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetric", transformed,
    )
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}

async function GetKubernetesMetric(request: GetKubernetesMetricsRequest,
                         abortController: AbortController,
                         setAbortController: React.Dispatch<React.SetStateAction<AbortController>>
): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    abortController.abort()
    let newAbortController = new AbortController();
    setAbortController(newAbortController)
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric", transformed, {
        signal: newAbortController.signal
    })
    d.catch((e) => {
        if (e.name === "AbortError") {
            console.log("Aborted")
        }
    })
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}


async function GetMetric(request: GetMetricRequest,
                         abortController: AbortController,
                         setAbortController: React.Dispatch<React.SetStateAction<AbortController>>
): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    abortController.abort()
    let newAbortController = new AbortController();
    setAbortController(newAbortController)
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric", transformed, {
        signal: newAbortController.signal
    })
    d.catch((e) => {
        if (e.name === "AbortError") {
            console.log("Aborted")
        }
    })
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}

async function GetAggregateMetricEvaluation(request: GetMetricAggregateEvaluationRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }

    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric/aggregate", transformed)
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}

function mapToObject<T>(map: Map<string, T> | undefined): Record<string, T> {
    if (!map) return {};
    return Object.fromEntries(map.entries());
}

async function GetMultiMetrics(request: MultiMetricRequest,
                               abortController: AbortController,
                               setAbortController: React.Dispatch<React.SetStateAction<AbortController>>
                               ): Promise<MultiMetricResponse> {
    const processedRequest = {
        metrics: request.metrics.map(req => ({
            ...req,
            kubernetes: req.kubernetes && {
                ...req.kubernetes,
                filters: mapToObject(req.kubernetes.filters),
                excludeFilters: mapToObject(req.kubernetes.excludeFilters)
            },
            metric: req.metric && {
                ...req.metric,
                filters: mapToObject(req.metric.filters),
                excludeFilters: mapToObject(req.metric.excludeFilters)
            },
            logs: req.logs && {
                ...req.logs,
                filters: mapToObject(req.logs.filters),
                excludeFilters: mapToObject(req.logs.excludeFilters)
            },
            trace: req.trace && {
                ...req.trace,
                filters: mapToObject(req.trace.filters),
                excludeFilters: mapToObject(req.trace.excludeFilters)
            }
        })),
        formulas: request.formulas
    };

    abortController.abort()
    let newAbortController = new AbortController();
    setAbortController(newAbortController)

    return axios.post("/api/v1/metrics", processedRequest, {
        signal: newAbortController.signal
    }).then((response) => {
        return response.data;
    });
}

export interface Formula {
    formula: string;
    visualization?: MetricVisualization;
}

export {GetAggregateMetricEvaluation};
export {GetMetric};
export {GetTraceMetric};
export {GetKubernetesMetric};
export {GetAllTraceMetrics};
export {GetLogMetrics};
export {GetLogMetricsResponse};
export {GetAllTraceMetricsResponse};
export {GetMultiMetrics};
export type {MetricVisualization}
export type {Metric};