import {RuntimeVariable} from "../Dashboard";
import React, {useEffect} from "react";
import {Variable} from "../internalwidgets";
import {SingleSelectorSearchableDropdown} from "../../Input/SingleSelectorSearchableDropdown";
import {DropDownItem} from "../../Input/MultiSelectorDropdown/MultiSelectorDropDown";
import axios from "../../../utility/customAxios";
import timerange from "../../../store/reducers/timerange";
import {useSelector} from "react-redux";
import {MetricType} from "../../../pages/MetricsTest";


export function GroupVariableManager(props: {
    widgetId: string,
    variables: Variable[],
    runtimeVariables: Map<string, RuntimeVariable[]>,
    setRuntimeVariables: React.Dispatch<React.SetStateAction<Map<string, RuntimeVariable[]>>>
}) {
    let currentRuntimeVariables = props.runtimeVariables.get(props.widgetId) ?? []
    if (currentRuntimeVariables.length === 0) {
        return null
    }
    // Filter the runtime variables to only show the ones that actually can be set
    let variableMap = new Map<string, Variable>()
    props.variables.forEach(x => variableMap.set(x.name, x))
    let overrideableVariables = currentRuntimeVariables.filter(x => variableMap.has(x.name) && variableMap.get(x.name)?.isOverrideable)

    // Sort overrideable variables to match the order in props.variables
    overrideableVariables.sort((a, b) => {
        const aIndex = props.variables.findIndex(v => v.name === a.name);
        const bIndex = props.variables.findIndex(v => v.name === b.name);
        return aIndex - bIndex;
    });

    return <div className={"flex gap-x-2"}>
        {overrideableVariables.map(variable => {
            return <VariableSelectorDropDown
                widgetId={props.widgetId}
                runtime={variable} setRuntimeVariables={props.setRuntimeVariables}
                variable={variableMap.get(variable.name)!}/>
        })}
    </div>
}

export function VariableSelectorDropDown(props: {
    widgetId: string,
    variable: Variable,
    runtime: RuntimeVariable,
    setRuntimeVariables: React.Dispatch<React.SetStateAction<Map<string, RuntimeVariable[]>>>
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)

    const [items, setItems] = React.useState<DropDownItem[]>([])
    useEffect(() => {
        if (props.variable.defaultType === MetricType.Trace) {
            axios.post("/api/v1/tracesSummaryIndividualAttribute", {
                    "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                    "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                    // Removed because we want to keep everything for now
                    // "excludeFilters": {},
                    // Removed because we want to keep everything for now
                    // "filters": {},
                    // Removed because we want to keep everything for now
                    // "regexes": [],
                    // Removed because we want to keep everything for now
                    // "excludeRegexes": [],
                    "attribute": props.variable.key,
                    "limit": 10000
                },
            ).then((response) => {
                let items = response.data.attribute.map((x: any) => {
                    return {
                        displayName: x.value,
                        value: x.value
                    }
                })
                setItems(items)
            })
        }
        if (props.variable.defaultType === MetricType.Metric) {
            axios.post("/api/v1/metricIndividualAttribute", {
                "startTime": Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                "endTime": Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
                "metricName": props.variable.defaultMetricName,
                // Removed because we want to keep everything for now
                // "excludeFilters": excludeFilters,
                // Removed because we want to keep everything for now
                // "filters": filters,
                // Removed because we want to keep everything for now
                // "regexes": props.chartProps.regexes,
                // Removed because we want to keep everything for now
                // "excludeRegexes": props.chartProps.excludeRegexes,
                // "environments": environments[0] === "" ? [] : environments,
                "attribute": props.variable.key,
                "limit": 10000
            }).then(
                (response) => {
                    setItems(response.data.attribute.map((x: any) => {
                        return {
                            displayName: x,
                            value: x,
                        }
                    }))
                }
            )
        }

    }, [props.variable.key, props.variable.defaultType, props.variable.defaultMetricName, timeRange]);

    return <div className={"flex"}>
        <div className={"flex flex-col justify-center"}>
            <div className={"bg-secondarytransparenter flex border-secondary border rounded-l h-5 px-2 flex-col justify-center text-center text-textlight text-xs"}>
                ${props.variable.name}
            </div>
        </div>
        <SingleSelectorSearchableDropdown
            allowAnyString={true}
            displaySettings={{
                smallSize: true,
                borderClassName: "border-t border-b border-r rounded-r"
        }}
            noMatchString={"Nothing found"}
            possibleItems={items}
            selected={{displayName: props.runtime.value, value: props.runtime.value}}
            setSelected={(x) => {
                props.setRuntimeVariables(prevState => {
                    let newState = new Map(prevState)
                    let prevGroupVariables = newState.get(props.widgetId) ?? []
                    let newGroupVariables = prevGroupVariables.filter(y => y.name !== props.runtime.name)
                    newGroupVariables.push({...props.runtime, value: x.value})
                    newState.set(props.widgetId, newGroupVariables)
                    return newState
                })
            }}
            searchPlaceholder={"Select a value"}/>
    </div>
}
