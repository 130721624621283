import {BaseView} from "./BaseView";
import {useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AlertType, ApiServerAlert, getGraphAnnotationProperties, ThresholdComparator} from "./AlertCreation";
import axios from "../utility/customAxios";
import {Button} from "../components/ui/button";
import {AiOutlineKubernetes} from "react-icons/ai";
import {FaSlack} from "react-icons/fa";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {GanttChart} from "../components/Gantt/GanttChart";
import {Dialog, DialogContent, DialogDescription, DialogTitle} from "../components/ui/dialog";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {ChartType, MetoroMetricsChart, MetricType} from "./MetricsTest";
import {MailIcon, SmileIcon} from "lucide-react";
import {adjustAlertThreshold} from "./alerts/utils";
import {TimeRange} from "../types/time";
import {useDebouncedCallback} from "use-debounce";
import {AggregationFunction, EvalType} from "./alerts/MetricAlert";

var hdate = require('human-date')


function AlertDetails(props: { alert: ApiServerAlert | undefined }) {
    const navigate = usePreserveQueryParamsNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    function getMonitorType() {
        if (props.alert === undefined) {
            return ""
        }
        if ((props.alert.metricAlert != null) || (props.alert.traceAlert != null)) {
            const typedAlert = props.alert.metricAlert || props.alert.traceAlert
            if (typedAlert!.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
                return "Static Threshold"
            }
            if (typedAlert!.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
                return "Evaluate Aggregate Function"
            }
        }
        return "Unknown"
    }

    function getConditionType() {
        if (props.alert === undefined) {
            return ""
        }

        // Get metric string e.g. average:cpu_usage_namespace
        let metricString = ""
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
            metricString = props.alert.metricAlert.filters.metricName
            metricString = props.alert.metricAlert.filters.aggregation + ":" + metricString
        }
        if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
            metricString = props.alert.traceAlert.filters.aggregation
        }

        // Wrap metric string with functions e.g. monotonic_difference(average:cpu_usage_namespace)
        let functionsString = metricString
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null && props.alert.metricAlert.filters.functions != null) {
                props.alert.metricAlert.filters.functions.forEach((func) => {
                    functionsString = func.functionType + "(" + functionsString + ")"
                })
        }
        if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null && props.alert.traceAlert.filters.functions != null) {
            props.alert.traceAlert.filters.functions.forEach((func) => {
                functionsString = func.functionType + "(" + functionsString + ")"
            })
        }

        // Get threshold comparator string e.g. > 0.5
        let thresholdComparatorString = ""
        if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
            thresholdComparatorString = getThresholdComparatorSymbol(props.alert.metricAlert.alarmCondition.condition as ThresholdComparator) + " " + props.alert.metricAlert.alarmCondition.threshold
        } else if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
            thresholdComparatorString = getThresholdComparatorSymbol(props.alert.traceAlert.alarmCondition.condition as ThresholdComparator) + " " + props.alert.traceAlert.alarmCondition.threshold
        }

        let typedAlert = props.alert.metricAlert || props.alert.traceAlert
        if (typedAlert === undefined) {
            console.log("typedAlert is undefined")
            return ""
        }
        let evaluationString = ""
        if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Static) {
            evaluationString += "any_datapoint:" + functionsString
        } else if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
            if (typedAlert.monitorEvaluation.monitorEvalutionPayload === undefined) {
                console.log("evaluationFunction is undefined")
                return ""
            }
            let aggregateFunction = typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction.toString()
            if (typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits != null) {
                typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits.forEach((split) => {
                    aggregateFunction += " of " + split
                })
            }

            let typeName = ""
            if (props.alert.type === AlertType.Metric && props.alert.metricAlert != null) {
                typeName = "metric"
            }
            if (props.alert.type === AlertType.Trace && props.alert.traceAlert != null) {
                typeName = "trace"
            }

            aggregateFunction = aggregateFunction + "(every " + typedAlert.monitorEvaluation.monitorEvalutionPayload.window + typedAlert.monitorEvaluation.monitorEvalutionPayload.windowUnit.toString() + ")"
            evaluationString = aggregateFunction + " of " + typeName + " " + functionsString
        }
        return evaluationString + " " + thresholdComparatorString
    }

    const alert = props.alert;
    if (!alert) {
        return <div className={"flex justify-center items-center w-full h-full"}>
            <div className={"text-textmedium"}>No alert selected</div>
        </div>
    }
    return <div className={"flex grow flex-col gap-4 m-4"}>
        <div className={"flex justify-between"}>
            <div className={"text-lg font-semibold"}>
                Summary
            </div>
            <div className={"flex gap-4"}>
                <Button onClick={() => {
                    navigate(`/new-alert?alertId=${alert?.uuid}`)
                }} className={"bg-primarytransparent border border-primary h-full"}>
                    Edit
                </Button>
                <Button onClick={() => {
                    setDeleteDialogOpen(true)
                }} className={"bg-red-500/20 border border-red-500 h-full"}>
                    Delete
                </Button>
                <Dialog open={deleteDialogOpen}>
                    <DialogContent
                        onInteractOutside={() => setDeleteDialogOpen(false)}
                        className={"w-[40vw] text-textmedium bg-backgrounddark"}>
                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                        <DialogDescription>
                            This action cannot be undone. This will permanently delete this alert
                            and all firing history associated with it.
                        </DialogDescription>
                        <div className={"flex py-4 gap-4"}>
                            <Button onClick={() => {
                                axios.delete(`/api/v1/alert?alertId=${alert?.uuid}`)
                                    .then(() => {
                                        navigate("/alerts")
                                    })
                            }} className={"bg-red-500/20 border border-red-500 h-full flex grow shrink"}>
                                Delete
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
        <div className={"grid grid-rows-1 grid-cols-2"}>
            <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Name</div>
                    <div className={"text-textmedium"}>{alert.name}</div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Description</div>
                    <div className={"text-textmedium"}>{alert.description}</div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Destination</div>
                    <div className={"text-textmedium"}>
                        {alert.destination.type === "Slack" && <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-purple-500 gap-2">
                                    <FaSlack
                                        className={"text-white h-4 w-4"}/>Slack
                                </div>}
                        {alert.destination.type == "PagerDuty" && <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-textlight shadow hover:bg-primary/80 rounded bg-[#06AC38] gap-2">
                                        PagerDuty
                                    </div>}
                        {alert.destination.type === "Email" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-red-400 gap-2">
                            <MailIcon
                                className={"text-white h-4 w-4"}/>Email
                        </div>}
                        {alert.destination.type === "None" && <div
                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-gray-500 gap-2">
                            None
                        </div>}
                    </div>
                </div>
                <div className={"flex gap-4"}>
                    <div className={"text-textmedium font-bold w-[80px]"}>Status</div>
                    <div className={"text-textmedium"}>
                        {
                            alert.status === "FIRING" ? <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-red-500">Firing</div>
                                :
                                <div
                                    className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white shadow hover:bg-primary/80 rounded bg-secondaryTransparent">Ok</div>
                        }
                    </div>
                </div>
            </div>
            <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                    <div
                        className={`text-textmedium font-bold ${alert.type === "Metric" || alert.type === "Trace" ? "w-[120px]" : "w-[80px]"}`}>Type
                    </div>
                    <div className={"text-textmedium"}>
                        {
                            alert.type === "Kubernetes" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                <AiOutlineKubernetes className={"text-white h-4 w-4"}/>Kubernetes
                            </div>
                        }
                        {
                            alert.type === "Log" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Log
                            </div>
                        }
                        {
                            alert.type === "Metric" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Metric
                            </div>
                        }
                        {
                            alert.type === "Trace" &&
                            <div
                                className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white">
                                Trace
                            </div>
                        }
                    </div>
                </div>
                {
                    alert.type === "Kubernetes" ? <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Monitor</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.monitorEvent === "pod_restart" ? "Pod Restart" :
                                    alert.kubernetesAlert!.monitorEvent === "pod_crash" ? "Pod Crash" : "Unknown"
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Clusters</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.filters.clusters.length === 0 ? "All" : alert.kubernetesAlert!.filters.clusters.join(", ")
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"text-textmedium font-bold w-[80px]"}>Services</div>
                            <div className={"text-textmedium"}>{
                                alert.kubernetesAlert!.filters.services.length === 0 ? "All" : alert.kubernetesAlert!.filters.services.join(", ")
                            }</div>
                        </div>
                    </div> : null
                }
                {
                    alert.type === "Log" && <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Monitor</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.monitorEvent === "regex_match" ? "Regex Match" : "Unknown"
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Clusters</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.filters.clusters.length === 0 ? "All" : alert.logAlert!.filters.clusters.join(", ")
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[80px]"}>Services</div>
                            <div className={"text-textmedium"}>{
                                alert.logAlert!.filters.services.length === 0 ? "All" : alert.logAlert!.filters.services.join(", ")
                            }</div>
                        </div>
                    </div>
                }
                {
                    alert.type === "Metric" && alert.metricAlert != null && <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Monitor Type</div>
                            <div className={"text-textmedium"}>{getMonitorType()}</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Condition</div>
                            <div className={"text-textmedium line-clamp-3 break-all max-h-[90px] text-sm"}>{
                                getConditionType()
                            }</div>
                        </div>
                        {alert.metricAlert.filters.filters && Object.entries(alert.metricAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && formatFilters(new Map(Object.entries(alert.metricAlert.filters.filters)))
                                }</div>
                            </div>}
                        {alert.metricAlert.filters.filters && Object.entries(alert.metricAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Exclude Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && formatFilters(new Map(Object.entries(alert.metricAlert.filters.excludeFilters)), true)
                                }</div>
                            </div>}
                        {alert.metricAlert.filters.splits && alert.metricAlert.filters.splits.length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Group by</div>
                                <div className={"text-textmedium"}>{
                                    alert.metricAlert.filters && alert.metricAlert.filters.splits.join(", ")
                                }</div>
                            </div>}
                    </div>
                }
                {
                    alert.type === "Trace" && alert.traceAlert != null && <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Monitor Type</div>
                            <div className={"text-textmedium"}>{
                                getMonitorType()
                            }</div>
                        </div>
                        <div className={"flex gap-4"}>
                            <div className={"flex-none text-textmedium font-bold w-[120px]"}>Condition</div>
                            <div className={"text-textmedium line-clamp-3 break-all max-h-[90px] text-sm"}>{
                                getConditionType()
                            }</div>
                        </div>
                        {alert.traceAlert.filters.filters && Object.entries(alert.traceAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && formatFilters(new Map(Object.entries(alert.traceAlert.filters.filters)))
                                }</div>
                            </div>}
                        {alert.traceAlert.filters.filters && Object.entries(alert.traceAlert.filters.filters).length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Exclude Filter</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && formatFilters(new Map(Object.entries(alert.traceAlert.filters.excludeFilters || {})), true)
                                }</div>
                            </div>}
                        {alert.traceAlert.filters.splits && alert.traceAlert.filters.splits.length > 0 &&
                            <div className={"flex gap-4"}>
                                <div className={"flex-none text-textmedium font-bold w-[120px]"}>Group by</div>
                                <div className={"text-textmedium"}>{
                                    alert.traceAlert.filters && alert.traceAlert.filters.splits.join(", ")
                                }</div>
                            </div>}
                    </div>
                }
            </div>
        </div>
    </div>
}

function getThresholdComparatorSymbol(comparator: ThresholdComparator) {
    if (comparator === ThresholdComparator.GreaterThan) {
        return ">"
    }
    if (comparator === ThresholdComparator.LessThan) {
        return "<"
    }
    if (comparator === ThresholdComparator.GreaterThanOrEqual) {
        return ">="
    }
    if (comparator === ThresholdComparator.LessThanOrEqual) {
        return "<="
    }
    return "?"
}

function formatFilters(filters: Map<string, string[]>, isExclude: boolean = false) {
    let formattedFilters = ""
    filters.forEach((value, key) => {
        formattedFilters += key + (isExclude ? " != " : " = ") + value.join(" || ") + " "
    })
    return formattedFilters
}

interface AlertFire {
    uuid: string;
    alertUuid: string;
    organizationUuid: string;
    startTime: number;
    endTime: number;
    message: string;
    deepLink: string;
    kubernetesEnvironment: string;
    kubernetesService: string;
    properties: MetricAlertFireProperties;
}

interface MetricAlertFireProperties {
    metricFilters: Map<string, string[]>;
    metricAttributes: Map<string, string>;
    metricName: string;
    aggregation: string;
    datapointBreaching: {
        time: number;
        value: number;
    };
}

function AlertFiringHistoryList(props: { alertFires: AlertFire[], alertType: string, evaluationType: EvalType, evaluationFunction: AggregationFunction }) {
    const isMetricAlert = props.alertType == "Metric";
    const isTraceAlert = props.alertType == "Trace";
    const isMetricAttributePropsSet = props.alertFires.find((alertFire) => {
        if (alertFire.properties && alertFire.properties.metricAttributes) {
            return new Map(Object.entries(alertFire.properties.metricAttributes)).size > 0
        } else {
            return false
        }
    }) != undefined;


    return <div className="min-w-0 bg-backgroundmedium border-b min-h-0 w-full flex flex-col grow shrink">
        <div
            className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex border-b">
            <div className="h-full w-[240px] font-normal leading-8 text-textmedium text-xl">Start
            </div>
            <div
                className="h-full w-[240px] shrink font-normal  leading-8 text-textmedium text-xl">End
            </div>
            {!isMetricAlert && !isTraceAlert &&
                <div className="h-full w-[160px] font-normal leading-8 text-textmedium text-xl">Cluster
                </div>}
            {!isMetricAlert && !isTraceAlert &&
                <div className="h-full w-[360px] font-normal leading-8 text-textmedium text-xl">Service
                </div>}
            {(isMetricAlert || isTraceAlert) && isMetricAttributePropsSet &&
                <div className="h-full w-[360px] font-normal leading-8 text-textmedium text-xl truncate">Attributes
                </div>}
            {(isMetricAlert || isTraceAlert) &&
                <div className="h-full w-[160px] font-normal leading-8 text-textmedium text-xl truncate">Alert Message
                </div>}
        </div>
        <div className="flex flex-col grow shrink overflow-y-auto">
            {props.alertFires.length === 0 && <div className={"flex justify-center items-center text-textdark text-lg"}>
                <div className={"p-2"}>
                    No alerts fired yet
                </div>
                <SmileIcon/>
            </div>}
            {props.alertFires.length > 0 &&
                props.alertFires.map((alertFire) => {
                    const isMetricAlert = alertFire.kubernetesService == null && alertFire.kubernetesEnvironment == null;
                    let splitService: string[] = []
                    if (!isMetricAlert) {
                        // If not a metric alert, then its a kubernetes or log alert so we can get the service.
                        splitService = alertFire.kubernetesService.split("/");
                    }
                    const metricAttributes = []
                    const metricAttributeMap = alertFire.properties && alertFire.properties.metricAttributes ? new Map(Object.entries(alertFire.properties.metricAttributes)) : new Map();
                    for (let key of metricAttributeMap.keys()) {
                        let value = metricAttributeMap.get(key);
                        metricAttributes.push(`${key}: ${value}`);
                    }

                    const datapointBreachTime = new Date(alertFire.properties.datapointBreaching.time).toLocaleTimeString()
                    let message;

                    if (props.alertType == "Metric") {
                        message = "Metric " + alertFire.properties.metricName + " was " + alertFire.properties.datapointBreaching.value.toFixed(2) + " at " + datapointBreachTime
                        if (props.evaluationType === EvalType.Aggregate) {
                            message = props.evaluationFunction + " of metric " + alertFire.properties.metricName + " is " + alertFire.properties.datapointBreaching.value.toFixed(2) + " at " + datapointBreachTime
                        }
                    } else if (props.alertType == "Trace") {
                        message = alertFire.properties.aggregation + " was " + alertFire.properties.datapointBreaching.value.toFixed(2) + " at " + datapointBreachTime
                        if (props.evaluationType === EvalType.Aggregate) {
                            message = props.evaluationFunction + " of " + alertFire.properties.aggregation + " is " + alertFire.properties.datapointBreaching.value.toFixed(2)+ " at " + datapointBreachTime
                        }
                    } else {
                        message = alertFire.message
                    }

                    return <div
                        className="w-full flex-none h-[64px] px-4 py-2 justify-start items-start gap-4 flex hover:bg-backgrounddark">
                        <div className="h-full w-[240px] font-normal leading-8 text-textmedium truncate">
                            {hdate.prettyPrint(new Date(alertFire.startTime * 1000), {showTime: true})}
                        </div>
                        <div
                            className="h-full w-[240px] shrink font-normal leading-8 text-textmedium truncate">
                            {
                                alertFire.endTime === null ? "Ongoing" :
                                    hdate.prettyPrint(new Date(alertFire.endTime * 1000), {showTime: true})
                            }
                        </div>
                        {!isMetricAlert &&
                            <div className="h-full w-[160px] font-normal leading-8 text-textmedium truncate">
                                {alertFire.kubernetesEnvironment}
                            </div>}
                        {!isMetricAlert &&
                            <div className="h-full w-[360px] font-normal leading-8 text-textmedium truncate">
                                {splitService[splitService.length - 1]}
                            </div>}
                        {isMetricAlert && isMetricAttributePropsSet &&
                            <div className="h-full w-[360px] font-normal leading-tight text-textmedium text-wrap">
                                {metricAttributes.join(", ")}
                            </div>}
                        {isMetricAlert && <div className="h-full font-normal leading-8 text-textmedium truncate">
                            {message}
                        </div>}
                    </div>
                })
            }
        </div>
    </div>
}

function alertFiresToGanttData(alertFires: AlertFire[], startTime: Date, endTime: Date) {
    let cells = alertFires.map((alertFire) => {
        return {
            startTime: new Date(alertFire.startTime * 1000),
            endTime: alertFire.endTime === null ? new Date() : new Date(alertFire.endTime * 1000),
            text: "Alert",
            color: "rgb(239 68 68)",
            onClick: () => {
            },
        }
    });
    let baseline = {
        startTime: startTime, endTime: endTime, text: "Ok", color: "rgb(34 197 94)", onClick: () => {
        }
    };
    cells.push(baseline);

    return {
        barThickness: 20,
        tooltipCallback: (tooltipItem: any) => {
            return hdate.prettyPrint(new Date(tooltipItem.raw[0]), {showTime: true}) + " - " + hdate.prettyPrint(new Date(tooltipItem.raw[1]), {showTime: true})
        },
        startTime: startTime,
        endTime: endTime,
        rows: [
            {
                label: "Status",
                cells: cells
            }
        ]
    }
}

function updateAlertFires(timeRange: TimeRange, alertId: string | null, setAlertFires: (value: (((prevState: AlertFire[]) => AlertFire[]) | AlertFire[])) => void) {
    // unix seconds
    const start = Math.floor(timeRange.getStartEnd()[0].getTime()! / 1000);
    const end = Math.floor(timeRange.getStartEnd()[1].getTime()! / 1000);

    axios.get(`/api/v1/alertFires?alertId=${alertId}&startTime=${start}&endTime=${end}`)
        .then((res) => setAlertFires(res.data.alertFires))
}

function updateAlert(alertId: string, setAlert: (value: (((prevState: (ApiServerAlert | undefined)) => (ApiServerAlert | undefined)) | ApiServerAlert | undefined)) => void) {
    axios.get<ApiServerAlert>(`/api/v1/alert?alertId=${alertId}`)
        .then((res) =>
            setAlert(adjustAlertThreshold(res.data))
        )
}

function Alert() {
    const [searchParams, setSearchParams] = useSearchParams();
    const alertId = searchParams.get("alertId");
    const [alert, setAlert] = React.useState<ApiServerAlert>();
    const [alertFires, setAlertFires] = React.useState<AlertFire[]>([]);
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [chartResolution, setChartResolution] = React.useState(1)
    const debouncedUpdateAlertFires = useDebouncedCallback(updateAlertFires, 10);
    const debouncedUpdateAlert = useDebouncedCallback(updateAlert, 10);


    useEffect(() => {
        debouncedUpdateAlertFires(timeRange, alertId, setAlertFires);
    }, [alertId, timeRange]);


    useEffect(() => {
        if (alertId) {
            debouncedUpdateAlert(alertId, setAlert);
        }
    }, [alertId]);

    useEffect(() => {
        const startEnd = timeRange.getStartEnd();
        axios.post("/api/v1/resolution", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
            }
        ).then((res) => {
            setChartResolution(res.data.resolution)
        }).catch((e) => {
            console.error(e)
        })
    }, [timeRange]);

    let alertEvaluationType = EvalType.Static
    let alertEvaluationFunction = AggregationFunction.Sum
    let annotationProps;
    if (alert && alert.type == "Metric" && alert.metricAlert) {
        annotationProps = getGraphAnnotationProperties(alert.metricAlert.monitorEvaluation, timeRange, parseFloat(alert.metricAlert.alarmCondition.threshold), alert.metricAlert.alarmCondition.condition, chartResolution, MetricType.Metric, alert.metricAlert.filters.aggregation)
        alertEvaluationType = alert.metricAlert.monitorEvaluation.monitorEvaluationType
        if (alert.metricAlert.monitorEvaluation.monitorEvalutionPayload) {
            alertEvaluationFunction = alert.metricAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction
        }
    } else if (alert && alert.type == "Trace" && alert.traceAlert) {
        annotationProps = getGraphAnnotationProperties(alert.traceAlert.monitorEvaluation, timeRange, parseFloat(alert.traceAlert.alarmCondition.threshold), alert.traceAlert.alarmCondition.condition, chartResolution, MetricType.Trace, alert.traceAlert.filters.aggregation)
        alertEvaluationType = alert.traceAlert.monitorEvaluation.monitorEvaluationType
        if (alert.traceAlert.monitorEvaluation.monitorEvalutionPayload) {
            alertEvaluationFunction = alert.traceAlert.monitorEvaluation.monitorEvalutionPayload.evaluationFunction
        }
    } else {
        annotationProps = {thresholdToSet: 0, timePeriodToHighlight: [], annotationLabel: ""}
    }

    return <BaseView title={"Alert"} disableClusterSelector={true}>
        <div className={"flex flex-col overflow-y-auto gap-4 m-4 grow shrink min-h-0 min-w-0"}>
            <div className={"text-textmedium border flex h-max bg-backgroundmedium"}>
                <AlertDetails alert={alert}/>
            </div>
            {alert && alert.type == "Metric" && alert.metricAlert &&
                <div className={"flex flex-col"}>
                    <div className={"flex relative h-[300px]"}>
                        <MetoroMetricsChart
                            className={"flex flex-grow"}
                            startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                            endTime={Math.floor((timeRange.getStartEnd()[1].getTime()) / 1000)}
                            metricName={alert.metricAlert.filters.metricName}
                            aggregation={alert.metricAlert.filters.aggregation}
                            type={ChartType.Line}
                            filters={new Map(Object.entries(alert.metricAlert.filters.filters))}
                            excludeFilters={new Map(Object.entries(alert.metricAlert.filters.excludeFilters || {}))}
                            splits={alert.metricAlert.filters.splits}
                            title={`${alert.metricAlert.filters.metricName}-${alert.metricAlert.filters.aggregation}`}
                            threshold={getGraphAnnotationProperties(alert.metricAlert.monitorEvaluation, timeRange, parseFloat(alert.metricAlert.alarmCondition.threshold), alert.metricAlert.alarmCondition.condition, chartResolution, MetricType.Metric, alert.metricAlert.filters.aggregation).thresholdToSet}
                            thresholdLabel={annotationProps.annotationLabel}
                            timePeriodHighlight={getGraphAnnotationProperties(alert.metricAlert.monitorEvaluation, timeRange, parseFloat(alert.metricAlert.alarmCondition.threshold), alert.metricAlert.alarmCondition.condition, chartResolution, MetricType.Metric, alert.metricAlert.filters.aggregation).timePeriodToHighlight}
                            metricType={MetricType.Metric}
                            hideLegend={false}
                            functions={alert.metricAlert.filters.functions}
                        />
                    </div>
                </div>}
            {alert && alert.type == "Trace" && alert.traceAlert &&
                <div className={"flex flex-col"}>
                    <div className={"flex relative h-[300px]"}>
                        <MetoroMetricsChart
                            className={"flex flex-grow"}
                            startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                            endTime={Math.floor((timeRange.getStartEnd()[1].getTime()) / 1000)}
                            metricName={""}
                            aggregation={alert.traceAlert.filters.aggregation}
                            type={ChartType.Line}
                            filters={new Map(Object.entries(alert.traceAlert.filters.filters))}
                            excludeFilters={new Map(Object.entries(alert.traceAlert.filters.excludeFilters || {}))}
                            splits={alert.traceAlert.filters.splits}
                            title={`${alert.traceAlert.filters.aggregation}`}
                            threshold={getGraphAnnotationProperties(alert.traceAlert.monitorEvaluation, timeRange, parseFloat(alert.traceAlert.alarmCondition.threshold), alert.traceAlert.alarmCondition.condition, chartResolution, MetricType.Trace, alert.traceAlert.filters.aggregation).thresholdToSet}
                            thresholdLabel={annotationProps.annotationLabel}
                            timePeriodHighlight={getGraphAnnotationProperties(alert.traceAlert.monitorEvaluation, timeRange, parseFloat(alert.traceAlert.alarmCondition.threshold), alert.traceAlert.alarmCondition.condition, chartResolution, MetricType.Trace, alert.traceAlert.filters.aggregation).timePeriodToHighlight}
                            metricType={MetricType.Trace}
                            hideLegend={false}
                            functions={alert.traceAlert.filters.functions}
                        />
                    </div>
                </div>}
            <div className={"text-textmedium border flex flex-none flex-col h-[128px] bg-backgroundmedium p-4"}>
                <div className={"text-lg font-semibold"}>
                    Firing History
                </div>
                <GanttChart
                    data={alertFiresToGanttData(alertFires, timeRange.getStartEnd()[0], timeRange.getStartEnd()[1])}/>
            </div>
            {alert &&
                <div className={"text-textmedium border flex grow shrink bg-backgroundmedium min-h-[400px] min-w-0"}>
                    <AlertFiringHistoryList alertFires={alertFires} alertType={alert.type} evaluationType={alertEvaluationType} evaluationFunction={alertEvaluationFunction}/>
                </div>}
        </div>
    </BaseView>
}

export {
    Alert
}