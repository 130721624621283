import React, { useState } from "react";
import { Button } from "../components/ui/button";
import axios from "../utility/customAxios";
import { Input } from "../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { toast } from "../components/ui/use-toast";
import { XIcon } from "lucide-react";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { useForm } from "react-hook-form";
import { RegexTester } from "../components/RegexTester";
import { cn } from "../components/ui/lib/utils";

interface UptimeMonitor {
    uuid: string;
    name: string;
    description?: string;
    endpoint: string;
    method: string;
    headers: { [key: string]: string };
    body: string;
    expectedStatusCode: string;
    checkFrequencySeconds: number;
    responseTimeMetricName: string;
    statusMetricName: string;
    metricNameBase?: string;
    responseBodyRegex?: string;
}

interface FormValues {
    expectedStatusCode: string;
    name: string;
    description?: string;
    endpoint: string;
    method: string;
    headers: { [key: string]: string };
    body: string;
    checkFrequencySeconds: number;
    responseTimeMetricName: string;
    statusMetricName: string;
    metricNameBase?: string;
    responseBodyRegex?: string;
}

interface HeaderEntry {
    key: string;
    value: string;
}

interface UptimeMonitorFormProps {
    initialData?: UptimeMonitor;
    onSubmit: (data: UptimeMonitor) => void;
    isEditing?: boolean;
}

export function UptimeMonitorForm({
    initialData,
    onSubmit,
    isEditing = false
}: UptimeMonitorFormProps) {
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState<Record<string, string>>(initialData?.headers || {});
    const [showResponseRegexTester, setShowResponseRegexTester] = useState(false);
    const [responseTestString, setResponseTestString] = useState("Example response body");

    const form = useForm<FormValues>({
        defaultValues: {
            name: initialData?.name || "",
            description: initialData?.description || "",
            endpoint: initialData?.endpoint || "",
            method: initialData?.method || "GET",
            expectedStatusCode: initialData?.expectedStatusCode || "",
            body: initialData?.body || "",
            checkFrequencySeconds: initialData?.checkFrequencySeconds || 60,
            headers: initialData?.headers || {},
            responseTimeMetricName: initialData?.responseTimeMetricName || "",
            statusMetricName: initialData?.statusMetricName || "",
            responseBodyRegex: initialData?.responseBodyRegex || "",
            metricNameBase: initialData?.metricNameBase || (initialData?.responseTimeMetricName 
                ? initialData.responseTimeMetricName.replace(/^uptime_monitor_(.+)_response_time$/, '$1')
                : "")
        }
    });

    const httpMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"];
    const frequencies = [
        { value: "60", label: "Every minute" },
        { value: "300", label: "Every 5 minutes" },
        { value: "900", label: "Every 15 minutes" },
        { value: "1800", label: "Every 30 minutes" },
        { value: "3600", label: "Every hour" },
    ];

    // Update headers when method changes
    const handleMethodChange = (newMethod: string) => {
        form.setValue("method", newMethod);
        if (newMethod === "POST" || newMethod === "PUT") {
            // Add Content-Type header if it doesn't exist
            if (!Object.keys(headers).includes("Content-Type")) {
                setHeaders({...headers, "Content-Type": "application/json"});
            }
        } else {
            // Remove Content-Type header for other methods
            const newHeaders = {...headers};
            delete newHeaders["Content-Type"];
            setHeaders(newHeaders);
        }
    };

    const addHeader = () => {
        setHeaders({...headers, "": ""});
    };

    const updateHeader = (key: string, field: "key" | "value", value: string) => {
        const newHeaders = {...headers};
        if (field === "key") {
            // Keep the same object reference for the value
            const oldValue = newHeaders[key];
            delete newHeaders[key];
            newHeaders[value] = oldValue;
        } else {
            newHeaders[key] = value;
        }
        setHeaders(newHeaders);
        form.setValue("headers", newHeaders);
    };

    const removeHeader = (key: string) => {
        // Don't allow removing the Content-Type header for POST/PUT methods
        if (key === "Content-Type" && (form.getValues("method") === "POST" || form.getValues("method") === "PUT")) {
            return;
        }
        const newHeaders = {...headers};
        delete newHeaders[key];
        setHeaders(newHeaders);
        form.setValue("headers", newHeaders);
    };

    // Validate status code pattern
    const validateStatusCode = (code: string): boolean => {
        // Split by comma for multiple codes
        const codes = code.split(',').map(c => c.trim());
        
        // Match pattern [1-5X][0-9X][0-9X]
        const statusCodeRegex = /^[1-5X][0-9X][0-9X]$/;
        
        return codes.every(code => statusCodeRegex.test(code));
    };

    // Validate regex pattern
    const validateRegexPattern = (pattern: string): boolean => {
        if (!pattern) return true; // Empty pattern is valid
        try {
            new RegExp(pattern);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleSubmit = form.handleSubmit(async (data) => {
        if (!data.metricNameBase?.trim()) {
            toast({
                className: "text-textlight",
                title: "Error",
                description: "Metric name is required",
                variant: "destructive",
            });
            return;
        }

        if (!validateStatusCode(data.expectedStatusCode)) {
            toast({
                className: "text-textlight",
                title: "Error",
                description: "Invalid status code format. Use exact codes (e.g., 200), wildcards (e.g., 2XX), patterns (e.g., 2X5), or comma-separated combinations.",
                variant: "destructive",
            });
            return;
        }

        if (data.responseBodyRegex && !validateRegexPattern(data.responseBodyRegex)) {
            toast({
                className: "text-textlight",
                title: "Error",
                description: "Invalid response body pattern. Please provide a valid regular expression.",
                variant: "destructive",
            });
            return;
        }

        setLoading(true);

        const fullMetricBase = `uptime_monitor_${data.metricNameBase}`;
        const monitorData: UptimeMonitor = {
            uuid: initialData?.uuid || "",
            ...data,
            responseTimeMetricName: `${fullMetricBase}_response_time`,
            statusMetricName: `${fullMetricBase}_status`,
        };

        onSubmit(monitorData);
    });

    return (
        <Form {...form}>
            <form onSubmit={handleSubmit} className="space-y-4 max-h-[70vh] overflow-y-auto text-textdark">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                                <Input {...field} placeholder="My Uptime Monitor" className="h-[36px]" />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="endpoint"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Endpoint URL</FormLabel>
                            <FormControl>
                                <Input {...field} placeholder="https://api.example.com/health" className="h-[36px]" />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="method"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>HTTP Method</FormLabel>
                            <Select value={field.value} onValueChange={handleMethodChange}>
                                <SelectTrigger className="w-full border-border text-textmedium rounded">
                                    <SelectValue placeholder="Select HTTP method"/>
                                </SelectTrigger>
                                <SelectContent className={"bg-background text-textmedium"}>
                                    {httpMethods.map((m) => (
                                        <SelectItem key={m} value={m}
                                                  className={"hover:border hover:border-secondary hover:bg-secondarytransparenter hover:cursor-pointer"}>
                                            {m}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="checkFrequencySeconds"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Check Frequency</FormLabel>
                            <Select value={field.value.toString()} onValueChange={(value) => field.onChange(parseInt(value))}>
                                <SelectTrigger className="w-full border-border text-textmedium rounded">
                                    <SelectValue placeholder="Select check frequency"/>
                                </SelectTrigger>
                                <SelectContent className={"bg-background text-textmedium"}>
                                    {frequencies.map((f) => (
                                        <SelectItem key={f.value} value={f.value}
                                                  className={"hover:border hover:border-secondary hover:bg-secondarytransparenter hover:cursor-pointer"}>
                                            {f.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="expectedStatusCode"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Expected Status Code</FormLabel>
                            <FormControl>
                                <Input {...field} placeholder="200, 2XX, 2X5" />
                            </FormControl>
                            <FormDescription>
                                Enter expected status codes. You can use X as a wildcard in any position (e.g., 2XX for any 200 response, X00 for any x00 response, XXX for any status code). Separate multiple patterns with commas.
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div>
                    <label className="text-sm font-medium text-textdark">Headers</label>
                    <div className={"flex flex-col gap-1"}>
                        {Object.entries(headers).map(([key, value], index) => (
                            <div key={`header-${index}`} className="flex gap-2">
                                <Input
                                    key={`header-key-${index}`}
                                    disabled={key === "Content-Type" && (form.getValues("method") === "POST" || form.getValues("method") === "PUT")}
                                    className={"h-[36px]"}
                                    value={key}
                                    onChange={(e) => updateHeader(key, "key", e.target.value)}
                                    placeholder="Header name"
                                />
                                <Input
                                    key={`header-value-${index}`}
                                    className={"h-[36px]"}
                                    value={value}
                                    onChange={(e) => updateHeader(key, "value", e.target.value)}
                                    placeholder="Header value"
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => removeHeader(key)}
                                >
                                    <XIcon className="h-4 w-4 text-textdark hover:cursor-pointer hover:text-primary"/>
                                </Button>
                            </div>
                        ))}
                    </div>
                    <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        onClick={addHeader}
                        className="mt-2 text-textmedium bg-primarytransparent border border-primary hover:text-textlight rounded"
                    >
                        Add Header
                    </Button>
                </div>

                <FormField
                    control={form.control}
                    name="body"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Request Body</FormLabel>
                            <FormControl>
                                <textarea
                                    {...field}
                                    className="w-full p-2 border rounded bg-backgrounddark text-textmedium"
                                    placeholder="Enter request body (if needed)"
                                    rows={4}
                                />
                            </FormControl>
                            <FormDescription>
                                The body to send with the request. Leave empty if no body is needed.
                            </FormDescription>
                        </FormItem>
                    )}
                />

                <div className="flex gap-8">
                    <div className={cn("flex-1", showResponseRegexTester ? "max-w-[50%]" : "w-full")}>
                        <FormField
                            control={form.control}
                            name="responseBodyRegex"
                            rules={{
                                validate: {
                                    validRegex: (value) => 
                                        !value || validateRegexPattern(value) || 
                                        "Invalid regular expression pattern"
                                }
                            }}
                            render={({ field }) => (
                                <FormItem>
                                    <div className="flex justify-between items-center">
                                        <FormLabel>Response Body Pattern (Optional)</FormLabel>
                                        <Button
                                            type="button"
                                            onClick={() => setShowResponseRegexTester(prev => !prev)}
                                            className="bg-primarytransparent border border-primary text-textmedium hover:text-textlight rounded"
                                        >
                                            {showResponseRegexTester ? "Hide Regex Tester" : "Test Regex"}
                                        </Button>
                                    </div>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="^.*$"
                                            className="h-[36px]"
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        Add a regular expression pattern to validate the response body. The request will only succeed if the response body matches this pattern exactly.
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    {showResponseRegexTester && (
                        <RegexTester
                            pattern={form.watch("responseBodyRegex") || ""}
                            onClose={() => setShowResponseRegexTester(false)}
                            testString={responseTestString}
                            setTestString={setResponseTestString}
                            title="Test Response Body Pattern"
                            description="Test your response body regex pattern. The request will only succeed if the response body matches this pattern exactly."
                        />
                    )}
                </div>

                <FormField
                    control={form.control}
                    name="metricNameBase"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Metric Name</FormLabel>
                            <div className="space-y-4 mt-2 p-4 bg-backgrounddark rounded">
                                <div className="flex items-center gap-2">
                                    <div className="text-sm text-textmedium whitespace-nowrap">uptime_monitor_</div>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            className="h-[36px]"
                                            onChange={(e) => field.onChange(e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '_'))}
                                            placeholder="metric_name"
                                        />
                                    </FormControl>
                                </div>
                                <div className="space-y-2">
                                    <p className="text-sm text-textmedium">Generated Metric Names:</p>
                                    <div className="text-sm text-textdark space-y-1">
                                        <code>Response Time: uptime_monitor_{field.value}_response_time</code>
                                        <br />
                                        <code>Status: uptime_monitor_{field.value}_status</code>
                                    </div>
                                </div>
                                <p className="text-xs text-textdark mt-2">
                                    This name will be used to generate the metric names for response time and status. 
                                    It defaults to the monitor name but can be customized if needed.
                                    Only lowercase letters, numbers, and underscores are allowed.
                                </p>
                            </div>
                        </FormItem>
                    )}
                />

                <Button type="submit" disabled={loading}
                        className={"text-textmedium bg-primarytransparent border border-primary hover:text-textlight rounded"}>
                    {loading ? (isEditing ? "Updating..." : "Adding...") : (isEditing ? "Update Uptime Monitor" : "Add Uptime Monitor")}
                </Button>
            </form>
        </Form>
    );
}

export default function UptimeMonitor() {
    const handleSubmit = async (data: UptimeMonitor) => {
        try {
            if (data.uuid) {
                await axios.put(`/api/v1/uptimeMonitor/update?uuid=${encodeURIComponent(data.uuid)}`, data);
                toast({
                    className: "text-textlight",
                    title: "Success",
                    description: "Uptime monitor updated successfully",
                });
            } else {
                await axios.post("/api/v1/uptimeMonitor/create", data);
                toast({
                    className: "text-textlight",
                    title: "Success",
                    description: "Uptime monitor added successfully",
                });
            }
        } catch (e) {
            console.error(e);
            toast({
                className: "text-textlight",
                title: "Error",
                description: `Failed to ${data.uuid ? 'update' : 'add'} uptime monitor`,
                variant: "destructive",
            });
        }
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6 text-textlight">Add Uptime Monitor</h1>
            <div className="bg-backgrounddark rounded-lg p-6">
                <UptimeMonitorForm onSubmit={handleSubmit} />
            </div>
        </div>
    );
} 